import { useCallback, useEffect, useState } from 'react';
import { Button, Table } from "antd";
import moment from "moment";
import { dateFormats, sortOrderMap } from "@ph/common/global/Constants";
import { isRowExpanded, paginationDef } from "../../AdminLabPortal/LabPortalConstants";
import { useOutletContext } from "react-router-dom";
import { handleErrorMsg } from '@ph/common/global/Helpers';
import ServiceDashboardTopSectionContainer from '../ServiceDashboardTopSectionContainer/ServiceDashboardTopSectionContainer';
import UnassignedOrdersForm from './UnassignedOrdersForm';
import { getAllUnassignedKitcode, getOrderUrl, getUnassignedOrders } from '../ServiceDashboardAPIService';
import Components from "@ph/common/components";
import style from "../GenerateKits/GenerateKits.module.scss";
import { ALL_KITS_TYPE } from '../ServiceDashboardConstants';

function UnassignedOrders({ id }) {
    const [refreshSideBarCount, setRefreshSideBarCount, kitTypeList] = useOutletContext();
    const [fetchingUnassignedOrders, setFetchingUnassignedOrders] = useState(true);
    const [unassignedOrders, setUnassignedOrders] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');
    const [kitType, setKitType] = useState('')
    const [kitList,setKitList] =useState([])

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('order_creation_date');

    const { pageSize } = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleUnassignedOrdersTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const getKitTypesTable = (data) => {
        const result = []
        data.map(res => result.push(res.kit_type_id))
        return result.join(",")
    }

    let unassignedOrdersColList = [
        {
            title: 'Order Name',
            key: 'shopify_order_name',
            render: (d) => <a href={getOrderUrl(d.shopify_order_id)} target='_blank' className='text-dark td-underline'>{d.shopify_order_name}</a>
        },
        {
            title: 'Customer Name',
            key: 'purchaser_name',
            render: (d) => `${d?.purchaser_first_name} ${d?.purchaser_last_name}`
        },
        {
            title: 'Order Date',
            dataIndex: 'order_creation_date',
            key: 'order_creation_date',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        },
        {
            title: 'Kit/s to be assigned',
            key: 'line_item_info',
            dataIndex: 'line_item_info',
            render: (d) => getKitTypesTable(d)
        },

        {
            title: 'Action',
            key: 'order_action',
            render: (d) => <Button type="primary" className="ph-btn">ASSIGN</Button>
        }
    ];

    const updateKitcodeList = () => {
        getAllUnassignedKitcode()
            .then(res => {
                const allKits = {}
                const availableKits = res.data
                availableKits.map(kit => {
                    if (allKits[kit.kit_type_id]) {
                        allKits[kit.kit_type_id] = [...allKits[kit.kit_type_id], { value: kit.encrypted_kitcode, label: kit.encrypted_kitcode }]
                    } else {
                        allKits[kit.kit_type_id] = [{ value: kit.encrypted_kitcode, label: kit.encrypted_kitcode }]
                    }
                }
                )
                setKitList(allKits)
            })
            .catch(error => handleErrorMsg(error, "Something went wrong while fetching available kits!"))
    }


    const handleValidationStatusChange = () => {
        fetchValidatedKits(false);
        updateKitcodeList()
        setRefreshSideBarCount(!refreshSideBarCount)
    };

    useEffect(() => {
        fetchValidatedKits();
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy, kitType]);

    useEffect(() => {
        updateKitcodeList()
    },[])

    function fetchValidatedKits(refresh = true) {
        if (refresh){
            setFetchingUnassignedOrders(true);
        }
        closeExpandedRow();
        getUnassignedOrders('unassigned', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            sortOrder,
            sortBy,
            kitType
        }).then(res => {
            if (res?.data?.unassigned_kit_orders) {
                setTotalCount(res?.data.count);
                setUnassignedOrders(res?.data?.unassigned_kit_orders);
            }
        }).catch(err => {
            setUnassignedOrders([]);
        }).finally(() => setFetchingUnassignedOrders(false));
    }

    function expandedRowRenderer(record) {
        return <UnassignedOrdersForm key={record.order_id} record={record}
            closeClickHandler={closeExpandedRow}
            onValidationStatusChanged={handleValidationStatusChange} kitList={kitList} />;
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function rowOnExpandHandler(expanded, { key }) {
        setExpandedRowKey(expanded ? key : '');
    }

    function onRowHandler({ key }) {
        return (isRowExpanded(key, expandedRowKey) &&
            { className: "expanded-row" });
    }

    const selectOnChangeHandler = useCallback((value) => {
        setCurrentPage(1);
        setKitType(value)
    }, [])

    return (
        <>
            <ServiceDashboardTopSectionContainer
                timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter}
                searchPlaceholder="Search Order ID or Name"
                bottomRightContent={<div className={style.filterContainer} >
                    <Components.CustomSelect
                        options={[ALL_KITS_TYPE, ...kitTypeList]}
                        selected={kitType}
                        showSearch={false}
                        onChangeHandler={selectOnChangeHandler}
                    />
                </div>}
            />

            <Table
                loading={fetchingUnassignedOrders}
                columns={unassignedOrdersColList}
                dataSource={unassignedOrders}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleUnassignedOrdersTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderer,
                    expandIcon: () => ''
                }}
            />

        </>
    );
}

export default UnassignedOrders;