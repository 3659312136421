import React, {useState} from "react";
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import AdminComponent from '@ph/admin/src/component';
import { Layout, Modal, Spin } from 'antd';
import LabPortalSidebar from '@ph/admin/src/component/global/Sidebars/LabPortalSidebar';
import ScanToReceiveModalContent from "../component/AdminLabPortal/ScanToReceiveModalContent/ScanToReceiveModalContent";
import {close_icon_dark} from "@ph/common/assets";
import './App.scss';
import { globalSearchKit } from "src/component/AdminLabPortal/AdminPortalAPIService";
import { GLOBAL_SEARCH_INIT, GLOBAL_SEARCH_NAV } from "@ph/common/global/Constants";
import { LAB_PORTAL_SIDE_NAV } from "src/utils/nav-menu-items";
import { handleErrorMsg } from "@ph/common/global/Helpers";

const { Content } = Layout;

const LabPortalLayout = () => {
    const [isScanToReceiveModalOpen, setIsScanToReceiveModalOpen] = useState(false);
    const [refreshList, setRefreshList] = useState(false);
    const [refreshSideBarCount, setRefreshSideBarCount] = useState(false);
    const [kitGotReceived, setKitGotReceived] = useState(false);
    const currentLocation = useLocation();
    const pathName = currentLocation.pathname.replace("/", "");
    const [currNav, setCurrentNav] = useState(pathName);
    const [globalSearch, setGlobalSearch] = useState(GLOBAL_SEARCH_INIT)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const scanKitClickHandler = () => {
        setIsScanToReceiveModalOpen(true);
    };

    const closeScanKitModal = () => {
        setIsScanToReceiveModalOpen(false);
        if (kitGotReceived) {
            setRefreshList(!refreshList);
            setRefreshSideBarCount(!refreshSideBarCount);
            setKitGotReceived(false);
        }
    };

    const searchKitHandler = (kitCode) => {
        if (kitCode) {
            setLoading(true)
            const trimmedKitCode = kitCode.replaceAll(/[\s-_]/g, '');
            globalSearchKit(trimmedKitCode)
            .then(res => {
                if (res?.data) {
                    const kitState = GLOBAL_SEARCH_NAV[res.data.current_stage]
                    let menuItem = [...LAB_PORTAL_SIDE_NAV['Kit Accession'], ...LAB_PORTAL_SIDE_NAV['History']].filter((el) => el.name === kitState)
                    if (menuItem.length > 0) {
                        const selectedMenu = menuItem[0]
                        setCurrentNav(selectedMenu.key);
                        localStorage.setItem('currentNavLabel', selectedMenu.name);
                        setGlobalSearch({
                            GSNav: selectedMenu.name,
                            GSKitCode: kitCode,
                            GSKitResponse: res.data.response_data
                        })
                        navigate(selectedMenu.key)
                    }
                    setRefreshSideBarCount(!refreshSideBarCount);
                }
            })
            .catch(error => handleErrorMsg(error, "Something went wrong,Please enter valid kit code!"))
            .finally(() => setLoading(false))
        }
    }

    return (
        <>
            <AdminComponent.AdminHeader title="Lab Operations Portal"
                searchKit={true} scanKit={true} headerNav={false}
                scanKitClickHandler={scanKitClickHandler} searchKitHandler={searchKitHandler} />
            <section className="section">
                <div className='container' style={{padding: 0}}>
                    <Spin
                        spinning={loading}
                        size="large"
                        style={{ marginTop: '150px', textAlign: 'center', position: 'absolute', zIndex: '999' }}
                    />
                    <Layout>
                        <LabPortalSidebar {...{ refreshSideBarCount, setRefreshSideBarCount, currNav, setCurrentNav }} />
                        <Layout>
                            <Content className='admin-portal-main-content-wrapper'>
                                <Outlet
                                    context={[refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch]} />
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </section>

            <Modal open={isScanToReceiveModalOpen} onCancel={closeScanKitModal} destroyOnClose={true}
                   width='95%' footer={null} closeIcon={<img src={close_icon_dark} alt='close icon'/>}
                   style={{top: 20}}>
                <ScanToReceiveModalContent setKitGotReceived={setKitGotReceived}/>
            </Modal>
        </>
    );
};

export default LabPortalLayout;
