import React, { useEffect, useState } from 'react'
import { Col, Row, Divider } from 'antd'
import style from './MiniProtocol.module.scss'
import { md03_banner } from '@ph/common/assets';
import PhButton from '../../Global/PhButton/PhButton';
import { SHOPIFY_PAGES } from '@ph/common/constants/ShopifyPages';
import { buildCompleteShopifyLink } from '@ph/common/global/Helpers';
import { PROTOCOL_FETCH } from '@ph/subject/src/api_services/Protocol/protocolFetch';
import { MINI_PROTOCOL_PRODUCTS } from '@ph/common/constants/Protocol/MD03Protocol';

function MiniProtocol() {
    const [total, setTotal] = useState(0)
    return (
        <div className='bg-uv-blue'>
            <div className={`${style.miniProtocolContainer} section`}>
                <div className={style.titleContainer}>
                    <h3 className='text-light'>
                        Start your <br />
                        <span className='font-Neue-medium'>MD-03 Phage Protocol™</span>
                    </h3>
                </div>
                <Row gutter={{ xs: 8, sm: 16, md: 32 }}>
                    <Col className={style.hideDesktop} xs={24}>
                        <img src={md03_banner} className={style.banner} />
                    </Col>
                    <Col className="gutter-row" xs={24} md={14}>
                        {MINI_PROTOCOL_PRODUCTS.map(product => (
                            <Product product={product} setTotal={setTotal} />
                        ))}
                        <div className={style.totalcontainer}>
                            <p className={style.totalText}>Total</p>
                            <p className={style.totalPrice}>{`$${total}/mo`}</p>
                        </div>
                    </Col>
                    <Col className={style.hideMobile} xs={24} md={9} offset={1}>
                        <img src={md03_banner} className={style.banner} />
                    </Col>
                <Col xs={24} className={style.buttonContainer}>
                    <PhButton type="secondary" text={'Start Your Protocol'} onClick={() => window.open(`${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`, '_self')} />
                </Col>
            </Row>
        </div>
        </div>
    )
}

export default MiniProtocol

const Product = ({ product, setTotal }) => {
    const [productData, setProductData] = useState(product)

    useEffect(() => {
        if (product?.fetch_id) {
            PROTOCOL_FETCH[product?.fetch_id].onFetch().then(res => {
                const updatedProduct = {
                    ...product,
                    ...res
                }
                if (updatedProduct?.product_price) {
                    setTotal(parseInt(updatedProduct?.product_price))
                }
                setProductData(updatedProduct)
            })
                .catch(error => console.error(error))
        } else {
            setProductData(product)
        }
    }, [])
    return (
        <Row gutter={16} className={style.miniProduct}>
            <Col xs={24} md={20} className={style.title}>
                {productData?.product_title}
            </Col>
            <Col xs={24} md={4} className={`${style.priceContainer} ${style.hideMobile}`}>
                <span className={style.discountPrice}>{productData?.product_discount_price}</span>
                <span className={style.price}>{productData?.product_price ? `$${parseInt(productData?.product_price)}/mo` : 'Free'}</span>
            </Col>
            <Col xs={20} md={18} className={style.description}>
                {productData?.product_description}
            </Col>
            <Col xs={24} md={4} className={`${style.priceContainer} ${style.hideDesktop}`}>
                <span className={style.discountPrice}>{productData?.product_discount_price}</span>
                <span className={style.price}>{productData?.product_price ? `$${parseInt(productData?.product_price)}/mo` : 'Free'}</span>
            </Col>
            <Col xs={24}>
                <Divider className={style.divider} />
            </Col>

        </Row >
    )
}