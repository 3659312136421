import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table, Typography, List } from "antd";
import moment from "moment";
import { dateFormats, sortOrderMap } from "@ph/common/global/Constants";
import { CheckColum, isRowExpanded, paginationDef } from "../../AdminLabPortal/LabPortalConstants";
import ServiceDashboardTopSectionContainer from '../ServiceDashboardTopSectionContainer/ServiceDashboardTopSectionContainer';
import OrderedKitsForm from './OrderedKitsForm';
import { getOrderUrl, getOrderedKits } from '../ServiceDashboardAPIService';
import Components from "@ph/common/components";
import style from "../GenerateKits/GenerateKits.module.scss";
import { getFriendlyKitCode } from '@ph/common/global/Helpers';
import { ALL_KITS_TYPE, ALL_KIT_STATUS } from '../ServiceDashboardConstants';
import { useOutletContext } from "react-router-dom";
import UpdateOrderUser from './UpdateOrderUser';


const { Text } = Typography;

function OrderedKits({ id }) {
    const [refreshSideBarCount, setRefreshSideBarCount, kitTypeList] = useOutletContext();
    const [fetchingOrderedKits, setFetchingOrderedKits] = useState(true);
    const [updateUserModal, setUpdateUserModel] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [orderedKits, setOrderedKits] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');
    const [kitType, setKitType] = useState('')
    const [kitStatus, setKitStatus] = useState('')


    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('generated_on');

    const { pageSize } = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleOrderedKitsTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const handleAssignButtonClick = (event, record) => {
        setSelectedRecord(record)
        setUpdateUserModel(true)
        event.stopPropagation();
    }

    let ordersKitsColList = [

        {
            title: 'Kit Code',
            key: 'kitcode',
            render: (d) => <List.Item key={d?.kitcode}>
                <List.Item.Meta
                    title={<Typography className={`body-txt-md ${d?.remark ? "text-error" : ' '}`}>{getFriendlyKitCode(d?.kitcode)}</Typography>}
                    description={<Typography className={`${d?.remark ? "text-error" : ' '}`}>{d?.kit_type_name}</Typography>}
                />
            </List.Item>

        },
        {
            title: 'Kit Status & Date',
            key: 'kit_status_date',
            render: (d) => <List.Item>
                <List.Item.Meta
                    title={<Typography className={`body-txt-md ${d?.remark ? "text-error" : ' '}`}>{d?.latest_kit_status}</Typography>}
                    description={<Typography className={`${d?.remark ? "text-error" : ' '}`}>{d.latest_kit_status_datetime ? moment(d.latest_kit_status_datetime).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"}</Typography>}
                />
            </List.Item>
        },

        {
            title: 'Assigned To',
            key: 'purchaser_name',
            render: (d) => d?.assigned_to_first_name && d?.assigned_to_email ? <List.Item onClick={(event) => handleAssignButtonClick(event, d)} className="hand-cursor">
                <List.Item.Meta
                    title={<Typography className={`body-txt-md td-underline ${d?.remark ? "text-error" : ' '}`} >{`${d?.assigned_to_first_name ?? ''} ${d?.assigned_to_last_name ?? '-'}`}</Typography>}
                    description={<Typography className={`td-underline ${d?.remark ? "text-error" : ' '}`}>{d?.assigned_to_email ?? ""}</Typography>}

                />
            </List.Item>
                : <Button className="ph-btn transparent" onClick={(event) => handleAssignButtonClick(event, d)}>ASSIGN</Button>

        },
        {
            title: 'Order Name',
            key: 'shopify_order_name',
            render: (d) => <a href={getOrderUrl(d.shopify_order_id)} target='_blank' className={` ${d?.remark ? "text-error" : 'text-dark'} td-underline`}>{d.shopify_order_name}</a>
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            render: (d) => d ? d : "-"
        },
        {
            title: 'Action',
            key: 'order_action',
            render: (d) => <Button type="primary" className="ph-btn">Manage</Button>
        }
    ];

    const handleValidationStatusChange = () => {
        fetchOrderedKits(false);
        setRefreshSideBarCount(!refreshSideBarCount)
    };

    useEffect(() => {
        fetchOrderedKits();
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy, kitType, kitStatus]);

    function fetchOrderedKits(refresh = true) {
        if (refresh) {
            setFetchingOrderedKits(true);
        }
        closeExpandedRow();
        getOrderedKits('inventory', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            kitType,
            kitStatus
        }).then(res => {
            if (res?.data?.kit_code_inventorty_list) {
                setTotalCount(res?.data.count);
                setOrderedKits(res?.data?.kit_code_inventorty_list);
            }
        }).catch(err => {
            setOrderedKits([]);
        }).finally(() => setFetchingOrderedKits(false));
    }

    function expandedRowRenderer(record) {
        return <OrderedKitsForm key={record.kitcode} record={record}
            closeClickHandler={closeExpandedRow}
            onValidationStatusChanged={handleValidationStatusChange} />;
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function rowOnExpandHandler(expanded, { key }) {
        setExpandedRowKey(expanded ? key : '');
    }

    function onRowHandler({ key }) {
        return (isRowExpanded(key, expandedRowKey) &&
            { className: "expanded-row" });
    }

    const selectOnChangeHandler = useCallback((value) => {
        setCurrentPage(1);
        setKitType(value)
    }, [])

    const selectOnKitStatusHandler = useCallback((value) => {
        setCurrentPage(1);
        setKitStatus(value)
    }, [])

    return (
        <>
            <ServiceDashboardTopSectionContainer
                timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter}
                searchPlaceholder='Search Kit Code or Name'
                bottomRightContent={
                    <>
                        <div className={style.filterContainer} >
                            <Components.CustomSelect
                                options={ALL_KIT_STATUS}
                                selected={kitStatus}
                                showSearch={false}
                                onChangeHandler={selectOnKitStatusHandler}
                            />
                        </div>
                        <div className={style.filterContainer} >
                            <Components.CustomSelect
                                options={[ALL_KITS_TYPE, ...kitTypeList]}
                                selected={kitType}
                                showSearch={false}
                                onChangeHandler={selectOnChangeHandler}
                            />
                        </div>
                    </>
                }
            />

            <Table
                loading={fetchingOrderedKits}
                columns={ordersKitsColList}
                dataSource={orderedKits}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleOrderedKitsTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderer,
                    expandIcon: () => ''
                }}
            />
            <UpdateOrderUser
                modalTitle='Assign User'
                open={updateUserModal}
                closeModal={() => setUpdateUserModel(false)}
                selectedRecord={selectedRecord}
                onCallback={handleValidationStatusChange}
                assignFlag={true}
                initCreateNew={true}
            />
        </>
    );
}

export default OrderedKits;