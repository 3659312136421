import React from 'react';
import './styles.scss';

function PHHex({ size = "200px",
    baseColor = "white",
    gradientColor = "blue" }) {
    const styles = {
        '--size': size,
        '--base-color': baseColor,
        '--gradient-color': gradientColor,
    };

    return (
        <div className="hexagon-container" style={styles}>
            <div className="hexagon"></div>
        </div>
    );
};

export default PHHex;