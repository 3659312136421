import React from 'react';
import style from './PhCard.module.scss';
import {Typography} from "antd";

const { Text } = Typography;

const PhCard = ({
                    children,
                    title = '',
                    titleClass = '',
                    cardClass = '',
                    headerClass = '',
                    cardBodyClass = '',
                    headerIcon = '',
                    isStoneHead = false,
                    isSandHead = false,
                    isDarkBackGround = false,
                    showHeaderIcon = false,
                    disableHeader = false
}) => {
    return (
        <div className={`${style.box} ${cardClass} ${(isDarkBackGround) ? style.darkBackGround : ''}`}>
            { !disableHeader && (
            <div className={`${style.headingContainer} ${(isStoneHead) ? style.stone : ''} ${(isSandHead) ? style.sand : ''} ${headerClass}`}>
                <Text className={`${style.heading} ${titleClass}`}>{title}</Text>
                <span className={`${style.iconContainer} ${(showHeaderIcon) ? '' : 'hide'}`}>
                    {headerIcon}
                </span>
            </div>
            )}
            <div className={`${style.bodyContainer} ${cardBodyClass}`}>
                {children}
            </div>
        </div>
    );
}

export default PhCard;