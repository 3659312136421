import React, {useEffect, useState} from 'react';
import {convertToURIPath} from "@ph/common/global/Helpers";
import {useLocation, useNavigate} from "react-router-dom";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";
import queryString from "query-string";

function PhysicianPrescriptionFilled() {
    let navigate = useNavigate();
    let location = useLocation();

    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const navigateToPhysicianPortal = () => {
        navigate(`/${convertToURIPath(localStorage.getItem('currentNavLabel'), 'physician-portal')}`);
    }

    useEffect(() => {
        const values = queryString.parse(location.search);
        if (values && values.status && values.status === 'success') {
            localStorage.setItem('savedPrescriptionState', 'prescribed');
            navigateToPhysicianPortal();
        } else {
            setShowErrorMessage(true);
        }
    }, []);
    return (
        <>
            {showErrorMessage ?
                <div>Failed to provide the prescription! <span className='clickable-link'
                                                               onClick={navigateToPhysicianPortal}>Click here</span> to
                    try again</div>
                : <FullPageLoader/>}
        </>
    );
}

export default PhysicianPrescriptionFilled;