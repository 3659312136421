import React, {useState} from 'react';
import style from "../Report.module.scss";
import {Tooltip} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {PH_constalation} from "@ph/common/assets";

function SkinMicrobiomeTypeCard({Text, Paragraph, reportData}) {
    const [showFullDescription, setShowFullDescription] = useState(false);

    const constellationWordCount = 99;
    const skinMicrobiomeTypeTooltip = "Your skin microbiome type is determined by a variety of factors, including your hydration level, skin microbial diversity and bacterial overgrowth. It can alter due to environmental factors, such as weather, location, and who you live with. It likely changes twice a year on average and can change more often if your environment or lifestyle is altered.";

    const showFullDescriptionHandler = (showOrHide) => {
        if(typeof showOrHide === 'boolean') {
            setShowFullDescription(showOrHide);
            return ;
        }
        setShowFullDescription(!showFullDescription);
    };

    const getSkinTypeDesc = (skinTypeDescription) => {
        let hasShortDesc = (skinTypeDescription.length <= constellationWordCount);
        if(hasShortDesc || showFullDescription) {
            return <Paragraph className={`${style.descriptionLight}`}>
                {skinTypeDescription}
                {!hasShortDesc && <>
                    <div className={`ant-typography-expand note-1 ${style.toggleExpand}`}
                          onClick={() => showFullDescriptionHandler(false)}>READ LESS</div>
                </>}
            </Paragraph>;
        }
        return <div className={`${style.bodyText2}`}>
            <Paragraph className={`${style.descriptionLight}`}
                       ellipsis={{rows:2}}>
                {skinTypeDescription}
            </Paragraph>
            <div className={`ant-typography-expand note-1 ${style.toggleExpand}`}
                  onClick={() => showFullDescriptionHandler(true)}>READ MORE</div>
        </div>;
    }

    return (
        <div className={`${style.tile1} ${style.skinMBTypeCard}`}>
            <Text className="h4" style={{ color: '#fff', paddingRight: '30px' }}>Your Skin Microbiome <strong>TYPE</strong></Text>
            <Tooltip title={skinMicrobiomeTypeTooltip}
                     color={"#2d2a26"}
                     placement="topRight"
                     autoAdjustOverflow
                overlayInnerStyle={{ color: '#fff', padding: '16px' }} trigger="click"
                     className={style.showInSmallScreen}
            >
                <ExclamationCircleOutlined className={style.positionedIcon} />
            </Tooltip>
            <Tooltip title={skinMicrobiomeTypeTooltip}
                     color={"#2d2a26"}
                     autoAdjustOverflow
                overlayInnerStyle={{ color: '#fff', padding: '16px' }} trigger="hover"
                     className={style.showInBigScreen}
            >
                <ExclamationCircleOutlined className={style.positionedIcon} />
            </Tooltip>
            <div className={style.tileContent}>
                <img src={reportData.constellation_image_url ? process.env.REACT_APP_REDIRECT_URI + "/" + reportData.constellation_image_url : PH_constalation}
                    style={{ border: '1px solid #fff' }}
                     className={style.tileImage} alt='' />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px' }}>
                    <Paragraph style={{ color: '#fff', textTransform: 'uppercase' }}
                               ellipsis={{ rows: 2, tooltip: {overlayClassName: 'tooltip-dark'} }}
                               className={style.bodyText1}>
                        Primary Constellation<br/>
                        <span style={{ fontFamily: 'Medium' }}>
                            {`${reportData.skin_type_name}: ${reportData.constellation}`}
                        </span>
                    </Paragraph>
                    <div className={style.descContainer}>
                        {reportData.skin_type_description && getSkinTypeDesc(reportData.skin_type_description)}
                    </div>
                    <div className={style.skinTypePercentContainer}>
                        <div className={style.skinTypePercent}>
                            {(typeof reportData.skin_type_percentage === 'number') ?
                                `${reportData.skin_type_percentage}%` : 'N/A' }
                        </div>
                        <span className={`${style.bodyText2} ${style.skinTypePercentDesc} font-Mono-regular`}>
                            Percentage of people who share this type
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SkinMicrobiomeTypeCard;