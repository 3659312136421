import React from 'react';
import {Col} from "antd";
import style from "./kitResult.module.scss";
import Components from "@ph/common/components";
import {getProductDescription} from "@ph/common/global/Helpers";

function SecondaryAndOtherRecommendations({recommendedProds, fetchingReportData}) {
    if(recommendedProds.length < 2) return '';
    return (recommendedProds.slice(1).map((productItem, i) => (
            <Col xs={{span: 24, order: 3}} sm={{span: 24, order: 3}} md={{span: 24, order: 3}}
                 xl={{span: 12, order: 3}} key={i}
                 className={`${(i % 2 === 0) ? style.colLeft : style.colRight} ${style.reportSubRow}`}>
                <Components.PhMediaCard
                    imgLoading={fetchingReportData}
                    imgSrc={productItem.image_url}
                    tagPill={(i === 0) ? {className: '', text: 'SECONDARY'} : {className: 'hide'}}
                    cardTitle={productItem.name}
                    cardDescription={getProductDescription(recommendedProds[0])}
                    buttonText='SUBSCRIBE'
                    navigateTo={{path: productItem.shopify_product_url, openInNewTab: true}}
                />
            </Col>
        )));
}

export default SecondaryAndOtherRecommendations;