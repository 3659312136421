import { createSlice } from "@reduxjs/toolkit";

const userDataInitVal = {
    customer_id: "",
    email: "",
    first_name: "",
    last_name: "",
    subject_id: ""
};

const userDataSlice = createSlice({
    name: "userData",
    initialState: userDataInitVal,
    reducers: {
        updateUserData(state, action) {
            if (action.payload) {
                state.userData = action.payload;
            }
        }
    },
});

export const userDataActions = userDataSlice.actions;

export default userDataSlice;
