import { createSlice } from "@reduxjs/toolkit";

const adminUserDataInitVal = {
};

const adminUserDataSlice = createSlice({
    name: "adminUserData",
    initialState: adminUserDataInitVal,
    reducers: {
        updateAdminUserData(state, action) {
            if (action.payload) {
                state.adminUserData = action.payload;
            }
        }
    },
});

export const adminUserDataActions = adminUserDataSlice.actions;

export default adminUserDataSlice;
