import React from 'react';
import { Col, Collapse, Row, Typography } from "antd";
import style from "./reportv1.module.scss";
import { Pie } from "react-chartjs-2";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const { Text } = Typography;


function BacteriaInfo({ bacteriaInfoList, buttonColor, bgDescription, expandIconPosition, headerClass, collapseClass }) {
    return (
        <div className='full-width'>
            <Collapse bordered={false} defaultActiveKey={['0']}
                accordion={true} expandIconPosition={expandIconPosition}
                className={`${style.collpaseItems} ${collapseClass}`}
                expandIcon={({ isActive }) =>
                    isActive ?
                        <MinusOutlined style={{ fontSize: '18px', color: buttonColor }} /> :
                        <PlusOutlined style={{ fontSize: '18px', color: buttonColor }} />
                }>
                {bacteriaInfoList.map((v, i) => {
                    return v &&
                        <Panel key={i} style={{ border: 'none' }}
                            header={
                                <div className={`${style.headerContainer} ${headerClass}`} >
                                    {/* <div style={{ background: 'transperant' }}
                                        className={style.positionedHeaderBlock}>
                                        {Number(v.percentage) < 1 ? '<1' :
                                            (Number(v.percentage) > 99 ? '>99' :
                                                Number(v.percentage))}%
                                    </div> */}
                                    <Text className={style.heading}>
                                        {v.bacteria_name}
                                    </Text>
                                </div>
                            }>
                            <div style={{ backgroundColor: bgDescription }} className={style.description}>
                                <div className={style.text}>
                                    {v.description ? v.description : v.bacteria_name}
                                </div>
                            </div>
                        </Panel>;
                })}
            </Collapse>
        </div>
    )
}

export default BacteriaInfo