import React from 'react'
import { Button } from 'antd'
import { button_arrow, button_arrow_black } from '@ph/common/assets'

function PhButton({
    text = '',
    onClick,
    arrow = true,
    type = 'primary',
    className = '',
    ...props
}) {
    const handleButtonClick = () => {
        if (typeof onClick === "function") {
            onClick()
        }
    }
    return (
        <Button type={type} className={className} onClick={handleButtonClick} {...props}>
            {text}
            {arrow && (
                <img src={type === 'primary' ? button_arrow : button_arrow_black} className='ph-btn-arrow' />
            )}
        </Button>
    )
}

export default PhButton