import { useState } from 'react';
import style from './ReportContainer.module.scss';
import { close_icon_dark } from '@ph/common/assets/index';
import { Modal, Button } from "antd";

const MODAL_INFO = {
    title: 'Welcome!',
    subTitle: <><span className="font-Neue-medium">You're upgrading to the MD-03 Phage Protocol™.</span> <br />Enjoy exclusive discounted pricing, 24/7 access to Microbiome Dermatology™ doctors, and skin microbiome testing twice a year.</>
}

function MD03UpgradeModal({ openModal, setOpenModal, title, subTitle, cancelText, successText, onCancel, onSuccess }) {
    return (
        <Modal className={style.upgradeModal}
            open={openModal}
            closable={true}
            closeIcon={<img src={close_icon_dark} alt='x' />}
            footer={null} onCancel={() => setOpenModal(false)}
            style={{
                top: '20%',
            }}
        >
            <div>
                <div className={style.modalTitle}>
                    {title}
                </div>
                <div className={style.modalSubtitle}>
                    {subTitle}
                </div>

                <div className={style.buttonContainer}>
                    {cancelText && onCancel && (
                        <Button
                            onClick={onCancel}
                            className={style.cancelButton}
                        >
                            {cancelText}
                        </Button>
                    )}
                    {successText && onSuccess && (
                        <Button
                            onClick={onSuccess}
                            className={style.successButton}
                        >
                            {successText}
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default MD03UpgradeModal