import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Row, Table, Typography} from "antd";
import style from './ScanToReceiveModalContent.module.scss';
import OtpInput from "react18-input-otp";
import {receiveKit} from "../AdminPortalAPIService";
import moment from "moment/moment";
import {dateFormats} from "@ph/common/global/Constants";
import {no_logs} from "@ph/common/assets";
import ConfirmationModal from '../ValidateKitFormContent/ConfirmationModal';

const { Text } = Typography;

const UNREGISTER_MSG = <>
    The kit code you are trying to receive is <span className='medium-text'>unregistered.</span><br /> Do you still want to receive the kit?
</>
const UNREGISTER_ERROR = "No Registered Kitcode Found"

function ScanToReceiveModalContent({setKitGotReceived}) {
    const [code, setCode] = useState("");
    const [hideReceiveBtn, setHideReceiveBtn] = useState(true);
    const [receivingKit, setReceivingKit] = useState(false);
    const [receivedKitData, setReceivedKitData] = useState([]);
    const [receiveKitError, setReceiveKitError] = useState("");
    const [allReceivedKits, setAllReceivedKits] = useState([]);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const receivedKitColumns = [{
        title: 'Kit Type',
        dataIndex: 'kit_type',
        key: 'kit_type',
    },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        }
    ];
    const kitListTableColumns = [].concat([{
        title: 'Kit Code',
        dataIndex: 'kit_code',
        key: 'kit_code',
    }], receivedKitColumns);

    const handleInputChange = (input) => {
        setCode(input?.toUpperCase());
        setHideReceiveBtn(input.toString().length !== 7);
    };

    const receiveKitClickHandler = (event, skipRegistration = false) => {
        setReceivedKitData([]);
        setReceivingKit(true);
        setReceiveKitError("");
        if (skipRegistration)
            setOpenConfirmationModal(false)
        receiveKit(code, { skip_registration: skipRegistration }).then(res => {
            if (res?.data) {
                let {data} = res;
                data.kit_code = code;
                data.key = '' + code;
                setReceivedKitData([data]);
                let consolidatedReceivedKits = [...allReceivedKits];
                consolidatedReceivedKits.push(res.data);
                setAllReceivedKits(consolidatedReceivedKits);
                setKitGotReceived(true);
            } else {
                setReceivedKitData([]);
                setReceiveKitError("Oops! An error occurred. Try again!");
            }
        }).catch(err => {
            setReceivedKitData([]);
            setReceiveKitError(err.response.data.message);
            if (!skipRegistration && err.response.data.message === UNREGISTER_ERROR)
                setOpenConfirmationModal(true)
            console.log(err);
        }).finally(() => setReceivingKit(false));
    };

    useEffect(() => {
        setCode("");
        setHideReceiveBtn(true);
        setReceivingKit(false);
        setReceiveKitError("");
        setAllReceivedKits([]);
    }, [])

    return (
        <>
            <div className={style.headerContainer}>
                <div>
                    <Text className='h1'>Scan to Receive</Text>
                </div>
                <Text className='body-txt-lg'>
                    Please make sure that your device is connected with the system.
                </Text>
            </div>
            <Divider className='no-margin' />
            <Row className={style.kitScanFormContainer}>
                <Col span={16} offset={2}>
                    <label className={`body-txt-md`}>Scan or enter the kit codes to be received</label>                        
                    <OtpInput
                        value={code}
                        onChange={handleInputChange}
                        numInputs={7}
                        separator={<span className='input-separator'></span>}
                        isInputNum={false}
                        className='otp-input'
                        shouldAutoFocus={true}
                        focusStyle={{
                            border: "1px solid #CFD3DB",
                            outline: "none"
                        }}
                    />
                    <div className={style.receiveKitResultContainer}>
                        {receiveKitError ?
                            <Text className='text-error body-txt-lg'>{receiveKitError}</Text> :
                            <div className={(!receivedKitData.length && !receivingKit) ? 'invisible' : ''}>
                                <Table loading={receivingKit}
                                    columns={receivedKitColumns}
                                    dataSource={receivedKitData}
                                    pagination={false}
                                    className='lab-portal-table' />
                            </div>}
                    </div>


                </Col>
                <Col span={3} >
                    <div className={`${style.receiveBtnContainer}`}>
                        <Button type="primary" className={hideReceiveBtn && 'hide'}
                            onClick={receiveKitClickHandler} disabled={receivingKit}>
                            <Text className='body-txt-lg text-light'>RECEIVE</Text>
                        </Button>
                    </div>
                </Col>
            </Row>
            <Divider className='no-margin' />
            <p>
                <Text className='h3'>Scanned & Received Log(s): </Text> &nbsp;
                <Text className='body-txt-lg'>{allReceivedKits.length} Kits</Text>
            </p>
            <p>
                <Text className='body-txt-lg'>
                    Upon closing this window, the scanned & received  logs will be listed in the ‘Received’ Tab under ‘Kit Intake’
                </Text>
            </p>
            <Row>
                <Col span={12} offset={6} className='text-center'>
                    <div className={style.allReceivedKitsSummaryContainer}>
                        {allReceivedKits.length ?
                            <>
                                <Table dataSource={allReceivedKits}
                                       columns={kitListTableColumns}
                                       pagination={false} loading={receivingKit}
                                       className='lab-portal-table'/>
                            </> :
                            <>
                                <img src={no_logs} alt='no logs'/> <Text className='h2'>No Scanned Logs</Text>
                            </>
                        }
                    </div>
                </Col>
                <Col span={6}></Col>
            </Row>
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                closeConfirmationModal={() => setOpenConfirmationModal(false)}
                confirmationMsg={UNREGISTER_MSG}
                cancelBtnText={"CANCEL"}
                cancelBtnAction={() => setOpenConfirmationModal(false)}
                proceedBtnText={"YES, RECEIVE"}
                proceedBtnAction={(event) => receiveKitClickHandler(event, true)}
                proceedBtnClass='ph-btn-success'
                isConfirmationInProgress={false}
                closable={false}
                modalTop={'30%'}
            />
        </>
    );
}

export default ScanToReceiveModalContent;