import { Outlet } from "react-router-dom";
import "./App.scss";

const ReportLayout = () => {
    return (
        <>
            {/* <AdminComponent.AdminHeader /> */}
            <section className="section">
                <div className={"reportContainer"}>
                    <Outlet />
                </div>
            </section>
        </>
    );
};

export default ReportLayout;