import React, {useEffect, useState} from 'react';
import {Table, Typography} from "antd";
import moment from "moment";
import { getKitsList, updateReportStatus } from "../AdminPortalAPIService";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import { dateFormats, sortOrderMap } from "@ph/common/global/Constants";
import { getFriendlyKitCode, handleErrorMsg } from "@ph/common/global/Helpers";
import { CheckColum, isRowExpanded, paginationDef, updateGlobalSearchStates } from "../LabPortalConstants";
import ValidateKitFormContent from "../ValidateKitFormContent/ValidateKitFormContent";
import { useOutletContext } from "react-router-dom";

const { Text } = Typography;

function CompletedKits({ id }) {
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch] = useOutletContext();
    const [fetchingCompletedKits, setFetchingCompletedKits] = useState(true);
    const [completedKits, setCompletedKits] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('result_date');

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleCompletedKitsTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const handlePublishOn = async (record) => {
        if (record?.kitcode) {
            updateReportStatus({ kitcode: record?.kitcode })
                .then(res => {
                    getCompletedKitsData()
                })
                .catch(error => {
                    handleErrorMsg(error, "Unable to publish report or resend email")
                })
        }
    }

    const completedKitsColList = [
        {
            title: 'Kit Code',
            dataIndex: 'kitcode',
            key: 'kitcode',
            render: (kitCode) => getFriendlyKitCode(kitCode)
        },
        {
            title: 'Kit Type',
            dataIndex: 'kit_type',
            key: 'kit_type',
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        },
        {
            title: 'Hydration Strip',
            dataIndex: 'oil_strip_image_urls',
            key: 'oil_strip_image_urls',
            render: (d) => <CheckColum valid={d && d?.Oil_Strip} />
        },
        {
            title: 'Date of Receipt',
            dataIndex: 'received_on',
            key: 'received_on',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        },
        {
            title: 'Batch Code',
            dataIndex: 'batch_code',
            key: 'batch_code'
        },
        {
            title: 'Date of Completion',
            dataIndex: 'result_date',
            key: 'result_date',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => {
                return (<div className='clearfix'>
                    <div className='fl'>{moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS)}</div>
                </div>)
            }
        },
        {
            title: 'Report Published On',
            dataIndex: 'is_approved_at',
            key: 'is_approved_at',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => {
                return (<div className='clearfix'>
                    <div className='fl'>{d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : '-'}</div>
                    <div className='fr'>
                        <Text className='body-txt-md clickable-link visible-on-hover'>View Details</Text>
                    </div>
                </div>)
            }
        }
    ];

    function expandedRowRenderer(record) {
        return <ValidateKitFormContent key={record.kit_accession_id} record={record} isReadOnly={true}
            closeClickHandler={closeExpandedRow} onPublish={() => handlePublishOn(record)} />;
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function rowOnExpandHandler(expanded, {key}) {
        setExpandedRowKey(expanded ? key : '');
    }

    function onRowHandler({key}) {
        return (isRowExpanded(key, expandedRowKey) &&
            {className: "expanded-row"});
    }

    const getCompletedKitsData = () => {
        setFetchingCompletedKits(true);
        closeExpandedRow();
        getKitsList('completed', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            sortOrder,
            sortBy
        }).then(res => {
            if (res?.data?.kits_info_list) {
                setTotalCount(res.data.count);
                setCompletedKits(res.data.kits_info_list);
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => setFetchingCompletedKits(false));
    }
    useEffect(() => {
        // if (id === localStorage.getItem("currentNavLabel") && globalSearch.GSKitCode) {
        //     setKitCodeFilter(globalSearch.GSKitCode)
        //     setCompletedKits(globalSearch.GSKitResponse?.kits_info_list)
        //     setTotalCount(1)
        //     setFetchingCompletedKits(false);
        //     setTimeout(() => {
        //         setGlobalSearch(GLOBAL_SEARCH_INIT)
        //     }, 100)
        // }
        updateGlobalSearchStates(id, globalSearch, setGlobalSearch, setKitCodeFilter, setCompletedKits, setTotalCount, setFetchingCompletedKits)

    }, [globalSearch])

    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            getCompletedKitsData()
        }
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy]);

    return (
        <>
            <LabPortalTopSectionContainer timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter} />

            <Table
                loading={fetchingCompletedKits}
                columns={completedKitsColList}
                dataSource={completedKits}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleCompletedKitsTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderer,
                    expandIcon: () => ''
                }}
            />
        </>
    );
}

export default CompletedKits;