import React, { useEffect, useRef, useState } from "react";
import {Button, Input, Typography} from "antd";
// import Icon from '@ant-design/icons';
import style from "./adminHeader.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {PH_scan_svg, search_icon_light} from "@ph/common/assets/index";

const {Text, Link, Title} = Typography;

const AdminHeader = ({ title, searchKit, searchKitHandler, scanKit, scanKitClickHandler, headerNav = true }) => {
    let navigate = useNavigate();
    let location = useLocation();
    const searchTimerRef = useRef(null)
    const [currentPath, setcurrentPath] = useState(location.pathname);
    const [searchValue, setSearchValue] = useState('')

    const handleSearchKitChange = (event) => {
        const { value } = event.target;
        const trimmedVal = value.replaceAll(/[\s-_]/g, '');
        setSearchValue(trimmedVal)
        if (searchTimerRef.current) {
            clearTimeout(searchTimerRef.current);
        }

        if (trimmedVal.length >= 1 && trimmedVal.length < 7) {
            //Show Error
            return;
        }

        searchTimerRef.current = setTimeout(() => {
            searchKitHandler(trimmedVal);
        }, 500);
    };

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    return (
        // <div className={style.hideHeaderInSmallScreen}>
        <header className={style.headerContainer}>
            {/* style={reportLayout ? { borderBottom: '2px solid #E18672' } : { borderBottom: 'none' }}> */}
            <div className={"viewBoxWidth" + " " + style.customViewBoxPadding}
                 style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div className={style.logoSection}>
                    <img alt="Parallel" style={{cursor: 'pointer'}} onClick={() => navigate('/')}/>
                </div>
                {title &&
                    <div className={style.headTitle}>
                        <span>{title}</span>
                    </div>
                }
                {searchKit &&
                    <div>
                        <Input
                            placeholder="Search"
                            prefix={<img src={search_icon_light} alt='Search'/>}
                            className={style.searchInput}
                            onChange={handleSearchKitChange}
                            value={searchValue}
                            onPressEnter={(event) => searchKitHandler(event.target.value)}
                            status={(searchValue && searchValue.length < 7) ? 'error' : ''}
                            allowClear={true}
                        />
                    </div>
                }
                {scanKit &&
                    <div>
                        <Button type="primary" icon={<img src={PH_scan_svg} alt='Scan'/>} size="large"
                                className={style.scanButton} onClick={scanKitClickHandler}>
                            SCAN
                        </Button>
                    </div>
                }
                <div className={style.logoutSection}>
                    {headerNav &&
                        <React.Fragment>
                            <Text
                                className={`${style.linkText} ${currentPath.toString().includes('dashboard') ? style.active : ''}`}
                                onClick={() => navigate('/dashboard')}>ADMIN DASHBOARD</Text>
                            <Text className={`${style.linkText}`}
                                  onClick={() => navigate('lab-portal/registered-kits')}>LAB OPERATIONS PORTAL</Text>
                            <Text className={`${style.linkText}`}
                                  onClick={() => navigate('physician-portal/acne')}>PHYSICIAN PORTAL</Text>
                            <Text className={`${style.linkText}`}
                                onClick={() => { 
                                    navigate('service-dashboard/kits')
                                    localStorage.setItem('currentNavLabel', "Kits"); 
                                    }}
                                >SERVICE DASHBOARD
                            </Text>
                        </React.Fragment>
                    }
                    <img alt='Logout' className={style.logoutIcon}
                         onClick={() => window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`}/>
                </div>
            </div>
        </header>
        // </div>
    );
};

AdminHeader.propTypes = {
    reportLayout: PropTypes.objectOf(PropTypes.any)
};

export default AdminHeader;
