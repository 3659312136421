import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import style from './Jotform.module.scss';
import {message} from "antd";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";
import {getPhysicianJotformLink} from "@ph/admin/src/component/AdminLabPortal/AdminPortalAPIService";

function PhysicianPrescriptionForm() {
    const {prescriptionOrderId} = useParams();

    const [fetchingPrescriptionFormLink, setFetchingPrescriptionFormLink] = useState(false);
    const [prescriptionFormLink, setPrescriptionFormLink] = useState('');

    useEffect(() => {
        setFetchingPrescriptionFormLink(true);
        getPhysicianJotformLink(prescriptionOrderId)
            .then((res) => {
                setPrescriptionFormLink(res.data.jotform_url);
            })
            .catch((error) => {
                message.error(error.response.data.message);
            }).finally(() => setFetchingPrescriptionFormLink(false));
    }, []);

    return (
        <div className={`${style.jotformContainer} jot-form-full-height`}>
            {
                fetchingPrescriptionFormLink || !prescriptionFormLink ?
                    <FullPageLoader/> :
                    <iframe title="Prescription flow"
                            onLoad={window.parent.scrollTo(0, 0)}
                            allowTransparency="true"
                            allowFullScreen={true}
                            allow="geolocation; microphone; camera"
                            src={prescriptionFormLink}
                            sandbox="allow-forms allow-popups allow-scripts allow-top-navigation allow-same-origin"/>
            }
        </div>
    );
}

export default PhysicianPrescriptionForm;