import React from 'react'
import { Row, Col } from 'antd';
import style from './ReportSections.module.scss'

function ReportSectionHeaderSmall({ headerData }) {
    return (
        <div className={style.reportSectionHeaderSmall}>
            <span className={style.reportSectionCount}>{headerData.count}</span>
            <Row>
                <Col xs={24} xl={8} >
                    <div className={style.title}>{headerData.title}</div>
                </Col>
                <Col xs={24} xl={16} >
                    <div className={style.subTitle}>{headerData.description}</div>
                </Col>
            </Row>
        </div>
    )
}

export default ReportSectionHeaderSmall