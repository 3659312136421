import React, {useEffect, useState} from 'react';
import {getKitsList} from "../AdminPortalAPIService";
import { CheckColum, defaultKitManagementColumnList, isRowExpanded, paginationDef, updateGlobalSearchStates } from "../LabPortalConstants";
import {Button, message, Table} from "antd";
import moment from "moment/moment";
import {dateFormats, sortOrderMap} from "@ph/common/global/Constants";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import ValidateKitFormContent from "../ValidateKitFormContent/ValidateKitFormContent";
import {useOutletContext} from "react-router-dom";

function ReceivedKits({ id }) {
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch] = useOutletContext();
    const [fetchingReceivedItems, setFetchingReceivedItems] = useState(true);
    const [receivedItems, setReceivedItems] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('received_on');

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleReceivedKitsTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const receivedColList = [...defaultKitManagementColumnList].concat([
        {
            title: 'Date of Receipt',
            dataIndex: 'received_on',
            key: 'received_on',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <div className='text-right fl'>
                    <Button type='primary'>
                        VALIDATE
                    </Button>
                </div>
            )
        }]);

    const handleValidationStatusChange = () => {
        setRefreshSideBarCount(!refreshSideBarCount);
        fetchReceivedKits();
    };

    useEffect(() => {
        updateGlobalSearchStates(id, globalSearch, setGlobalSearch, setKitCodeFilter, setReceivedItems, setTotalCount, setFetchingReceivedItems)
    }, [globalSearch])

    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            fetchReceivedKits();
        }
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy]);

    function fetchReceivedKits() {
        setFetchingReceivedItems(true);
        closeExpandedRow();
        getKitsList('received', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            sortOrder,
            sortBy
        }).then(res => {
            if (res?.data?.kits_info_list) {
                setTotalCount(res.data.count);
                setReceivedItems(res.data.kits_info_list);
            }
        }).catch(error => {
            setReceivedItems([]);
            message.error(error?.response?.data?.message);
        }).finally(() => setFetchingReceivedItems(false));
    }

    function rowOnExpandHandler(expanded, {key}) {
        setExpandedRowKey(expanded ? key : '');
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function expandedRowRenderMethod(record) {
        return <ValidateKitFormContent record={record}
                                       onValidationStatusChanged={handleValidationStatusChange}
                                       closeClickHandler={closeExpandedRow}/>;
    }

    function expandIconRenderer() {
        return '';
    }

    function onRowHandler({key}) {
        return (isRowExpanded(key, expandedRowKey) &&
            {className: "expanded-row"});
    }

    return (
        <>
            <LabPortalTopSectionContainer subTitle='Ready for Validation'
                                          timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter} />
            <Table
                loading={fetchingReceivedItems}
                columns={receivedColList}
                dataSource={receivedItems}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleReceivedKitsTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderMethod,
                    expandIcon: expandIconRenderer
                }}
            />
        </>
    );
}

export default ReceivedKits;