import { useEffect } from 'react';
import { AuthToken } from "@ph/subject/src/api_services/localstorage";
import { Space, Spin } from 'antd';
import Cookies from 'universal-cookie';

const Logout = () => {
    const {
        REACT_APP_MINI_ORANGE_URL,
        REACT_APP_MINI_ORANGE_LOGOUT_PATH,
        REACT_APP_SHOPIFY_URL
    } = process.env;
  const cookies = new Cookies();

  useEffect(() => {
    AuthToken.removeStorage();
    cookies.remove('returnToUrl')
    return window.location.replace(`${REACT_APP_MINI_ORANGE_URL}/${REACT_APP_MINI_ORANGE_LOGOUT_PATH}?post_logout_redirect_uri=${REACT_APP_SHOPIFY_URL}/account/logout`)
  }, []);
  return (
    <div style={{ marginTop: '60px', textAlign: 'center' }}>
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  )
}
export default Logout