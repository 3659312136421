import React, {useEffect, useState} from 'react';
import {message, Spin } from "antd";
import Components from "@ph/common/components";

const SuggestManyHealthKits = (props) => {
    const [reTestStatusIsLoading, setReTestStatusIsLoading] = useState(true);
    const [reTestStatus, setReTestStatus] = useState([]);
 
    const { connectionInstance } = props;

    const getUserTestStatus = async () => {
        setReTestStatusIsLoading(true);
        return await connectionInstance.get(
            `${process.env.REACT_APP_API_URL}/subject/retest`
        );
    };

    function transformKitData(inputDict) {
        const outputArray = [];

        for (const key in inputDict) {
            if (inputDict.hasOwnProperty(key)) {
                const outputItem = {
                    kit_type: key,
                    ...inputDict[key]
                };
                outputArray.push(outputItem);
            }
        }
        return outputArray;
    }

    useEffect(() => {
        // kitInfo();
        getUserTestStatus()
            .then((res) => {
                if (res && res.data) setReTestStatus(transformKitData(res.data));
            })
            .catch((error) => {
                message.error("Something went wrong!");
            })
            .finally(() => {
                setReTestStatusIsLoading(false);
            });
    }, []);

    
    if(reTestStatusIsLoading) {
        return (
            <div className={`text-center ${reTestStatusIsLoading && "hide"}`}>
                <Spin
                    size="large"
                    style={{
                        marginTop: "60px",
                        textAlign: "center",
                    }}
                />
            </div>
        )
    }else {
        return (
            <div>

                {reTestStatus && reTestStatus.map((restestData, index) => {
                    return (
                      <React.Fragment key={index}>
                          <Components.SuggestHealthKit reTestStatus={restestData}
                                                       connectionInstance={connectionInstance}/>
                      </React.Fragment>
                    )
                    })
                }
                
            </div>             
        )
    }
};
export default SuggestManyHealthKits