import React, {useEffect, useState} from 'react';
import {Table, Typography} from "antd";
import moment from "moment/moment";
import {getKitsList} from "../AdminPortalAPIService";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import {getFriendlyKitCode} from "@ph/common/global/Helpers";
import {dateFormats, sortOrderMap} from "@ph/common/global/Constants";
import ValidateKitFormContent from "../ValidateKitFormContent/ValidateKitFormContent";
import { CheckColum, isRowExpanded, paginationDef, updateGlobalSearchStates } from "../LabPortalConstants";
import { useOutletContext } from "react-router-dom";

const { Text } = Typography;

function RejectedKits({ id }) {
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch] = useOutletContext();
    const [fetchingRejectedKits, setFetchingRejectedKits] = useState(true);
    const [rejectedKits, setRejectedKits] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('validated_on');

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleRejectedKitsTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const rejectedKitsColList = [
        {
            title: 'Kit Code',
            dataIndex: 'kitcode',
            key: 'kitcode',
            render: (kitCode) => getFriendlyKitCode(kitCode)
        },
        {
            title: 'Kit Type',
            dataIndex: 'kit_type',
            key: 'kit_type',
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        },
        {
            title: 'Hydration Strip',
            dataIndex: 'oil_strip_image_urls',
            key: 'oil_strip_image_urls',
            render: (d) => <CheckColum valid={d && d?.Oil_Strip} />
        },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        },
        {
            title: 'Date of Rejection',
            dataIndex: 'validated_on',
            key: 'validated_on',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => {
                return (<div className='clearfix'>
                    <div className='fl'>{moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS)}</div>
                    <div className='fr'>
                        <Text className='body-txt-md clickable-link visible-on-hover'>View Details</Text>
                    </div>
                </div>)
            }
        }];

    function expandedRowRenderer(record) {
        return <ValidateKitFormContent key={record.kit_accession_id} record={record}
                                       closeClickHandler={closeExpandedRow} isReadOnly={true}/>;
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function rowOnExpandHandler(expanded, {key}) {
        setExpandedRowKey(expanded ? key : '');
    }

    function onRowHandler({key}) {
        return (isRowExpanded(key, expandedRowKey) &&
            {className: "expanded-row"});
    }

    useEffect(() => {
        updateGlobalSearchStates(id, globalSearch, setGlobalSearch, setKitCodeFilter, setRejectedKits, setTotalCount, setFetchingRejectedKits)
    }, [globalSearch])

    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            setFetchingRejectedKits(true);
            closeExpandedRow();
            getKitsList('rejected', {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
                kitCodeFilter,
                startAndEndDate,
                sortOrder,
                sortBy
            }).then(res => {
                if (res?.data?.kits_info_list) {
                    setTotalCount(res.data.count);
                    setRejectedKits(res.data.kits_info_list);
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => setFetchingRejectedKits(false));
        }
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy]);

    return (
        <>
            <LabPortalTopSectionContainer timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter} />

            <Table
                loading={fetchingRejectedKits}
                columns={rejectedKitsColList}
                dataSource={rejectedKits}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleRejectedKitsTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderer,
                    expandIcon: () => ''
                }}
            />
        </>
    );
}

export default RejectedKits;