import h_constellation_type_1 from './h_constellation_type_1.svg';
import h_constellation_type_2 from './h_constellation_type_2.svg';
import h_constellation_type_3 from './h_constellation_type_3.svg';
import h_constellation_type_4 from './h_constellation_type_4.svg';
import h_constellation_type_5 from './h_constellation_type_5.svg';
import h_constellation_type_6 from './h_constellation_type_6.svg';
import h_constellation_type_7 from './h_constellation_type_7.svg';
import h_constellation_type_8 from './h_constellation_type_8.svg';
import h_mini_constellation_type_1 from './h_mini_constellation_type_1.svg';
import h_mini_constellation_type_2 from './h_mini_constellation_type_2.svg';
import h_mini_constellation_type_3 from './h_mini_constellation_type_3.svg';
import h_mini_constellation_type_4 from './h_mini_constellation_type_4.svg';
import h_mini_constellation_type_5 from './h_mini_constellation_type_5.svg';
import h_mini_constellation_type_6 from './h_mini_constellation_type_6.svg';
import h_mini_constellation_type_7 from './h_mini_constellation_type_7.svg';
import h_mini_constellation_type_8 from './h_mini_constellation_type_8.svg';
import v_constellation_type_1 from './v_constellation_type_1.svg';
import v_constellation_type_2 from './v_constellation_type_2.svg';
import v_constellation_type_3 from './v_constellation_type_3.svg';
import v_constellation_type_4 from './v_constellation_type_4.svg';
import v_constellation_type_5 from './v_constellation_type_5.svg';
import v_constellation_type_6 from './v_constellation_type_6.svg';
import v_constellation_type_7 from './v_constellation_type_7.svg';
import v_constellation_type_8 from './v_constellation_type_8.svg';

export default {
    h_constellation_type_1,
    h_constellation_type_2,
    h_constellation_type_3,
    h_constellation_type_4,
    h_constellation_type_5,
    h_constellation_type_6,
    h_constellation_type_7,
    h_constellation_type_8,
    h_mini_constellation_type_1,
    h_mini_constellation_type_2,
    h_mini_constellation_type_3,
    h_mini_constellation_type_4,
    h_mini_constellation_type_5,
    h_mini_constellation_type_6,
    h_mini_constellation_type_7,
    h_mini_constellation_type_8,
    v_constellation_type_1,
    v_constellation_type_2,
    v_constellation_type_3,
    v_constellation_type_4,
    v_constellation_type_5,
    v_constellation_type_6,
    v_constellation_type_7,
    v_constellation_type_8
}