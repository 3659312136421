import axios from 'axios';
import Client from 'shopify-buy';

// const API_KEY = process.env.REACT_APP_PROTOCOL_API_KEY
// const API_PASS = process.env.REACT_APP_PROTOCOL_API_PASS
// const ACCESS_TOKEN = process.env.REACT_APP_PROTOCOL_ACCESS_TOKEN
const SHOPIFY_DOMAIN = process.env.REACT_APP_PROTOCOL_SHOPIFY_DOMAIN
const STOREFRONT_ACCESS_TOKEN = process.env.REACT_APP_STOREFRONT_ACCESS_TOKEN
const ADMIN_API = 'admin/api'
const API_VERSION = '2022-04'

// const instance = axios.create({
//     baseURL: `${SHOPIFY_DOMAIN}/${ADMIN_API}/${API_VERSION}/`,
//     headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*',
//         'X-Shopify-Access-Token': ACCESS_TOKEN,
//     },
// });

const ShopifyClient = Client.buildClient({
    domain: SHOPIFY_DOMAIN,
    storefrontAccessToken: STOREFRONT_ACCESS_TOKEN
});

export default ShopifyClient;
