import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Button, Col, DatePicker, Modal, Row, Typography} from 'antd';
import {calendar_dark, close_circle_black} from "@ph/common/assets";
import {CheckOutlined} from "@ant-design/icons";
import {dateFormats} from "@ph/common/global/Constants";
import style from "./TimeFilterButton.module.scss";

const {Text} = Typography;
const {RangePicker} = DatePicker;

const checkedIcon = <CheckOutlined style={{color: "#fff"}}/>;

function TimeFilterButton({filterFor_ = 'labAdminFilter', triggerTimeFilterChangeEvent}) {
    const rangePickerFormat = dateFormats.MMDOTDDDOTYYYY;
    const allTime = {"name": "ALL Time", "range": null};
    let savedFilter = localStorage.getItem(filterFor_);
    const [openTimeRangeFilterModal, setOpenTimeRangeFilterModal] = useState(false);
    const [selectedTimeRange, setSelectedTimeRange] = useState(allTime);
    const [customRange, setCustomRange] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const getStartAndEndDate = (rangeInDays) => {
        const endDate = moment();
        const startDate = moment().subtract(rangeInDays - 1, 'days');

        return {
            startDate: startDate.format(dateFormats.MMSLADDSLAYYYY),
            endDate: endDate.format(dateFormats.MMSLADDSLAYYYY),
        };
    };

    const openTimeRangeModalClickHandler = () => {
        setOpenTimeRangeFilterModal(true);
    };

    const closeTimeRangeModal = () => {
        if (selectedTimeRange.name === "CUSTOM" && selectedTimeRange.range === null) {
            setSelectedTimeRange(allTime);
            localStorage.setItem(filterFor_, JSON.stringify(allTime));
        }
        if (typeof triggerTimeFilterChangeEvent === 'function') {
            if (selectedTimeRange.range) {
                let startAndEndDate = {};
                if (selectedTimeRange.name === 'CUSTOM') {
                    startAndEndDate = {
                        startDate: moment(startDate).format(dateFormats.MMSLADDSLAYYYY),
                        endDate: moment(endDate).format(dateFormats.MMSLADDSLAYYYY)
                    };
                } else {
                    startAndEndDate = getStartAndEndDate(selectedTimeRange.range);
                }
                triggerTimeFilterChangeEvent(startAndEndDate);
            } else {
                triggerTimeFilterChangeEvent(null);
            }
        }
        setOpenTimeRangeFilterModal(false);
    };

    const timeRangeFilterList = [{
        name: "Past 1 Week",
        range: 7
    }, {
        name: "Past 15 Days",
        range: 15
    }, {
        name: "Past 1 Month",
        range: 30
    }, {
        name: "Past 3 Months",
        range: 90
    }, {
        name: "Past 6 Months",
        range: 180
    }, {
        name: "Past 1 Year",
        range: 365
    }, {
        name: "CUSTOM",
        range: customRange,
        startDate,
        endDate
    }, allTime];

    const handleTimeRangeSelection = (filter) => {
        setSelectedTimeRange(filter);
        localStorage.setItem(filterFor_, JSON.stringify(filter));
    };

    const clearAndHandleTimeRangeSelection = (filter) => {
        setStartDate(null)
        setEndDate(null)
        handleTimeRangeSelection(filter)
    }

    const handleRangePickerSelection = ([...[selectedStartDate, selectedEndDate]]) => {
        setStartDate(selectedStartDate);
        setEndDate(selectedEndDate);
        const selectedRangeInDays = moment(selectedEndDate).diff(moment(selectedStartDate), 'days') || 1;
        setCustomRange(selectedRangeInDays);
        handleTimeRangeSelection({
            name: "CUSTOM",
            range: selectedRangeInDays,
            startDate: selectedStartDate,
            endDate: selectedEndDate
        });
    };

    useEffect(() => {
        if (!savedFilter) {
            localStorage.setItem(filterFor_, JSON.stringify(allTime));
        }
    }, []);

    return (
        <>
            <Button
                icon={<img src={calendar_dark} className={style.calenderIcon} alt='date range'/>}
                onClick={openTimeRangeModalClickHandler} className={`ph-btn no-border ${style.timeFilter}`}
            >
                <Text strong={true} className={`body-txt-md ${style.filterLabel}`}>
                    {selectedTimeRange.name}
                </Text>
            </Button>

            <Modal open={openTimeRangeFilterModal} onCancel={closeTimeRangeModal} maskClosable={false}
                   closeIcon={<img className={style.closeIcon} src={close_circle_black} alt='x'/>}
                   className={style.timeRangeFilterModal} footer={''}>
                <Row>
                    {timeRangeFilterList.map((filter, i) => {
                        return (
                            <Col span={12} key={i.toString()}>
                                <Button className={`ph-btn-selectable text-left 
                                ${(selectedTimeRange.name === filter.name) ? 'selected' : ''}`}
                                    onClick={() => clearAndHandleTimeRangeSelection(filter)}>
                                    <div className='clearfix'>
                                        <div className='fl body-txt-md'>{filter.name}</div>
                                        <div className='fr'>{checkedIcon}</div>
                                    </div>
                                </Button>
                            </Col>);
                    })}
                </Row>
                <Row className={`${(selectedTimeRange.name === 'CUSTOM') ? '' : 'hide'} 
                ${style.timeRangePickerContainer}`}>
                    <Col span={12}>
                        <Text className={`body-txt-md ${style.label}`} strong={true}>From</Text>
                    </Col>
                    <Col span={12}>
                        <Text className={`body-txt-md ${style.label}`} strong={true}>To</Text>
                    </Col>
                    <Col span={24}>
                        <RangePicker size='large' className={style.timeRangePickerFilter}
                                     format={rangePickerFormat}
                                     placeholder={[rangePickerFormat, rangePickerFormat]}
                                     value={[startDate, endDate]}
                                     onChange={handleRangePickerSelection}/>
                    </Col>
                </Row>
                <div className={`ant-modal-footer text-center`}>
                    <Button className={`ph-btn transparent ${style.saveTimerBtn}`} onClick={closeTimeRangeModal}>
                        <Text className={`body-txt-md`}>APPLY</Text>
                    </Button>
                </div>
            </Modal>
        </>
    );
}

export default TimeFilterButton;