import React from 'react';
import style from "../Report.module.scss";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {Row} from "antd";
import RecommendedItemsColList from "./RecommendedItemsColList";
import {navigateToShopifyUrl} from "@ph/common/components/Report/reportUtils";
import {lock_icon} from "@ph/common/assets";
import {microBiomeDiscoveryBundleLink} from "@ph/common/global/Constants";
import { buildCompleteShopifyLink } from '@ph/common/global/Helpers';
import { SHOPIFY_PAGES } from '@ph/common/constants/ShopifyPages';
import { useNavigate } from 'react-router-dom';

function FoodRecommendationsPanel({
                                    Text, reportData, lastItemStyle, sandBorderBottom, getGoodItemStyle,
                                    getBadItemStyle, isETypeKit
                                  }) {
  const navigate = useNavigate()
  const sampleFoodRecommendations = [{
    name: 'FOOD NAME A',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }, {
    name: 'FOOD NAME B',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }, {
    name: 'FOOD NAME X',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }, {
    name: 'FOOD NAME Y',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }];

    return (
        <>
            <div className={style.recommendationLegend}>
          <span><CheckCircleFilled style={{ color: '#2E5ACC' }} className={style.circleIconSmall} /> <Text className={style.bodyText2}><strong>Recommended</strong> Foods</Text></span>
          <span><CloseCircleFilled style={{ color: '#A50832' }} className={style.circleIconSmall} /> <Text className={style.bodyText2}>Food to <strong>avoid</strong></Text></span>
            </div>
            <Row gutter={[30]} className={style.recommendationRow} align="stretch" style={{ height: '100%' }}>
                {reportData.good_food_recommendations.map((foodItem, index) => {
                  let itemKey = `${foodItem.name}_${index}`;
                    let inlineStyle = sandBorderBottom;
                  let iconComp = <CheckCircleFilled className={style.circleIconBig} style={{ color: '#2E5ACC' }} />;
                    let itemName = foodItem.name;
                    let itemDesc = foodItem.description;
                    let itemImgUrl = foodItem.image_url;
                    let itemColClass = '';
                  if (!isETypeKit && reportData.bad_food_recommendations.length < 1) {
                        inlineStyle = getGoodItemStyle(reportData.good_food_recommendations, index);
                        itemColClass = style.lastItem;
                    }
                    // console.log(`inlineStyle: ${JSON.stringify(inlineStyle)} | key: ${itemKey}`);
                    // else if(index === reportData.good_food_recommendations.length - 1) {
                    //     inlineStyle = lastItemStyle;
                    // }
                    return (
                        <RecommendedItemsColList
                          {...{Text, itemKey, itemColClass, inlineStyle, iconComp, itemName, itemDesc, itemImgUrl}} />
                    );
                })}

                {reportData.bad_food_recommendations.map((foodItem, index) => {
                  let itemKey = `${foodItem.name}_${index}`;
                    let inlineStyle = sandBorderBottom;
                  let iconComp = <CloseCircleFilled className={style.circleIconBig} style={{ color: '#A50832' }} />;
                    let itemName = foodItem.name;
                    let itemDesc = foodItem.description;
                    let itemImgUrl = foodItem.image_url;
                    let itemColClass = '';
                    if(index === reportData.bad_food_recommendations.length - 1) {
                        inlineStyle = lastItemStyle;
                        itemColClass = style.lastItem;
                    } else if(reportData.bad_food_recommendations.length > 0) {
                        inlineStyle = getBadItemStyle(reportData.good_food_recommendations,
                            reportData.bad_food_recommendations, index);
                    }

                    return (
                        <RecommendedItemsColList
                          {...{Text, itemKey, itemColClass, inlineStyle, iconComp, itemName, itemDesc, itemImgUrl}} />
                    );
                })}
            </Row>

          {isETypeKit ?
            <Row gutter={[30]} className={`${style.recommendationRow} ${style.masked}`}
                 align="stretch" style={{height: '100%'}}>
            <div className={`${style.contentMask} hand-cursor`} onClick={() => window.open(`${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`, '_self')}>
                <div className={style.overlayContent}>
                  <img src={lock_icon} alt='locked'/>
                  <div className='body-txt-lg'>
                  Get your <Text className='body-txt-lg' underline={true} strong={true}>MD-03 Protocol™ </Text> to unlock <br />
                    <Text className='body-txt-lg'>more information about Food Recommendations</Text>
                  </div>
                </div>
              </div>
              {sampleFoodRecommendations.map((foodItem, index) => {
                let itemKey = `${foodItem.name}_${index}`;
                let inlineStyle = sandBorderBottom;
                let iconComp = <CheckCircleFilled className={style.circleIconBig} style={{ color: '#2E5ACC' }} />;
                let itemName = foodItem.name;
                let itemDesc = foodItem.description;
                let itemColClass = '';
                if (index > 1) {
                  iconComp = <CloseCircleFilled className={style.circleIconBig} style={{ color: '#A50832' }} />;
                  itemColClass = style.lastItem;
                }
                return <RecommendedItemsColList
                  {...{Text, itemKey, itemColClass, inlineStyle, iconComp, itemName, itemDesc}} />;
              })}
            </Row>
            :
            <></>}
        </>
    );
}

export default FoodRecommendationsPanel;