import { warning, arrow_next } from "@ph/common/assets/index";
import style from "./reportRow.module.scss";
import { Button, Typography } from 'antd';
import { button_arrow } from "@ph/common/assets/index";
const { Text } = Typography;

const ReportRow = ({ 
    sampleDate = "",
    sampleDateLabel = "",
    kitNo = "",
    kitName = "",
    buttonLabel = "",
    onClick = null,
    isReportError = false
}) => {
    return (
        <div className={style.reportContainer}>
            <div className={style.reportHeader}>
                <div className={style.reportHeaderSection1}>
                    <div className={style.sampleDateContainer}>
                        <Text className={`${style.bodyText} ${style.kitDate}`}>
                            {sampleDateLabel} <span style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}>
                                { sampleDate }</span>
                        </Text>
                    </div>
                    <Text className={`body-txt-lg show-only-on-desktop`}>
                        { kitName }:&nbsp; 
                        { kitNo && (
                            <span className="font-Neue-medium">{kitNo}</span>
                        )}
                    </Text>
                    <div className={`${style.mobileSection} hide-on-desktop`}>
                        <div>
                            <div>
                                <Text className="body-txt-lg">{kitName} </Text>
                                { isReportError && (
                                <img src={warning} alt="error" style={{width: '20px',position: 'absolute',right: '20px'}}/>
                                )}
                            </div>
                            <div>
                                <Text className={style.bodyText}><strong>{ kitNo }</strong></Text>
                            </div>
                        </div>
                        { !isReportError && (
                        <div>
                            <img src={arrow_next} alt="view report" onClick={onClick} className={style.reportImage}/>
                        </div>
                        )}
                    </div>
                    
                </div>
                { !isReportError && (
                <div className={style.reportHeaderSection2 + " " + style.showSectionDesktop}>
                    <Button type="primary" className={style.button}
                            onClick={onClick}>
                        { buttonLabel }
                            <img src={button_arrow} className='ph-btn-arrow' />
                    </Button>
                </div>
                )}
            </div>
            { isReportError && (
            <div className={style.warningTextContainer}>
                <img src={warning} alt="error" className={`${style.warningImage} show-only-on-desktop`}/>
                <Text className={style.warningText} >
                        <span>Your kit requires attention.</span> Our customer support team will reach out to you immediately. You can reach us directly at support@parallelhealth.io.
                </Text>
            </div>
            )}
        </div>
    )
};

export default ReportRow;