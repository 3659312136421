import React from 'react';
import style from "../Report.module.scss";
import {Col, Row, Typography} from "antd";
import ReportConstellationType from "./ReportConstellationType";
import SkinMicrobiomeTypeCard from "./SkinMicrobiomeTypeCard";
import RecommendedMBSerumCard from "./RecommendedMBSerumCard";
import SkinAgeAndHydrationLevelCard from "./SkinAgeAndHydrationLevelCard";
import MbDiversityScoreSection from "./MBDiversityScoreSection";

const { Text, Paragraph } = Typography;

function ReportMidSection({ reportData, recommendedProduct }) {

    return (
        <>
            <div className={style.box}>
                <ReportConstellationType constellationType={reportData.constellationType}
                                         skin_type_name={reportData.skin_type_name} />
            </div>

            <div className={`${style.box} ${style.skinTypeBox}`}>
                <Row align="stretch" gutter={[40, 30]}>
                    <Col xs={24} sm={24} md={24} xl={12}>
                        <SkinMicrobiomeTypeCard {...{Text, Paragraph, reportData}}/>
                    </Col>

                    {/* {primarySerum &&
                                        <Col xs={24} sm={24} md={24} xl={12}>
                                            <div className={style.tile2}>
                                                <Text className={style.h2Text}>Recommended Microbiome <strong>SERUM</strong></Text>
                                                <div style={{ height: '2px' }} className={style.showInSmallScreen}></div>
                                                <div className={style.tileContent}>
                                                    {primarySerum.image_url ?
                                                        <img src={primarySerum.image_url}
                                                            className={style.tileImage + " " + style.pointContent}
                                                            onClick={() => {
                                                                if (primarySerum.shopify_product_url) {
                                                                    window.location = primarySerum.shopify_product_url
                                                                }
                                                            }} />
                                                        :
                                                        <img className={style.placeHolderImage} />
                                                    }
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', position: 'relative' }}>
                                                        <Paragraph className={style.bodyText1 + " " + style.pointContent}
                                                            ellipsis={{ rows: 2 }}
                                                            onClick={() => {
                                                                if (primarySerum.shopify_product_url) {
                                                                    window.location = primarySerum.shopify_product_url
                                                                }
                                                            }}>
                                                            <strong>{primarySerum.name && primarySerum.name}</strong>
                                                        </Paragraph>
                                                        {primarySerum.description &&
                                                            <Paragraph className={style.bodyText2 + " " + style.pointContent}
                                                                ellipsis={{ rows: contentRowCount }}
                                                                onClick={() => {
                                                                    if (primarySerum.shopify_product_url) {
                                                                        window.location = primarySerum.shopify_product_url
                                                                    }
                                                                }}>
                                                                {primarySerum.description}
                                                            </Paragraph>
                                                        }
                                                        <Button type="primary" className={style.button}
                                                            onClick={() => {
                                                                if (primarySerum.shopify_product_url) {
                                                                    window.location = primarySerum.shopify_product_url
                                                                }
                                                            }}>PRE-ORDER</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    } */}
                    {reportData.serum_recommendations && reportData.serum_recommendations.length > 0 &&
                        <Col xs={24} sm={24} md={24} xl={12}>
                            <RecommendedMBSerumCard
                                {...{Text, Paragraph, reportData}} />
                        </Col>
                    }
                </Row>
            </div>

            <SkinAgeAndHydrationLevelCard {...{Text, Paragraph, reportData, recommendedProduct}} />

            <MbDiversityScoreSection {...{Text, reportData}} 
                leftSidePara="Generally speaking, a higher microbiome diversity score means better skin health. “But isn't having a lot of different types of bacteria on my skin a bad thing?!” Not really. When one or two types of bacteria overgrow and take up too much room, they kill off other types of bacteria, leading to lower microbial diversity." 
                rightSidePara='When several bacteria grow and share space, they support healthier, balanced skin. Thus, several types of "good" bacteria can turn "bad" if they overgrow and leave little room for other bacteria to flourish. On the other hand, some bacteria are always classified as "good" or "bad" bacteria based on their unique properties, regardless of their quantity.' 
            />
        </>
    );
}

export default ReportMidSection;