import React from 'react';
import style from "./Report.module.scss";
import {Col, Divider, Row, Typography} from "antd";
import Marquee from "react-fast-marquee";
import {marquee_1, marquee_2, marquee_3, beta_banner} from "@ph/common/assets";

const { Text } = Typography;
function ReportUnavailable({isAdmin, user, customKitcode}) {
    return (
        <>
            <div className='viewBoxWidth'>
                <div className={style.box} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {!isAdmin && <Text className={style.h1Text} style={{ fontFamily: 'Light' }}>Hi {(user && user.first_name) && user.first_name},</Text>}
                    <Text className={style.h2Text}>Seems like there are no skin microbiome reports linked to {isAdmin ? customKitcode.toString().toUpperCase() : 'your account'}</Text>
                    <Divider type="horizontal" className={style.horizontalseparator} />
                </div>
            </div>
            <Text className={`${style.headingText} ${style.quincyText} ${style.box}`}>
                Explore our preorder offerings</Text>

            <div className={style.box}>
                <div className={style.bannerContent}>
                    <div className={style.marqueeSection}
                         onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}`}>
                        <Marquee className={style.marqueeContent} gradient={false} loop={0}>
                            <img src={marquee_1} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_2} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_3} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_1} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_2} className={style.marqueeImage} alt="PH"/>
                            <img src={marquee_3} className={style.marqueeImage} alt="PH"/>
                        </Marquee>
                    </div>
                    {/* <div className='viewBoxWidth'> */}
                    <div className={`${style.bannerSection} viewBoxWidth`}>
                        <div className={`${style.banner} ${style.box}`}>
                            <Row justify="space-around" align="middle">
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <div className={`h2 ${style.bannerTitle}`}>
                                        All our products are made responsibly with precision and care.
                                    </div>
                                    {/* <Button className={style.bannerButton} type="secondary">SHOP NOW</Button> */}
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <img className={style.bannerImage} alt="Parallel"/>
                                </Col>
                            </Row>
                            {/* <Button className={style.bannerButtonSmall} type="secondary">SHOP NOW</Button> */}
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
}

export default ReportUnavailable;