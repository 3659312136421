import React, {useEffect, useState} from 'react';
import { Button, message, Spin, Typography, Row, Col, Card } from "antd";
import style from "./prescriptions.module.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import Components from "@ph/common/components";
import { dateFormats } from "@ph/common/global/Constants";

function Prescriptions({connectionInstance}) {
  return (
    <div className={style.container} >
        <div className={style.navSection}>
            <Components.BackLInk title="Your Prescriptions" link="/dashboard" />
        </div>
        <Components.PrescriptionsKitResult connectionInstance={connectionInstance} />
    </div>
  )
}

export default Prescriptions