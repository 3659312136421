import React, { useEffect, useState } from "react";
import { Typography, Col, Row, Button, Space, Drawer, Divider, message } from "antd";
import { PH_twitter_svg, instagram, PH_facebook, admin_header } from "@ph/common/assets";
import style from "./adminFooter.module.scss";
import { useLocation } from "react-router-dom";

const { Text, Link, Title } = Typography;

const AdminFooter = () => {
    let location = useLocation();
    const [currentPath, setcurrentPath] = useState(location.pathname);

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    return (
        // <div className={style.hideHeaderInSmallScreen}>
        currentPath.toString().includes('report') && (
            <footer className={style.footerContainer}>
                {/* style={reportLayout ? { borderBottom: '2px solid #E18672' } : { borderBottom: 'none' }}> */}
                <div className="viewBoxWidth">
                    <Row>
                        <Col className={style.centerAlignMobile} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} xs={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} md={{ order: 1, span: 8 }} lg={{ order: 1, span: 8 }}>
                            <Text className={style.bodyText2}
                                onClick={() => window.location = process.env.REACT_APP_SHOPIFY_URL}>©WWW.PARALLELHEALTH.IO</Text>
                        </Col>
                        <Col className={style.centerAlignMobile} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ order: 2, span: 8 }} lg={{ order: 2, span: 8 }}>
                            {/* <Text className={style.h1Text}>parallel</Text> */}
                            <img src={admin_header} className={style.logo} />
                        </Col>
                        <Col className={style.centerAlignMobile} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ order: 3, span: 8 }} lg={{ order: 3, span: 8 }}>
                            <Space className={style.socialIconSection}>
                                <img src={PH_twitter_svg} className={style.socialIcon} alt="twitter"
                                    onClick={() => window.location = 'https://twitter.com/urtrueparallel'} />
                                <img src={PH_facebook} className={style.socialIcon} alt="fb"
                                    onClick={() => window.location = 'https://www.facebook.com/yourtrueparallel/'} />
                                <img src={instagram} className={style.socialIcon} alt="insta"
                                    onClick={() => window.location = 'https://www.instagram.com/yourtrueparallel/'} />
                            </Space>
                        </Col>
                    </Row>
                </div>
            </footer>
        )
    );
};

export default AdminFooter;
