import React from 'react';
import style from "./PHProtocol.module.scss";
import StepProducts from './StepProducts';
import { Button } from 'antd';
import { button_arrow, Back_Arrow_Border } from '@ph/common/assets/index';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';

function StepDetails({ activeTab, stepData, setActiveTab }) {

    const { currStep, updateCurrStep,
        updateToPreviousStep,
        updateToNextStep,
        protocolData } = useProtocolContext()

    return (
        <div className={`${style.tabContain} ${currStep === stepData.id ? style.active : ''}`}>
            <h2 className='text-uv-blue font-NeueHGDP-light'>{stepData.title}</h2>
            <p className='body-txt-lg text-uv-blue'><div dangerouslySetInnerHTML={{ __html: stepData.description }}></div></p>
            {stepData?.selectionLabel && (
                <p style={{ marginTop: '24px' }}><div dangerouslySetInnerHTML={{ __html: stepData.selectionLabel }} className='protocol-description'></div> </p>
            )}
            <StepProducts products={stepData.step_data} stepId={stepData.id} />
            <div className={style.buttonBox}>
                <div className={`${style.buttonContainer} ${currStep > 1 ? style.showButton : ''}`}>
                    {currStep > 1 && (
                        <img src={Back_Arrow_Border} alt='back' className={style.backButton} onClick={updateToPreviousStep} />
                    )}
                    <Button type="primary" className={style.nextButton} onClick={updateToNextStep}> Next <img src={button_arrow} className='ph-btn-arrow' /></Button>
                </div>
            </div>
        </div>
    )
}

export default StepDetails