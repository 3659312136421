import React, {useEffect, useRef, useState} from 'react';
import style from './PrescriptionPortalTopSectionContainer.module.scss';
import {Col, Input, Row, Typography} from "antd";
import {search_icon} from "@ph/common/assets";
import PrescriptionStates from "./PrescriptionStates";
import TimeFilterButton from "@ph/admin/src/component/global/TimeRangeFilterButton/TimeFilterButton";

const {Text} = Typography;

function PrescriptionPortalTopSectionContainer({
                                                   allPrescriptionStates,
                                                   activePrescriptionState,
                                                   subTitle, topRightContent,
                                                   prescriptionStateChangeEventHandler,
                                                   timeFilterChangedEventHandler,
                                                   setSubjectNameFilter
                                               }) {
    const [subjectNameSearch, setSubjectNameSearch] = useState('');
    const searchTimerRef = useRef(null);

    const timeFilterChangedEvent = (startAndEndDate) => {
        if (typeof timeFilterChangedEventHandler === "function") {
            timeFilterChangedEventHandler(startAndEndDate);
        }
    };

    const prescriptionStateChangeEvent = (prescriptionState) => {
        if (typeof prescriptionStateChangeEventHandler === "function") {
            prescriptionStateChangeEventHandler(prescriptionState);
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(searchTimerRef.current);
        };
    }, []);

    const searchInputChangedHandler = (event) => {
        const {value} = event.target;
        setSubjectNameSearch(value);

        if (searchTimerRef.current) {
            clearTimeout(searchTimerRef.current);
        }

        searchTimerRef.current = setTimeout(() => {
            setSubjectNameFilter(value);
        }, 500);
    };

    return (
        <div className={style.topSectionWrapper}>
            <Row gutter={[0, 20]}>
                <Col span={18}>
                    <Text className={`${style.title} ${subTitle && style.subTitleBorder} h2`}>
                        Prescriptions for {localStorage.getItem('currentNavLabel')}
                    </Text>
                    {subTitle &&
                        <Text className={`${style.subTitle} h4`}>{subTitle}</Text>}
                </Col>
                <Col span={6} className='text-right'>
                    {topRightContent}
                </Col>
                <Col span={12}>
                    <PrescriptionStates allPrescriptionStates={allPrescriptionStates}
                                        activePrescriptionState={activePrescriptionState}
                                        prescriptionStateChanged={prescriptionStateChangeEvent}/>
                </Col>
                <Col span={12} className='text-right'>
                    <div className={style.searchAndTimeFilterContainer}>
                        <Input
                            placeholder="Search Name"
                            prefix={<img src={search_icon} alt='Search'/>}
                            className={`main-search-input ${style.searchInput}`} value={subjectNameSearch}
                            onChange={searchInputChangedHandler}
                        />
                        <>
                            <TimeFilterButton filterFor_='physicianFilter'
                                              triggerTimeFilterChangeEvent={timeFilterChangedEvent}/>
                        </>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PrescriptionPortalTopSectionContainer;