import React, { useEffect } from 'react';

const TestFile = () => {
    // const user = useSelector((state) => state.adminUserData.adminUserData);
    // console.log('user', user);
    return (
        <div style={{marginTop: '200px'}}>
            TestFile
        </div>
    )
};

export default TestFile;