import moment from "moment/moment";
import {dateFormats} from "@ph/common/global/Constants";
import {Button} from "antd";
import React from "react";

export const defaultPrescriptionColumnList = () => [
    {
        title: 'Name',
        key: 'name',
        render: (entry) => `${entry.first_name} ${entry.last_name}`
    },
    {
        title: 'Customer ID',
        dataIndex: 'customer_id',
        key: 'customer_id'
    },
    {
        title: 'Shopify Order ID',
        dataIndex: 'shopify_order_id',
        key: 'shopify_order_id'
    },
    {
        title: 'Order Creation Date',
        dataIndex: 'creation_date',
        key: 'creation_date',
        defaultSortOrder: 'descend',
        width: '150px',
        sorter: true, //(a, b) => new Date(a.creation_date) - new Date(b.creation_date)
        render: (d) => moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMM)
    }
];

const viewSubmissionsClickHandler = (navigate, submittedBy, submission_id, prescription_order_id) => {
    let navigateUrl = `/prescription/form-submission/${submittedBy}/${submission_id}`;
    if (prescription_order_id) {
        navigateUrl = `${navigateUrl}/${prescription_order_id}`;
    }
    navigate(navigateUrl);
};

export const pendingPrescriptionsColList = (navigate) => {
    return [...defaultPrescriptionColumnList()].concat([
        {
            title: 'Previous History',
            dataIndex: 'previous_history',
            key: 'previous_history',
            render: (history) => <span className='text-capitalize'>{history}</span>
        },
        {
            title: 'Pending Since (days)',
            dataIndex: 'pending_since',
            key: 'pending_since'
        },
        {
            title: 'Shipping State',
            dataIndex: 'shopify_order_state',
            key: 'shopify_order_state',
            render: (state, record) => {
                const {prescription_order_id, subject_prescription_request_submission_id} = record;
                return (<div className='flex-box space-between valign-middle'>
                    <div className='hf-col-1'>{state}</div>
                    <div className='hf-col-2'>
                        <Button type='primary' className='ph-btn transparent dark-txt visible-on-hover'
                                onClick={() =>
                                    viewSubmissionsClickHandler(navigate, 'subject',
                                        subject_prescription_request_submission_id,
                                        prescription_order_id)}>VIEW SUBMISSION</Button>
                    </div>
                </div>)
            }
        }
    ]);
};

export const prescribedColList = (navigate, moveToPendingClickHandler) => {
    let defaultPrescriptionColumnListForPrescribed = [...defaultPrescriptionColumnList()];
    defaultPrescriptionColumnListForPrescribed[3].render = (d, record) => {
        const {subject_prescription_request_submission_id} = record;
        return (
            <div className='flex-box space-between valign-middle'>
                <div className='hide-on-hover'>{moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMM)}</div>
                <div className='show-on-hover'>
                    <span className='body-txt-md clickable-link'
                          onClick={() =>
                              viewSubmissionsClickHandler(navigate, 'subject',
                                  subject_prescription_request_submission_id)}>View Submission</span>
                </div>
            </div>
        );
    };

    const triggerMoveToPending = (prescription_order_id) => {
        if (typeof moveToPendingClickHandler === 'function')
            moveToPendingClickHandler(prescription_order_id);
    };

    return [...defaultPrescriptionColumnListForPrescribed].concat([
        {
            title: 'Diagnosis Date',
            dataIndex: 'physician_prescription_submission_date',
            key: 'physician_prescription_submission_date',
            width: '150px',
            render: (d, record) => {
                const {physician_prescription_submission_id} = record;
                return (
                    <div className='flex-box space-between valign-middle'>
                        <div
                            className='hide-on-hover'>{moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMM)}</div>
                        <div className='show-on-hover'>
                            <span className='body-txt-md clickable-link'
                                  onClick={() =>
                                      viewSubmissionsClickHandler(navigate, 'physician',
                                          physician_prescription_submission_id)}>View Prescription</span>
                        </div>
                    </div>
                );
            }
        },
        {
            title: '',
            key: 'action',
            render: ({prescription_order_id}) => {
                return (
                    <div className='visible-on-hover flex-box space-between valign-middle'>
                        <span className='body-txt-md clickable-link'
                              onClick={() => triggerMoveToPending(prescription_order_id)}>Move to Pending</span>
                        <Button className='ph-btn transparent invisible'>DOWNLOAD XML</Button>
                    </div>
                );
            }
        }
    ]);
};

export const completedPrescriptionColList = (navigate) => {
    const prescribedColListModified = [...prescribedColList(navigate)].filter((col) => col.key !== 'action');
    return [...prescribedColListModified].concat([
        {
            title: 'Order Date',
            dataIndex: 'order_date',
            key: 'order_date',
            width: '150px',
            render: (d) => moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMM)
        },
        {
            title: 'Order ID',
            dataIndex: 'prescription_order_id',
            key: 'prescription_order_id'
        }
    ]);
};