import React from 'react';
import {CheckCircleFilled} from "@ant-design/icons";
import style from "./kitResult.module.scss";
import {Button, Collapse, Row, Typography} from "antd";
import {chevron_down, chevron_up} from "@ph/common/assets";
import PrimaryRecommendationColumn from "./PrimaryRecommendationColumn";
import SkinScoreSummaryColumn from "./SkinScoreSummaryColumn";
import SecondaryAndOtherRecommendations from "./SecondaryAndOtherRecommendations";

const { Text } = Typography;
const { Panel } = Collapse;

function KitResultCollapsible({
                                  fetchingReportData,
                                  reportData,
                                  reportHeader,
                                  viewReportHandler
}) {
    let reportPanel = '';
    if(reportData && reportData.length) {
        reportPanel = reportData.map((report, index) => {
            let {sample_date, kitcode, kit_type_name, skin_type_name, microbiome_diversity, recommendedProds, skin_age,
                cheek_skin_hydration, forehead_skin_hydration} = report;
            return (
                <Panel header={<div>{reportHeader(sample_date, kitcode, kit_type_name, true)}</div>}
                       key={index}>
                    <div className={style.reportContent}>
                        <div className={style.showSectionMobile}>
                            <div className={style.sectionSeparator}></div>
                            <div className={style.reportHeaderSection2} style={{margin: '20px 0'}}>
                                <Button type="primary"
                                        className={style.button + " " + style.viewReportButton}
                                        onClick={() => viewReportHandler(kitcode)}>
                                    VIEW REPORT</Button>
                                <Button type="default" className={style.button + " " + style.healthProfileButton}>
                                    <CheckCircleFilled className={style.checkIcon}/>
                                    <Text className={style.subtitle}>SKIN HEALTH PROFILE</Text>
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <PrimaryRecommendationColumn {...{recommendedProds, fetchingReportData}} />
                            <SkinScoreSummaryColumn {...{
                                Text,
                                skin_type_name,
                                microbiome_diversity,
                                skin_age,
                                cheek_skin_hydration,
                                forehead_skin_hydration
                            }} />
                            <SecondaryAndOtherRecommendations {...{recommendedProds, fetchingReportData}} />
                        </Row>
                    </div>
                </Panel>
            )
        })
    }
    return (
        <Collapse bordered={false} expandIconPosition="end" defaultActiveKey={['1']}
                  expandIcon={({ isActive }) =>
                      isActive ?
                          <img src={chevron_up} width='25px' alt='' /> :
                          <img src={chevron_down} width='25px' alt='' />
                  }
                  className="kit-result-collapse"
        >
            {reportPanel}
        </Collapse>
    );
}

export default KitResultCollapsible;