import React from 'react';
import style from "../Report.module.scss";
import {Col, Row} from "antd";
import RecommendedProductsColItem from "./RecommendedProductsColItem";
import {
    primary_product_image,
    secondary_product_image
} from "@ph/common/assets";
import {microBiomeDiscoveryBundleLink} from "@ph/common/global/Constants";
import { KIT_REPORTS_CONTENT } from '@ph/common/constants/KitConstants';

function ProductRecommendationsPanel({Text, Paragraph, reportData, bundle, conciergeCare, isETypeKit,kitType}) {
    return (
        <>
            <Text className={`${style.bodyText1} ${style.highlight}`} strong={true}>
                <span>Curious how to get started?</span>
            </Text><br/><br/>
            <Row style={{gap: '20px 0'}}>
                <Col xs={24} sm={24} md={24} xl={12} className={style.introColLeft}
                     key='prodRow-1-col-left'>
                    {/* <div className={style.fullWidthTile1}> */}
                    <Text className={style.bodyText1}>
                      {KIT_REPORTS_CONTENT[kitType].getStartedTextCol1}
                    </Text>
                    {/* </div> */}
                </Col>
                <Col xs={24} sm={24} md={24} xl={12} className={style.introColRight}
                     key='prodRow-1-col-right'>
                    {/* <div className={style.fullWidthTile2}> */}
                    <Text className={style.bodyText1}>
                      {KIT_REPORTS_CONTENT[kitType].getStartedTextCol2}
                    </Text>
                    {/* </div> */}
                </Col>
            </Row>

            <Row className={`${style.box} ${style.rProdListRowContainer} ${style.productRecommendationRow}`}
                 gutter={[20]}>
                
                {(!isETypeKit && reportData?.serum_recommendations.length > 0) ?
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} className={style.prodColLeft}
                    key='prodRow-2-col-left'>
                    <Row className={style.productRecommendationRow} gutter={[20]}
                          key='prodRow-2-col-left-sub-row-1'>
                        {reportData.serum_recommendations.map((productItem, i) => {
                            return <RecommendedProductsColItem key={i} {...{Text, Paragraph, productItem}} />;
                        })}
                    </Row>
                  </Col>
                  : ''}
                {(isETypeKit) ?
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={style.prodColLeft} key='prodRow-2-col-left'>
                  <Row className={style.productRecommendationRow} gutter={[20]}
                        key='prodRow-2-col-left-sub-row-1'>
                      <RecommendedProductsColItem
                        Text={Text}
                        Paragraph={Paragraph}
                        activateMask={true}
                        productItem={{
                            product_id: 1,
                            isPrimary: true,
                            productLabel: 'The Must Have: Your Primary Serum',
                            image_url: primary_product_image,
                            name: 'Phoenix: Active Phage Serum',
                            finalDescription: 'If your budget only allows for one product, "Phoenix Active Phage Serum" is the must-have: your primary hero active phage serum. Carefully crafted to support your specific skin...',
                            price: 'xxx',
                            shopify_product_url: microBiomeDiscoveryBundleLink
                        }}/>

                      <RecommendedProductsColItem
                        Text={Text}
                        Paragraph={Paragraph}
                        activateMask={true}
                        productItem={{
                            product_id: 2,
                            isSecondary: false,
                            productLabel: 'Extra Credit: Your Secondary Serum',
                            image_url: secondary_product_image,
                            name: 'Cassiopeia: Active Phage Serum',
                            finalDescription: 'For extra credit, add Cassiopeia Active Phage Serum to augment your complete Parallel skin regimen. Your secondary phage serum provides additional support by targeting your...',
                            price: 'xxx',
                            shopify_product_url: microBiomeDiscoveryBundleLink
                        }}/>
                  </Row>
                </Col>
                  : ''}
               
                <Col xs={24} sm={24} md={24} lg={12} xl={12} className={style.prodColRight}
                     key='prodRow-2-col-right'>
                    <Row className={style.productRecommendationRow} gutter={[20]}
                         key='prodRow-2-col-right-sub-row-1'>
                        <>
                            <RecommendedProductsColItem {...{Text, Paragraph, productItem: bundle}} />
                {/* <RecommendedProductsColItem {...{Text, Paragraph, productItem: conciergeCare}} /> */}
                        </>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ProductRecommendationsPanel;