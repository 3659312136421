import React, { useEffect, useState } from 'react'
import './FrontRow.css';

function FrontRow() {

    var url = process.env.REACT_APP_FRONTROW_PRODUCT_URL

    function fetchDataAndUpdateDiv() {
        if (url) {
            fetch(url)
                .then(response => {
                    if (response.ok) return response.text();
                    else throw new Error("failed to fetch content")
                })
                .then(html => {
                    document.getElementById('frontrow-widget').innerHTML = html;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            document.getElementById('frontrow-widget').innerHTML = "";
        }
    }

    useEffect(() => {
        fetchDataAndUpdateDiv();
    })

    return (
        <div className='frontrow-container'>
            <div id="frontrow-widget">
                <div class="fr-loading-box">
                    <div class="fr-provider-container">
                        <div class="fr-loading-provider"></div>
                        <div class="fr-loading-provider fr-sample"></div>
                        <div class="fr-loading-provider fr-sample"></div>
                    </div>
                    <div class="fr-text">
                        <span class=""></span>
                        <span class="fr-shorter"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontRow