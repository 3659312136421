import { useEffect, useState } from 'react';
import { check_circle_black } from "@ph/common/assets";
import style from './KitUserGuide.module.scss';
import { Button, message, Spin } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { getHealthProfileStatus } from '@ph/subject/src/api_services/api';
import { HEALTH_PROFILE_STATUS } from '@ph/common/global/Constants';
import { useNavigate } from 'react-router-dom';

const TempComponent = ({ valid, showHealthProfile, kitId }) => {
    const navigate = useNavigate()
    if (!valid) {
        return <></>
    }
    return < div className={style.kitRegistContainer} >
        <img src={check_circle_black} className={style.icon} alt='' style={{ marginBottom: '8px' }} />
        <h4 className='text-center'>Your test kit has been successfully registered.</h4>
        <div className={style.formButtons}>
            <div className={style.buttonContainer}>
                <Button type="secondary" className={style.reviewButton}>Review Test Kit Instructions</Button>
                <CaretDownOutlined className={style.chevron} />
            </div>
            {showHealthProfile && (
                <Button
                    type="secondary"
                    onClick={() => navigate(`/healthprofile-form/${kitId}`)}
                >
                    Complete Your Health Intake Form
                </Button>
            )}

        </div>
    </div >
}

function KitRegisterationStatus({ kitId }) {
    const [showHealthProfile, setShowHealthProfile] = useState(false)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    function healthProfileResponseHandler(res) {
        if (!res || !res.data) {
            message.error("Unable to fetch Response from server!");
            return;
        }
        if (res.data.status !== HEALTH_PROFILE_STATUS.COMPLETED && res.data.status !== HEALTH_PROFILE_STATUS.NOT_REQUIRED) {
            setShowHealthProfile(true)
        }
        setValid(true)
    }

    useEffect(() => {
        if (kitId) {
            setLoading(true)
            getHealthProfileStatus(kitId)
                .then(healthProfileResponseHandler)
                .catch(() => message.error("Kitcode is Invalid!"))
                .finally(() => setLoading(false))
        }
    }, [kitId])

    return (
        <>
            {loading ?
                <div className="text-center" style={{ padding: "40px" }} >
                    <Spin size="large" className="dark-spinner card-content-spinner" />
                </div >
                :
                <TempComponent valid={valid} showHealthProfile={showHealthProfile} kitId={kitId} />
            }
        </>
    )
}

export default KitRegisterationStatus