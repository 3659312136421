import React from 'react';
import style from "./Report.module.scss";
import SampleReportDetailsBox from "./SampleReportDetailsBox/SampleReportDetailsBox";
import Marquee from "react-fast-marquee";
import {
  report_promo_image_1,
  report_promo_image_2,
  report_promo_image_3
} from "@ph/common/assets";
import {microBiomeDiscoveryBundleLink} from "@ph/common/global/Constants";
import {Button, Divider} from "antd";
import { button_arrow } from '@ph/common/assets/index';
import { buildCompleteShopifyLink } from '@ph/common/global/Helpers';
import { SHOPIFY_PAGES } from '@ph/common/constants/ShopifyPages';
import { useNavigate } from 'react-router-dom';

function SampleReportDetailsContainer() {
  const navigate = useNavigate()
  return (
    <>
      <div className={`${style.box} ${style.sampleReportTitleFlexBox}`}>
        {/* <p className={`${style.headingText} ${style.quincyText}`}>
          Want to know more about <br/>your skin microbiome?
        </p> */}
      </div>
      <SampleReportDetailsBox/>
      {/* <div className={`${style.box} ${style.sampleReportTitleFlexBox}`}>
        <p className={`${style.headingText} ${style.quincyText} ${style.smallWidth}`}>
          Get your skin discovery kit to unlock more information about your skin microbiome
        </p>
      </div> */}
      <div className={`${style.promoImagesBox}`}>
        <div className={style.bannerContent}>
          {/* <div className={style.marqueeSection}
               onClick={() => window.open(microBiomeDiscoveryBundleLink, '_blank')}>
            <div className={`${style.marqueeContent} show-only-on-desktop`}>
              <img src={report_promo_image_1} className={style.marqueeImage} alt="PH"/>
              <img src={report_promo_image_2} className={style.marqueeImage} alt="PH"/>
              <img src={report_promo_image_3} className={style.marqueeImage} alt="PH"/>
            </div>
            <div className='hide-on-desktop'>
              <Marquee className={`${style.marqueeContent}`} gradient={false}>
                <img src={report_promo_image_1} className={style.marqueeImage} alt="PH"/>
                <img src={report_promo_image_2} className={style.marqueeImage} alt="PH"/>
                <img src={report_promo_image_3} className={style.marqueeImage} alt="PH"/>
              </Marquee>
            </div>
          </div> */}
          <div className={style.orderSkinKitBtnContainer}>
            <Button type="primary" className={`${style.button}`}
              onClick={() => window.location = `${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`}>
              Start Your MD-03 Phage Protocol
              <img src={button_arrow} className='ph-btn-arrow' />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SampleReportDetailsContainer;