import { Col, Row, Typography, Divider } from "antd";
import style from './kitStatus.module.scss';
import Components from '@ph/common/components';
import { useNavigate } from "react-router-dom";

const { Text } = Typography;


function FindYourKit() {
    const navigate = useNavigate()
    return (
        <div className={style.findkitContainer}>
            <Row gutter={[32, 16]} align="middle">
                <Col xs={24} md={10}>
                    <Text className="h4">Don’t see your test kit here? <br />Let’s find and register your kit.</Text>
                </Col>
                <Col xs={24} md={6}>
                    <div>
                        <Text className="body-txt-lg">If you know your kit code</Text>
                    </div>
                    <Components.PhButton
                        type="secondary"
                        style={{ marginTop: '14px' }}
                        text={'Register Here'}
                        onClick={() => navigate('/getstarted')}
                        className="transparent"
                    />
                </Col>
                <Col xs={24} md={1} style={{ padding: 0 }}>
                    <Divider type='horizontal' className={style.lineSeperator} />
                </Col>
                <Col xs={24} md={7}>
                    <div>
                        <Text className="body-txt-lg">If you do not know your kit code</Text>
                    </div>
                    <a href='mailto:questions@parallelhealth.io' rel="noreferrer"> 
                        <Components.PhButton
                            type="secondary"
                            style={{ marginTop: '14px' }}
                            text={'Reach Out To Us'}
                            className="transparent"
                        />
                    </a>
                </Col>
            </Row>
        </div>
    )
}

export default FindYourKit