import React, {useEffect, useState} from 'react';
import style from './PCRWells.module.scss';
import {isEmpty} from "@ph/common/global/Helpers";

const ROWS = " ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
function PcrWells({ batchData, setSelectedCell, rowCount = 9, colCount = 12 }) {
    const [activeWell, setActiveWell] = useState({});
    let pcrWellsList = [];

    const pcrWellClickHandler = (el, row, col) => {
        const labelCell = `${ROWS[row]}${col}`
        console.log(labelCell)
        const targetElement = document.getElementById(labelCell)

        if (!isEmpty(activeWell) && activeWell.targetElement) {
            activeWell.targetElement.classList.remove(style.ativeCell)
        }
        if (targetElement) {
            targetElement.classList.add(style.ativeCell)
        }
        // el.className = el.className.trim() + ` ${style.active}`;
        setActiveWell({ row, col, targetElement });
        setSelectedCell(labelCell)
    };

    function generatePCRWellsList() {
        for (let r = 0; r < rowCount; r++) {
            let rL = ROWS[r];
            pcrWellsList.push(
                <div className={`${style.wellLabel} ${style.left}`}
                     key={`left-${rL}`}>{rL}</div>
            );
            pcrColIterator(r, rL)
        }
    }

    function pcrColIterator(r, rL) {
        for (let c = 0; c <= colCount; c++) {
            if(c===0) continue;
            if(r === 0) {
                pcrWellsList.push(
                    <div className={`${style.wellLabel} top`}
                         key={`top-${c}`}>{c}</div>
                );
                continue;
            }
            const currCell = `${ROWS[r]}${c}`
            if (currCell in batchData) {
                const cellInfo = batchData[currCell]
                const RenderComponent = cellInfo.cellComponent
                pcrWellsList.push(
                    <div className={`${style.wellContent}`}
                        onClick={e => {
                            pcrWellClickHandler(e.target.parentElement, r, c)
                        }}
                        key={`el-${rL + c}`}>
                        <div className={style.innerContainer} id={currCell}>
                            <cellInfo.cellComponent {...cellInfo} />
                        </div>
                    </div>
                );
            } else {
                pcrWellsList.push(
                    <div className={`${style.wellContent}`}
                        onClick={e => {
                            pcrWellClickHandler(e.target.parentElement, r, c)
                        }}
                        key={`el-${rL + c}`}>
                        <div className={style.innerContainer} id={currCell}>
                        </div>
                    </div>
                );
            }
        }
    }

    generatePCRWellsList();
    // useEffect(() => {
    // }, []);

    return (
        <div className={style.pcrWellContainer}>
            {pcrWellsList}
        </div>
    );
}

export default PcrWells;