import React from 'react';
import { Col, Divider, Row, Typography } from "antd";
import style from "./reportv1.module.scss";
import style1 from "../Report.module.scss";
import moment from "moment/moment";
import { getFriendlyKitCode } from "@ph/common/global/Helpers";
import { KIT_REPORT_TITLES, KIT_REPORTS_CONTENT } from '@ph/common/constants/KitConstants';
import { header_hex_black } from '@ph/common/assets';
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants';

const { Text } = Typography;

function ReportTopSection({ reportData, isETypeKit, kitType, reportPlan }) {

    let kitTypeName = reportData.kit_type_name ?
        (reportData.kit_type_name.includes('Skin Microbiome Discovery Kit') ?
            'Skin Microbiome Discovery Kit' : reportData.kit_type_name) : '';

    return (
        <>
            <Row className={`${style.topSection} ${style1.box}`} align="stretch">
                <Col xs={24} sm={24} md={10} className={style.reportNameCol}>
                    <div>
                        <div className='center-flex'>
                            <img src={header_hex_black} className={style.hexLogo} />
                            <span className={style.name}>SKIN</span>
                        </div>
                        <div className='center-flex'>
                            <span className={style.name}>HEALTH</span>
                        </div>
                        <div className='center-flex'>
                            <span className={style.name}>REPORT</span>
                        </div>
                    </div>
                </Col>
                <Col xs={8} sm={6} md={4} className={style.tableCol}>

                    <div className={style.tableCell}>
                        <Text className={style.cellTitle}>TEST TYPE:</Text>
                    </div>
                    <div className={style.tableCell}>
                        <Text className={style.cellTitle}>KIT CODE:</Text>
                    </div>
                    <div className={style.tableCell}>
                        <Text className={style.cellTitle}>SAMPLE DATE:</Text>
                    </div>
                    <div className={`${style.tableCell} ${style.last}`}>
                        <Text className={style.cellTitle}>SEASON:</Text>
                    </div>

                </Col>
                <Col xs={16} sm={16} md={10} className={style.tableCol}>

                    <div className={style.tableCell}>
                        <Text className={style.tableText}>
                            {reportData?.kit_type_name}
                        </Text>
                    </div>

                    <div className={style.tableCell}>
                        <Text className={style.tableText}>
                            {getFriendlyKitCode(reportData.kitcode)}
                        </Text>
                    </div>
                    <div className={style.tableCell}>
                        <Text className={style.tableText}>
                            {reportData.sample_date ? moment(reportData.sample_date).format('MM.DD.YYYY') : '-'}
                        </Text>
                    </div>
                    <div className={`${style.tableCell} ${style.last}`}>
                        <Text className={style.tableText}>
                            {reportData.season ? reportData.season : '-'}
                        </Text>
                    </div>

                </Col>
            </Row>

            <div className={style1.box + " " + style1.userGreetingSection}>
                <Text className={style1.userText} style={{ fontFamily: 'Light' }}>Hi {reportData.first_name ? reportData.first_name : (reportData.last_name && reportData.last_name)},</Text>
                <Text className="h4" style={{ marginBottom: '10px' }}>{REPORT_PLAN_DETAILS[reportPlan]?.welcome_text}</Text>
            </div >
        </>
    );
}

export default ReportTopSection;