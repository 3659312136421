import React from 'react';
import style from "../Report.module.scss";
import {chevron_down, chevron_up} from "@ph/common/assets";
import {Collapse, Typography,Tooltip,Col} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import BacteriaPlotPanel from "./BacteriaPlotPanel";
import ProductRecommendationsPanel from "./ProductRecommendationsPanel";
import FoodRecommendationsPanel from "./FoodRecommendationsPanel";
import SkinCareRecommendationsPanel from "./SkinCareRecommendationsPanel";
import {sandBorderBottom} from "@ph/common/global/Constants";
import MbDiversityScoreSection from '../MidSectionComponents/MBDiversityScoreSection';
import { KIT_TYPES,KIT_REPORTS_CONTENT } from '@ph/common/constants/KitConstants';

const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

const SHOW_INGREDIENTS_KITS = [KIT_TYPES.TYPE_A,KIT_TYPES.TYPE_B,KIT_TYPES.TYPE_E,KIT_TYPES.TYPE_X]
const SHOW_DEODORANT_KITS = [KIT_TYPES.TYPE_C]

function ReportCollapsibleSections({ reportData, bundle, conciergeCare, contentRowCount,
                                     primarySerumDesc, secondarySerumDesc, isETypeKit,kitType
                                   }) {

    const chartOptions = {
        events: [],
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const lastItemStyle = ({ border: 'none !important', paddingBottom: 0 });

    const getGoodItemStyle = (goodItem, index) => {
        let style = sandBorderBottom;
        if (goodItem.length % 2 === 0 || goodItem.length === 2) {
            if (index === goodItem.length - 1 || index === goodItem.length - 2)
                style = lastItemStyle;
        } else if (index === goodItem.length - 1) {
            style = lastItemStyle;
        }
        return style;
    };

    const getBadItemStyle = (goodItem, badItem, index) => {
        let style = sandBorderBottom;
        const isLastIndex = index === badItem.length - 1;
        const isSecondToLastIndex = index === badItem.length - 2;
        const isEvenBadItemCount = badItem.length % 2 === 0;
        const isEvenGoodItemCount = goodItem.length % 2 === 0;

        if (badItem.length === 1) {
            return lastItemStyle;
        }

        if (badItem.length === 2 && (isEvenGoodItemCount || isLastIndex)) {
            return lastItemStyle;
        }

        if (badItem.length > 2) {
            if (isEvenBadItemCount) {
                if (isEvenGoodItemCount && (isLastIndex || isSecondToLastIndex)) {
                    return lastItemStyle;
                }
                if (!isEvenGoodItemCount && isLastIndex) {
                    return lastItemStyle;
                }
            } else {
                if (isEvenGoodItemCount && isLastIndex) {
                    return lastItemStyle;
                }
                if (!isEvenGoodItemCount && (isLastIndex || isSecondToLastIndex)) {
                    return lastItemStyle;
                }
            }
        }
        return style;
    };

    const plotBacteriaGraph = (bacteriaInfo, bgColor) => {
        // [bacteriaInfo[0].bacteria_name, bacteriaInfo[1].bacteria_name, bacteriaInfo[2].bacteria_name, bacteriaInfo[3].bacteria_name, bacteriaInfo[4].bacteria_name]
        // bacteriaInfo[0].percentage, bacteriaInfo[1].percentage, bacteriaInfo[2].percentage, bacteriaInfo[3].percentage, bacteriaInfo[4].percentage
        const data = {
            labels: [],
            datasets: [
                {
                    // label: 'Microbes',
                    data: [],
                    backgroundColor: bgColor,
                    borderWidth: 0,
                },
            ]
        };
        bacteriaInfo.forEach(item => {
            data.labels.push(item.bacteria_name)
            data.datasets[0].data.push(item.percentage)
        });
        if (bacteriaInfo.length === data.labels.length) {
            return data;
        }
    };

    return (
        <Collapse defaultActiveKey={['goodBacteria', 'productRecommendations']}
                  expandIconPosition="end"
                  className={`${style.box} ${style.reportCollapse}`}
                  expandIcon={({ isActive }) =>
                      <img src={isActive ? chevron_up : chevron_down} className={style.collapseIcon} alt='' />
                  }>
            {(reportData.good_bacteria && reportData.good_bacteria.length > 0) &&
                <Panel header={<Text className="h4">Your Top <strong>GOOD</strong> Skin Microbes</Text>}
                       key="goodBacteria">
                    <BacteriaPlotPanel {...{Text, Panel, chartOptions, plotBacteriaGraph}}
                                       descCol1={KIT_REPORTS_CONTENT[kitType].goodBacteriaDescCol1} descCol2={KIT_REPORTS_CONTENT[kitType].goodBacteriaDescCol2}
                                       bacteriaInfoList={reportData.good_bacteria}
                        colorList={['#2E5ACC', '#6283D8', '#ABBDEA', '#D5DEF5', '#EEF3FF']}
                        textColorList={['#FFFFFF', '#FFFFFF', '#000000', '#000000', '#000000']}
                        buttonColor={"#2D2926"} />

                </Panel>
            }

            {(reportData.bad_bacteria && reportData.bad_bacteria.length > 0) &&
                <Panel header={<Text className="h4">Your Top <strong>BAD</strong> Skin Microbes</Text>}
                       key="badBacteria">
                    <BacteriaPlotPanel {...{Text, Panel, chartOptions, plotBacteriaGraph}}
                                       descCol1={KIT_REPORTS_CONTENT[kitType].badBacteriaDescCol1} descCol2={KIT_REPORTS_CONTENT[kitType].badBacteriaDescCol2}
                                       bacteriaInfoList={reportData.bad_bacteria}
                        colorList={['#A50832', '#B7395B', '#C96B84', '#DB9CAD', '#EDCED6']}
                        textColorList={['#FFFFFF', '#FFFFFF', '#FFFFFF', '#000000', '#000000']}
                        buttonColor={"#FFFFFF"}
                    />
                </Panel>
            }

            { [KIT_TYPES.TYPE_B,KIT_TYPES.TYPE_C].includes(kitType) && (
            <Panel 
                header={
                    <Col>
                            <Text className="h4" style={{ paddingRight: '30px' }}>Microbiome Diversity <strong>SCORE</strong></Text>
                        <Tooltip title="Your microbiome diversity score is a measurement of the balance and variety of bacteria on your skin."
                                color={"#2d2a26"} overlayInnerStyle={{ color: '#fbeee4', padding: '16px' }}
                                placement="topRight"
                                className={style.showInSmallScreen}
                                trigger="click">
                            <ExclamationCircleOutlined key="microbiomeScore" className={style.tooltipIcon} />
                        </Tooltip>
                        <Tooltip title="Your microbiome diversity score is a measurement of the balance and variety of bacteria on your skin."
                                color={"#2d2a26"} overlayInnerStyle={{ color: '#fbeee4', padding: '16px' }}
                                trigger="hover">
                            <ExclamationCircleOutlined key="microbiomeScore" className={style.tooltipIcon} />
                        </Tooltip>
                    </Col>
                } 
                key="MBDiversity"
            >
                <MbDiversityScoreSection
                    {...{
                    Text,
                    reportData,
                    }} 
                    showHeader={false}
                    leftSidePara="Generally speaking, a higher microbiome diversity score means better skin health. “But isn't having a lot of different types of bacteria on my skin a bad thing?!” Not really. When one or two types of bacteria overgrow and take up too much room, they kill off other types of bacteria, leading to lower microbial diversity." 
                    rightSidePara='When several bacteria grow and share space, they support healthier, balanced skin. Thus, several types of "good" bacteria can turn "bad" if they overgrow and leave little room for other bacteria to flourish. On the other hand, some bacteria are always classified as "good" or "bad" bacteria based on their unique properties, regardless of their quantity.' 
                />
            </Panel>
            )}

            {(reportData.other_recommendations && reportData.other_recommendations.length > 0) &&
                <Panel header={<Text className="h4">Parallel Product Recommendations</Text>}
                       key="productRecommendations" className={style.recommendedProductListPanel}>
                    <ProductRecommendationsPanel {...{Text, Paragraph, reportData, bundle, conciergeCare,
                      contentRowCount, primarySerumDesc, secondarySerumDesc, isETypeKit,kitType
                    }} />
                </Panel>
            }

            {((reportData.good_food_recommendations && reportData.good_food_recommendations.length > 0) ||
                    (reportData.bad_food_recommendations && reportData.bad_food_recommendations.length > 0)) &&
                <Panel header={<Text className="h4">Food Recommendations</Text>} key="foodRecommendations">
                    <FoodRecommendationsPanel
                      {...{
                        Text,
                        reportData,
                        lastItemStyle,
                        sandBorderBottom,
                        getGoodItemStyle,
                        getBadItemStyle,
                        isETypeKit
                      }} />
                </Panel>
            }
            
            {( SHOW_DEODORANT_KITS.includes(kitType) && ((reportData.good_skincare_ingredients_recommendations &&
                    reportData.good_skincare_ingredients_recommendations.length > 0) ||
                    (reportData.bad_skincare_ingredients_recommendations &&
                        reportData.bad_skincare_ingredients_recommendations.length > 0))) &&
                <Panel header={<Text className="h4">Deodorant Ingredients Recommendations</Text>}
                       key="deodorantRecommendations">
                    <SkinCareRecommendationsPanel
                      {...{
                        Text,
                        reportData,
                        lastItemStyle,
                        sandBorderBottom,
                        getGoodItemStyle,
                        getBadItemStyle,
                        isETypeKit
                      }} />
                </Panel>
            }

            {( SHOW_INGREDIENTS_KITS.includes(kitType) && ((reportData.good_skincare_ingredients_recommendations &&
                    reportData.good_skincare_ingredients_recommendations.length > 0) ||
                    (reportData.bad_skincare_ingredients_recommendations &&
                        reportData.bad_skincare_ingredients_recommendations.length > 0))) &&
                <Panel header={<Text className="h4">Skin Care Ingredients Recommendations</Text>}
                       key="ingredientRecommendations">
                    <SkinCareRecommendationsPanel
                      {...{
                        Text,
                        reportData,
                        lastItemStyle,
                        sandBorderBottom,
                        getGoodItemStyle,
                        getBadItemStyle,
                        isETypeKit
                      }} />
                </Panel>
            }
        </Collapse>
    );
}

export default ReportCollapsibleSections;