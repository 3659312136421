import { Back_Arrow_Border } from '@ph/common/assets/index'
import { Typography } from "antd";
import style from "./backLink.module.scss";
import { Link } from 'react-router-dom';

const { Text } = Typography;

function BackLInk({
                title = "",
                link = "#"
}) {
  return (
    <Link to={link}>
        <div className={style.backContainer} >
        <img src={Back_Arrow_Border} alt='back' />
            <Text className={style.navText}>
                { title } 
            </Text>
        </div>
    </Link>
  )
}

export default BackLInk