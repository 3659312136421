import React, { useState } from 'react'
import { Typography, Button, Spin } from 'antd';
import style from './adminDashboard.module.scss';
import { useNavigate, useParams } from "react-router-dom";
import OtpInput from 'react18-input-otp';

const { Text } = Typography;

const AdminDashboard = () => {
    const params = useParams();
    let navigate = useNavigate();
    const [code, setCode] = useState("");
    const [disableContinue, setDisableContinue] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);

    const handleInputChange = (input) => {
        setCode(input);
        if (input && input.toString().length == 7) {
            setDisableContinue(false);
        } else {
            setDisableContinue(true);
        }
    };

    const reportNavigation = () => {
        navigate('/report/' + code.toString().toUpperCase());
    }

    return (
        <>
            {showSpinner ?
                <Spin
                    spinning={showSpinner}
                    size="large"
                    style={{ marginTop: '150px', textAlign: 'center' }}
                />
                :
                <div className={style.adminContainer}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                        <Text className={style.h1Text}>View Report</Text>
                        <Text className={style.bodyText2}>Enter kit code to find the report that you are <span style={{ whiteSpace: 'nowrap' }}>looking for</span></Text>
                        <form onSubmit={reportNavigation}
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
                            <OtpInput
                                value={code}
                                onChange={handleInputChange}
                                numInputs={7}
                                separator={<span style={{ width: "8px" }}></span>}
                                isInputNum={false}
                                className='otp-input'
                                shouldAutoFocus={true}
                                focusStyle={{
                                    border: "1px solid #CFD3DB",
                                    outline: "none"
                                }}
                            />
                            {/* <Text className={style.bodyText1} style={{ textTransform: 'uppercase' }}>{kitType && kitType}</Text> */}
                            <Button type="primary" htmlType="submit" className={style.continueButton}
                                    disabled={disableContinue}>SEARCH</Button>
                        </form>
                    </div>
                </div>
            }
        </>
    )
}
export default AdminDashboard