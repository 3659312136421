import React from 'react'
import style from './ReportContainer.module.scss'

function RecommendedText() {
    return (
        <div className={style.reconTextContainer}>
            <div className={style.bulletPoint}></div>
            <div className={style.reconText}>RECOMMENDED (1 CURRENTLY INCLUDED WITH MD-03)</div>
        </div>
    )
}

export default RecommendedText