import React from 'react';
import {Col, Collapse, Row} from "antd";
import style from "../Report.module.scss";
import {Pie} from "react-chartjs-2";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";

function BacteriaPlotPanel({ Text, Panel, descCol1, descCol2, colorList, textColorList, bacteriaInfoList, chartOptions, plotBacteriaGraph, buttonColor }) {
    return (
        <>
            <Row gutter={[20]} style={{ rowGap: '20px' }}>
                <Col xs={24} sm={24} md={24} xl={12}>
                    {/* <div className={style.fullWidthTile1}> */}
                    <Text className={style.bodyText1}>
                        {/*We all have hundreds, even thousands, of microbes living in our skin microbiome. Here you can find your top “good'' microbes listed as a percentage relative to each other. This list can change, and usually does 2-4 times a year due to a variety of factors like climate, stress level, diet, hormone changes, and household members.*/}
                        {descCol1}
                    </Text>
                    {/* </div> */}
                </Col>
                <Col xs={24} sm={24} md={24} xl={12}>
                    {/* <div className={style.fullWidthTile2}> */}
                    <Text className={style.bodyText1}>
                        {/*These microbes usually serve important functions, such as protecting your skin barrier, ensuring appropriate sebum production, contributing to microbial diversity, and limiting the growth of other bacteria.*/}
                        {descCol2}
                    </Text>
                    {/* </div> */}
                </Col>
            </Row>
            <Row className={style.boxMargin}>
                <Col xs={24} sm={24} md={24} xl={10}>
                    <div className={style.fullWidthTile1}
                         style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {(bacteriaInfoList && bacteriaInfoList.length > 0) &&
                            <Pie data={plotBacteriaGraph(bacteriaInfoList, colorList)}
                                 options={chartOptions} style={{ maxWidth: '400px', maxHeight: '400px' }} />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} xl={14}>
                    <div className={style.fullWidthTile2}
                         style={{ height: '100%', display: 'flex',
                             flexDirection: 'column', justifyContent: 'center' }}>
                        <div className="pieChartCollapse">
                            <Collapse bordered={false} defaultActiveKey={['0']}
                                      accordion={true} expandIconPosition="end"
                                      className={`${style.reportCollapse} ${style.pieChartCollapse}`}
                                      expandIcon={({ isActive }) =>
                                          isActive ?
                                              <MinusOutlined style={{ fontSize: '18px', color: buttonColor }} /> :
                                              <PlusOutlined style={{ fontSize: '18px', color: buttonColor }} />
                                      }>
                                {bacteriaInfoList.map((v, i) => {
                                    return v &&
                                        <Panel key={i} style={{ backgroundColor: colorList[i], border: 'none' }}
                                               header={
                                                <div className={style.bacteriaPanel}>
                                                       <div style={{ background: colorList[i], color: textColorList[i] }}
                                                         className={style.positionedHeaderBlock}>
                                                        {Number(v.percentage) < 1 ? '<1' :
                                                            (Number(v.percentage) > 99 ? '>99' :
                                                                Number(v.percentage))}%
                                                    </div>
                                                    <Text className={style.bodyText1}
                                                           style={{ paddingRight: '30px', textTransform: 'uppercase', color: textColorList[i] }}>
                                                        {v.bacteria_name}
                                                    </Text>
                                                </div>
                                        }>
                                            <div>
                                                <div className={style.bodyText2} style={{ color: textColorList[i] }}>
                                                    { v.description ? v.description : v.bacteria_name }
                                                </div>
                                            </div>
                                        </Panel>;
                                })}
                            </Collapse>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default BacteriaPlotPanel;