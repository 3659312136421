import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import "./layouts/App.scss";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store, persistor } from "@ph/common/store/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} persistor={persistor}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// import React from 'react';
// // import ReactDOM from 'react-dom';
// import './index.scss';
// import { hydrate, render } from 'react-dom';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// const rootElement = document.getElementById('root');
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

