import React from 'react';
import { Tabs } from 'antd';
import style from './PhTabs.module.scss'

function PhTabs({
    defaultActiveKey,
    onTabChange,
    tabItems,
    tabSize = 'small',
    className = '',
    ...props
}) {
    return (
        <Tabs
            defaultActiveKey={defaultActiveKey}
            onChange={onTabChange}
            size={tabSize}
            items={tabItems}
            className={`${style.PhTabs} ${className}`}
            {...props}
        />
    )
}

export default PhTabs