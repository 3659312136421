import React from 'react'
import style from './ReportContainer.module.scss'

function ProductHelpLabel({ text, justify = 'center' }) {
    return (
        <div className={style.productHelpLabel} style={{ justifyContent: justify }}>
            {text}
        </div>
    )
}

export default ProductHelpLabel