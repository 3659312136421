import { useEffect } from 'react';
import { AuthToken } from "@ph/subject/src/api_services/localstorage";
import { message, Space, Spin } from 'antd';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userDataActions } from "@ph/common/store/userData.reducer";
import connectionInstance from "@ph/subject/src/api_services/connection";
import Cookies from 'universal-cookie';

const HandleToken = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  // const user = useSelector((state) => state.userData);

  const getUserInfo = async () => {
    try {
      const res = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/subject`
      );
      if (res?.data) {
        console.log(res.data);
        dispatch(userDataActions.updateUserData(res.data));
        cookies.remove('pageToRedirect', { domain: 'parallelhealth.io' });
        // const returnToUrl = getLocalStorage('returnTo')
        //const returnToUrl = getCookie('returnToUrl')
        const returnToUrl = cookies.get('returnToUrl');
        console.log(returnToUrl, "ReturnTo URL!!!")
        if (returnToUrl) {
          cookies.set('returnToUrl', '', {path: '/'})
          cookies.remove('returnToUrl', {path: '/'});
          if (returnToUrl.indexOf('https') !== -1) {
            window.location = returnToUrl
          } else {
            navigate(returnToUrl);
          }
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      message.error("Something went wrong!");
      // console.log(error);
      window.location = `${process.env.REACT_APP_SHOPIFY_URL}`;
    }
  };

  useEffect(() => {
    let token = params.get('id_token');
    localStorage.setItem('ph_token', token);
    AuthToken.setToken('ph_token', token);
    if (AuthToken.getToken('ph_token')) {
      console.log('Initiating Get User Info!');
      getUserInfo()
    }
  }, []);

  return (
    <div style={{ marginTop: '60px', textAlign: 'center' }}>
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  )
}
export default HandleToken