import React from 'react';
import style from "./kitResult.module.scss";
import {Col, Row, Space} from "antd";

function SkinScoreSummaryColumn({
                                    Text,
                                    skin_type_name,
                                    microbiome_diversity,
                                    skin_age,
                                    cheek_skin_hydration,
                                    forehead_skin_hydration
                                }) {
    return (
        <Col xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{span: 24, order: 1}}
             xl={{span: 12, order: 2}} className={`${style.colRight}`}>
            <div className={style.reportData}>
                <Row>
                    <Col xs={12} sm={12} md={12} xl={12}>
                        <Space direction="vertical" size={4}>
                            <Text className={style.subtitle}>MICROBIOME TYPE</Text>
                            <Text className={style.title}>{skin_type_name}</Text>
                        </Space>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12}>
                        <Space direction="vertical" size={4}>
                            <Text className={style.subtitle}>MICROBIOME DIVERSITY SCORE</Text>
                            <Text className={style.title}>{microbiome_diversity}</Text>
                        </Space>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} style={{marginTop: '25px'}}>
                        <Space direction="vertical" size={4}>
                            <Text className={style.subtitle}>SKIN MICROBIOME AGE</Text>
                            <Text className={style.title}>
                                <small className='hide-on-desktop'>&nbsp;</small>
                                {skin_age}
                            </Text>
                        </Space>
                    </Col>
                    <Col xs={12} sm={12} md={12} xl={12} style={{marginTop: '25px'}}>
                        <Space direction="vertical" size={4}>
                            <Text className={style.subtitle}>SKIN HYDRATION LEVEL</Text>
                            <Text className={`${style.title} ${style.hydrationLevels} clearfix`}>
                                <div className='float-left'>
                                    <small>Cheek: </small><span>{cheek_skin_hydration}</span>
                                </div>
                                <div className='float-right'>
                                    <small>Forehead: </small><span>{forehead_skin_hydration}</span>
                                </div>
                            </Text>
                        </Space>
                    </Col>
                </Row>
                <div className={`${style.sectionSeparator} show-only-on-desktop`}></div>
                <Text
                    className={`${style.subtitle} show-only-on-desktop`}>TARGETING: <strong>?</strong></Text>
            </div>
        </Col>
    );
}

export default SkinScoreSummaryColumn;