import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {
    getPrescriptionCount,
    getPrescriptionList,
    movePrescriptionToPendingState
} from "../../AdminLabPortal/AdminPortalAPIService";
import {Button, message, Modal, Table} from "antd";
import {paginationDef} from "../../AdminLabPortal/LabPortalConstants";
import {
    completedPrescriptionColList,
    pendingPrescriptionsColList,
    prescribedColList
} from "../PrescriptionPortalConstants";
import PrescriptionPortalTopSectionContainer
    from "../PrescriptionPortalTopSectionContainer/PrescriptionPortalTopSectionContainer";
import {sortOrderMap} from "@ph/common/global/Constants";

function PrescriptionList() {
    const prescriptionStates = [
        {
            label: 'Pending (0)',
            value: 'pending_review',
        },
        {
            label: 'Order Prescription (0)',
            value: 'prescribed',
        },
        {
            label: 'Completed (0)',
            value: 'completed'
        },
    ];
    const [allPrescriptionStates, setAllPrescriptionStates] = useState(prescriptionStates);
    const [activePrescriptionState, setActivePrescriptionState] = useState(localStorage.getItem('savedPrescriptionState') || 'pending_review');
    const [totalCount, setTotalCount] = useState(0);

    const [fetchingSubmissions, setFetchingSubmissions] = useState(false);
    const [prescriptionSubmissions, setPrescriptionSubmissions] = useState([]);
    const [prescriptionListColDefinition, setPrescriptionListColDefinition] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [confirmMoveToPendingState, setConfirmMoveToPendingState] = useState(false);
    const [movingToPendingState, setMovingToPendingState] = useState(false);
    const [selectedPrescriptionOrderId, setSelectedPrescriptionOrderId] = useState(0);
    const [refreshCount, setRefreshCount] = useState(0);
    const [subjectNameFilter, setSubjectNameFilter] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('creation_date');

    const [activePrescriptionType, setActivePrescriptionType] = useState(localStorage.getItem('currentNavLabel') || 'Acne');

    const location = useLocation();
    let navigate = useNavigate();

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const fetchPrescriptionList = (prescriptionType) => {
        setPrescriptionSubmissions([]);
        setTotalCount(0);
        setFetchingSubmissions(true);
        setRefreshCount(refreshCount + 1);
        getPrescriptionList(prescriptionType,
            {
                status: activePrescriptionState,
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
                startAndEndDate,
                subjectNameFilter,
                sortOrder,
                sortBy
            }).then(res => {
            if (res?.data?.prescription_order_list) {
                setTotalCount(res.data.count);
                setPrescriptionSubmissions(res.data.prescription_order_list);
            }
        }).catch(error => {
            message.error(error.response.data.message);
        }).finally(() => setFetchingSubmissions(false));
    };

    const getLatestPrescriptionCount = (prescriptionType) => {
        setPrescriptionSubmissions([]);
        setTotalCount(0);
        setFetchingSubmissions(true);
        setAllPrescriptionStates(prescriptionStates);
        getPrescriptionCount(prescriptionType).then((res) => {
            if (!res.data[prescriptionType]) return;
            // setActivePrescriptionStateCounts(res.data[prescriptionType]);
            let updatePrescriptionStates = prescriptionStates.map((state) => {
                const finalCount = res.data[prescriptionType][state.value];
                state.label = state.label.replace(/\d/, finalCount);
                return state;
            });
            setAllPrescriptionStates(updatePrescriptionStates);
        }).catch((error) => {
            console.log(error);
        }).finally(() => fetchPrescriptionList(prescriptionType));
    };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (subjectName) => {
        setCurrentPage(1);
        setSubjectNameFilter(subjectName);
    };

    const onPrescriptionStateChangeEvent = (savedPrescriptionState) => {
        setCurrentPage(1);
        localStorage.setItem('savedPrescriptionState', savedPrescriptionState);
        setActivePrescriptionState(savedPrescriptionState);
    };

    const moveToPendingClickHandler = (prescription_order_id) => {
        setSelectedPrescriptionOrderId(prescription_order_id);
        setConfirmMoveToPendingState(true);
    };

    const confirmClickHandler = () => {
        setMovingToPendingState(true);
        movePrescriptionToPendingState(selectedPrescriptionOrderId).then(() => {
            message.success("The prescription is moved to pending state.");
            getLatestPrescriptionCount(activePrescriptionType);
        }).catch((error) => {
            message.error(error.response.data.message);
        }).finally(() => {
            setMovingToPendingState(false);
            setConfirmMoveToPendingState(false);
        });
    };

    useEffect(() => {
        if (activePrescriptionState === "pending_review") {
            setPrescriptionListColDefinition(pendingPrescriptionsColList(navigate));
        } else if (activePrescriptionState === "prescribed") {
            setPrescriptionListColDefinition(prescribedColList(navigate, moveToPendingClickHandler));
        } else {
            setPrescriptionListColDefinition(completedPrescriptionColList(navigate));
        }
        getLatestPrescriptionCount(activePrescriptionType);
    }, [currentPage, activePrescriptionState, activePrescriptionType, startAndEndDate, subjectNameFilter, sortOrder, sortBy]);

    useEffect(() => {
        setCurrentPage(1);
        setActivePrescriptionType(localStorage.getItem('currentNavLabel'));
    }, [location]);

    const handlePrescriptionTableChange = (pagination, filters, sorter) => {
        const {field, order} = sorter;
        if ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder)) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            } else {
                setSortOrder('');
            }
            setCurrentPage(1);
        }
    };

    return (
        <>
            <PrescriptionPortalTopSectionContainer allPrescriptionStates={allPrescriptionStates}
                                                   activePrescriptionState={activePrescriptionState}
                                                   prescriptionStateChangeEventHandler={onPrescriptionStateChangeEvent}
                                                   timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                                                   setSubjectNameFilter={handleSearchFilterChange}/>
            <Table
                loading={fetchingSubmissions}
                columns={prescriptionListColDefinition}
                dataSource={prescriptionSubmissions}
                pagination={pagination}
                className='lab-portal-table'
                onChange={handlePrescriptionTableChange}
            />

            <Modal className='ph-confirmation-modal' title='' open={confirmMoveToPendingState}
                   closable={false} maskClosable={!movingToPendingState}
                   footer={null}>
                <div>
                    <div className='text-center body-txt-lg'>This action will move the Submission, back to pending
                        state!
                    </div>
                    <p className='text-center body-txt-lg'>Are you sure you want to make this change?</p>
                </div>
                <div className='ant-modal-confirm-btns'>
                    <Button className='ph-btn transparent' disabled={movingToPendingState}
                            onClick={() => setConfirmMoveToPendingState(false)}>CANCEL</Button>
                    <Button className='ph-btn' type='primary' disabled={movingToPendingState}
                            loading={movingToPendingState}
                            onClick={confirmClickHandler}>CONFIRM</Button>
                </div>
            </Modal>
        </>
    )
}

export default PrescriptionList;