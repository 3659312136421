import {getFriendlyKitCode} from "@ph/common/global/Helpers";
import moment from "moment/moment";
import { dateFormats, GLOBAL_SEARCH_INIT } from "@ph/common/global/Constants";
import { PH_checked, PH_CrossRed } from "@ph/common/assets/index";

export const defaultKitManagementColumnList = [
    {
        title: 'Kit Code',
        dataIndex: 'kitcode',
        key: 'kitcode',
        render: (kitCode) => getFriendlyKitCode(kitCode)
    },
    {
        title: 'Kit Type',
        dataIndex: 'kit_type',
        key: 'kit_type',
    },
    {
        title: 'Registration Date',
        dataIndex: 'registration_date',
        key: 'registration_date',
        sorter: true,
        render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
    }
];

export const paginationDef = {
    position: ['bottomCenter'],
    hideOnSinglePage: true,
    showSizeChanger: false,
    pageSize: 10
};

export const isRowExpanded = (key, expandedRowKey) => {
    return key === expandedRowKey;
};

export const CheckColum = ({ valid }) => {
    if (valid) {
        return <img src={PH_checked} style={{ marginLeft: '6px' }} />
    } else {
        return <img src={PH_CrossRed} />
    }
}

export const updateGlobalSearchStates = (id, globalSearch, setGlobalSearch, setKitCodeFilter, setKitsData, setTotalCount, setFetching) => {
    if (id === localStorage.getItem("currentNavLabel") && globalSearch.GSKitCode) {
        setKitCodeFilter(globalSearch.GSKitCode)
        setKitsData(globalSearch.GSKitResponse?.kits_info_list)
        setTotalCount(1)
        setFetching(false);
        setTimeout(() => {
            setGlobalSearch(GLOBAL_SEARCH_INIT)
        }, 100)
    }
}