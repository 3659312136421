import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";

const RedirectionFromBetaSignUp = () => {
    let navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, []);

    return (
        <FullPageLoader />
    )
}
export default RedirectionFromBetaSignUp