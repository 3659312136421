import connectionInstance from "@ph/subject/src/api_services/connection";

const baseUrl = process.env.REACT_APP_API_URL

export const getHealthProfileStatus = (kitcode) => connectionInstance.get(`${baseUrl}/healthprofile/${kitcode}`);


// Prescriptions

export const getAllPrescriptions = () => connectionInstance.get(`${baseUrl}/prescription/all`);
export const getPrescriptionRecommendation = () => connectionInstance.get(`${baseUrl}/prescription/recommendation`);
export const getPersonalizePrescription = (prescription_order_id) => connectionInstance.get(`${baseUrl}/prescription/${prescription_order_id}/prescription_subject_jotform_request`);
export const postPersonalizePrescription = (prescription_order_id) => connectionInstance.post(`${baseUrl}/prescription/${prescription_order_id}/prescription_subject_jotform_request`);
export const getPrescriptionMI = (prescription_order_id) => connectionInstance.get(`${baseUrl}/prescription/${prescription_order_id}/physician_jotform_submission_data`);