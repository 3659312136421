import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { SERVICE_DASHBOARD_SIDE_NAV, SERVICE_DASHBOARD_ROOT_NAV } from '../../../utils/nav-menu-items';
import style from './sidebar.module.scss';
import { convertToURIPath } from '@ph/common/global/Helpers';
import { getPrescriptionCount, getRecordsCount } from "../../AdminLabPortal/AdminPortalAPIService";
import { getServiceDashboardRecordsCount } from 'src/component/AdminServiceDashboard/ServiceDashboardAPIService';

const { Sider } = Layout;


function ServiceDashboardSidebar({ refreshSideBarCount, setRefreshSideBarCount }) {
    const currentLocation = useLocation();
    const pathName = currentLocation.pathname.replace("/", "");
    let navigate = useNavigate();
    const [currentNav, setCurrentNav] = useState(pathName);
    const [menuItemsList, setMenuItemsList] = useState([]);
    const [sideNavItemsList, setSideNavItemsList] = useState(Object.entries(SERVICE_DASHBOARD_SIDE_NAV));

    const getItem = (label, key, children) => {

        if (children) {
            return {
                key,
                label,
                children
            };
        }
        return {
            key,
            label
        };

    }

    const subMenuNavItemBuilder = ({ name, id, count, key }) => {
        if (key === currentNav) {
            localStorage.setItem('currentNavLabel', name);
            localStorage.setItem('activePrescriptionType', id);
            localStorage.setItem('activePrescriptionCount', count);
        }
        return <div className='clearfix'>
            <span className='fl'>{name}</span>
            <span className='fr'>{count}</span>
        </div>;
    };

    const updateServiceDashboardCount = () => {
        getServiceDashboardRecordsCount()
            .then(recordsCountSuccessHandler)
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setMenuItemsList([]);
                sideNavItemsList.forEach(([rootItem, navItem]) => {
                    let subMenuItems = navItem.map(subMenuItem => {
                        subMenuItem.key = convertToURIPath(subMenuItem.name, 'service-dashboard');
                        return getItem(subMenuNavItemBuilder(subMenuItem), subMenuItem.key);
                    });

                    const newItems = subMenuItems.length > 0 ? getItem(rootItem, rootItem, subMenuItems) : getItem(rootItem, rootItem)
                    const newList = [newItems, { type: 'divider' }]
                    setMenuItemsList(prevState => [...prevState, ...newList]);
                });
            });
    };

    function recordsCountSuccessHandler(result) {
        if (!result || !result.data) {
            return;
        }
        const { data } = result;
        setSideNavItemsList(prevState => {
            prevState.forEach(([rootItem, navItem]) => {
                navItem.forEach((navObj) => {
                    navObj.count = 0;
                    if (data.hasOwnProperty(navObj.id)) {
                        navObj.count = data[navObj.id] || 0;
                    }
                })
            })
            return [...prevState];
        });
    }

    useEffect(() => {
        updateServiceDashboardCount();
    }, [currentNav, refreshSideBarCount]);

    function handleClick({ key, keyPath, ...otherProps }) {
        if (keyPath && keyPath.length > 1 && SERVICE_DASHBOARD_SIDE_NAV[keyPath[1]]) {
            let menuItem = SERVICE_DASHBOARD_SIDE_NAV[keyPath[1]].find((el) =>
                convertToURIPath(el.name, 'service-dashboard') === key) || {};
            localStorage.setItem('currentNavLabel', menuItem.name);
        }
        if (keyPath && keyPath.length === 1 && keyPath[0]) {
            localStorage.setItem('currentNavLabel', keyPath[0]);
            navigate(`/${convertToURIPath(keyPath[0], 'service-dashboard')}`);
        } else {
            navigate(`/${convertToURIPath(key)}`);
        }
        setCurrentNav(key);
    }

    return <Sider width={260} className={style.portalSidebar}>
        <Menu
            mode="inline"
            defaultSelectedKeys={[pathName]}
            defaultOpenKeys={SERVICE_DASHBOARD_ROOT_NAV}
            onClick={handleClick}
            selectedKeys={[currentNav]}
            multiple={false}
            className="sidebar"
            items={menuItemsList}
        />
    </Sider>;
}

export default ServiceDashboardSidebar;