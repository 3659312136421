import React, { useEffect } from 'react'
import { Typography } from 'antd';
import style from './hydrationRangeSlider.module.scss';

const { Text } = Typography;

const HydrationRangeSlider = (props) => {
    const { rangeMin, rangeMax, cheekValue, foreheadValue } = props;

    useEffect(() => {
        setTimeout(() => {
            showVal(cheekValue, foreheadValue);
        }, 1000);
        // }
    }, []);

    const showVal = (cheekVal, foreheadVal) => {
        var cheek = document.getElementById('cheek');
        var forehead = document.getElementById('forehead');
        var track = document.getElementById('track');
        var thumbContainer = document.getElementById('thumbContainer');
        var trackWidth = track.getBoundingClientRect().width;
        thumbContainer.setAttribute('style', 'width: ' + (Number(trackWidth)) + 'px');
        var thumbWidth = cheek.getBoundingClientRect().width;

        if (cheekVal) {
            const newCheekVal = ((Number(cheekVal) - Number(rangeMin)) * 100) / (Number(rangeMax) - Number(rangeMin));
            cheek.setAttribute('style', 'left: ' + `calc(${newCheekVal}% - ${(Number(thumbWidth) * newCheekVal / 100)}px);`);
        } else {
            cheek.setAttribute('style', 'left: 0');
        }

        if (foreheadVal) {
            const newForeheadVal = ((Number(foreheadVal) - Number(rangeMin)) * 100) / (Number(rangeMax) - Number(rangeMin));
            forehead.setAttribute('style', 'left: ' + `calc(${newForeheadVal}% - ${(Number(thumbWidth) * newForeheadVal / 100)}px);`);
        } else {
            forehead.setAttribute('style', 'left: 0');
        }
    };

    const handleThumbClick = (cheek) => {
        var cheekThumb = document.getElementById('cheek');
        var foreheadThumb = document.getElementById('forehead');
        if (cheek) {
            cheekThumb.style.zIndex = '300';
            foreheadThumb.style.zIndex = '200';
            // cheekThumb.setAttribute('style', `${cheekThumb.getAttribute("style")} z-index: 900;`);
            // foreheadThumb.setAttribute('style', `${foreheadThumb.getAttribute("style")} z-index: 800;`);
        } else {
            cheekThumb.style.zIndex = '200';
            foreheadThumb.style.zIndex = '300';
            // cheekThumb.setAttribute('style', `${cheekThumb.getAttribute("style")} z-index: 800;`);
            // foreheadThumb.setAttribute('style', `${foreheadThumb.getAttribute("style")} z-index: 900;`);
        }
    };

    return (
        <div className={style.slideContainer}>
            {!(cheekValue || foreheadValue) ?
                <div className={style.NAContainer}>
                    <svg className={style.hexagon} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#FFFFFF" stroke="#767472" strokeWidth="3" />
                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#767472" className={style.NAtext}>
                            NA
                        </text>
                    </svg>
                    <div>
                        <div className={style.smallText} style={{textAlign: 'left'}}>
                                Sample Not Received/Damaged/Result Not Generated
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className={style.textContainer}>
                        <Text className={style.smallText} style={{ position: 'absolute', left: '10px' }}>Dry</Text>
                        {/* <Text className={style.smallText} style={{ position: 'absolute', width: 'fit-content', left: '50%', transform: 'translate(-50%)' }}>Moderate</Text> */}
                        <Text className={style.smallText} style={{ position: 'absolute', right: '10px' }}>Oily</Text>
                    </div>
                    <div className={style.inputContainer}>
                        <div className={style.thumbContainer} id='thumbContainer'>
                            <div className={style.thumbForehead} id="forehead" onClick={() => handleThumbClick(false)}>
                                <svg className={style.hexagon} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#FFFFFF" stroke="#767472" strokeWidth="3" />
                                    {foreheadValue ?
                                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#767472" className={style.valueText}>
                                            {foreheadValue}
                                        </text>
                                        :
                                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#767472" className={style.NAtext}>
                                            NA
                                        </text>}
                                </svg>
                            </div>
                            {/* width="110" height="110" */}
                            <div className={style.thumbCheek} id="cheek" onClick={() => handleThumbClick(true)}>
                                <svg className={style.hexagon} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#FFFFFF" stroke="#BAB9B8" strokeWidth="3" />
                                    {cheekValue ?
                                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#BAB9B8" className={style.valueText}>
                                            {cheekValue}
                                        </text>
                                        :
                                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#BAB9B8" className={style.NAtext}>
                                            NA
                                        </text>}
                                </svg>
                            </div>
                        </div>
                        <div className={style.track} id="track"></div>
                    </div>
                </>
            }
        </div>
    )
}

export default HydrationRangeSlider;