import React from 'react';
import style from "../Report.module.scss";
import {Button, Divider} from "antd";
import ReadMoreDescContainer from "../ReadMoreDescContainer";
import {navigateToShopifyUrl} from "../reportUtils";
import { button_arrow } from '@ph/common/assets/index';

function RecommendedMBSerumCard({Text, Paragraph, reportData}) {
    const serum_recommendations = (reportData.serum_recommendations.length) ? reportData.serum_recommendations[0] : {};

    return (
        <div className={style.tile2}>
            <Text className="h4">Recommended Microbiome <strong>SERUM</strong></Text>
            <div style={{ height: '2px' }} className={style.showInSmallScreen}></div>
            <div className={style.tileContent}>
                {serum_recommendations.image_url ?
                    <img src={serum_recommendations.image_url}
                         className={style.tileImage + " " + style.pointContent}
                         onClick={() => navigateToShopifyUrl(serum_recommendations)} alt='' />
                    :
                    <img className={style.placeHolderImage} alt='' />
                }
                <div style={{ display: 'flex', flexDirection: 'column',
                    justifyContent: 'flex-start', gap: '20px', position: 'relative' }}>
                    <Paragraph className={style.bodyText1 + " " + style.pointContent}
                               ellipsis={{ rows: 2 }}
                               onClick={() => navigateToShopifyUrl(serum_recommendations)}>
                        <strong>{serum_recommendations.name && serum_recommendations.name}</strong>
                    </Paragraph>
                    <div className={style.descContainer}>
                        {serum_recommendations.shortDesc &&
                            <ReadMoreDescContainer Paragraph={Paragraph}
                                                   productId={serum_recommendations.product_id}
                                                   productName={serum_recommendations.name}
                                                   description={serum_recommendations.shortDesc} />
                        }
                    </div>
                    <Button type="primary" className={`${style.button} ${style.preOrder}`}
                            onClick={() => navigateToShopifyUrl(serum_recommendations)}>
                        Buy Now
                        <Divider type="vertical"
                            style={{ background: '#fff', height: '70%', margin: '0 10px' }} />
                        ${serum_recommendations.price}
                        <img src={button_arrow} className='ph-btn-arrow' />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default RecommendedMBSerumCard;