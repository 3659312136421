import style from './MegaMenu.module.scss';
import { Popover } from 'antd';
import MegaMenuCards from './MegaMenuCards';

function MegaMenu({
    placement = 'top',
    title = '',
    rootButton,
    menuConfig,
    ...props
}) {
    return (
        <Popover
            placement={placement}
            title={title}
            content={<div className='center-flex'><MegaMenuCards menuConfig={menuConfig} /></div>}
            overlayClassName={style.megaMenu}
            arrow={false}
            {...props}
        >
            {rootButton}
        </Popover>
    )
}

export default MegaMenu
