import ReactStars from 'react-stars'
import React from 'react'
import { render } from 'react-dom'


function RatingStars() {
    const ratingChanged = (newRating) => {
        console.log(newRating)
    }
    return (
        < ReactStars
            count={5}
            value={5}
            size={26}
            edit={false}
            color2={'#2E5ACC'}
        />
    )
}

export default RatingStars

