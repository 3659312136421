import React from 'react'
import { Typography } from 'antd';
import style from '../AdminDashboard/adminDashboard.module.scss';

const { Text } = Typography;

const Home = () => {

    return (
        <div className={style.adminContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                <Text className={style.h1Text}>Hi,Welcome Back!</Text>
            </div>
        </div>      
    )
}
export default Home