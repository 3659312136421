import { Typography } from "antd";
import style from "./emptyData.module.scss";
import { Divider } from 'antd';

const { Text } = Typography;

function EmptyData({
                title = "",
                link = "#"
}) {
  return (
        <div className={style.emptyDataContainer} >
            <div className={style.divider}> </div>
                <div className={style.textContainer}>
                    <Text className={style.text}>
                        { title } 
                    </Text>
                </div>
            <div className={style.divider}> </div>
        </div>
  )
}

export default EmptyData