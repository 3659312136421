import React, { useEffect, useState } from "react";
import { Typography, Space, Drawer } from "antd";
// import Icon from '@ant-design/icons';
import { beta_profile, cart_report_beta, PH_menu_icon, PH_menu_icon_light } from "@ph/common/assets";
import style from "./betaHeader.module.scss";
import { useLocation } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import useScrollingUp from '../UseScrollingUp';

const { Text, Link, Title } = Typography;

const BetaHeader = (props) => {
    const scrolled = useScrollingUp();
    const { darkHeader, reportLayout } = props;
    let location = useLocation();
    const [currentPath, setcurrentPath] = useState(location.pathname);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const user = useSelector((state) => state.userData.userData);

    useEffect(() => {
        console.log("currentPath: ", currentPath);
        setcurrentPath(location.pathname);
    }, [location]);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('#desktopHeader');
        if(!header) return ;
        const scrollTop = window.scrollY;
        if(header && header.classList) {
            scrollTop >= 250 ?
                header.classList.add(style.headerContainer) :
                header.classList.remove(style.headerContainer);
        }
    };

    return (
        !(currentPath.toString().includes('getstarted')) ?
            <>
                <div className={style.hideHeaderInSmallScreen}>
                    <header className={darkHeader ? (scrolled ? style.stickyHeader + " " + style.headerContainer + " " + style.darkHeader : style.headerContainer + " " + style.darkHeader) : (scrolled ? style.stickyHeader + " " + style.headerContainer : style.headerContainer)}>
                        {/* style={reportLayout ? { borderBottom: '2px solid #E18672' } : { borderBottom: 'none' }}> */}
                        <div className="viewBoxWidth" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div className={style.logoSection}>
                                <img alt="Parallel" style={{ cursor: 'pointer' }} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}`} />
                            </div>
                            <div className={style.buttonSection}>
                                <Space className={style.space}>
                                    <Link>
                                        <Text className={style.linkText} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}`}>EXCLUSIVE SHOP</Text>
                                    </Link>
                                    <Link href="/report">
                                        <Text className={style.linkText + " " + style.active}>MY DASHBOARD</Text>
                                    </Link>
                                    <Link onClick={() => window.location = `https://www.parallelhealth.io/`}>
                                        <Text className={style.linkText}>PARALLEL HEALTH MAIN WEBSITE</Text>
                                    </Link>
                                    {/* <img src={darkHeader ? search_icon_light : search_icon} alt="Parallel" className={style.icon} /> */}
                                    <Link onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/account`}>
                                        <img src={beta_profile} alt='' className={style.icon} />
                                    </Link>

                                    <div className={style.verticalSeparator}></div>
                                </Space>
                                <Space>
                                    <img src={cart_report_beta} className={style.cartIcon} alt=''
                                         onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/cart`}/>
                                </Space>
                            </div>
                        </div>

                        {/* <Row style={{ height: 'inherit' }}>
                            <Col xs={24} sm={19} md={19} xl={19} className={style.headerTabContainer}>
                                <div className={style.logoSection}>
                                    <img src={darkHeader ? PH_logo_light : PH_logo_dark} alt="Parallel" />
                                </div>
                                {!reportLayout &&
                                    <div className={style.headerTab}>
                                        <Link className={style.headerTabLink}>
                                            <Text className={style.text}>TAKE QUIZ</Text>
                                        </Link>
                                        <Link className={style.headerTabLink}>
                                            <Text className={style.text}>OFFERINGS</Text>
                                        </Link>
                                        <Link className={style.headerTabLink}>
                                            <Text className={style.text}>SCIENCE</Text>
                                        </Link>
                                        <Link className={style.headerTabLink}>
                                            <Text className={style.text}>ABOUT US</Text>
                                        </Link>
                                        <Link className={style.headerTabLink}>
                                            <Text className={style.text}>STORIES</Text>
                                        </Link>
                                        <Link className={style.headerTabLink} to={`${process.env.REACT_APP_SHOPIFY_URL}/cart`}>
                                            <Text className={style.text}>PARALLELOGRAM</Text>
                                        </Link>
                                    </div>
                                }
                            </Col>
                            <Col xs={24} sm={5} md={5} xl={5} className={style.buttonSection}>
                                <Space className={style.space}>
                                    <Link>
                                        <Text className={style.linkText}>MY DASHBOARD</Text>
                                    </Link>
                                    <img src={darkHeader ? search_icon_light : search_icon} alt="Parallel" className={style.icon} />
                                    <Link href="/logout"><img src={darkHeader ? user_icon_light : user_icon} className={style.icon} /></Link>
                                    <div className={style.verticalSeparator}></div>
                                    <img src={darkHeader ? shopify_icon_light : shopify_icon} className={style.icon}
                                        onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/cart`} />
                                </Space>
                            </Col>
                        </Row> */}
                    </header>
                </div >

                {/* <div id="PHheader"> */}
                < div className={style.hideHeaderInBigScreen} >
                    <header className={darkHeader ? style.headerContainer + " " + style.darkHeader : style.headerContainer}>
                        <div className="viewBoxWidth" style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
                            {/* <div className={style.buttonSection}> */}
                            {/* <Space className={style.space}> */}
                            {/* <img src={darkHeader ? search_icon_light : search_icon} className={style.icon} /> */}

                            {/* <Button className={style.sidebarToggleButton} type="link" onClick={() => showDrawer()}> */}
                            <img src={darkHeader ? PH_menu_icon_light : PH_menu_icon} className={style.icon} alt="PH" onClick={() => showDrawer()} />
                            {/* </Button> */}
                            <div className={style.logoSection}>
                                <img alt="Parallel" />
                            </div>
                            <img src={cart_report_beta} className={style.cartIcon}
                                onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/cart`} />
                            {/* </Space> */}
                            {/* </div> */}
                        </div>
                        <Drawer
                            placement="left"
                            closable={true}
                            onClose={closeDrawer}
                            open={drawerVisible}
                            width={'80vw'}
                        >
                            <div className="sidebar-routes-section">
                                <div className="route-url" onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}`}>Exclusive Shop</div>
                                <div className="route-url">My Dashboard</div>
                                <div className="route-url" onClick={() => window.location = `https://www.parallelhealth.io/`}>Parallel Health Main Site</div>
                                <div className="route-url" onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/account`}>My Profile</div>
                            </div>
                        </Drawer>
                    </header>
                </div >
            </>
            : <></>
    );
};

export default BetaHeader;
