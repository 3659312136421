import React from "react";
import AdminComponent from "../component";
import { LAB_REPORT_NAV } from "@ph/common/utils/constants";

export const LAB_REPORT_ROOT_NAV = [
    LAB_REPORT_NAV.KIT_ACCESSION,
    LAB_REPORT_NAV.BATCH_MANAGEMENT,
    LAB_REPORT_NAV.HISTORY,
];

export const LAB_PORTAL_SIDE_NAV = {
    [LAB_REPORT_NAV.KIT_ACCESSION]: [
        {
            name: LAB_REPORT_NAV.REGISTERED_KITS,
            id: 'registered_kits',
            count: 0
        },
        {
            name: LAB_REPORT_NAV.RECEIVED_KITS,
            id: 'received_kits',
            count: 0
        },
        {
            name: LAB_REPORT_NAV.VALIDATED_KITS,
            id: 'valid_kits',
            count: 0
        },
        {
            name: LAB_REPORT_NAV.ON_HOLD_KITS,
            id: 'on_hold_kits',
            count: 0
        }
    ],
    [LAB_REPORT_NAV.BATCH_MANAGEMENT]: [
        {
            name: LAB_REPORT_NAV.ACTIVE_BATCHES,
            id: 'active_batch',
            count: 0
        },
        {
            name: LAB_REPORT_NAV.COMPLETED_BATCHES,
            id: 'complete_batch',
            count: 0
        },
        {
            name: LAB_REPORT_NAV.CANCELLED_BATCHES,
            id: 'cancelled_batch',
            count: 0
        }
    ],
    [LAB_REPORT_NAV.HISTORY]: [
        {
            name: LAB_REPORT_NAV.COMPLETED_KITS,
            id: 'completed_kits',
            count: 0
        },
        {
            name: LAB_REPORT_NAV.REJECTED_KITS,
            id: 'rejected_kits',
            count: 0
        }
    ]
};

export const LabPortalRoutesList = [
    {
        path: LAB_REPORT_NAV.REGISTERED_KITS,
        component: <AdminComponent.RegisteredKits id={LAB_REPORT_NAV.REGISTERED_KITS} />
    }, {
        path: LAB_REPORT_NAV.RECEIVED_KITS,
        component: <AdminComponent.ReceivedKits id={LAB_REPORT_NAV.RECEIVED_KITS} />
    }, {
        path: LAB_REPORT_NAV.VALIDATED_KITS,
        component: <AdminComponent.ValidatedKits id={LAB_REPORT_NAV.VALIDATED_KITS} />
    }, {
        path: LAB_REPORT_NAV.ON_HOLD_KITS,
        component: <AdminComponent.OnHoldKits id={LAB_REPORT_NAV.ON_HOLD_KITS} />
    }, {
        path: LAB_REPORT_NAV.ACTIVE_BATCHES,
        component: <AdminComponent.ActiveBatches id={LAB_REPORT_NAV.ACTIVE_BATCHES} />
    }, {
        path: LAB_REPORT_NAV.COMPLETED_BATCHES,
        component: <AdminComponent.CompletedBatches id={LAB_REPORT_NAV.COMPLETED_BATCHES} />
    }, {
        path: LAB_REPORT_NAV.CANCELLED_BATCHES,
        component: <AdminComponent.CancelledBatches id={LAB_REPORT_NAV.CANCELLED_BATCHES} />
    }, {
        path: LAB_REPORT_NAV.COMPLETED_KITS,
        component: <AdminComponent.CompletedKits id={LAB_REPORT_NAV.COMPLETED_KITS} />
    }, {
        path: LAB_REPORT_NAV.REJECTED_KITS,
        component: <AdminComponent.RejectedKits id={LAB_REPORT_NAV.REJECTED_KITS} />
    }];

export const PHYSICIAN_PORTAL_NAV_ITEMS = {
    PRESCRIPTION_SERVICES: 'Prescription Services',
    ACNE: 'Acne',
    ROSACEA: 'Rosacea',
    MELASMA: 'Melasma',
    FINE_LINES_WRINKLES: 'Fine Lines and Wrinkles'
};

export const PHYSICIAN_ROOT_NAV = [
    PHYSICIAN_PORTAL_NAV_ITEMS.PRESCRIPTION_SERVICES
];

export const PHYSICIAN_PORTAL_SIDE_NAV = {
    [PHYSICIAN_PORTAL_NAV_ITEMS.PRESCRIPTION_SERVICES]: [
        {
            name: PHYSICIAN_PORTAL_NAV_ITEMS.ACNE,
            id: 'Acne',
            count: 0
        },
        {
            name: PHYSICIAN_PORTAL_NAV_ITEMS.ROSACEA,
            id: 'Rosacea',
            count: 0
        },
        {
            name: PHYSICIAN_PORTAL_NAV_ITEMS.MELASMA,
            id: 'Melasma',
            count: 0
        },
        {
            name: PHYSICIAN_PORTAL_NAV_ITEMS.FINE_LINES_WRINKLES,
            id: 'Fine%20Lines%20and%20Wrinkles',
            count: 0
        }]
};

export const PhysicianPortalRoutesList = [
    {
        path: PHYSICIAN_PORTAL_NAV_ITEMS.ACNE,
        component: <AdminComponent.PrescriptionList/>
    }, {
        path: PHYSICIAN_PORTAL_NAV_ITEMS.ROSACEA,
        component: <AdminComponent.PrescriptionList/>
    }, {
        path: PHYSICIAN_PORTAL_NAV_ITEMS.MELASMA,
        component: <AdminComponent.PrescriptionList/>
    }, {
        path: PHYSICIAN_PORTAL_NAV_ITEMS.FINE_LINES_WRINKLES,
        component: <AdminComponent.PrescriptionList/>
    }
];

export const SERVICE_DASHBOARD_NAV_ITEMS = {
    KIT_TRACKING: 'Kit Tracking',
    GENERATE_KITS: 'Generate Kits',
    UNASSIGNED_ORDERS: 'Unassigned Orders',
    KITS: 'Kits',
    CUSTOMER_SUPPORT: 'Customer Support',
    REPORTS: 'Reports'
};

export const SERVICE_DASHBOARD_ROOT_NAV = [
    SERVICE_DASHBOARD_NAV_ITEMS.KIT_TRACKING,
    SERVICE_DASHBOARD_NAV_ITEMS.CUSTOMER_SUPPORT,
    SERVICE_DASHBOARD_NAV_ITEMS.REPORTS,
    SERVICE_DASHBOARD_NAV_ITEMS.GENERATE_KITS,
];

export const SERVICE_DASHBOARD_SIDE_NAV = {
    [SERVICE_DASHBOARD_NAV_ITEMS.KIT_TRACKING]: [
        {
            name: SERVICE_DASHBOARD_NAV_ITEMS.UNASSIGNED_ORDERS,
            id: 'unassigned_shopify_orders_count',
            count: 0
        },
        {
            name: SERVICE_DASHBOARD_NAV_ITEMS.KITS,
            id: 'kit_inventory_count',
            count: 0
        },
    ],
    // [SERVICE_DASHBOARD_NAV_ITEMS.CUSTOMER_SUPPORT]: [],
    // [SERVICE_DASHBOARD_NAV_ITEMS.REPORTS]: [],
    [SERVICE_DASHBOARD_NAV_ITEMS.GENERATE_KITS]: []
};

export const SerivceDashboardRoutesList = [
    {
        path: SERVICE_DASHBOARD_NAV_ITEMS.UNASSIGNED_ORDERS,
        component: <AdminComponent.UnassignedOrders />
    }, {
        path: SERVICE_DASHBOARD_NAV_ITEMS.KITS,
        component: <AdminComponent.OrderedKits />
    }, {
        path: SERVICE_DASHBOARD_NAV_ITEMS.CUSTOMER_SUPPORT,
        component: <AdminComponent.UnassignedOrders />
    }, {
        path: SERVICE_DASHBOARD_NAV_ITEMS.REPORTS,
        component: <AdminComponent.UnassignedOrders />
    },
    {
        path: SERVICE_DASHBOARD_NAV_ITEMS.GENERATE_KITS,
        component: <AdminComponent.GenerateKits />
    }
];
