import { Typography, Row, Col, Button, Grid } from 'antd';
import style from './ReportContainer.module.scss';
import { capitalizeFirstLetter } from '../reportUtils';

const { Text } = Typography
const { useBreakpoint } = Grid;

function ReportClinicalProduct({ product, title = 'PRODUCT HIGHLIGHT', actionButton, allowSelect = true, compact = false }) {

    const screens = useBreakpoint();
    const isMobile = screens.xs


    return (
        <Row className={`${style.reportSingleProduct}`}>
            <Col xs={8} xl={6} className={`${style.productCol} show-desktop-hide-mobile`}>
                <Text className={style.title}>{title}</Text>
                <div className={style.nameContainer}>
                    <Text className={style.productName}>{product?.name}</Text>
                </div>
            </Col>
            <Col xs={24} xl={10 - (actionButton?.colSpan ? actionButton?.colSpan : 0)} className={`${style.descriptionCol} show-desktop-hide-mobile`}>
                <Col xs={24}>
                    <div className={style.productDesc}>{capitalizeFirstLetter(product?.description?.short_text)}</div>
                </Col>
            </Col>
            {actionButton && actionButton?.text && (
                <Col xs={24} md={6} className={`${style.descriptionCol} show-desktop-hide-mobile`}>
                    <Button onClick={actionButton.onClick} className={style.actionButton}>{actionButton?.text}</Button>
                </Col>
            )}

            <Row className="show-mobile-hide-desktop">
                <Col xs={14} sm={17} md={18}>
                    <Row>
                        <Col xs={24} className={style.productCol}>
                            <Text className={style.title}>{title}</Text>
                            <div className={style.nameContainer}>
                                <Text className={style.productName}>{product?.name}</Text>
                            </div>
                        </Col>
                        <Col xs={24} className={style.descriptionCol}>
                            <div className={style.productDesc}>{capitalizeFirstLetter(product?.description?.short_text)}</div>
                        </Col>
                        <Col xs={24} className={style.descriptionCol}>
                            <Button onClick={actionButton.onClick} className={style.actionButton}>{actionButton?.text}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={10} sm={7} md={6} className={`${style.clinicalImage}`}>
                    <img src={product?.image_url} />
                </Col>
            </Row>

            <Col xs={24} xl={8} className="show-desktop-hide-mobile">
                <Row className={`${style.dividerRow} ${(compact && isMobile) ? style.nonImage : ''}`}>

                    <Col xs={8} sm={6} md={5} xl={10} className={style.imageCol}>
                        <img src={product?.image_url} />
                    </Col>

                    <Col xs={16} sm={18} md={19} lg={14} className={style.labelCol}>
                        <div className={style.labelRow}>
                            {/* {allowSelect && REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_short &&
                                <ProductHelpLabel text={REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_short} justify='left' />
                            } */}
                        </div>
                    </Col>
                </Row>

            </Col>
        </Row >
    )
}
export default ReportClinicalProduct;