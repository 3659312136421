import { createSlice } from "@reduxjs/toolkit";

const reportDataInitVal = {

};

const reportDataSlice = createSlice({
    name: "reportData",
    initialState: reportDataInitVal,
    reducers: {
        updateReportData(state, action) {
            if (action.payload) {
                state.reportData = action.payload;
            }
        }
    },
});

export const reportDataActions = reportDataSlice.actions;

export default reportDataSlice;
