import React from 'react'
import style from './ReportContainer.module.scss'
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants'
import { calculateDiscountPercentage } from '../reportUtils'
import { formatFloat } from '@ph/common/global/Constants'

function ProductPrice({ originalPrice, discountPrice, reportPlan, showCompact = true }) {
    let showDiscount = REPORT_PLAN_DETAILS[reportPlan]?.show_product_discount
    if (originalPrice === discountPrice) {
        showDiscount = false
    }

    return (
        <div
            className={`${style.priceContainer} ${showDiscount ? '' : style.extraPadding} ${showCompact ? style.compact : ''}`}

        >
            <div className={`${style.strikeContainer}`}>
                <span className={style.price}>{`$${showDiscount ? formatFloat(discountPrice) : formatFloat(originalPrice)}/MO`}</span>
                {showDiscount && (
                    <>
                        <span className={style.discountPrice}>{`$${formatFloat(originalPrice)}`}</span>
                    </>
                )}
            </div>
            {showDiscount && (
                <>
                    <span className={style.discountPercentage}>{`${calculateDiscountPercentage(originalPrice, discountPrice)}% OFF`}</span>
                </>
            )}

        </div>
    )
}

export default ProductPrice