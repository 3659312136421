import React from 'react';
import style from '../ReportV1/reportv1.module.scss';

import { Row, Col } from 'antd';
import {
    report_score_hex,
    report_skin_age,
    hydration_summary,
    sensitivity_summary,
    antibiotic_image
} from '@ph/common/assets';
import { addLeadingZero } from '../reportUtils';

function SummarySection({ reportData }) {

    return (
        // Summary Section
        <div className={style.scoreSection}>
            <Row className={`${style.row} ${style.headerRow} show-desktop-hide-mobile`}>
                <Col xs={24} md={5} className={style.scoreCol}>
                    <div className={`${style.scoreTitle} ${style.first}`}>
                        DIVERSITY
                    </div>
                </Col>
                <Col xs={12} md={5} className={`${style.scoreCol} ${style.scoreHalfColumn}`}>
                    <div className={style.scoreTitle}>
                        SKIN AGE
                    </div>
                </Col>
                <Col xs={12} md={5} className={`${style.scoreCol} ${style.scoreHalfColumn}`}>
                    <div className={style.scoreTitle}>
                        HYDRATION
                    </div>
                </Col>
                <Col xs={12} md={5} className={`${style.scoreCol} ${style.scoreHalfColumn}`}>
                    <div className={`${style.scoreTitle} ${style.bigTitle}`}>
                        SENSITIVITY
                    </div>
                </Col>
                <Col xs={12} md={4} className={`${style.scoreCol} ${style.scoreHalfColumn} ${style.last}`}>
                    <div className={`${style.scoreTitle} ${style.antiText} ${style.bigTitle}`}>
                        ANTIBIOTIC RESISTANCE
                    </div>
                </Col>
            </Row>
            <Row className={style.row}>
                <Col xs={24} md={5} className={style.scoreCol}>
                    <div className={`${style.scoreTitle} show-mobile-hide-desktop`}>
                        DIVERSITY
                    </div>
                    <div className={`${style.scoreContainer}`}>
                        <img src={report_score_hex} className={`${style.scoreImage} ${style.hexIcon}`} />
                        <span className={`${style.scoreText} text-light`}>{addLeadingZero(parseInt(reportData?.microbiome_diversity))}</span>
                    </div>
                </Col>
                <Col xs={12} md={5} className={`${style.scoreCol} ${style.scoreHalfColumn}`}>
                    <div className={`${style.scoreTitle} show-mobile-hide-desktop`}>
                        SKIN AGE
                    </div>
                    <div className={`${style.scoreContainer}`}>
                        <img src={report_skin_age} className={`${style.scoreImage}`} />
                        <span className={`${style.scoreText}`}>{addLeadingZero(reportData?.skin_age)}</span>
                    </div>
                </Col>
                <Col xs={12} md={5} className={`${style.scoreCol} ${style.scoreHalfColumn}`}>
                    <div className={`${style.scoreTitle} show-mobile-hide-desktop`}>
                        HYDRATION
                    </div>
                    <div className={`${style.scoreContainer}`}>
                        <img src={hydration_summary} className={`${style.scoreImage} ${style.smallImage}`} />
                        <span className={`${style.scoreText}`}>{addLeadingZero(reportData?.average_hydration)}</span>
                    </div>
                </Col>
                <Col xs={12} md={5} className={`${style.scoreCol} ${style.scoreHalfColumn}`}>
                    <div className={`${style.scoreTitle} ${style.bigTitle} show-mobile-hide-desktop`}>
                        SENSITIVITY
                    </div>
                    <div className={`${style.scoreContainer}`}>
                        <img src={sensitivity_summary} className={`${style.scoreImage} ${style.smallImage}`} />
                        <span className={`${style.scoreText}`}>{addLeadingZero(reportData?.sensitivity)}</span>
                    </div>
                </Col>
                <Col xs={12} md={4} className={`${style.scoreCol} ${style.scoreHalfColumn} ${style.last}`}>
                    <div className={`${style.scoreTitle} ${style.antiText} ${style.bigTitle} show-mobile-hide-desktop`}>
                        ANTIBIOTIC RESISTANCE
                    </div>
                    <div className={`${style.scoreContainer}`}>
                        <img src={antibiotic_image} className={`${style.scoreImage} ${style.capsuleImage}`} />
                        <span className={`${style.scoreText}`}>{addLeadingZero(reportData?.antibiotic_resistance)}</span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SummarySection