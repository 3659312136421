import { createContext, useContext, useEffect, useState } from "react";
import { PROTOCOL } from "@ph/common/constants/Protocol/MD03Protocol"

const ProtocolContext = createContext()

export const useProtocolContext = () => useContext(ProtocolContext)

const initialValue = {
    initStep: 1,
}

export const ProtocolProvider = ({ children }) => {
    const [currStep, setCurrStep] = useState(initialValue.initStep)
    const [protocolData, setProtocolData] = useState(PROTOCOL)
    const [selectedResult, setSelectedResult] = useState({})
    const [variantsData, setVariantsData] = []


    const updateCurrStep = (number) => {
        setCurrStep(number)
    }
    const updateToNextStep = () => {
        setCurrStep(currStep + 1)
    }

    const updateToPreviousStep = () => {
        if (currStep > 1) {
            setCurrStep(currStep - 1)
        }
    }

    const getProtocolStepDataById = (id) => {
        const result = protocolData.filter(data => data.id === id)
        return result
    }

    const getStepDataById = (id, stepData) => {
        const result = stepData.filter(data => data.id === id)
        return result
    }

    // const toggleProductInResult = (stepId, stepProductId, product, selectedProducts) => {
    //     const productAlreadyPresent = selectedProducts.filter(item => item.id = product.id)
    //     if (productAlreadyPresent && productAlreadyPresent.length === 0) {
    //         let updateStep = selectedResult.filter(item => item.id === stepId)
    //         const updatedStepProducts = [
    //             ...updateStep[stepProductId],
    //             product
    //         ]
    //         setSelectedResult({
    //             ...selectedResult,
    //             updatedStepProducts
    //         })
    //     } else {
    //         let updateStep = selectedResult.filter(item => item.id === stepId)
    //         const updatedStepProducts = updateStep.filer(item => item !== product.id)
    //         setSelectedResult({
    //             ...selectedResult,
    //             updatedStepProducts
    //         })

    //     }
    // }
    const updateSelectionResult = (stepId, stepProductId, product, minNumberOfSelection, productQuantity = 1) => {
        setSelectedResult(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState));

            // Check if stepId exists
            if (newState.hasOwnProperty(stepId)) {
                const step = newState[stepId];

                // Check if stepProductId exists
                if (step.hasOwnProperty(stepProductId)) {
                    let productsArray = step[stepProductId];

                    // Check if product exists in productsArray
                    const existingProductIndex = productsArray.findIndex(p => p.product_id === product.product_id);

                    if (existingProductIndex !== -1) {
                        // If product already exists, remove it if minNumberOfSelection is 0
                        if (minNumberOfSelection === 0) {
                            productsArray.splice(existingProductIndex, 1);
                        } else {
                            return prevState;
                        }
                        // Otherwise, do nothing
                    } else {
                        // Add the product
                        const newProduct = { ...product, quantity: productQuantity };
                        productsArray.push(newProduct);

                        // Ensure productsArray length is less than minNumberOfSelection
                        if (minNumberOfSelection > 0 && productsArray.length > minNumberOfSelection) {
                            // If the length exceeds minNumberOfSelection, remove the first product
                            productsArray.shift();
                        }
                    }
                } else {
                    // Create a new products array if stepProductId doesn't exist
                    const newProduct = { ...product, quantity: productQuantity };
                    step[stepProductId] = [newProduct];
                }
            } else {
                // Create a new step object if stepId doesn't exist
                const newProduct = { ...product, quantity: productQuantity };
                newState[stepId] = {
                    [stepProductId]: [newProduct]
                };
            }
            return newState;
        });
    };

    const updateQuantitySelectionResult = (stepId, stepProductId, product, productQuantity = 1) => {
        setSelectedResult(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState));

            if (newState.hasOwnProperty(stepId)) {
                const step = newState[stepId];

                if (step.hasOwnProperty(stepProductId)) {
                    let productsArray = step[stepProductId];

                    const existingProductIndex = productsArray.findIndex(p => p.product_id === product.product_id);

                    if (existingProductIndex !== -1) {
                        if (productQuantity === 0) {
                            productsArray.splice(existingProductIndex, 1);
                        } else {
                            productsArray.splice(existingProductIndex, 1, { ...product, quantity: productQuantity });
                        }
                    }
                }
            }
            return newState;
        });
    };

    const modifySelectedResult = (stepId, stepProductId, productId, newProduct) => {
        setSelectedResult(prevState => {
            // Make a deep copy of the previous state
            const newState = JSON.parse(JSON.stringify(prevState));

            // Check if the stepId exists in newState
            if (newState.hasOwnProperty(stepId)) {
                const step = newState[stepId];

                // Check if the stepProductId exists in the step
                if (step.hasOwnProperty(stepProductId)) {
                    const productsArray = step[stepProductId];

                    // Find the index of the product with the given productId
                    const productIndex = productsArray.findIndex(product => product.product_id === productId);
                    // If the product with the given productId is found
                    if (productIndex !== -1) {
                        // Replace the product object at the found index with the newProduct
                        productsArray.splice(productIndex, 1, newProduct);
                    }
                }
            }
            return newState;
        });
    };


    useEffect(() => {
        const result = {};

        if (protocolData?.steps) {
            protocolData.steps.forEach(step => {
                const stepId = step.id;

                if (step?.step_data) {
                    step.step_data.forEach(stepItem => {
                        const stepProductId = stepItem.id;

                        if (stepItem?.defaultSelectId && Array.isArray(stepItem.defaultSelectId)) {
                            stepItem.defaultSelectId.forEach(defaultId => {
                                const product = stepItem.data.find(product => product.product_id === defaultId);

                                if (product) {
                                    if (!result.hasOwnProperty(stepId)) {
                                        result[stepId] = {};
                                    }

                                    if (!result[stepId].hasOwnProperty(stepProductId)) {
                                        result[stepId][stepProductId] = [];
                                    }

                                    result[stepId][stepProductId].push({ ...product, quantity: 1 });
                                }
                            });
                        }
                    });
                }
            });
        }

        setSelectedResult(result)

    }, [])

    return (
        <ProtocolContext.Provider
            value={{
                currStep,
                updateCurrStep,
                updateToNextStep,
                updateToPreviousStep,
                protocolData,
                selectedResult,
                updateSelectionResult,
                modifySelectedResult,
                updateQuantitySelectionResult,
                variantsData,
                setVariantsData
            }}
        >
            {children}
        </ProtocolContext.Provider>

    )
}



// const [selectedResult, setSelectedResult] = useState({
//     stepId1: {
//         stepProductId1: [
//             {
//                 ...product1
//             },
//             {
//                 ...product2
//             },

//         ],
//         stepProductId2: [
//             {
//                 ...product3
//             },
//             {
//                 ...product4
//             },

//         ]
//     },
//     stepId2: {
//         stepProductId3: [
//             {
//                 ...product5
//             },
//             {
//                 ...product6
//             },

//         ],
//         stepProductId4: [
//             {
//                 ...product7
//             },
//             {
//                 ...product8
//             },

//         ]
//     }

// })