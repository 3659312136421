import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, Switch, Typography, Upload} from "antd";
import Webcam from "react-webcam";
import style from './ValidateKitFormContent.module.scss';
import {InboxOutlined, WarningFilled} from "@ant-design/icons";
import {close_icon_dark} from "@ph/common/assets";

const {Dragger} = Upload;
const { Text } = Typography;

const WebcamModalComponent = ({ open, onClose, imgReceiver }) => {
    const webcamRef = useRef(null);
    const [modalTitle, setModalTitle] = useState('Take Photo');
    const [errorMessage, setErrorMessage] = useState('');
    const [takePhotoIsEnabled, setTakePhotoIsEnabled] = useState(true);
    const [fileList, setFileList] = useState([]);
    const [saveButtonText, setSaveButtonText] = useState('CAPTURE');

    const toggleTakePhoto = (checked) => {
        if(checked) {
            setModalTitle('Take Photo');
            setSaveButtonText('CAPTURE');
        } else {
            setModalTitle('Upload Image');
            setSaveButtonText('SAVE');
        }
        setTakePhotoIsEnabled(checked);
    };


    useEffect(() => {
        let stream = null;
        setErrorMessage('');

        const openWebcam = async () => {
            if (!stream) {
                try {
                    stream = await navigator.mediaDevices.getUserMedia({video: true});
                    webcamRef.current.video.srcObject = stream;
                } catch (ex) {
                    setErrorMessage('Unable to access the Web Cam!');
                }
            }
        };

        const closeWebcam = () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
                stream = null;
            }
        };

        if (open) {
            toggleTakePhoto(true);
            setFileList([]);
            openWebcam();
        } else {
            closeWebcam();
        }

        return () => {
            closeWebcam();
        };
    }, [open]);

    const handleCapture = () => {
        if(takePhotoIsEnabled) {
            const imageSrc = webcamRef.current.getScreenshot();
            imgReceiver(imageSrc);
        }
        onClose();
    };

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageDataURL = e.target.result;
            imgReceiver(imageDataURL);
            onClose();
        };
        reader.readAsDataURL(file);
    };

    const handleRemove = (file) => {
        setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
    };

    const handleBeforeUpload = (file) => {
        setFileList([...fileList, file]);
        return false;
    };

    return (
        <Modal
            open={open}
            onCancel={onClose}
            afterClose={onClose}
            closeIcon={<img src={close_icon_dark} alt='close icon'/>}
            destroyOnClose={true}
            width='45%'
            footer={[
                <Button className='ph-btn' type='primary' key="capture" disabled={errorMessage !== ''}
                        onClick={handleCapture}>
                    {saveButtonText}
                </Button>,
                <Button className='ph-btn' type='secondary' key="close" onClick={onClose}>
                    CANCEL
                </Button>,
            ]}
        >
            <Text className='h2'>{modalTitle}</Text>
            <div className={style.modalContent}>
                <div className={`${style.switchContainer} body-txt-md`}>
                    <div className={'hide'}>
                        <Text strong={true} className='body-txt-md'>Capture:</Text> <Switch
                        checked={takePhotoIsEnabled} onChange={toggleTakePhoto}/> <Text
                        className='body-txt-md'>{(takePhotoIsEnabled) ? 'Enabled' : 'Disabled'}</Text>
                    </div>
                    <Text className={errorMessage ? '' : 'hide'} type="danger">
                        <WarningFilled/> {errorMessage}
                    </Text>
                </div>
                {
                    takePhotoIsEnabled ?
                        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg"
                                style={{width: '100%'}}/> :
                        <Dragger
                            fileList={fileList}
                            beforeUpload={handleBeforeUpload}
                            onRemove={handleRemove}
                            showUploadList={true}
                            multiple={false}
                            accept="image/*"
                            maxCount={1}
                            listType='picture'
                            customRequest={handleUpload}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag image to this area to upload</p>
                            <p className="ant-upload-hint">Upload single image file only</p>
                        </Dragger>
                }
            </div>
        </Modal>
    );
};

export default WebcamModalComponent;
