import React from 'react'
import {Typography} from 'antd';
import style from './kitShowcase.module.scss';
import { PH_kit } from "@ph/common/assets";
import Components from "@ph/common/components";
import {getShopifyProdUrl} from "@ph/common/global/Helpers";
import TriColContentHolder from "@ph/common/components/KitShowcase/TriColContentHolder";
import { useNavigate } from 'react-router-dom';
import { SHOPIFY_PAGES } from '@ph/common/constants/ShopifyPages';
import { buildCompleteShopifyLink } from '@ph/common/global/Helpers';

const { Text } = Typography;

const KitShowcase = ({
                         heading,
                         title,
                         subtitle,
                         kitSubtitle,
                         buttonText,
                         bundleButtonText,
                         purchaseStatus,
                         shippingInfo,
                         productDetails,
                         bundleProductDetails,
                         productDetailsLoading=true,
                         isStoneHead=true,
                         isSandHead=false,
                         isDarkBackGround=false,
                         buttonCallToAction = null,
                         imgColClassName = '',
                         dueDate=''
}) => {
    const leftColSize = {
        'xs': 24,
        'sm': 24,
        'md': 4,
        'xl': 4
    };
    const rightColSize = {
        ...leftColSize,
        'md': 10,
        'xl': 10
    };
    const navigate = useNavigate()

    let imgSrc = PH_kit;
    if(productDetails && productDetails.image) {
        imgSrc = productDetails.image.src;
    }

    const kitButtonClickHandler = () => {
        if(buttonCallToAction && typeof buttonCallToAction === 'function') {
            buttonCallToAction();
            return ;
        }
        if (productDetails) {
            window.open(`${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`, '_self')
        }
    };

     const BundleButtonClickHandler = () => {
        if(bundleProductDetails && bundleProductDetails.handle) {
            getShopifyProdUrl(bundleProductDetails.handle)
            window.open(getShopifyProdUrl(bundleProductDetails.handle),
                '_blank', 'noreferrer');
        }
    };

    let showCaseContent = "";
    if(purchaseStatus) {
        showCaseContent = (
            <>
                <div className={style.desktopShowCase}>
                    <Components.PhMediaCard
                        hideButton={true}
                        rowProps={{justify: "center"}}
                        imgLoading={productDetailsLoading}
                        imgSrc={imgSrc}
                        imgColClassName={imgColClassName || ''}
                        imgColSize={leftColSize}
                        textColSize={rightColSize}
                        cardTitle={title}
                        cardTitleClassName={style.h2Title}
                        cardDescription={(<>
                            <Text className={style.kitSubtitle}>{subtitle}</Text>
                            <Text className={style.kitSubtitleSmall}>{shippingInfo}</Text>
                        </>)} />
                </div>
                <div className='hide-on-desktop'>
                    <TriColContentHolder title={title}
                                         subtitle={''}
                                         kitTitle={subtitle}
                                         kitSubTitle={shippingInfo}
                                         dueDate={dueDate}
                                         imgSrc={imgSrc}
                                         imgClassName={style.kitShowCaseImgContainer}
                                         buttonText={buttonText}
                                         bundleButtonText={bundleButtonText}
                                         kitButtonClickHandler={kitButtonClickHandler}
                                         bundleProductDetails={bundleProductDetails}
                                         BundleButtonClickHandler={BundleButtonClickHandler}
                                         productDetailsLoading={productDetailsLoading}
                                         hideButton={true}/>
                </div>
            </>
        );
    } else {
        showCaseContent = (
            <TriColContentHolder title={title}
                                 subtitle={subtitle}
                                 kitTitle={productDetails.title}
                                 dueDate={dueDate}
                                 kitSubTitle={kitSubtitle}
                                 imgSrc={imgSrc}
                                 buttonText={buttonText}
                                 bundleButtonText={bundleButtonText}
                                 bundleProductDetails={bundleProductDetails}
                                 BundleButtonClickHandler={BundleButtonClickHandler}
                                 kitButtonClickHandler={kitButtonClickHandler}
                                 productDetailsLoading={productDetailsLoading}/>
        );
    }

    return (
        <Components.PhCard title={heading}
                           isStoneHead={isStoneHead} isSandHead={isSandHead} isDarkBackGround={isDarkBackGround} >
            {showCaseContent}
        </Components.PhCard>
    )
}

export default KitShowcase;