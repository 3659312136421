import React from 'react';
import style from "../Report.module.scss";
import {Button, Col, Divider} from "antd";
import ReadMoreDescContainer from "../ReadMoreDescContainer";
import {navigateToShopifyUrl} from "../reportUtils";
import ReactMarkdown from "react-markdown";
import {lock_icon} from "@ph/common/assets";
import { button_arrow } from '@ph/common/assets/index';
import { buildCompleteShopifyLink } from '@ph/common/global/Helpers';
import { SHOPIFY_PAGES } from '@ph/common/constants/ShopifyPages';
import { useNavigate } from 'react-router-dom';

function RecommendedProductsColItem({Text, Paragraph, productItem, activateMask}) {
    const navigate = useNavigate()
    return (
        <Col xs={24} sm={24} md={24} lg={24} xl={24} key={`PROD-COL-${productItem.product_id}`}
             className={style.fullWidthColumnMobile}>
            <div className={style.productLabel}>{productItem.productLabel}</div>
            <div className={`${style.tileContent} ${activateMask ? style.masked : ''}`}>
                <div className={`${style.contentMask} hand-cursor`}
                    onClick={() => window.open(`${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`, '_self')}>
                    <div className={style.overlayContent}>
                        <img src={lock_icon} alt='locked'/>
                        <div className='body-txt-lg'>
                            Get your <Text className={'body-txt-lg'}
                                           underline={true}
                                strong={true}>MD-03 Protocol™ </Text> to unlock
                        </div>
                    </div>
                </div>
                <div className={style.productImgContainer}>
                    {productItem.image_url ?
                        <>
                            <img src={productItem.image_url} alt=''
                                 className={`${style.tileImageRecommendation} ${style.pointContent}`}
                                 onClick={() => navigateToShopifyUrl(productItem)}/>
                            {/*<div className={style.productTag}>{index === 0 ? 'PRIMARY' : 'SECONDARY'}</div>*/}
                        </> :
                        <img className={style.placeHolderImage} alt='' />}
                </div>
                <div className={style.innerContentPadding}
                     style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px',
                         position: 'relative' }}>
                    <Paragraph className={`${style.h3Text} ${style.pointContent} ${style.prodName}`}
                               ellipsis={{ rows: 2 }}
                               onClick={() => navigateToShopifyUrl(productItem)}>
                        <ReactMarkdown>
                            {productItem.name}
                        </ReactMarkdown>
                    </Paragraph>
                    <div className={`${style.descContainer} ${style.bodyText2}`}>
                        {productItem.finalDescription &&
                            <ReadMoreDescContainer Paragraph={Paragraph}
                                                   productId={productItem.product_id}
                                                   productName={productItem.name}
                                                   description={productItem.finalDescription} />
                        }
                    </div>
                    <Button type="primary" className={`${style.button} ${style.preOrder}`}
                            onClick={() => navigateToShopifyUrl(productItem)}>
                        Buy Now
                        <Divider type="vertical"
                                 style={{ background: '#fbeee4', height: '70%', margin: '0 10px' }} />
                        ${productItem.price}
                        <img src={button_arrow} className='ph-btn-arrow' />
                    </Button>
                </div>
            </div>
        </Col>
    );
}

export default RecommendedProductsColItem;