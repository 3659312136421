import { Row, Col } from 'antd';
import PHFaceRangeSlider from '../../Global/PHFaceRangeSlider/PHFaceRangeSlider';
import {
    face_range_slider, face_range_slider_1, face_range_slider_mobile,
    face_range_slider_1_mobile
} from '@ph/common/assets';
import style from './ReportSections.module.scss';
import ReportProduct from '../ReportContainer.js/ReportProduct';
import { useCart } from '@ph/common/context/CartContext';
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants';
import ProductHelpLabel from '../ReportContainer.js/ProductHelpLabel';
import { addLeadingZero } from '../reportUtils';

function HandSSection({ reportData, header1, header2 }) {

    const recommendedHydrationProduct = reportData?.other_recommendations.filter(product => product.product_id === Number(process.env.REACT_APP_HYDRATION_PRODUCT_ID))
    const recommendedSensitivityProduct = reportData?.other_recommendations.filter(product => product.product_id === Number(process.env.REACT_APP_SENSITIVITY_PRODUCT_ID))
    const { reportPlan } = useCart()
    return (
        <div className={style.handSSection}>
            <Row className={style.row}>
                <Col xs={24} xl={12} className={`${style.col2} ${style.first}`}>
                    <HandSHeader headerData={header1} score={reportData?.average_hydration} />
                    <PHFaceRangeSlider
                        rangeMax={'105'}
                        rangeMin={'-5'}
                        cheekValue={reportData.cheek_skin_hydration && Number(reportData.cheek_skin_hydration) !== -1 ? Number(reportData.cheek_skin_hydration) : null}
                        foreheadValue={reportData.forehead_skin_hydration && Number(reportData.forehead_skin_hydration) !== -1 ? Number(reportData.forehead_skin_hydration) : null}
                        chinValue={null}
                        color={"#2E5ACC"}
                        endColor={"#2E5ACC"}
                        domId={'hydration'}
                        backgroundSrc={face_range_slider}
                        sliderLabelRange={['DRY', 'MODERATE', 'OILY']}
                        mobileBackgroundSrc={face_range_slider_mobile}
                    />
                    <div className='show-mobile-hide-desktop'>
                        <ReportProduct product={recommendedHydrationProduct[0]} title={"RECOMMENDED PRODUCT"} first={true} reportPlan={reportPlan} />
                    </div>
                </Col>
                <Col xs={24} xl={12} className={style.col2}>
                    <HandSHeader headerData={header2} score={reportData?.sensitivity} combined={true} />
                    <PHFaceRangeSlider
                        rangeMax={'105'}
                        rangeMin={'-5'}
                        cheekValue={reportData.skin_site_specific_sensitivity.CK && Number(reportData.skin_site_specific_sensitivity.CK) !== -1 ? Number(reportData.skin_site_specific_sensitivity.CK) : null}
                        foreheadValue={reportData.skin_site_specific_sensitivity.TZ && Number(reportData.skin_site_specific_sensitivity.TZ) !== -1 ? Number(reportData.skin_site_specific_sensitivity.TZ) : null}
                        chinValue={reportData?.skin_site_specific_sensitivity.CN && Number(reportData.skin_site_specific_sensitivity.CN) !== -1 ? Number(reportData.skin_site_specific_sensitivity.CN) : null}
                        color={"#25A18E"}
                        endColor={"#25A18E"}
                        gradientColor={"#FFFFFF"}
                        domId={'sensitivity'}
                        backgroundSrc={face_range_slider_1}
                        sliderLabelRange={['REGULAR', 'MODERATE', 'INFLAMED']}
                        mobileBackgroundSrc={face_range_slider_1_mobile}
                    />
                    <div className='show-mobile-hide-desktop'>
                        <ReportProduct product={recommendedSensitivityProduct[0]} title={"PRODUCT HIGHLIGHT"} first={true} reportPlan={reportPlan} />
                    </div>
                </Col>
            </Row>
            <Row className="show-desktop-hide-mobile">

                <Col xs={24} md={12}>
                    {recommendedHydrationProduct[0] && (
                        <ReportProduct product={recommendedHydrationProduct[0]} title={"PRODUCT HIGHLIGHT"} first={true} reportPlan={reportPlan} />
                    )}
                </Col>
                <Col xs={24} md={12}>
                    {recommendedSensitivityProduct[0] && (
                        <ReportProduct product={recommendedSensitivityProduct[0]} title={"PRODUCT HIGHLIGHT"} reportPlan={reportPlan} />
                    )}
                </Col>
                {REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_large &&
                    <Col xs={24} md={24}>
                        <ProductHelpLabel text={REPORT_PLAN_DETAILS[reportPlan]?.discount_suggestion_text_large} justify='center' />
                    </Col>
                }
            </Row>

        </div>
    )
}

export default HandSSection

const HandSHeader = ({ headerData, score = 26, combined = false }) => {
    return (
        <Row className={style.header}>
            <span className={`${style.reportSectionCount} ${combined ? style.combined : ''} show-mobile-hide-desktop`}>{headerData.count}</span>
            {!combined && (
                <div className={`${style.doubleScore} show-desktop-hide-mobile`}>
                    <span className={style.leftCount}>03</span>
                    <span className={style.rightCount}>04</span>
                </div>
            )}

            <Col xs={7} md={5}>
                <div className={style.reportSectionScore}>
                    {addLeadingZero(score)}
                </div>

            </Col>
            <Col xs={17} md={19}>
                <div className={style.titleContainer}>
                    <div className={style.title}>
                        {headerData.title}
                    </div>
                    <div className={style.subTitle}>
                        {headerData.subTitle}
                    </div>
                </div>
            </Col>
            <Col xs={24} md={24}>
                <div className={style.description}>
                    {headerData.description}
                </div>
            </Col>
        </Row>
    )
}