import React, { useEffect, useState } from "react";
import { Typography, Col, Row, Button, Space, Input, Divider } from "antd";
import {
    PH_logo_light,
    PH_facebook,
    PH_insta,
    PH_twitter,
    instagram,
    youtube,
    facebook,
    PH_beta_logo_light,
    PH_twitter_svg
} from "@ph/common/assets";
import style from "./betaFooter.module.scss";
import { useLocation } from "react-router-dom";
import Components from "@ph/common/components";
import { YoutubeFilled, InstagramOutlined, FacebookOutlined, ArrowRightOutlined } from '@ant-design/icons';

const { Text, Link } = Typography;

const BetaFooter = () => {
    let location = useLocation();
    const [currentPath, setcurrentPath] = useState(location.pathname);
    const [footerInput, setFooterInput] = useState("");

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    // useEffect(() => {
    //     $(window).scroll(function () {
    //         if (!sticky_closed) {
    //             var docScroll = $(document).scrollTop();
    //             if (docScroll <= 20) {
    //                 $("#PHfooter").hide();
    //             } else {
    //                 $("#PHfooter").show();
    //             }
    //         }
    //     });
    // });

    return (
        !(currentPath.toString().includes('getstarted') || currentPath.toString().includes('jotform')) ?
            <footer className={style.footerContainer}>
                <div className='viewBoxWidth'>
                    <Row>
                        <Col xs={24} sm={24} md={12} xl={12} className={style.logoSection + ' ' + style.centerAlignmentMobile}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            {/* <Text className={style.appName}>parallel</Text> */}
                            <img src={PH_beta_logo_light} alt="Parallel" />
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={12} className={style.centerAlignmentMobile}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div className={style.disclaimerSection}>
                                <Text className={style.linkText} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/pages/privacy-policy`}>Privacy Policy</Text>
                                <Text className={style.linkText} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/pages/terms-of-service`}>Terms of Service</Text>
                                <Text className={style.linkText} onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/pages/disclaimer`}>Disclaimer</Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={12} className={style.centerAlignmentMobile} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                            {/* <div className={style.centerAlignmentMobile} style={{ width: '100%' }}> */}
                            <Text className={style.subscribeText}>Subscribe to our emails</Text>
                            <Components.FloatInput
                                label="Email"
                                placeholder=""
                                name="email"
                                value={footerInput}
                                onChange={(evt) => setFooterInput(evt.target.value)}
                            />
                            {/* </div> */}
                        </Col>
                        <Col xs={24} sm={24} md={12} xl={12} className={style.centerAlignmentMobile + " " + style.verticalEnd}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Space className={style.socialIconSection}>
                                <Text className={style.faq}
                                    onClick={() => window.location = `${process.env.REACT_APP_SHOPIFY_URL}/a/faq`}>FAQ</Text>
                                <img src={PH_twitter_svg} className={style.socialIcon} alt="twitter"
                                    onClick={() => window.location = 'https://twitter.com/urtrueparallel'} />
                                <img src={PH_facebook} className={style.socialIcon} alt="fb"
                                    onClick={() => window.location = 'https://www.facebook.com/yourtrueparallel/'} />
                                <img src={instagram} className={style.socialIcon} alt="insta"
                                    onClick={() => window.location = 'https://www.instagram.com/yourtrueparallel/'} />
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={0} lg={0} xl={0} className={style.centerAlignmentMobile + ' ' + style.hideOnLargeScreen}>
                            <Text className={style.mediumText}><a className={style.parallelLink} href="https://www.parallelhealth.io/">PARALLEL HEALTH PUBLIC WEBSITE</a></Text>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} className={style.centerAlignmentMobile}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Text className={style.smallText}>© 2022 , <a className={style.parallelLink} href={process.env.REACT_APP_SHOPIFY_URL}>Parallel Health</a></Text>
                        </Col>
                    </Row>
                </div>
            </footer>
            : <></>
    );
};

export default BetaFooter;