import React from 'react';
import {Col, Row, Tooltip} from "antd";
import style from "../Report.module.scss";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Components from "@ph/common/components";

function MbDiversityScoreSection({Text, reportData,leftSidePara,rightSidePara,showHeader = true}) {
    const skinMicrobiomeRangeMin = 1;
    const skinMicrobiomeRangeMax = 100;
    if(!reportData.microbiome_diversity || Number(reportData.microbiome_diversity) === -1) {
        return <></>;
    }
    return (
        <>
        <Row className={`${style.microbiomeScoreBox}`} style={{ paddingTop: '10px' }}>
            {showHeader && (
            <Col className={`${style.box} ${style.leftAlignmentMobile}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ order: 1, span: 24 }} lg={{ order: 1, span: 24 }} xl={{ order: 1, span: 24 }}>
                        <Text className="h4" style={{ paddingRight: '30px' }}>Microbiome Diversity <strong>SCORE</strong></Text>
                <Tooltip title="Your microbiome diversity score is a measurement of the balance and variety of bacteria on your skin."
                         color={"#2d2a26"} overlayInnerStyle={{ color: '#fbeee4', padding: '16px' }}
                         placement="topRight"
                         className={style.showInSmallScreen}
                         trigger="click">
                    <ExclamationCircleOutlined key="microbiomeScore" className={style.tooltipIcon} />
                </Tooltip>
                <Tooltip title="Your microbiome diversity score is a measurement of the balance and variety of bacteria on your skin."
                         color={"#2d2a26"} overlayInnerStyle={{ color: '#fbeee4', padding: '16px' }}
                         className={style.showInBigScreen}
                         trigger="hover">
                    <ExclamationCircleOutlined key="microbiomeScore" className={style.tooltipIcon} />
                </Tooltip>
            </Col>
            )}
            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ order: 3, span: 24 }} lg={{ order: 3, span: 24 }} xl={{ order: 3, span: 24 }}>
                <Components.SkinMicrobiomeSlider
                    sliderLength={skinMicrobiomeRangeMax}
                    min={skinMicrobiomeRangeMin}
                    sliderVal={reportData.microbiome_diversity} />
                {/*<Components.RangeSlider*/}
                {/*    rangeMax={skinMicrobiomeRangeMax}*/}
                {/*    rangeMin={skinMicrobiomeRangeMin}*/}
                {/*    rangeVal={reportData.microbiome_diversity && reportData.microbiome_diversity}*/}
                {/*    inputId="skinMicrobiomeInput"*/}
                {/*    outputId="skinMicrobiomeOutput"*/}
                {/*    outputContainerId="skinMicrobiomeOutputContainer"*/}
                {/*/>*/}
            </Col>
        </Row>
        <Row className={style.box} gutter={[20]} style={{ rowGap: '20px', marginBottom: '10px' }}>
            <Col xs={24} sm={24} md={24} xl={12} className={style.introColLeft}>
                <Text className={style.bodyText1}>
                    { leftSidePara }
                </Text>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12} className={style.introColRight}>
                <Text className={style.bodyText1}>
                    { rightSidePara }
                </Text>
            </Col>
        </Row>
        </>
    );
}

export default MbDiversityScoreSection;