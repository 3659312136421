import React, { useEffect } from 'react';
import style from './reportv1.module.scss';
import style1 from "../Report.module.scss";
import ReportTopSection from './ReportTopSection';
import { Row, Col, Divider, Typography } from 'antd';
import ReportSection from '../ReportSections/ReportSection';
import ReportSectionHeader from '../ReportSections/ReportSectionHeader';
import { REPORT_SECTION_INFO, REPORT_SECTIONS } from '@ph/common/constants/Report/ReportConstants';
import HallmarkSection from '../ReportSections/HallmarkSection';
import AntibioticSection from '../ReportSections/AntibioticSection';
import HandSSection from '../ReportSections/HandSSection';
import RecommendationSection from '../ReportSections/RecommendationSection';
import ReportSectionHeaderSmall from '../ReportSections/ReportSectionHeaderSmall';
import {
    header_hex, PH_logo_light_tm
} from '@ph/common/assets/index';
import ProductSection from '../ReportSections/ProductSection';
import DiversitySection from '../ReportSections/DiversitySection';
import SummarySection from '../ReportSections/SummarySection';
import { REPORT_PLAN_DETAILS } from '@ph/common/constants/Report/ReportConstants';
import { useCart } from '@ph/common/context/CartContext';

const { Text } = Typography;



function ReportV1({ reportData, isETypeKit, kitType, reportPlan }) {

    useEffect(() => {
        // Hide header and footer immediately on page load
        if (document.getElementById('PHheader') && document.getElementById('footer')) {
            document.getElementById('PHheader').style.display = 'none';
            document.getElementById('footer').style.display = 'none';
        }
    }, [document.getElementById('PHheader')]);


    function renderUserName(firstName, lastName) {
        let formattedName = '';

        if (firstName && lastName) {
            formattedName = `${firstName} ${lastName}`;
        } else if (firstName) {
            formattedName = firstName; // Only first name is present
        } else if (lastName) {
            formattedName = lastName; // Only last name is present
        }

        if (formattedName.length > 18) {
            // If both names are present, use first initial of last name
            if (firstName && lastName) {
                // first crop last name
                formattedName = `${firstName} ${lastName.charAt(0)}.`;
                if (formattedName.length > 18) {
                    // Check again if it exceeds 18 character then crop first name
                    formattedName = `${firstName.charAt(0)}. ${lastName} `;
                    if (formattedName.length > 18) {
                        // Check again if it exceeds 18 character then crop both first name and last name
                        formattedName = `${firstName.charAt(0)}. ${lastName.charAt(0)}.`;
                    }
                }
            } else if (firstName) {
                formattedName = `${firstName.charAt(0)}.`;
            } else if (lastName) {
                formattedName = lastName.charAt(0) + '.';
            }
        }

        return formattedName;
    }


    return (
        <div>
            <div className={style.reportHeaderContainer}>
                <img src={PH_logo_light_tm} alt='parallel' className={`${style.logo} show-desktop-hide-mobile`} />
                <svg width="89" height="23" viewBox="0 0 89 23" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${style.logo} show-mobile-hide-desktop`}>
                    <path d="M0.0550327 22.5633C1.31745 22.3998 1.77272 22.1014 1.77272 21.2062V8.06929C1.77272 6.87564 1.63764 6.30579 0 6.46764V6.05964L2.60488 5.40887C3.0885 5.27231 3.43704 5.51677 3.43704 6.22318V6.65815C3.43704 6.90261 3.57045 7.09313 3.8673 6.76605C4.3776 6.1962 5.93519 5.35492 7.70624 5.35492C10.6863 5.35492 12.9961 7.82483 12.9961 11.544C12.9961 15.6695 10.2044 18.0045 7.22429 18.0045C4.51268 18.0045 3.43871 16.1315 3.43871 17.7331V21.2078C3.43871 22.1031 3.89565 22.4015 5.29148 22.565V23H0.0550327V22.565V22.5633ZM11.4118 12.5202C11.4118 9.20898 9.4256 6.65815 6.74067 6.65815C5.34484 6.65815 3.43871 7.30893 3.43871 9.83447V12.9551C3.43871 16.0489 5.6667 17.433 7.57283 17.433C9.88254 17.433 11.4118 15.452 11.4118 12.5202Z" fill="#FAEEE4" />
                    <path d="M15.6812 15.0171C15.6812 12.9535 17.2121 11.2439 23.5742 10.5931C23.7893 10.5662 24.0578 10.4296 24.0578 9.99632V9.20898C24.0578 7.3359 23.0639 5.92477 20.7558 5.92477C19.1449 5.92477 18.3394 6.57554 18.3394 7.93273C18.3394 8.77401 18.0442 9.26293 17.4005 9.26293C16.8368 9.26293 16.515 8.80099 16.515 8.09626C16.515 6.06133 19.0932 5.35492 21.4012 5.35492C24.6231 5.35492 25.7238 6.90261 25.7238 9.61698V16.2663C25.7238 17.2442 26.3675 17.2155 27.1463 17.1346V17.3791C26.8511 17.6775 26.1524 18.0029 25.4553 18.0029C24.7582 18.0029 24.0061 17.5409 24.0061 15.9949C24.0328 15.7504 23.8177 15.8044 23.6842 15.9949C22.5836 17.3521 20.7575 18.0029 19.1465 18.0029C17.3738 18.0029 15.6828 17.1346 15.6828 15.0171H15.6812ZM24.0578 13.6329V12.1408C24.0578 11.517 23.9244 11.0551 22.9838 11.1917C18.5262 11.7615 17.5339 13.1996 17.5339 14.6124C17.5339 15.8887 18.3928 17.0284 20.2188 17.0284C22.2601 17.0284 24.0578 15.5903 24.0578 13.6363V13.6329Z" fill="#FAEEE4" />
                    <path d="M29.5407 17.379C30.8031 17.2442 31.2584 16.9441 31.2584 16.1028V8.06926C31.2584 6.87561 31.1249 6.30576 29.4873 6.46761V6.05961L32.0922 5.40884C32.5758 5.27228 32.9243 5.51674 32.9243 6.19618V7.06444C32.9243 7.25495 33.1128 7.44377 33.408 7.17234C33.6498 6.95485 34.9389 5.40884 37.0318 5.40884C39.2598 5.40884 39.3415 6.92956 39.3415 7.38982C39.3415 8.01362 38.9112 8.47557 38.3209 8.47557C37.7305 8.47557 37.382 8.06757 37.382 7.71521C37.382 7.06444 36.8984 6.385 35.9595 6.385C34.2952 6.385 32.926 8.06757 32.926 10.5914V16.1011C32.926 16.9424 33.383 17.2408 34.6971 17.3773V17.8393H29.5423V17.3773L29.5407 17.379Z" fill="#FAEEE4" />
                    <path d="M40.8726 15.0171C40.8726 12.9535 42.4035 11.2439 48.7656 10.5931C48.9807 10.5662 49.2492 10.4296 49.2492 9.99632V9.20898C49.2492 7.3359 48.2553 5.92477 45.9472 5.92477C44.3363 5.92477 43.5308 6.57554 43.5308 7.93273C43.5308 8.77401 43.2356 9.26293 42.5919 9.26293C42.0282 9.26293 41.7064 8.80099 41.7064 8.09626C41.7064 6.06133 44.2846 5.35492 46.5926 5.35492C49.8145 5.35492 50.9152 6.90261 50.9152 9.61698V16.2663C50.9152 17.2442 51.5589 17.2155 52.3377 17.1346V17.3791C52.0425 17.6775 51.3438 18.0029 50.6467 18.0029C49.9496 18.0029 49.1975 17.5409 49.1975 15.9949C49.2242 15.7504 49.0091 15.8044 48.8756 15.9949C47.775 17.3521 45.9489 18.0029 44.3379 18.0029C42.5652 18.0029 40.8742 17.1346 40.8742 15.0171H40.8726ZM49.2492 13.6329V12.1408C49.2492 11.517 49.1158 11.0551 48.1752 11.1917C43.7176 11.7615 42.7253 13.1996 42.7253 14.6124C42.7253 15.8887 43.5842 17.0284 45.4103 17.0284C47.4515 17.0284 49.2492 15.5903 49.2492 13.6363V13.6329Z" fill="#FAEEE4" />
                    <path d="M55.5948 16.3742V2.66753C55.5948 1.50086 55.4614 0.931011 53.8237 1.09286V0.684863L56.4286 0.0340894C56.9122 -0.100786 57.2608 0.170651 57.2608 0.740499V16.2663C57.2608 17.2442 57.9045 17.2155 58.6833 17.1346V17.3791C58.3881 17.6775 57.6894 18.0029 56.9923 18.0029C56.1868 18.0029 55.5965 17.5679 55.5965 16.3742H55.5948Z" fill="#FAEEE4" />
                    <path d="M62.1934 16.3742V2.66753C62.1934 1.50086 62.06 0.931011 60.4224 1.09286V0.684863L63.0272 0.0340894C63.5109 -0.100786 63.8594 0.170651 63.8594 0.740499V16.2663C63.8594 17.2442 64.5031 17.2155 65.2819 17.1346V17.3791C64.9867 17.6775 64.288 18.0029 63.5909 18.0029C62.7854 18.0029 62.1951 17.5679 62.1951 16.3742H62.1934Z" fill="#FAEEE4" />
                    <path d="M67.9238 11.6249C67.9238 7.71692 70.5554 5.35492 73.9641 5.35492C76.6757 5.35492 78.1799 6.87564 78.1799 8.80267C78.1799 10.4313 77.1326 11.2186 72.9451 11.3535L70.4753 11.4614C69.7766 11.4884 69.6965 11.9234 69.8049 12.6011C70.2085 15.1519 72.3831 16.6996 74.9063 16.6996C76.142 16.6996 77.4028 16.3473 78.29 15.098L78.6652 15.3964C78.1549 16.2916 76.7324 18.0029 73.9674 18.0029C70.6121 18.0029 67.9272 15.452 67.9272 11.6249H67.9238ZM72.7834 10.8646C75.495 10.8376 76.3271 10.4296 76.3271 8.82965C76.3271 7.22969 75.495 5.95174 73.4538 5.95174C71.2792 5.95174 70.0167 7.36288 69.7216 9.69791C69.5598 10.8376 69.91 10.9185 70.5537 10.8916L72.7817 10.8646H72.7834Z" fill="#FAEEE4" />
                    <path d="M82.4171 16.3742V2.66753C82.4171 1.50086 82.2836 0.931011 80.646 1.09286V0.684863L83.2509 0.0340894C83.7345 -0.100786 84.083 0.170651 84.083 0.740499V16.2663C84.083 17.2442 84.7268 17.2155 85.5055 17.1346V17.3791C85.2104 17.6775 84.5116 18.0029 83.8145 18.0029C83.0091 18.0029 82.4187 17.5679 82.4187 16.3742H82.4171Z" fill="#FAEEE4" />
                    <path d="M86.0497 0.472898H85.4663V0.223267H86.9237V0.472898H86.3403V2.00745H86.0497V0.472898ZM87.2281 0.223267H87.6524L88.1081 1.65332H88.114L88.5697 0.223267H88.9998V2.00939H88.7268V0.573524H88.715C88.715 0.573524 88.6207 0.89282 88.5382 1.14245L88.2534 2.00939H87.9686L87.6838 1.14245C87.6072 0.912171 87.513 0.573524 87.513 0.573524H87.5012V2.00939H87.2281V0.223267Z" fill="#FAEEE4" />
                </svg>

                <svg width="1925" height="428" viewBox="0 0 1925 428" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${style.headContainer} show-desktop-hide-mobile`}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1779.19 0C1788.94 0 1797.52 6.40713 1800.29 15.7475L1821.15 86.1314C1824.93 98.8683 1836.63 107.605 1849.91 107.605H1895C1911.57 107.605 1925 121.037 1925 137.605V377.605C1925 394.174 1911.57 407.605 1895 407.605H30C13.4314 407.605 0 394.174 0 377.605V137.605C0 121.037 13.4315 107.605 30 107.605H1162.21C1175.42 107.605 1187.08 98.9608 1190.92 86.3175L1212.37 15.611C1215.19 6.33923 1223.74 0 1233.43 0H1779.19Z" fill="#FAEEE4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1199.17 166.692C1193.67 152.416 1179.95 143 1164.65 143H37C16.5655 143 0 159.565 0 180V391C0 411.435 16.5654 428 37 428H1888C1908.43 428 1925 411.435 1925 391V180C1925 159.565 1908.43 143 1888 143H1847.67C1832.26 143 1818.47 152.545 1813.04 166.962L1800.98 198.987C1797.76 207.559 1789.56 213.235 1780.4 213.235H1232.21C1223.11 213.235 1214.95 207.636 1211.68 199.148L1199.17 166.692Z" fill="white" />
                    <rect x="1233.2" y="20" width="551.432" height="123" rx="16" fill="white" />
                    <text x="67%" y="21%" dominantBaseline="middle" textAnchor="start" fill="#000000" className={style.picText}>
                        {renderUserName(reportData?.first_name, reportData?.last_name)}
                    </text>
                    <mask id="mask0_6419_2200" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1233" y="13" width="552" height="29">
                        <rect x="1233.2" y="13" width="551.432" height="29" fill="#2D2926" />
                    </mask>
                    <g mask="url(#mask0_6419_2200)">
                        <rect x="1233.2" y="20" width="551.432" height="100" rx="16" fill="#2E5ACC" />
                    </g>
                    <path d="M1256.26 42V20" stroke="white" stroke-width="2" />
                    <path d="M1508.92 42V20" stroke="white" stroke-width="2" />
                    <path d="M1761.58 42V20" stroke="white" stroke-width="2" />
                    <path d="M1256.26 89H1233.2" stroke="#2e5acc" stroke-width="2" />
                    <path d="M1784.64 89H1761.58" stroke="#2e5acc" stroke-width="2" />
                </svg>
                <svg width="375" height="72" viewBox="0 0 375 72" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${style.headContainer} show-mobile-hide-desktop`}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M138.648 2.48623C139.263 0.982529 140.726 0 142.351 0H343.495C345.131 0 346.602 0.996799 347.209 2.51655L357.633 28.6177C357.721 28.8402 357.929 29 358.168 29H360.5C370.717 29 379 37.2827 379 47.5C379 57.7173 370.717 66 360.5 66H15.5C5.28273 66 -3 57.7173 -3 47.5C-3 37.2827 5.28273 29 15.5 29H127.396C127.655 29 127.879 28.8271 127.977 28.5874L138.648 2.48623Z" fill="#FAEEE4" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M129.878 45.9654C129.222 44.5531 127.807 43.6495 126.249 43.6495H-133C-135.209 43.6495 -137 45.4403 -137 47.6495V68C-137 70.2091 -135.209 72 -133 72H381.429C383.638 72 385.429 70.2091 385.429 68V47.6495C385.429 45.4403 383.638 43.6495 381.429 43.6495H358.278C356.708 43.6495 355.283 44.5679 354.635 45.9979L348.107 60.3977C347.458 61.8276 346.034 62.7461 344.464 62.7461H140.22C138.662 62.7461 137.247 61.8425 136.591 60.4301L129.878 45.9654Z" fill="white" />
                    <rect x="146" y="7" width="194" height="32" rx="4.23624" fill="white" />
                    <text x="64%" y="37%" dominantBaseline="middle" textAnchor="middle" fill="#000000" className={style.picText}>
                        {renderUserName(reportData?.first_name, reportData?.last_name)}
                    </text>
                    <mask id="mask0_4746_428" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="146" y="5" width="194" height="8">
                        <rect x="146" y="5" width="194" height="7.67818" fill="#2D2926" />
                    </mask>
                    <g mask="url(#mask0_4746_428)">
                        <rect x="146" y="6.85333" width="194" height="26.4765" rx="4.23624" fill="#2E5ACC" />
                    </g>
                    <path d="M152.105 25.1221H146" stroke="#2E5ACC" stroke-width="0.52953" />
                    <path d="M340 25H333" stroke="#2E5ACC" stroke-width="0.52953" />
                    <path d="M153.193 12.6782V6.85333" stroke="white" stroke-width="0.52953" />
                    <path d="M243.096 12.6782V6.85333" stroke="white" stroke-width="0.52953" />
                    <path d="M333 12.6782V6.85333" stroke="white" stroke-width="0.52953" />
                </svg>
            </div>
            <div className={style.reportSection}>
                <div className='viewBoxWidth' style={{ width: '100%' }}>
                    <ReportTopSection reportData={reportData} isETypeKit={isETypeKit} kitType={kitType} reportPlan={reportPlan} />
                </div>
                <Divider type="horizontal" className={style1.horizontalseparator} style={{ marginTop: '30px' }} />
                <div className={`${style.reportBox} bg-sand`}>
                    <Row align="stretch" gutter={[40, 20]} className={style.scoreHeadingSection}>
                        <Col xs={24}>
                            <Text className={style.scoreHeading}>Score Summary</Text>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: "justify" }}>
                            <Text className={`${style1.bodyText1}`}>
                                This section summarizes your important skin health scores, derived from microbial samples and clinical data from three facial areas. Detailed scores for each area follow below.
                            </Text>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: "justify" }}>
                            <Text className={`${style1.bodyText1}`}>
                                Scores for diversity, hydration, sensitivity, and antibiotic resistance are measured on a scale of 1-100. Your skin age is compared to your actual age.
                            </Text>
                        </Col>
                    </Row>
                    <SummarySection reportData={reportData} />
                    <ReportSection
                        headerComponent={<ReportSectionHeader headerData={REPORT_SECTION_INFO[REPORT_SECTIONS.DIVERSITY]} score={parseInt(reportData?.microbiome_diversity)} />}
                    >
                        <DiversitySection reportData={reportData} />
                    </ReportSection>
                    <ReportSection
                        headerComponent={<ReportSectionHeader headerData={{ ...REPORT_SECTION_INFO[REPORT_SECTIONS.SKIN_AGE], subTitle: <>{`YOUR REAL SKIN AGE: ${reportData?.real_skin_age} YRS`}</> }} score={reportData?.skin_age} />}
                    >
                        <HallmarkSection reportData={reportData} />
                    </ReportSection>

                    <HandSSection reportData={reportData} header1={REPORT_SECTION_INFO[REPORT_SECTIONS.HYDRATION]} header2={REPORT_SECTION_INFO[REPORT_SECTIONS.SENSITIVITY]} />

                    <ReportSection
                        headerComponent={<ReportSectionHeader headerData={REPORT_SECTION_INFO[REPORT_SECTIONS.ANTIBIOTIC]} score={reportData?.antibiotic_resistance} />}
                    >
                        <AntibioticSection reportData={reportData} />
                    </ReportSection>
                    <ReportSection
                        headerComponent={<ReportSectionHeaderSmall headerData={REPORT_SECTION_INFO[REPORT_SECTIONS.NUTRIENT]} />}
                    >
                        <RecommendationSection
                            color={'#EAFFA6'}
                            recommendationData={reportData?.good_nutrient_recommendations}
                            columnNames={['NUTRIENTS', 'FOUND IN THESE FOODS:', 'BENEFITS']}
                        />
                    </ReportSection>

                    <ReportSection
                        headerComponent={<ReportSectionHeaderSmall headerData={REPORT_SECTION_INFO[REPORT_SECTIONS.SKINCARE]} />}
                    >
                        <RecommendationSection
                            color={'#F1DDFF'}
                            recommendationData={reportData?.good_skincare_ingredients_recommendations}
                            columnNames={['INGREDIENTS', 'FOUND IN THESE PRODUCTS:', 'BENEFITS']}
                        />
                    </ReportSection>
                    <ReportSection
                        headerComponent={<ProductHeader headerData={REPORT_SECTION_INFO[REPORT_SECTIONS.PRODUCT]} />}
                        className={`${style.hideMobileBorder} ${style.lastSection}`}
                    >
                        <ProductSection reportData={reportData} />
                    </ReportSection>
                </div>
            </div>
        </div>
    )
}

export default ReportV1


const ProductHeader = ({ headerData }) => {
    const { reportPlan } = useCart()
    return (
        <div className={style.productHeader}>
            <span className={style.reportSectionCount}>{headerData.count}</span>
            <Row style={{ width: '100%' }}>
                <Col xs={24} md={14}>
                    <div className={style.leftSide}>
                        <img src={header_hex} />
                        <div className={style.title}>{headerData.title}</div>
                    </div>

                </Col>
                <Col xs={24} md={10} className={style.rightCol}>
                    <div className={style.rightSide}>
                        <div className={`${style.title} center-flex`} style={{ height: '100%' }}>{REPORT_PLAN_DETAILS[reportPlan]?.skin_regimen_title}</div>
                        {REPORT_PLAN_DETAILS[reportPlan]?.skin_regimen_subtitle && (
                            <div className={style.subTitle}>{REPORT_PLAN_DETAILS[reportPlan]?.skin_regimen_subtitle}</div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>

    )
}