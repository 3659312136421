import React, {useState, useEffect} from 'react'
import Cookies from 'universal-cookie';
import {AuthToken} from "@ph/subject/src/api_services/localstorage";
import {Row, Col, Typography, Button, Space, Spin} from 'antd';
import {QR_flow_featured_web, PH_QR, QR_flow_featured_mobile, PH_Logo_v2} from "@ph/common/assets";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {useSelector} from 'react-redux';
import OtpInput from 'react18-input-otp';
import style from './qrContainer.module.scss';
import { KIT_TYPES } from '@ph/common/constants/KitConstants';
import { button_arrow, button_arrow_grey } from '@ph/common/assets/index';
const {Text, Link} = Typography;

const KIT_REGISTER_STATUS = {
    ALREADY_REGISTERED_TO_SOMEONE_ELSE: 'ALREADY_REGISTERED_TO_SOMEONE_ELSE',
    ALREADY_REGISTERED_TO_YOU: 'ALREADY_REGISTERED_TO_YOU',
    ALREADY_REGISTERED_TO_YOU_AND_INWARDED: 'ALREADY_REGISTERED_TO_YOU_AND_INWARDED',
    SUCCESSFULLY_REGISTERED: 'SUCCESSFULLY_REGISTERED'
}

const QRLoginContainer = (props) => {
    const cookies = new Cookies();
    const {kitId} = useParams();
    const {connectionInstance} = props;
    let navigate = useNavigate();
    let location = useLocation();
    const user = useSelector((state) => state.userData.userData);
    const hasToken = !!(AuthToken.getToken('ph_token') && AuthToken.getToken('ph_token') !== 'null');
    const [code, setCode] = useState("");
    const [error, setError] = useState({
        code: '',
        message: ''
    });
    const [disableContinue, setDisableContinue] = useState(false);
    const [disabledInput, setDisabledInput] = useState(false);
    const [kitType, setKitType] = useState(null);
    const [focusInput, setFocusInput] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    const getErrorMessage = (error) => {
        switch (error) {
            case 'INVALID KITCODE':
            case 'INVALID_KITCODE':
                return <span className='note-1'>Oops! We could not find your skin discovery kit code in our system!</span>;
            case 'ALREADY_REGISTERED_TO_YOU':
            case 'ALREADY_REGISTERED_TO_YOU_AND_INWARDED':
                return <span className='note-1'>This kit seems to be already registered <b>TO YOU</b>.</span>;
            case 'ALREADY_REGISTERED_TO_SOMEONE_ELSE':
                return <span className='note-1'>Oops! This kit seems to be already registered <b>TO SOMEONE ELSE</b>.</span>;
            default:
                return error;
        }
    };

    const resetErrorMessage = () => {
        setError({code: '', message: ''});
    }

    const getKitType = async (kitCode) => {
        try {
            const res = await connectionInstance.get(
                `${process.env.REACT_APP_API_URL}/kit/${kitCode}/kit_type`
            );
            if (res && res.data && res.data.kit_type_name) {
                setKitType(res.data.kit_type_name);
                resetErrorMessage();
            }
        } catch (error) {
            if (error && error.response && error.response.data) {
                setKitType(null);
                setError({code: 'INVALID_KITCODE', message: 'INVALID KITCODE'});
                setDisableContinue(true);
            }
        }
    };

    const handleInputChange = (input) => {
        let kitInput = (input || '').toUpperCase();
        setCode(kitInput);
        if (kitInput && kitInput.length === 7) {
            getKitType(input);
            setDisableContinue(false);
        } else if (kitInput && kitInput.length === 0) {
            setKitType(null);
        } else {
            setDisableContinue(true);
        }
        resetErrorMessage();
    };

    const verifyKitRedirect = (status,kitCode) => {
        if(kitCode[0] === KIT_TYPES.TYPE_E){
            if(status === KIT_REGISTER_STATUS.ALREADY_REGISTERED_TO_YOU || 
                status === KIT_REGISTER_STATUS.SUCCESSFULLY_REGISTERED){
                navigate(`/healthprofile-form/${kitCode}`)
                return True
            }else if(status === KIT_REGISTER_STATUS.ALREADY_REGISTERED_TO_YOU_AND_INWARDED){
                navigate(`/dashboard`)
                return True
            }
        }
        return false
    }

    const registerKitCode = (kitCode) => {
        if(showSpinner) return ;
        if(code || !kitCode) {
            kitCode = code;
        }
        setShowSpinner(true);
        connectionInstance.post(`${process.env.REACT_APP_API_URL}/kit/${kitCode}`).then(res => {
            let eReponse = false
            if(res && res.data){
                eReponse = verifyKitRedirect(res.data.status,kitCode)
            }
            if (res && res.data && res.data.status === 'ALREADY_REGISTERED_TO_YOU') {
                setError({
                    code: 'ALREADY_REGISTERED_TO_YOU',
                    message: getErrorMessage('ALREADY_REGISTERED_TO_YOU'),
                });
                setKitType(null);
                return ;
            }
            resetErrorMessage();
            // navigate('/kit-user-guide');
            if(!eReponse){
                navigate(`/kit-user-guide/${kitCode}?register=success`)

            }
        }).catch(error => {
            let errorMessage = 'Something went wrong!';
            setDisableContinue(true);

            if (error.response && error.response.data && error.response.data.message) {
                const { message } = error.response.data;
                verifyKitRedirect(message,kitCode)
                errorMessage = getErrorMessage(message);
            }
            setError({ code: 'UNKNOWN', message: errorMessage });
        }).finally(() => setShowSpinner(false));
    }

    const kitCodeSubmitHandler = () => {
        if(error && error.code === 'ALREADY_REGISTERED_TO_YOU') {
            navigate(`/kit-user-guide/${kitCode}`)
            return;
        }

        if (!hasToken) {
            QRlogin();
            return;
        }

        navigate('/getstarted/' + code);
        registerKitCode();
    };

    const QRlogin = () => {
        cookies.set('kitcode', code);
        AuthToken.setToken('kitcode', code);
        // AuthToken.setToken('page', 'video');
        // cookies.remove('pageToRedirect', { domain: 'parallelhealth.io' });
        // cookies.set('pageToRedirect', 'video', { domain: 'parallelhealth.io' });
        navigate('/qr-login');
    }

    const editKitCode = () => {
        setDisabledInput(false);
        setCode("");
        setKitType(null);
        setError({code: null, message: null});
    }

    // const QrLogout = () => {
    //   console.log('qr logout')
    //   AuthToken.removeStorage();
    //   return window.location.replace(`https://store.xecurify.com/moas/idp/oidc/logout?post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/getstarted/${code}`)
    // }

    useEffect(() => {
        let kitCode = '';
        let initKitRegistration = false;
        if (kitId) {
            const kitIdLength = kitId.toString().length;
            kitCode = (kitIdLength > 7 ? kitId.toString().substring(0, 7) : kitId).toUpperCase();
            setCode(kitCode);
            // setDisabledInput(kitIdLength > 6);
            setFocusInput(kitIdLength < 7);
            if(kitCode !== kitId) {
                navigate(`/getstarted/${kitCode}`);
            }
            initKitRegistration = true
        } else {
            setDisabledInput(false);
            setFocusInput(true);
            setDisableContinue(true);
        }

        if (location && location.state && location.state.errorMessage) {
            const {errorMessage} = location.state;
            setError({code: errorMessage.toUpperCase(), message: getErrorMessage(errorMessage)});
            setKitType(null);
            if (errorMessage === 'ALREADY_REGISTERED_TO_SOMEONE_ELSE') {
                setDisableContinue(true);
            }
        } else if (kitCode) {
            getKitType(kitCode, initKitRegistration).finally(kitRegistrationCheckHandler.bind(null, initKitRegistration, kitCode));
        }
    }, []);

    function kitRegistrationCheckHandler(initKitRegistration, kitCode) {
        if (initKitRegistration) {
            registerKitCode(kitCode);
        }
    }

    return (
        <div className={style.container}>
            <Spin
                spinning={showSpinner}
                size="large"
                style={{position: "fixed", top: "15vh"}}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} xl={11} className={style.featureSection}>
                        <img src={QR_flow_featured_web} className={style.featureImageBigScreen} alt='qr-feature'/>
                        <img src={QR_flow_featured_mobile} className={style.featureImageSmallScreen}
                             alt='qr-feature'/>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={13} className={style.contentSection}>
                        <img src={PH_Logo_v2} className={style.logo} alt='PH-logo'/>
                        {(hasToken && (user && user.first_name)) &&
                            <Text className={style.welcomeText}>Hi {(user && user.first_name) && user.first_name}, Welcome
                                Back!</Text>}
                        <Text className={`${style.titleText} h1`} >Enter your kit code </Text>
                        <OtpInput
                            value={code}
                            onChange={handleInputChange}
                            numInputs={7}
                            separator={<span className='input-separator'></span>}
                            isInputNum={false}
                            className='otp-input'
                            isDisabled={disabledInput}
                            shouldAutoFocus={focusInput}
                            focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                            }}
                        />
                        {/* {hasToken ? ( */}
                        <div className={style.kitTypeContainer}>
                            {error.message ?
                                <Text className={`note-1`} style={{ color: '#FF505F' }}>{error.message}</Text> :
                                <Text className={`note-1`} style={{ textTransform: 'uppercase' }}>
                                    {(kitType || '').replace('(Facial)', '')}
                                </Text>
                                // : <Text className={style.textType3}>SKIN MICROBIOME FACIAL <span style={{ whiteSpace: 'nowrap' }}>DISCOVERY KIT</span></Text>
                            }
                        </div>

                        {/* <div className={style.verticalSpace}></div> */}
                        <Row className={style.continueButtonSection}>
                            <Col span={8}></Col>
                            <Col span={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Button type="primary" className={style.continueButton} disabled={disableContinue}
                                    onClick={kitCodeSubmitHandler}>
                                    CONTINUE
                                    <img src={disableContinue ? button_arrow_grey : button_arrow} className='ph-btn-arrow' />
                                </Button>
                            </Col>
                            <Col span={8} className='hide'
                                 style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Button type="link" className={style.textType3 + ' ' + style.linkButton}
                                        onClick={() => editKitCode()}><u>Edit Code</u></Button>
                            </Col>
                        </Row>
                        <div className={style.verticalSpace}></div>
                        <Text className={style.textType4}>
                            Any questions? <Link href='mailto:questions@parallelhealth.io'
                                className={`${style.boldLink} ${style.textType4}`}>Contact Us</Link>
                        </Text>

                        {(hasToken && (user && user.first_name)) && <div className={style.separator}></div>}

                        {(hasToken && (user && user.first_name)) &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
                                <img src={PH_QR} className={style.addUserImage} alt="ph"/>
                                {/* </div> */}

                                <Text className={style.textType3}>
                                    Not {(user && user.first_name) && user.first_name}? <Link
                                    href="/logout" className={style.boldLink}>Click here</Link></Text>
                            </div>
                        }
                        {/* {!hasToken &&
              <div className={style.loginSection}>
                <div className={style.accountSection1}>
                  <Text className={style.textType1} style={{ whiteSpace: 'nowrap' }}>Already have an account?</Text>
                </div>
                <div className={style.accountSection2}>
                  <Button className={style.loginButton} onClick={() => QRlogin()}>LOGIN</Button>
                </div>
              </div>
            } */}
                        <Space className={style.policy}>
                            <Text className={style.textType4}>
                                <Link href='https://www.parallelhealth.io/pages/privacy-policy'
                                      className={style.linkText}>Privacy Policy</Link>
                            </Text>
                            <Text className={style.textType4}>
                                <Link href='https://www.parallelhealth.io/pages/terms-of-service'
                                      className={style.linkText}>Terms of Service</Link>
                            </Text>
                        </Space>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
export default QRLoginContainer