import React, {useEffect} from 'react';
import {AuthToken} from "@ph/subject/src/api_services/localstorage";
import RedirectFromShopify from "./RedirectFromShopify";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import Cookies from "universal-cookie";
import connectionInstance from "@ph/subject/src/api_services/connection";
import {userDataActions} from "@ph/common/store/userData.reducer";
import {message} from "antd";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";

const RedirectToPrescriptionJotForm = () => {

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  // const user = useSelector((state) => state.userData);

  const getUserInfo = async () => {
    try {
      const res = await connectionInstance.get(
          `${process.env.REACT_APP_API_URL}/subject`
      );
      if (res && res.data) {
        dispatch(userDataActions.updateUserData(res.data));
        cookies.remove('pageToRedirect', { path: '/', domain: '.parallelhealth.io' });
        navigate("/prescription-form");
      }
    } catch (error) {
      message.error("Something went wrong!");
      window.location = `${process.env.REACT_APP_SHOPIFY_URL}`;
    }
  };

  const idToken = params.get('id_token') || '';

  useEffect(() => {
    if(idToken) {
      localStorage.setItem('ph_token', idToken);
      AuthToken.setToken('ph_token', idToken);
      getUserInfo();
    } else {
      AuthToken.setToken('page', 'prescription_form');
    }
  }, [idToken]);

  return (
      (idToken) ?
          <FullPageLoader /> :
          <RedirectFromShopify pageToRedirect='prescription_form' redirectURI='auth/prescription'/>
  )
}
export default RedirectToPrescriptionJotForm;