import React from 'react';
import style from "./Report.module.scss";
import OtpInput from "react18-input-otp";
import {Button, Typography} from "antd";

const { Text } = Typography;

function AdminFooter({code, disableContinue, handleInputChange, reportSearchClickHandler}) {
    return (
        <div className={style.box + " " + style.kitcodeSection}>
            <Text className={style.headingText + " " + style.quincyText}>Search Report</Text>
            <Text className={style.bodyText2}>Enter kit code to find the report that you are
                <span style={{ whiteSpace: 'nowrap' }}>looking for</span></Text>
            <OtpInput
                value={code}
                onChange={handleInputChange}
                numInputs={7}
                separator={<span style={{ width: "8px" }}></span>}
                isInputNum={false}
                className={style.otpInput}
                shouldAutoFocus={false}
                focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none"
                }}
            />
            {/* <Text className={style.bodyText1} style={{ textTransform: 'uppercase' }}>{kitType && kitType}</Text> */}
            <Button type="primary" className={style.continueButton} disabled={disableContinue}
                    onClick={() => reportSearchClickHandler(code)}>SEARCH</Button>
        </div>
    );
}

export default AdminFooter;