import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import Cookies from 'universal-cookie';
import {message} from 'antd';
import {userDataActions} from "@ph/common/store/userData.reducer";
import connectionInstance from "@ph/subject/src/api_services/connection";
import {AuthToken} from "@ph/subject/src/api_services/localstorage";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";

const RedirectToKitUserGuide = () => {
  const cookies = new Cookies();
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postKitCode = async () => {
    let kitcode = cookies.get('kitcode');
    if (!kitcode || kitcode === 'null') {
      navigate(`/getstarted`, { state: { errorMessage: <span><span style={{ whiteSpace: 'nowrap' }}>Something went wrong!</span> Please enter the kit code again.</span> } });
      return;
    }
    cookies.remove('kitcode');
    navigate(`/getstarted/${kitcode}`);
  };

  const getUserInfo = async () => {
    try {
      const res = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/subject`
      );
      if (res && res.data) {
        dispatch(userDataActions.updateUserData(res.data));
        cookies.remove('pageToRedirect', { domain: 'parallelhealth.io' });
        postKitCode();
      }
    } catch (error) {
      message.error("Something went wrong");
      window.location.replace(process.env.REACT_APP_SHOPIFY_URL);
    }
  };

  useEffect(() => {
    let token = params.get('id_token');
    if (token) {
      localStorage.setItem('ph_token', token);
      AuthToken.setToken('ph_token', token);
      getUserInfo();
      // postKitCode();
    }
  }, []);

  return (
      <FullPageLoader mTop='20vh' />
  )
}
export default RedirectToKitUserGuide