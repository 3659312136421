import React from 'react';
import style from "../Report.module.scss";
import {Col} from "antd";

function RecommendedItemsColList({ Text, itemKey, itemColClass, inlineStyle, iconComp, itemName, itemDesc, itemImgUrl, itemNameClass = "text-dark" }) {
    return (
      <Col xs={24} sm={24} md={24} xl={12} className={itemColClass} key={itemKey}>
            <div className={style.recommendationCols}
                 style={inlineStyle}>
                <div className={style.content}>
                    <span>
                        {iconComp}
                        <Text className={`body-txt-lg ${itemNameClass}`}>
                            {itemName}
                        </Text>
                    </span>
                    {itemDesc &&
                        <Text className={`body-txt-md ${itemNameClass}`}>{itemDesc}</Text>}
                </div>
                {itemImgUrl &&
                    <img src={itemImgUrl} className={style.image} alt=''/>
                }
            </div>
        </Col>
    );
}

export default RecommendedItemsColList;