import React, { useState } from "react";
import { Input } from "antd";

import style from "./floatInput.module.scss";
import { ArrowRightOutlined } from '@ant-design/icons';

const FloatInput = (props) => {
    const [focus, setFocus] = useState(false);
    let { label, value, placeholder, type, required } = props;

    if (!placeholder) placeholder = label;

    const isOccupied = focus || (value && value.length > 0);

    const labelClass = isOccupied ? `${style.label + " " + style.asLabel}` : `${style.label + " " + style.asPlaceholder}`;

    const requiredMark = required ? <span className="text-danger">*</span> : null;

    return (
        <div
            className={style.floatLabel}
            onBlur={() => setFocus(false)}
            onFocus={() => setFocus(true)}
        >
            <Input onChange={props.onChange} bordered={false} type={type} defaultValue={value}
                suffix={<ArrowRightOutlined className={style.icon} />}
            />
            <label className={labelClass}>
                {isOccupied ? label : placeholder} {requiredMark}
            </label>
        </div>
    );
};

export default FloatInput;