import React from 'react';
import style from "../Report.module.scss";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {Row} from "antd";
import RecommendedItemsColList from "./RecommendedItemsColList";
import {navigateToShopifyUrl} from "@ph/common/components/Report/reportUtils";
import {microBiomeDiscoveryBundleLink} from "@ph/common/global/Constants";
import {lock_icon} from "@ph/common/assets";
import { buildCompleteShopifyLink } from '@ph/common/global/Helpers';
import { SHOPIFY_PAGES } from '@ph/common/constants/ShopifyPages';
import { useNavigate } from 'react-router-dom';

function SkinCareRecommendationsPanel({
                                        Text,
                                        reportData,
                                        sandBorderBottom,
                                        getGoodItemStyle,
                                        getBadItemStyle,
                                        isETypeKit
                                      }) {

  const navigate = useNavigate()
  const sampleSkinCareRecommendations = [{
    name: 'INGREDIENT 01',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }, {
    name: 'INGREDIENT 02',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }, {
    name: 'INGREDIENT 03',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }, {
    name: 'INGREDIENT 04',
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam'
  }];
    return (
        <>
            <div className={style.recommendationLegend}>
          <span><CheckCircleFilled style={{ color: '#2E5ACC' }} className={style.circleIconSmall} /> <Text className={style.bodyText2}><strong>Recommended</strong> Ingredients</Text></span>
          <span><CloseCircleFilled style={{ color: '#A50832' }} className={style.circleIconSmall} /> <Text className={style.bodyText2}>Ingredients to <strong>avoid</strong></Text></span>
            </div>
            <Row gutter={[30]} className={style.recommendationRow} align="stretch" style={{ height: '100%' }}>
                {reportData.good_skincare_ingredients_recommendations.map((skinCareItem, index) => {
                    let key = `${skinCareItem.name}_${index}`;
                    let inlineStyle = sandBorderBottom;
                  let iconComp = <CheckCircleFilled className={style.circleIconBig} style={{ color: '#2E5ACC' }} />;
                    let itemName = skinCareItem.name;
                    let itemDesc = skinCareItem.description;
                    let itemImgUrl = skinCareItem.image_url;
                    let itemColClass = '';
                  if (!isETypeKit && reportData.bad_skincare_ingredients_recommendations.length < 1) {
                        inlineStyle = getGoodItemStyle(reportData.good_skincare_ingredients_recommendations, index);
                        itemColClass = style.lastItem;
                    }
                    return (
                        <RecommendedItemsColList
                            {...{Text, key, itemColClass, inlineStyle, iconComp, itemName, itemDesc, itemImgUrl}} />
                    );
                })}

                {reportData.bad_skincare_ingredients_recommendations.map((skinCareItem, index) => {
                    let key = `${skinCareItem.name}_${index}`;
                    let inlineStyle = getBadItemStyle(reportData.good_skincare_ingredients_recommendations,
                        reportData.bad_skincare_ingredients_recommendations, index);
                  let iconComp = <CloseCircleFilled className={style.circleIconBig} style={{ color: '#A50832' }} />;
                    let itemName = skinCareItem.name;
                    let itemDesc = skinCareItem.description;
                    let itemImgUrl = skinCareItem.image_url;
                    let itemColClass = '';
                    if(index === reportData.bad_skincare_ingredients_recommendations.length - 1) {
                        itemColClass = style.lastItem;
                    }

                    return (
                        <RecommendedItemsColList
                            {...{Text, key, itemColClass, inlineStyle, iconComp, itemName, itemDesc, itemImgUrl}} />
                    );
                })}
            </Row>

          {isETypeKit ?
            <Row gutter={[30]} className={`${style.recommendationRow} ${style.masked}`}
                 align="stretch" style={{height: '100%'}}>
              <div className={`${style.contentMask} hand-cursor`}
              onClick={() => window.open(`${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`, '_self')}>
                <div className={style.overlayContent}>
                  <img src={lock_icon} alt='locked'/>
                  <div className='body-txt-lg'>
                  Get your <Text className='body-txt-lg' underline={true} strong={true}>MD-03 Protocol™ </Text> to unlock more <br />
                    <Text className='body-txt-lg'>information about Skincare Ingredients Recommendations</Text>
                  </div>
                </div>
              </div>
              {sampleSkinCareRecommendations.map((skinCareItem, index) => {
                let key = `${skinCareItem.name}_${index}`;
                let inlineStyle = sandBorderBottom;
                let iconComp = <CheckCircleFilled className={style.circleIconBig} style={{ color: '#2E5ACC' }} />;
                let itemName = skinCareItem.name;
                let itemDesc = skinCareItem.description;
                let itemColClass = '';
                if (index > 1) {
                  iconComp = <CloseCircleFilled className={style.circleIconBig} style={{ color: '#A50832' }} />;
                  itemColClass = style.lastItem;
                }
                return <RecommendedItemsColList
                  {...{Text, key, itemColClass, inlineStyle, iconComp, itemName, itemDesc}} />
              })}
            </Row>
            :
            <></>}
        </>
    );
}

export default SkinCareRecommendationsPanel;