import React from 'react';
import {Radio} from 'antd';

const {Group} = Radio;

function PrescriptionStates({allPrescriptionStates, activePrescriptionState, prescriptionStateChanged}) {

    const prescriptionStateChangeHandler = ({target: {value}}) => {
        prescriptionStateChanged(value);
    };

    return (
        <Group
            options={allPrescriptionStates}
            onChange={prescriptionStateChangeHandler}
            value={activePrescriptionState}
            optionType="button"
            buttonStyle="solid"
            className='group-selection-tabs'
            size='large'
        />
    );
}

export default PrescriptionStates;