import React, {useEffect, useState} from 'react';
import { cancelBatch, createNewBatch, getBatchList } from "../AdminPortalAPIService";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import { Button, Modal, Table, Typography } from "antd";
import CreateBatch from "../CreateBatch/CreateBatch";
import {paginationDef} from "../LabPortalConstants";
import moment from "moment";
import {dateFormats} from "@ph/common/global/Constants";
import BatchDetails from '../BactchDetails/BatchDetails';
import { BATCH_INPROGRESS_LABELS } from "@ph/common/constants/BatchConstants";
import { handleErrorMsg } from '@ph/common/global/Helpers';
import { useOutletContext } from "react-router-dom";

const { Text } = Typography;

function ActiveBatches() {
    const [fetchingActiveBatches, setFetchingActiveBatches] = useState(true);
    const [activeBatches, setActiveBatches] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBatch, setSeletecBatch] = useState('');
    const [newBatchId, setNewBatchId] = useState('')
    const [newBatchLoading, setNewBatchLoading] = useState(false)
    const [reload, setReload] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount] = useOutletContext();

    const { pageSize } = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        setReload(!reload)
        setRefreshSideBarCount(!refreshSideBarCount)
    };

    const activeBatchColList = [
        {
            title: 'Batch Code',
            dataIndex: 'batch_code',
            key: 'batch_code'
        }, {
            title: 'Kit / Samples',
            key: 'kit_count',
            render: ({kit_count, sample_count}) => {
                return `${kit_count} / ${sample_count}`
            }
        }, {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by'
        }, {
            title: 'Last Activity',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        }, {
            title: 'In Process',
            dataIndex: 'batch_history_status',
            key: 'batch_history_status',
            render: (d) => {
                if(d.length) {
                    return <div className='clearfix'>
                        <div className='fl'>{BATCH_INPROGRESS_LABELS[d[0].status]}</div>
                        <div className='fr'>
                            <Text className='body-txt-lg clickable-link visible-on-hover'>
                                View Details
                            </Text>
                        </div>
                    </div>
                }
                return '';
            }
        }];

    useEffect(() => {
        setFetchingActiveBatches(true);
        getBatchList('active', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
        }).then(res => {
            if (res && res.data && res.data.batch_list && res.data.batch_list.length) {
                setActiveBatches(res.data.batch_list);
                setTotalCount(res.data.count)
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => setFetchingActiveBatches(false));
    }, [reload, currentPage, kitCodeFilter, startAndEndDate]);

    const newBatchResponseHandler = (res) => {
        if (res && res.data) {
            setNewBatchId(res.data.batch_code)
            setIsModalOpen(true)
        }
    }

    const cancelBatchHandler = (batchCode) => {
        cancelBatch(batchCode)
            .then(res => {
                setIsModalOpen(false)
                setSeletecBatch('')
                setReload(!reload)
                setRefreshSideBarCount(!refreshSideBarCount)
            })
    }

    const handleCreateBatch = () => {
        setNewBatchLoading(true)
        createNewBatch()
            .then(newBatchResponseHandler)
            .catch(error => handleErrorMsg(error, "Unable to Create Batch!"))
            .finally(() => setNewBatchLoading(false))
    }

    return (
        selectedBatch ?
            <BatchDetails batchData={selectedBatch} setSeletecBatch={setSeletecBatch} backLabel="BACK TO ACTIVE BATCHES" reload={reload} setReload={setReload} />
            :
        <>
            <LabPortalTopSectionContainer
                    topRightContent={<Button type="primary" onClick={handleCreateBatch} className="ph-btn" loading={newBatchLoading}>Create Batch</Button>}
                    timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                    searchFilterChangedEventHandler={handleSearchFilterChange}
                    searchPlaceholder={"Search Batch Code"}
            />

            <Table
                loading={fetchingActiveBatches}
                columns={activeBatchColList}
                dataSource={activeBatches}
                    pagination={pagination}
                className='lab-portal-table'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => setSeletecBatch(record)     
                        }
                    }}
            />

                <Modal open={isModalOpen}
                    onCancel={handleOk}
                    width='95%'
                    style={{ top: 5 }}
                    footer={[]}
                >
                    <CreateBatch createNew={true} batchId={newBatchId} closeModal={handleOk} cancelBatchHandler={cancelBatchHandler} />

            </Modal>
        </>
    );
}

export default ActiveBatches;