import React, {useEffect, useState} from 'react'
import style from './Jotform.module.scss';
// import {useSelector} from 'react-redux';
import {message} from "antd";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";

const PrescriptionForm = (props) => {
    const [fetchingPrescriptionFormLink, setFetchingPrescriptionFormLink] = useState(false);
    const [prescriptionFormLink, setPrescriptionFormLink] = useState('');
    const { connectionInstance } = props;

    const getPrescriptionFormLink = async () => {
        setFetchingPrescriptionFormLink(true);
        try {
            const res = await connectionInstance.post(
                `${process.env.REACT_APP_API_URL}/prescription/prescription_request`
            );
            if (res && res.data) {
                setPrescriptionFormLink(res.data.jotform_url)
            }
        } catch (error) {
            message.error("Something went wrong!");
            window.location = `${process.env.REACT_APP_SHOPIFY_URL}`;
        } finally {
            setFetchingPrescriptionFormLink(false);
        }
    };

    useEffect(() => {
        getPrescriptionFormLink();
    }, []);

    return (
        <div className={`${style.jotformContainer} jot-form-full-height`}>
            {
                fetchingPrescriptionFormLink || !prescriptionFormLink ?
                <FullPageLoader/> :
                    <iframe title="Prescription flow"
                            onLoad={window.parent.scrollTo(0,0)}
                            allowtransparency="true"
                            allowFullScreen={true}
                            allow="geolocation; microphone; camera"
                            src={prescriptionFormLink}
                            sandbox="allow-forms allow-popups allow-scripts allow-top-navigation allow-same-origin"/>


            }
        </div>
    )
}

export default PrescriptionForm;