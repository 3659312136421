import { useEffect, useState } from 'react';
import style from '../../AdminLabPortal/ValidateKitFormContent/ValidateKitFormContent.module.scss';
import { Button, Col, Form, Divider, List, Row, Input, Typography } from "antd";
import { close_icon } from "@ph/common/assets";
import UpdateOrderUser from './UpdateOrderUser';
import { postCommentOnKit, unassignKitcode } from '../ServiceDashboardAPIService';
import { getFormatedDateTime, handleErrorMsg, handleSuccessMsg } from '@ph/common/global/Helpers';
import ConfirmationModal from 'src/component/AdminLabPortal/ValidateKitFormContent/ConfirmationModal';

const { TextArea } = Input;
const { Text } = Typography


function OrderedKitsForm({
    title, record, closeClickHandler, onValidationStatusChanged }) {

    const [validationComment, setValidationComment] = useState("");
    const [updateUserModal, setUserModal] = useState(false)
    const [openConfirmationModal,setOpenConfirmationModal] = useState(false)

    useEffect(() => {
        if (record?.comments) {
            setValidationComment(record.comments);
        }
        return () => {
            setValidationComment('');
            setUserModal(false);
        };
    }, [record]);

    const onCommentChange = (e) => {
        setValidationComment(e.target.value);
    };

    const handleCommentPost = () => {
        const formData = new FormData();
        formData.append('comments',validationComment)
        postCommentOnKit(record.kitcode,formData)
        .then(res => {
           handleSuccessMsg("Comment updated successfully!")
        })
        .catch(error => handleErrorMsg(error,"Unable to add comment!"))
    }

    const handleUnassignKitcode = () => {
        // TODO: Unassign a git code
        unassignKitcode(record.kitcode)
            .then(() => {
                handleSuccessMsg("Unassigned kitcode successfully!")
                onValidationStatusChanged()
                setOpenConfirmationModal(false)
            })
            .catch(error => handleErrorMsg(error, "Unable to unassign this kitcode!"))

    }

    return (
        <>
            <div className={style.expandedRow}>
                <div className={style.expandedRowArrow}>
                    <div className={style.expandedRowArrowTR}></div>
                </div>
                <Row>
                    <Col span={12}>{title}</Col>
                    <Col span={12} className='text-right'>
                        <img className={`${style.closeIcon} hand-cursor`} src={close_icon} alt='close'
                            onClick={closeClickHandler} />
                    </Col>
                </Row>

                <Form>
                    <Row className={style.validateFormContainer}>
                    </Row>
                    <Form.Item>
                        <Row>
                            <Col span={4} style={{paddingRight: '20px'}}>
                                <List.Item>
                                    <List.Item.Meta
                                        title={<Typography className="text-light body-txt-lg medium-text">USER EMAIL ID:</Typography>}
                                        description={<Typography className="text-light td-underline hand-cursor" onClick={() => setUserModal(true)}>{record?.registered_by ? record?.registered_by : "NA"}</Typography>}
                                    />
                                </List.Item>
                            </Col>
                            <CustomList title="USER NAME:" value={`${record?.registered_user_first_name ?? ''} ${record?.registered_user_last_name ?? ''}`} colSpan={4} />
                            <Col span={1}>
                                <Divider type='vertical' className={style.lineSeperator} />
                            </Col>

                            <CustomList title="PURCHASER NAME:" value={`${record?.purchaser_first_name} ${record?.purchaser_last_name}`} colSpan={5} />
                            <CustomList title="PURCHASER EMAIL ID:" value={record?.purchaser_email} colSpan={5} />
                            <CustomList title="PURCHASER PHONE NO.:" value={record?.purchaser_phone} colSpan={5} />

                            <Divider className={style.lineSeperator} />
                            <ListStack label="GENERATED" primary={getFormatedDateTime(record?.generated_on)} seconday={record?.generated_by} />
                            <ListStack label="ASSIGNED" primary={getFormatedDateTime(record?.assigned_on)} seconday={record?.assigned_by} />
                            <ListStack label="REGISTERED" primary={getFormatedDateTime(record?.registered_on)} seconday={record?.registered_by} />
                            <ListStack label="RECEIVED" primary={getFormatedDateTime(record?.received_on)} seconday={record?.received_by} />
                            <ListStack label="VALIDATED" primary={getFormatedDateTime(record?.validated_on)} seconday={record?.validated_by} divider={false} />
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <ListStack label="PROCESSED" primary={getFormatedDateTime(record?.processed_on)} seconday={record?.processed_by} />
                            <ListStack label="SHIPPED" primary={getFormatedDateTime(record?.shipped_on)} seconday={record?.shipped_by} />
                            <ListStack label="SEQUENCED" primary={getFormatedDateTime(record?.sequenced_on)} seconday={false} />
                            <ListStack label="REPORTED" primary={getFormatedDateTime(record?.report_on)} seconday={false} divider={false} />

                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <TextArea rows={4} placeholder='Your comments here (if any)'
                            value={validationComment} onChange={onCommentChange} />
                    </Form.Item>
                    <Form.Item className="text-center">
                        {record?.latest_kit_status === "ASSIGNED" && (
                        <Text className="body-txt-lg text-light td-underline hand-cursor" style={{position: 'absolute', left: 4}} onClick={() => setOpenConfirmationModal(true)} >
                            UN-ASSIGN KIT CODE
                        </Text>
                        )}
                        <Button type="secondary" onClick={handleCommentPost} disabled={!validationComment.trim()}>SAVE</Button>
                    </Form.Item>
                </Form>
            </div>
            <UpdateOrderUser
                modalTitle='Change User Email ID'
                open={updateUserModal}
                closeModal={() => setUserModal(false)}
                selectedRecord={record}
                onCallback={onValidationStatusChanged}
            />
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                closeConfirmationModal={() => setOpenConfirmationModal(false)}
                confirmationMsg={`Are you sure you want to unassign kit code?`}
                cancelBtnText={"NO, GO BACK"}
                cancelBtnAction={() => setOpenConfirmationModal(false)}
                proceedBtnText={"YES, GO AHEAD"}
                proceedBtnAction={() => handleUnassignKitcode()}
                closable={true}
                modalTop='30%'
            />
        </>
    );
}

export default OrderedKitsForm;

const CustomList = ({ title, value, colSpan = 4 }) => {
    return (
        <Col span={colSpan}>
            <List.Item>
                <List.Item.Meta
                    title={<Typography className="text-light body-txt-lg medium-text">{title ? title : '-'}</Typography>}
                    description={<Typography className="text-light">{value && value?.trim() ? value : '-'}</Typography>}
                />
            </List.Item>
        </Col>
    )
}

const ListStack = ({ colSpan = 4, label, primary, seconday, divider = true }) => {
    return (
        <>
            <Col span={colSpan}>
                <List.Item>
                    <List.Item.Meta
                        title={<Typography className="text-light body-txt-lg">{`${label} ON:`}</Typography>}
                        description={<Typography className="text-light">{primary ? primary : '-'}</Typography>}
                    />
                </List.Item>
                {seconday !== false ? (
                    <List.Item>
                        <List.Item.Meta
                            title={<Typography className="text-light body-txt-lg">{`${label} BY:`}</Typography>}
                            description={<Typography className="text-light">{seconday ? seconday : '-'}</Typography>}
                        />
                    </List.Item>
                ) : <></>}
            </Col>
            {divider && (
                <Col span={1}>
                    <Divider type='vertical' className={style.lineSeperator} />
                </Col>
            )}
        </>
    )
}