import React, {useEffect, useState} from 'react';
import {getKitsList} from "../AdminPortalAPIService";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import { CheckColum, updateGlobalSearchStates, isRowExpanded, paginationDef } from "../LabPortalConstants";
import moment from "moment/moment";
import {dateFormats, sortOrderMap} from "@ph/common/global/Constants";
import {Table, Typography} from "antd";
import ValidateKitFormContent from "../ValidateKitFormContent/ValidateKitFormContent";
import {useOutletContext} from "react-router-dom";
import { getFriendlyKitCode } from '@ph/common/global/Helpers';

const {Text} = Typography;

function OnHoldKits({ id }) {
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch] = useOutletContext();
    const [fetchingOnHoldItems, setFetchingOnHoldItems] = useState(true);
    const [onHoldItems, setOnHoldItems] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('validated_on');

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleOnHoldKitsTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const onHoldColList = [
        {
            title: 'Kit Code',
            dataIndex: 'kitcode',
            key: 'kitcode',
            render: (kitCode) => getFriendlyKitCode(kitCode)
        },
        {
            title: 'Kit Type',
            dataIndex: 'kit_type',
            key: 'kit_type',
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        },
        {
            title: 'Hydration Strip',
            dataIndex: 'oil_strip_image_urls',
            key: 'oil_strip_image_urls',
            render: (d) => <CheckColum valid={d && d?.Oil_Strip} />
        },
        {
            title: 'Batch Code',
            dataIndex: 'batch_code',
            key: 'batch_code',
            render: (d) => 'Unassigned'
        }, {
            title: 'Date of Hold',
            dataIndex: 'validated_on',
            key: 'validated_on',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        }, {
            title: 'Days Since put on hold',
            dataIndex: 'days_since_validation',
            key: 'days_since_validation',
            render: (d) => {
                return (<div className='clearfix'>
                    <div className='fl'>{d}</div>
                    <div className='fr'>
                        <Text className='body-txt-md clickable-link visible-on-hover'>View / Edit Details</Text>
                    </div>
                </div>)
            }
        }];

    const handleValidationStatusChange = () => {
        setRefreshSideBarCount(!refreshSideBarCount);
        fetchOnHoldKits();
    };

    useEffect(() => {
        updateGlobalSearchStates(id, globalSearch, setGlobalSearch, setKitCodeFilter, setOnHoldItems, setTotalCount, setFetchingOnHoldItems)
    }, [globalSearch])

    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            fetchOnHoldKits();
        }
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy]);

    function fetchOnHoldKits() {
        setFetchingOnHoldItems(true);
        closeExpandedRow();
        getKitsList('on_hold', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            sortOrder,
            sortBy
        }).then(res => {
            if (res?.data?.kits_info_list) {
                setTotalCount(res.data.count);
                setOnHoldItems(res.data.kits_info_list);
                console.log(res.data.kits_info_list, "THIIIIIIIIISSSSSSS")
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => setFetchingOnHoldItems(false));
    }

    function expandedRowRenderer(record) {
        console.log("🚀 ~ expandedRowRenderer ~ record:", record)
        return <ValidateKitFormContent key={record.kit_accession_id} record={record}
                                       closeClickHandler={closeExpandedRow}
                                       onValidationStatusChanged={handleValidationStatusChange}
                                       validationFormContext={'onHold'}/>;
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function rowOnExpandHandler(expanded, value) {
        console.log("🚀 ~ rowOnExpandHandler ~ value:", value)
        const { key } = value;
        setExpandedRowKey(expanded ? key : '');
    }

    function onRowHandler(value) {
        console.log("🚀 ~ onRowHandler ~ value:", value)
        const { key } = value;
        return (isRowExpanded(key, expandedRowKey) &&
            {className: "expanded-row"});
    }

    return (
        <>
            <LabPortalTopSectionContainer subTitle='Accept or Reject'
                timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange}
                kitCodeFilter={kitCodeFilter}
            />

            <Table
                loading={fetchingOnHoldItems}
                columns={onHoldColList}
                dataSource={onHoldItems}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleOnHoldKitsTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderer,
                    expandIcon: () => ''
                }}
            />
        </>
    )
}

export default OnHoldKits;