import LightLayout from './LightLayout';
import ReportLayout from './ReportLayout';
import LabPortalLayout from './LabPortalLayout';
import PhysicianPortalLayout from "./PhysicianPortalLayout";
import ServiceDashboardLayout from './ServiceDashboardLayout';

export default {
  LightLayout,
  ReportLayout,
  LabPortalLayout,
  PhysicianPortalLayout,
  ServiceDashboardLayout
};
