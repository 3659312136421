import { LAB_REPORT_NAV } from "@ph/common/utils/constants";
import { buildCompleteShopifyLink } from "./Helpers";
import { SHOPIFY_PAGES } from "../constants/ShopifyPages";
import { megamenu_instruction, megamenu_orders, megamenu_report } from "../assets/index";

export const dateFormats = {
    'DDSPCMMMCOMSPCYYYY': 'DD MMM, YYYY',
    'DDSPCMMMCOMSPCAPOSYYSPCHHMM': 'DD MMM, \'YY HH:mm',
    'DDSPCMMMCOMSPCAPOSYYSPCHHMMSS': 'DD MMM, \'YY HH:mm:ss',
    'DDSPCMMMMCOMSPCYYYY': 'DD MMMM, YYYY',
    'MMDOTDDDOTYYYY': 'MM.DD.YYYY',
    'MMSLADDSLAYYYY': 'MM/DD/YYYY',
    'MMSLADDSLAYYYYSPCATSPCHHCOLMM': 'MM/DD/YYYY [at] HH:mm'
}

export const sortOrderMap = {
    'descend': 'desc',
    'ascend': 'asc',
    '': ''
};

export const RETURN_URL = 'returnToUrl'

export const KIT_REGISTER_PRARAM = 'register'

export const sandBorderBottom = ({borderBottom: '1px solid #cec3c1'});

export const microBiomeDiscoveryBundleLink = `${process.env.REACT_APP_SHOPIFY_URL}/products/full-microbiome-discovery-bundle`;

export const HEALTH_PROFILE_STATUS = {
    COMPLETED : "COMPLETED",
    IN_PROGRESS : "IN_PROGRESS",
    REQUIRED: "REQUIRED",
    NOT_REQUIRED: "NOT_REQUIRED"
}

export const GLOBAL_SEARCH_NAV = {
    registered: LAB_REPORT_NAV.REGISTERED_KITS,
    received: LAB_REPORT_NAV.RECEIVED_KITS,
    valid: LAB_REPORT_NAV.VALIDATED_KITS,
    on_hold: LAB_REPORT_NAV.ON_HOLD_KITS,
    rejected: LAB_REPORT_NAV.REJECTED_KITS,
    completed: LAB_REPORT_NAV.COMPLETED_KITS
}

export const GLOBAL_SEARCH_INIT = {
    GSNav: "",
    GSKitCode: "",
    GSKitResponse: {}
}

export const MEGA_MENU_CONFIG = [
    {
        id: '01',
        title: 'My Skin Test(s), Reports, and Forms',
        description: 'View your skin microbiome test kit statuses, respective health intake forms and their results.',
        imgSrc: megamenu_report,
        link: '/dashboard'
    },
    {
        id: '02',
        title: 'Kit Usage Instructions',
        description: 'Learn how to use the swab kits.',
        imgSrc: megamenu_instruction,
        link: '/kit-user-guide'
    },
    {
        id: '03',
        title: 'My Order History',
        description: 'Get access to your order history, add/edit your addresses or if incase you want to logout',
        imgSrc: megamenu_orders,
        link: `${process.env.REACT_APP_SHOPIFY_URL}/${SHOPIFY_PAGES.ACCOUNT}`
    }
]

export const PER_MONTH_LABEL = 'MO'

export const formatFloat = (num) => {
    // Round to one decimal place
    const rounded = Math.round(num * 10) / 10;
    // Convert to string and remove unnecessary trailing zeros
    return parseFloat(rounded.toFixed(1));
}