export const AuthToken = {
  setToken: (key, token) => {
    localStorage.setItem(key, token);
  },
  getToken: (key) => {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
    return null;
  },
  removeStorage: () => {
    // localStorage.removeItem('ph_token');
    localStorage.removeItem('ph_token')
  },
};

export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function removeLocalStorage(key) {
  localStorage.removeItem(key)
}

// export function setCookie(cname, cvalue, exdays) {
//   const d = new Date();
//   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
//   let expires = "expires=" + d.toUTCString();
//   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
// }

// export function getCookie(name) {
//   function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
//   var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
//   return match ? match[1] : null;
// }