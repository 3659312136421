import React, {useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import {LAB_REPORT_ROOT_NAV, PHYSICIAN_PORTAL_SIDE_NAV, PHYSICIAN_ROOT_NAV} from '../../../utils/nav-menu-items';
import style from './sidebar.module.scss';
import {convertToURIPath} from '@ph/common/global/Helpers';
import {getPrescriptionCount, getRecordsCount} from "../../AdminLabPortal/AdminPortalAPIService";

const {Sider} = Layout;


function PhysicianPortalSidebar() {
    const currentLocation = useLocation();
    const pathName = currentLocation.pathname.replace("/", "");
    let navigate = useNavigate();
    const [currentNav, setCurrentNav] = useState(pathName);
    const [menuItemsList, setMenuItemsList] = useState([]);
    const [sideNavItemsList, setSideNavItemsList] = useState(Object.entries(PHYSICIAN_PORTAL_SIDE_NAV));

    const getItem = (label, key, children) => {
        return {
            key,
            label,
            children
        };
    }

    const subMenuNavItemBuilder = ({name, id, count, key}) => {
        if (key === currentNav) {
            localStorage.setItem('currentNavLabel', name);
            localStorage.setItem('activePrescriptionType', id);
            localStorage.setItem('activePrescriptionCount', count);
        }
        return <div className='clearfix'>
            <span className='fl'>{name}</span>
            <span className='fr'>{count}</span>
        </div>;
    };

    const updatePrescriptionCount = () => {
        getPrescriptionCount().then(recordsCountSuccessHandler).catch((error) => {
            console.log(error);
        }).finally(() => {
            setMenuItemsList([]);
            sideNavItemsList.forEach(([rootItem, navItem]) => {
                let subMenuItems = navItem.map(subMenuItem => {
                    subMenuItem.key = convertToURIPath(subMenuItem.name, 'physician-portal');
                    return getItem(subMenuNavItemBuilder(subMenuItem), subMenuItem.key);
                });
                setMenuItemsList(prevState => [...prevState, getItem(rootItem, rootItem, subMenuItems)]);
            });
        });
    };

    function recordsCountSuccessHandler(result) {
        if (!result || !result.data) {
            return;
        }
        const {data} = result;
        setSideNavItemsList(prevState => {
            prevState.forEach(([rootItem, navItem]) => {
                navItem.forEach((navObj) => {
                    navObj.count = 0;
                    if (data.hasOwnProperty(navObj.name)) {
                        navObj.count = data[navObj.name].total || 0;
                    }
                })
            })
            return [...prevState];
        });
    }

    useEffect(() => {
        updatePrescriptionCount();
    }, [currentNav]);

    function handleClick({key, keyPath, ...otherProps}) {
        if (keyPath && keyPath.length > 1 && PHYSICIAN_PORTAL_SIDE_NAV[keyPath[1]]) {
            let menuItem = PHYSICIAN_PORTAL_SIDE_NAV[keyPath[1]].find((el) =>
                convertToURIPath(el.name, 'physician-portal') === key) || {};
            localStorage.setItem('currentNavLabel', menuItem.name);
            localStorage.setItem('activePrescriptionType', menuItem.id);
            localStorage.setItem('activePrescriptionCount', menuItem.count);
        }
        setCurrentNav(key);
        navigate(`/${convertToURIPath(key)}`);
    }

    return <Sider width={270} className={style.portalSidebar}>
        <Menu
            mode="inline"
            defaultSelectedKeys={[pathName]}
            defaultOpenKeys={PHYSICIAN_ROOT_NAV}
            onClick={handleClick}
            selectedKeys={[currentNav]}
            multiple={false}
            className="sidebar"
            items={menuItemsList}
        />
    </Sider>;
}

export default PhysicianPortalSidebar;