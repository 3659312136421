import React from 'react';
import style from "./Report.module.scss";
import {admin_logout, search_icon_light} from "@ph/common/assets";
import OtpInput from "react18-input-otp";
import {Button, Typography} from "antd";
import {useNavigate} from "react-router-dom";

const { Text } = Typography;
function AdminHeader({codeHeader, handleInputChange, disableContinueHeader, reportSearchClickHandler}) {
    const navigate = useNavigate();
    return (
        <header className={style.headerContainer}>
            {/* style={reportLayout ? { borderBottom: '2px solid #E18672' } : { borderBottom: 'none' }}> */}
            <div className={"viewBoxWidth " + style.customViewBoxPadding} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className={style.logoSection}>
                    <img alt="Parallel" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
                </div>
                <div className={style.logoutSection}>
                    <div className={style.showInBigScreen} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'row' }}>
                        {/* <Text className={style.linkText}>VIEW REPORT</Text> */}
                        {/* <Tooltip title="Search Report to View"
                                    color={"#fbeee4"}
                                    placement="bottom"
                                    autoAdjustOverflow
                                    overlayInnerStyle={{ color: '#fbeee4', padding: '16px' }} trigger="hover"
                                    className={style.showInBigScreen}
                                >
                                    <img src={search_icon_light} className={style.logoutIcon} />
                                </Tooltip> */}
                        <img src={search_icon_light} className={style.logoutIcon} alt='' />
                        <OtpInput
                            value={codeHeader}
                            onChange={(input) => handleInputChange(input, true)}
                            numInputs={7}
                            separator={<span style={{ width: "8px" }}></span>}
                            isInputNum={false}
                            className={style.otpInput}
                            focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                            }}
                        />
                        <Button type="secondary" className={style.continueButton}
                                disabled={disableContinueHeader}
                                onClick={() => reportSearchClickHandler(codeHeader)}>SEARCH</Button>
                    </div>
                    <Text className={style.linkText} style={{ cursor: 'pointer' }}
                          onClick={() => navigate('/')}>ADMIN DASHBOARD</Text>
                    <img src={admin_logout} className={style.logoutIcon} style={{ cursor: "pointer" }}
                         onClick={() => window.location = `${process.env.REACT_APP_AWS_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`} />
                </div>
            </div>
        </header>
    );
}

export default AdminHeader;