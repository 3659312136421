import { body_blemish_md03, md_03_01, olivia_md03, skin_md03, custom_active_1_md03, custom_active_3_md03 } from '@ph/common/assets/index';
import client from './connection';

export const PROTOCOL_IDS = {
    MD03_PROTOCOL: process.env.REACT_APP_SHOPIFY_MD03_PROTOCOL,
    SKIN_TEST_WITH_PACK_1: process.env.REACT_APP_SHOPIFY_SKIN_TEST_WITH_PACK_1,
    SKIN_TEST_WITH_PACK_3: process.env.REACT_APP_SHOPIFY_SKIN_TEST_WITH_PACK_3,
    BODY_BLEMISH_PACK_1: process.env.REACT_APP_SHOPIFY_BODY_BLEMISH_PACK_1,
    BODY_BLEMISH_PACK_3: process.env.REACT_APP_SHOPIFY_BODY_BLEMISH_PACK_3,
    HOLY_CALMING_CLEANSER_ID: process.env.REACT_APP_SHOPIFY_HOLY_CALMING_CLEANSER_ID,
    HOLY_CALMING_CLEANSER_VARIANT: process.env.REACT_APP_SHOPIFY_HOLY_CALMING_CLEANSER_VARIANT,
    SKIN_BARRIER_SILK_CREAM_ID: process.env.REACT_APP_SHOPIFY_SKIN_BARRIER_SILK_CREAM_ID,
    SKIN_BARRIER_SILK_CREAM_VARINAT: process.env.REACT_APP_SHOPIFY_SKIN_BARRIER_SILK_CREAM_VARINAT,
    PERSONALIZED_PRESCRIPTIONS: process.env.REACT_APP_SHOPIFY_PERSONALIZED_PRESCRIPTIONS,
    BLUE_PEPTIDE_CREAM_ID: process.env.REACT_APP_SHOPIFY_BLUE_PEPTIDE_CREAM_ID,
    BLUE_PEPTIDE_CREAM_VARIANT: process.env.REACT_APP_SHOPIFY_BLUE_PEPTIDE_CREAM_VARIANT,
    CUSTOM_PHAGE_ID: 4449652133092201
}

export const PROTOCOL_SELLING_PLAN = {
    BLUE_PEPTIDE_CREAM_SELLING_PLAN: process.env.REACT_APP_SHOPIFY_BLUE_PEPTIDE_CREAM_SELLING_PLAN,
    HOLY_CALMING_CLEANSER_SELLING_PLAN: process.env.REACT_APP_SHOPIFY_HOLY_CALMING_CLEANSER_SELLING_PLAN,
    MD03_PROTOCOL_SELLING_PLAN: process.env.REACT_APP_SHOPIFY_MD03_PROTOCOL_SELLING_PLAN,
    PERSONALIZED_PRESCRIPTIONS_SELLING_PLAN: process.env.REACT_APP_SHOPIFY_PERSONALIZED_PRESCRIPTIONS_SELLING_PLAN,
    SKIN_BARRIER_SILK_CREAM_ID_SELLING_PLAN: process.env.REACT_APP_SHOPIFY_SKIN_BARRIER_SILK_CREAM_ID_SELLING_PLAN
}

export const PROTOCOL_FETCH = {
    [PROTOCOL_IDS.MD03_PROTOCOL]: {
        onFetch: () => getMD03Protocol(PROTOCOL_IDS.MD03_PROTOCOL)
    },
    [PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1]: {
        onFetch: () => getMD03ProtocolVairant(PROTOCOL_IDS.MD03_PROTOCOL, PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1)
    },
    [PROTOCOL_IDS.BODY_BLEMISH_PACK_1]: {
        onFetch: () => getMD03ProtocolVairant(PROTOCOL_IDS.MD03_PROTOCOL, PROTOCOL_IDS.BODY_BLEMISH_PACK_1)
    },
    [PROTOCOL_IDS.BODY_BLEMISH_PACK_3]: {
        onFetch: () => getMD03ProtocolVairantPrice(PROTOCOL_IDS.MD03_PROTOCOL, PROTOCOL_IDS.BODY_BLEMISH_PACK_3, PROTOCOL_IDS.BODY_BLEMISH_PACK_3)
    },
    // Added custom 01 at the end of id since this id is already used.
    [PROTOCOL_IDS.CUSTOM_PHAGE_ID]: {
        onFetch: () => getMD03ProtocolVairantPrice(PROTOCOL_IDS.MD03_PROTOCOL, PROTOCOL_IDS.BODY_BLEMISH_PACK_1, PROTOCOL_IDS.CUSTOM_PHAGE_ID)
    },
    [PROTOCOL_IDS.HOLY_CALMING_CLEANSER_ID]: {
        onFetch: () => getAddOnsProtocol(PROTOCOL_IDS.HOLY_CALMING_CLEANSER_ID, PROTOCOL_IDS.HOLY_CALMING_CLEANSER_VARIANT)
    },
    [PROTOCOL_IDS.SKIN_BARRIER_SILK_CREAM_ID]: {
        onFetch: () => getAddOnsProtocol(PROTOCOL_IDS.SKIN_BARRIER_SILK_CREAM_ID, PROTOCOL_IDS.SKIN_BARRIER_SILK_CREAM_VARINAT)
    },
    [PROTOCOL_IDS.PERSONALIZED_PRESCRIPTIONS]: {
        onFetch: () => getAddOnsProtocol(PROTOCOL_IDS.PERSONALIZED_PRESCRIPTIONS)
    },
    [PROTOCOL_IDS.BLUE_PEPTIDE_CREAM_ID]: {
        onFetch: () => getAddOnsProtocol(PROTOCOL_IDS.BLUE_PEPTIDE_CREAM_ID, PROTOCOL_IDS.BLUE_PEPTIDE_CREAM_VARIANT)
    },

}

// Convert the MD03IMAGES array to an object
const MD03IMAGES = {
    [PROTOCOL_IDS.MD03_PROTOCOL]: md_03_01, // Map product_id to the image URL
    [PROTOCOL_IDS.BODY_BLEMISH_PACK_1]: body_blemish_md03,
    [PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1]: skin_md03,
    [PROTOCOL_IDS.HOLY_CALMING_CLEANSER_ID]: olivia_md03,
    [PROTOCOL_IDS.SKIN_BARRIER_SILK_CREAM_ID]: olivia_md03,
    [PROTOCOL_IDS.PERSONALIZED_PRESCRIPTIONS]: olivia_md03,
    [PROTOCOL_IDS.CUSTOM_PHAGE_ID]: custom_active_1_md03,
    [PROTOCOL_IDS.BODY_BLEMISH_PACK_3]: custom_active_3_md03,
  };
  
  // Define the getMD03Image function
const getMD03Image = (product_id) => {
    // Return the image URL if product_id exists in MD03IMAGES, otherwise return a default image URL
    return MD03IMAGES[product_id] || '';
};
export const getMD03Protocol = (productId) => {
    const productIdURL = `gid://shopify/Product/${productId}`;
    return new Promise((resolve, reject) => {
        client.product.fetch(productIdURL)
            .then((product) => {            
                const productData = product || {};
                const product_title = productData.title || '';
                const product_id = productData.id || '';
                // todo: descriptionHtml
                const product_description = productData.descriptionHtml || '';
                const product_vendor = productData.vendor || '';
                const product_handle = productData.handle || '';
                const product_image = productData.images[0].src || getMD03Image(productId)
                const product_variants = productData.variants;

                const finalData = {
                    product_title,
                    product_id,
                    product_description,
                    product_vendor,
                    product_handle,
                    product_image,
                    product_variants
                };
                resolve(finalData);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMD03ProtocolVairant = (productId, variantId) => {
    const productIdURL = `gid://shopify/Product/${productId}`;
    const variantIdUrl = `gid://shopify/ProductVariant/${variantId}`
    return new Promise((resolve, reject) => {
        client.product.fetch(productIdURL)
            .then((product) => {
                
                const product_variants = product.variants
                const product_variant = product_variants.filter(variant => variant.id === variantIdUrl)

                const productData = product_variant[0] || {};

                const product_title = productData.selectedOptions[0].value + " " + productData.selectedOptions[0].name || '';
                const product_vendor = productData.vendor || '';
                const product_handle = productData.handle || '';
                const product_image = productData.image.src || getMD03Image(variantId)

                const finalData = {
                    product_title,
                    product_vendor,
                    product_handle,
                    product_image,
                    product_variants
                };
                resolve(finalData);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMD03ProtocolVairantPrice = (productId, variantId, imageId) => {
    const productIdURL = `gid://shopify/Product/${productId}`;
    const variantIdUrl = `gid://shopify/ProductVariant/${variantId}`
    return new Promise((resolve, reject) => {
        client.product.fetch(productIdURL)
            .then((product) => {
                const product_variants = product.variants
                const product_variant = product_variants.filter(variant => variant.id === variantIdUrl)

                const productData = product_variant[0] || {};
                const product_image = getMD03Image(imageId);
                const product_price = productData?.price?.amount;

                const finalData = {
                    product_image,
                    product_variants,
                    product_price,
                };
                resolve(finalData);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getAddOnsProtocol = (productId, variantId) => {
    const productIdURL = `gid://shopify/Product/${productId}`;
    return new Promise((resolve, reject) => {
        client.product.fetch(productIdURL)
            .then((product) => {
                const productData = product || {};
                const product_title = productData.title || '';
                const product_description = productData.description || '';
                const product_vendor = productData.vendor || '';
                const product_handle = productData.handle || '';
                const product_image = productData?.images[0]?.src || ''
                const product_variant_id = variantId || productId;
                const product_price = product?.variants[0]?.price?.amount || '';
                const product_discount_price = product?.variants[0]?.compareAtPrice?.amount || '';
                const product_variants = productData.variants;

                const finalData = {
                    product_title,
                    product_description,
                    product_vendor,
                    product_handle,
                    product_image,
                    product_price,
                    product_discount_price,
                    product_variant_id,
                    product_variants
                };
                resolve(finalData);
            })
            .catch((error) => {
                reject(error);
            });
    });
};


