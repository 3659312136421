export const AuthToken = {
  setToken: (key, token) => {
    localStorage.setItem(key, token);
  },
  getToken: (key) => {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
    return null;
  },
  removeStorage: () => {
    // localStorage.removeItem('ph_token');
    localStorage.clear();
  },
};
