import React, {useEffect, useState} from 'react';
import {Button, Modal, Table, Typography} from "antd";
import CreateBatch from "../CreateBatch/CreateBatch";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import {getKitsList} from "../AdminPortalAPIService";
import moment from "moment";
import {dateFormats, sortOrderMap} from "@ph/common/global/Constants";
import ValidateKitFormContent from "../ValidateKitFormContent/ValidateKitFormContent";
import { CheckColum, defaultKitManagementColumnList, isRowExpanded, paginationDef, updateGlobalSearchStates } from "../LabPortalConstants";
import {useOutletContext} from "react-router-dom";
import { getFriendlyKitCode } from '@ph/common/global/Helpers';

const { Text } = Typography;

function ValidatedKits({ id }) {
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch] = useOutletContext();
    const [fetchingValidatedItems, setFetchingValidatedItems] = useState(true);
    const [validatedItems, setValidatedItems] = useState([]);
    const [expandedRowKey, setExpandedRowKey] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('validated_on');

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const handleValidatedKitsTableChange = (pagination, filters, sorter) => {
        const {field, order} = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    const validatedKitsColList = [
        {
            title: 'Kit Code',
            dataIndex: 'kitcode',
            key: 'kitcode',
            render: (kitCode) => getFriendlyKitCode(kitCode)
        },
        {
            title: 'Kit Type',
            dataIndex: 'kit_type',
            key: 'kit_type',
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        },
        {
            title: 'Hydration Strip',
            dataIndex: 'oil_strip_image_urls',
            key: 'oil_strip_image_urls',
            render: (d) => <CheckColum valid={d && d?.Oil_Strip} />
        },
        {
            title: 'Batch Code',
            dataIndex: 'batch_code',
            key: 'batch_code',
            sorter: true
        }, {
            title: 'Date of Validation',
            dataIndex: 'validated_on',
            key: 'validated_on',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        }, {
            title: 'Days Since Validation',
            dataIndex: 'days_since_validation',
            key: 'days_since_validation',
            render: (d) => {
                return (<div className='clearfix'>
                    <div className='fl' style={{ marginTop: '4px' }}>{d}</div>
                    <div className='fr'>
                        <Text className='body-txt-md clickable-link visible-on-hover'>View / Edit Details</Text>
                    </div>
                </div>)
            }
        }];

    const handleValidationStatusChange = () => {
        setRefreshSideBarCount(!refreshSideBarCount);
        fetchValidatedKits();
    };
    useEffect(() => {
        updateGlobalSearchStates(id, globalSearch, setGlobalSearch, setKitCodeFilter, setValidatedItems, setTotalCount, setFetchingValidatedItems)
    }, [globalSearch])

    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            fetchValidatedKits();
        }
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy]);

    function fetchValidatedKits() {
        setFetchingValidatedItems(true);
        closeExpandedRow();
        getKitsList('valid', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            sortOrder,
            sortBy
        }).then(res => {
            if (res?.data?.kits_info_list) {
                setTotalCount(res.data.count);
                setValidatedItems(res.data.kits_info_list);
            }
        }).catch(err => {
            setValidatedItems([]);
        }).finally(() => setFetchingValidatedItems(false));
    }

    function expandedRowRenderer(record) {
        return <ValidateKitFormContent key={record.kit_accession_id} record={record}
                                       closeClickHandler={closeExpandedRow}
                                       onValidationStatusChanged={handleValidationStatusChange}/>;
    }

    function closeExpandedRow() {
        setExpandedRowKey('');
    }

    function rowOnExpandHandler(expanded, {key}) {
        setExpandedRowKey(expanded ? key : '');
    }

    function onRowHandler({key}) {
        return (isRowExpanded(key, expandedRowKey) &&
            {className: "expanded-row"});
    }

    return (
        <>
            <LabPortalTopSectionContainer
                timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter} />

            <Table
                loading={fetchingValidatedItems}
                columns={validatedKitsColList}
                dataSource={validatedItems}
                pagination={pagination}
                className='lab-portal-table'
                onRow={onRowHandler}
                onChange={handleValidatedKitsTableChange}
                expandable={{
                    expandedRowKeys: [expandedRowKey],
                    expandRowByClick: true,
                    onExpand: rowOnExpandHandler,
                    expandedRowRender: expandedRowRenderer,
                    expandIcon: () => ''
                }}
            />

        </>
    );
}

export default ValidatedKits;