import {Outlet} from 'react-router-dom';
import AdminComponent from '@ph/admin/src/component';
import {Layout} from 'antd';
import PhysicianPortalSidebar from '@ph/admin/src/component/global/Sidebars/PhysicianPortalSidebar';
import './App.scss';
import React from "react";

const {Content} = Layout;

function PhysicianPortalLayout({hideSideBar}) {
    return (
        <>
            <AdminComponent.AdminHeader title="Physician's Portal"
                                        searchKit={false} scanKit={false} headerNav={false}/>
            <section className="section">
                <div className='container' style={{padding: 0}}>
                    <Layout>
                        {!hideSideBar && <PhysicianPortalSidebar/>}
                        <Layout>
                            <Content className='admin-portal-main-content-wrapper'>
                                <Outlet/>
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </section>
        </>
    );
}

export default PhysicianPortalLayout;