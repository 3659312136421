import React from 'react';
import {Button, Col, Row, Typography} from "antd";
import style from "./dashboard.module.scss";
import {useSelector} from "react-redux";
import Components from "@ph/common/components";
import {dashboard_nav1, dashboard_nav2, dashboard_nav3,} from "@ph/common/assets/index";
import { Link } from 'react-router-dom';
import { button_arrow } from '@ph/common/assets/index';

const { Text } = Typography;

const Dashboard = (props) => {
    const user = useSelector((state) => state.userData.userData);
    const { connectionInstance } = props;
 
    return (
        <div className={style.container}>
            <div
                className={`viewBoxWidth text-center ${style.dashboardContent}`}
            >
                <Text className={`${style.userTitle} h2`}>
                    Hi {user && user.first_name ? user.first_name : "User"},
                    Welcome to your dashboard
                </Text>
                <p></p>
            </div>

            <div
                className={`viewBoxWidth no-padding ${style.dashboardContent}`}
            >
                <div className={`${style.insideContainer}`}>
                    <Row gutter={[32, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Components.PhNavCard
                                title={<>Your Skin <br />Health Reports</>}
                                subTitle="View your skin microbiome test kit orders and results."
                                cardLink="health-report"
                            >
                                <img src={dashboard_nav1} className={style.marqueeImage} alt="PH" />
                            </Components.PhNavCard>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Components.PhNavCard
                                title={<>Your Precision <br /> Skincare</>}
                                subTitle="View your precision phage serums and other Microbiome Dermatology™ products."
                                cardLink="/dashboard"
                                disable={true}
                            >
                                <img src={dashboard_nav2} className={style.marqueeImage} alt="PH" />
                            </Components.PhNavCard>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Components.PhNavCard
                                title={<>Your <br /> Prescriptions</>}
                                subTitle="View your customized prescriptions."
                                cardLink="/dashboard"
                                disable={true}
                            >
                                <img src={dashboard_nav3} className={style.marqueeImage} alt="PH" />
                            </Components.PhNavCard>
                        </Col>
                    </Row>
                </div>
                 <div className={`viewBoxWidth no-padding ${style.dashboardContent}`}>
                    <div className={`${style.insideContainer}`}>
                        <Components.SuggestManyHealthKits connectionInstance={connectionInstance} />
                    </div>
                </div>
                <div className={`${style.insideContainer}`}>
                <Components.PhCard
                    headerClass="hide"
                    cardClass={style.kitUserGuideBox}
                    cardBodyClass={style.kitUseGuideSection}
                    isDarkBackGround={true}
                >
                    <Text className={style.text}>
                        Need help conducting your skin test?
                    </Text>
                    <br />
                    <Link to="/kit-user-guide">
                        <Button type="secondary" className={style.button}>
                                View Instructions
                                <img src={button_arrow} className='ph-btn-arrow' />
                        </Button>
                    </Link>
                </Components.PhCard>
                </div>
            </div>
        </div>
    );
};
export default Dashboard