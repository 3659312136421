import React, { useEffect } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthToken } from "../api_services/localstorage";
import { useDispatch } from 'react-redux';
import { adminUserDataActions } from "../store/adminUserData.reducer";
import { Space, Spin } from 'antd';

const SigninCallback = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const dispatch = useDispatch();

    const locationSearch = location.search;

    useEffect(() => {

        const init = async () => {
            try {
                const values = queryString.parse(locationSearch);
                if (values && values.code) {
                    const params = new URLSearchParams();
                    params.append('grant_type', 'authorization_code');
                    params.append('code', values.code);
                    params.append('client_id', process.env.REACT_APP_AWS_CLIENT_ID);
                    params.append('redirect_uri', process.env.REACT_APP_AWS_REDIRECT_URI);
                    params.append('client_secret', process.env.REACT_APP_AWS_CLIENT_SECRET);

                    const res = await axios.post(`${process.env.REACT_APP_AWS_DOMAIN}/oauth2/token`, params, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    });

                    if (res && res.data) {
                        const userData = jwt_decode(res.data.id_token);
                        AuthToken.setToken("ph_admin_access_token", res.data.access_token);
                        AuthToken.setToken("ph_admin_refresh_token", res.data.refresh_token);
                        AuthToken.setToken("ph_admin_token", res.data.id_token);

                        const userGroup = userData['cognito:groups'][0];
                        dispatch(adminUserDataActions.updateAdminUserData(userData));
                        //   if(userGroup === 'PowerUser') { }
                        //   else {
                        //     const data = await getRepSubsidiaries({rep: userGroup});
                        //     const masterQSEData = data;
                        //     /**
                        //      * Check if has subsidiary
                        //      *  => masterQSE
                        //      */
                        //     if(masterQSEData && masterQSEData.data) {
                        //       if(masterQSEData.data.subsidiaries.length > 0) {
                        //         userData.masterQSE = true
                        //         setToken('masterQSE', true);
                        //       }
                        //       else {
                        //         userData.masterQSE = false;
                        //         setToken('masterQSE', false);
                        //       }
                        //     }
                        //   }
                        //   userContext.setAuthenticated(true);
                        //   userContext.setUserRoles(userData['cognito:groups']);
                        //   userContext.setUser(userData);
                        navigate('/');
                    }
                }
            } catch (err) {
                console.log(err);
                // window.location = `${process.env.REACT_APP_AWS_DOMAIN}/login?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=${process.env.REACT_APP_AWS_RESPONSE_TYPE}&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_URI}`;
            }
        }

        init();
    }, []);

    return (
        <div style={{ marginTop: '150px', textAlign: 'center' }}>
            <Space size="middle">
                <Spin size="large" />
            </Space>
        </div>
    );
};

// SigninCallback.propTypes = {
//     history: PropTypes.objectOf(PropTypes.any).isRequired,
//     location: PropTypes.objectOf(PropTypes.any).isRequired,
// };

export default SigninCallback;