import React from 'react'
import style from './ReportContainer.module.scss'
import { Row, Col } from 'antd'
import { capitalizeFirstLetter } from '../reportUtils'




function TableContainer({ color, selectedItem, setSelectedItem, tableData, columnNames }) {
    const tableStyles = {
        '--base-color': color
    }

    const handleRowClick = (item) => {
        setSelectedItem(item)
    }

    const COLUMN_FORMAT = [
        {
            name: columnNames[0],
            desktopSpan: 8,
            mobileSpan: 24,
        },
        {
            name: columnNames[1],
            desktopSpan: 16,
            mobileSpan: 24,
        }
    ]
    return (
        <div className={style.tableContainer} style={tableStyles}>
            <Row className={style.tableHeader}>
                {COLUMN_FORMAT.map(column => (
                    <Col xs={column.mobileSpan} md={column.desktopSpan}>
                        <div className={style.text}>{column.name}</div>
                    </Col>
                ))}
            </Row>
            <div>
                {tableData.map(item => (
                    <Row key={item.id} className={`${style.dataRow} ${selectedItem?.name === item?.name ? style.selected : ''}`} onClick={() => handleRowClick(item)}>
                        <Col xs={24} md={8}>
                            <div className={style.dataTitle}>{item?.name}</div>
                        </Col>
                        <Col xs={24} md={16}>
                            <div className={style.dataDescription}>{capitalizeFirstLetter(item?.found_in?.join(', '))}</div>
                        </Col>
                    </Row>
                ))}

            </div>
        </div>
    )
}

export default TableContainer