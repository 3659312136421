import React, { useEffect, useState } from 'react';
import style from "./PHProtocol.module.scss";
import StepProducts from './StepProducts';
import { Button, Typography } from 'antd';
import { button_arrow, Back_Arrow_Border } from '@ph/common/assets/index';
import { useProtocolContext } from '@ph/subject/src/context/ProtocolContext';
import { PROTOCOL_IDS } from '@ph/subject/src/api_services/Protocol/protocolFetch';
import { PROTOCOL_SELLING_PLAN } from '@ph/subject/src/api_services/Protocol/protocolFetch';
const { Text } = Typography;


function ProtocolCart({ activeTab, stepData, setActiveTab }) {

    const { currStep, updateToPreviousStep, selectedResult } = useProtocolContext()
    const [allProducts, setAllProducts] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [skinTestVariant, setSkinTestVariant] = useState(false)
    const [multiSerumPack, setMultiSerumPack] = useState(false)
    const [variantIds, setVariantIds] = useState([])


    const getMDVariant = () => {
        if (skinTestVariant && !multiSerumPack) {
            return PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1
        } else if (skinTestVariant && multiSerumPack) {
            return PROTOCOL_IDS.SKIN_TEST_WITH_PACK_3
        }
        else if (!skinTestVariant && !multiSerumPack) {
            return PROTOCOL_IDS.BODY_BLEMISH_PACK_1
        }
        else if (!skinTestVariant && multiSerumPack) {
            return PROTOCOL_IDS.BODY_BLEMISH_PACK_3
        }
    }

    const getAllProductsAndSumPrices = (selectedResults) => {
        let allProducts = [];
        let variantIds = []
        let totalPrice = 0;

        // Iterate over each stepId
        for (const stepId in selectedResults) {
            if (selectedResults.hasOwnProperty(stepId)) {
                const step = selectedResults[stepId];

                // Iterate over each stepProductId
                for (const stepProductId in step) {
                    if (step.hasOwnProperty(stepProductId)) {
                        const productsArray = step[stepProductId];

                        // Add each product to the allProducts array and sum the prices
                        productsArray.forEach(product => {
                            allProducts.push(product);
                            if (product.product_id === 111) {
                                setSkinTestVariant(true)
                            } else if (product.product_id === 112) {
                                setSkinTestVariant(false)
                            }
                            if (product.product_id === 312) {
                                setMultiSerumPack(true)
                            } else if (product.product_id === 311) {
                                setMultiSerumPack(false)
                            }
                            if (product?.quantity > 0) {
                                totalPrice = totalPrice + (parseFloat(product.product_price) * product.quantity);
                            } else if (product?.product_price) {
                                totalPrice = totalPrice + (parseFloat(product.product_price) * 1);
                            }
                            if (product?.product_variant_id && product.quantity > 0) {
                                variantIds.push({
                                    variant_id: product.product_variant_id,
                                    quantity: product.quantity,
                                    selling_plan: product.selling_plan
                                })
                            }
                        });
                    }
                }
            }
        }

        return { allProducts, totalPrice, variantIds };
    };


    const getVariantUrl = () => {
        let url = ''
        if (variantIds && variantIds.length > 0) {
            variantIds.forEach(variant => {
                url += `items[][id]=${variant.variant_id}%26items[][quantity]=${variant.quantity}%26items[][selling_plan]=${variant.selling_plan}%26`
            })
        }
        return url
    }

    const generateShopifyCartLink = () => {
        // Base URL for adding items to the cart
        const domain = process.env.REACT_APP_SHOPIFY_URL
        const baseUrl = `${domain}/cart/clear?return_to=/cart/add?`;

        // Generate query parameters for each product
        // const queryParams = allProducts.map(product =>
        //     `items[][id]=${product.id}&items[][quantity]=${product.quantity}`
        // ).join('&');
        const MD03Variant = getMDVariant() 
        const variantUrl = getVariantUrl()
        // id = VARIANTID & quantity=1
        // items[][id]=44537131991274&items[][quantity]=2%26items[][selling_plan]=31371591914%26items[][id]=44537099747562%26items[][selling_plan]=31371493610

        const queryParams = variantUrl + `items[][id]=${MD03Variant}%26items[][quantity]=1%26items[][selling_plan]=${PROTOCOL_SELLING_PLAN.MD03_PROTOCOL_SELLING_PLAN}` 
        console.log(baseUrl + queryParams, "Complete URL")

        // Combine the base URL with the query parameters
        //window.open(baseUrl + queryParams, '_blank')
        window.open(baseUrl + queryParams, '_self')

    }

    useEffect(() => {
        const { allProducts, totalPrice, variantIds } = getAllProductsAndSumPrices(selectedResult)
        setAllProducts(allProducts)
        setTotalPrice(totalPrice)
        setVariantIds(variantIds)
    }, [selectedResult])

    const getQuantityText = (id, quantity) => {
        if ([111, 112].includes(id)) {
            return '2X/YEAR'
        }
        return quantity ?? '1'
    }

    const getVarinatTitle = (product) => {
        if (product?.product_variant_title) {
            return `(${product?.product_variant_title})`
        }
        return ''
    }


    return (
        <div className={`${style.tabContain} ${currStep === stepData.id ? style.active : ''}`}>
            <h2 className='text-uv-blue font-NeueHGDP-light'>Your MD-03</h2>
            <div className={style.cartContainer}>
                <div className={style.cartItem}>
                    <Text className={style.cartTitle}>
                        Protocol Summary
                    </Text>
                </div>
                {allProducts.map(item => (
                    <div className={style.cartItem} key={item.product_id}>
                        <Text className={style.itemTitle}>
                            {item.product_title} {getVarinatTitle(item)} {`(${getQuantityText(item.product_id, item?.quantity)})`}
                        </Text>
                        <Text className={style.itemPrice}>
                            {parseInt(item.product_price) === 0 ? item?.is_included ? "INCLUDED" : "FREE" : <><ProductDiscount productData={item} /> {`$${parseFloat(item.product_price) * (item?.quantity ?? 1)}/mo`}</>}
                        </Text>
                    </div>
                ))}
                <div className={style.subTotal}>
                    {/* <div className={style.subItem}>
                        <Text className={style.itemTitle}>
                            subTotal
                        </Text>
                        <Text className={style.itemPrice}>
                            ${totalPrice}/mo
                        </Text>
                    </div>
                    <div className={style.subItem}>
                        <Text className={style.itemTitle}>
                            MD-03 Discounts
                        </Text>
                        <Text className={style.itemPrice}>
                            -$0/mo
                        </Text>
                    </div> */}
                </div>
                <div className={style.total}>
                    <Text className={style.itemTitle}>
                        Total
                    </Text>
                    <Text className={style.itemPrice}>
                        ${totalPrice}/mo
                    </Text>
                </div>
            </div>

            <div className={style.cartButtonContainer}>
                <Button type="primary" className={style.addButton} onClick={generateShopifyCartLink}> Add Protocol to Cart <img src={button_arrow} className='ph-btn-arrow' /></Button>
            </div>
            <div className={style.buttonBox}>
                <div className={`${style.buttonContainer} ${style.showButton}`}>
                    <img src={Back_Arrow_Border} alt='back' className={style.backButton} onClick={updateToPreviousStep} />
                </div>
            </div>
        </div>
    )
}

export default ProtocolCart

const ProductDiscount = ({ productData }) => {
    if (productData?.product_price && productData?.product_discount_price) {
        return (
            <Text className={style.discountPrice}>
                {productData?.product_discount_price ? `$${parseInt(productData?.product_discount_price) * (productData?.quantity ?? 1)}` : ''}
            </Text>
        )
    }
    return <></>
}