import React, { useEffect, useState, useRef } from 'react';
import { Button, Table, Typography, Dropdown, Space, Input } from "antd";
import style from './ContolsAndKitsList.module.scss';
import { close_circle_black } from '@ph/common/assets/index';
import { DownOutlined } from '@ant-design/icons';
import { search_icon } from "@ph/common/assets";
import { BATCH_EDIT_ALLOWED, BATCH_STATUS } from '@ph/common/constants/BatchConstants';
import { getKitTypeSwabInfo } from '../../AdminPortalAPIService';
import { handleErrorMsg } from '@ph/common/global/Helpers';

const { Text } = Typography;

const DEFAULT_SWAB_SHEET = [
    {
        title: 'Kit Code',
        dataIndex: 'tableLabel',
        width: '25%',
    }
]

function ControlsAndKitsList({ Text, currBatchStatus, batchData, swabsData, handleRemoveKit, handleRemoveControl }) {
    const [isControlSheetActive, setIsControlSheetActive] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [kitTypeSwabInfo, setKitTypeSwabInfo] = useState({})
    const [swabSheetCols, setSwabSheetCols] = useState(DEFAULT_SWAB_SHEET)
    const [swabSheetData, setSwabSheetData] = useState([]);
    const [controlSheetData, setControlSheetData] = useState([])
    const [columns, setColumns] = useState([]);
    const [selectedKitType, setSelectedKitType] = useState('')
    const [allKitType, setAllKitType] = useState([])
    const [searchFilter, setSearchFilter] = useState('');
    const searchTimerRef = useRef(null);

    const searchInputChangedHandler = (event) => {

        const { value } = event.target;
        const trimmedVal = value.replaceAll(/[\s-_]/g, '');
        setSearchFilter(trimmedVal.toUpperCase());

        if (searchTimerRef.current) {
            clearTimeout(searchTimerRef.current);
        }

        if (trimmedVal.length >= 1 && trimmedVal.length < 7) {
            //Show Error
            return;
        }

        searchTimerRef.current = setTimeout(() => {
            searchFilterChangedEventHandler(trimmedVal);
        }, 200);
    };

    const searchFilterChangedEventHandler = (trimmedVal) => {
        const filteredData = swabsData.filter(item => item.tableLabel === trimmedVal)
        if (filteredData && filteredData.length > 0) {
            const currSelectedType = filteredData[0].kitTypeName
            setSelectedKitType(currSelectedType)
            setSwabSheetData(filteredData)
            setSwabSheetCols(generateSwabColSheet(filteredData))
        }
    }

    const controlsCols = [
        {
            title: 'Controls',
            dataIndex: 'tableLabel'
        },
        {
            title: 'Position',
            dataIndex: 'cellNo',
            render: (d, record) => {
                return (<div className='clearfix'>
                    <div className='fl'>{d}</div>
                    {BATCH_EDIT_ALLOWED[currBatchStatus] && (
                    <div className='fr'>
                        <img src={close_circle_black} alt='delete' onClick={() => handleRemoveControl(record)} />
                    </div>
                    )}
                </div>)
            }
        }
    ];

    const generateSwabColSheet = (swabsData) => {
        const excludeCol = ['Oil_Strip']
        const swabSheetCol = [
            {
                title: 'Kit Code',
                dataIndex: 'tableLabel',
                width: '25%',
            }
        ]

        for (const currData of swabsData) {
            for (const [key, value] of Object.entries(currData)) {
                if (key === 'tableLabel') {
                    const kitCode = currData[key][0]
                    if (kitTypeSwabInfo[kitCode]) {
                        for (const currSwab of kitTypeSwabInfo[kitCode]) {
                            if (!excludeCol.includes(currSwab.swab_area_code)) {
                                swabSheetCol.push({
                                    title: <div className={`${style.tableHeadCell} ${currSwab.swab_area_code}`} >{currSwab.swab_area_code}</div>,
                                    dataIndex: currSwab.swab_area_code,
                                    render: (d) => {
                                        return (
                                            <div className='text-center'>{d}</div>
                                        )
                                    }
                                })
                            }
                        }
                    }
                }
            }
            break;
        }

        swabSheetCol.push({
            title: '',
            dataIndex: 'tableLabel',
            width: 34,
            className: 'no-padding-column',
            render: (d, record) => {
                return (
                    <>
                        {
                            BATCH_EDIT_ALLOWED[currBatchStatus] && (
                                <img src={close_circle_black} alt='delete' onClick={() => handleRemoveKit(record)} />
                            )
                        }
                    </>

                )
            }
        })
        return swabSheetCol
    }

    useEffect(() => {
        if (isControlSheetActive) {
            setColumns(controlsCols)
            setDataSource(controlSheetData)
        } else {
            setColumns(swabSheetCols)
            setDataSource(swabSheetData)
        }
    }, [controlSheetData, swabSheetData])

    useEffect(() => {
        getKitTypeSwabInfo().then(res => {
            if (res && res.data) {
                setKitTypeSwabInfo(res.data.kit_type_swab_info)
            }
        }).catch(error => handleErrorMsg(error, "Uable to get kit swab types!"))
    }, [])

    const toggleControlsView = () => {
        if(isControlSheetActive) {
            setColumns(swabSheetCols);
            setDataSource(swabSheetData);
        } else {
            setColumns(controlsCols);
            setDataSource(controlSheetData);
        }
        setIsControlSheetActive(!isControlSheetActive);
    };

    useEffect(() => {
        if (batchData) {
            const controlData = []
            for (const [key, value] of Object.entries(batchData)) {
                if (value && value.isControl) {
                    controlData.push(value)
                }
            }
            const sortedData = controlData.sort((a, b) => a.tableLabel.localeCompare(b.tableLabel));
            setControlSheetData(sortedData)
        }
    }, [batchData])

    useEffect(() => {
        if (swabsData && swabsData.length > 0) {
            const currSelectedType = swabsData[0].kitTypeName
            const currSwabsData = swabsData.filter(item => item.kitTypeName === currSelectedType)
            setSelectedKitType(currSelectedType)
            setSwabSheetData(currSwabsData)
            setSwabSheetCols(generateSwabColSheet(currSwabsData))
            const availableKitTypes = []
            const addedKits = []
            swabsData.map((kit, index) => {
                if (!addedKits.includes(kit.kitTypeName)) {
                    addedKits.push(kit.kitTypeName)
                    availableKitTypes.push({ key: index, label: kit.kitTypeName })
                }
            })
            setAllKitType(availableKitTypes)
        } else {
            setSwabSheetData([])
        }
    }, [swabsData, kitTypeSwabInfo])

    const handleMenuClick = (e) => {
        const currSelectedType = allKitType.filter(kit => kit.key == parseInt(e.key))[0].label
        const currSwabsData = swabsData.filter(item => item.kitTypeName === currSelectedType)
        setSwabSheetCols(generateSwabColSheet(currSwabsData))
        setSelectedKitType(currSelectedType)
        setSwabSheetData(currSwabsData)
    }

    const menuProps = {
        items: allKitType,
        onClick: handleMenuClick,
    };

    return (
        <div>
            {swabsData && swabsData.length > 0 && !isControlSheetActive && (
                <Space size="small" direction="vertical" style={{ width: '100%' }}>
                    {currBatchStatus !== BATCH_STATUS.DRAFT && (
                        <Input
                            placeholder="Search Kit Code"
                            prefix={<img src={search_icon} alt='Search' />}
                            className={`main-search-input ${style.searchInput}`}
                            onChange={searchInputChangedHandler}
                            value={searchFilter}
                            status={(searchFilter && searchFilter.length < 7) ? 'error' : ''}
                        />
                    )}
                    <Dropdown menu={menuProps}>
                        <Button className={style.dropButton}>
                            <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                                <Text className="body-txt-lg">{selectedKitType}</Text>
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                </Space>
            )}
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{ y: 450 }}
                className='lab-portal-table control-table' />
            <br />
            <Text className='body-txt-lg td-underline hand-cursor'
                  onClick={toggleControlsView}>
                <b>{isControlSheetActive ? "SWITCH TO SWABS SHEET" : "SWITCH TO CONTROL SHEET"}</b>
            </Text>
        </div>
    );
}

export default ControlsAndKitsList;