import style from './MegaMenu.module.scss';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';


const MegaMenuCards = ({ menuConfig }) => {
    return (
        <div className={style.megaMenuCards}>
            <Row gutter={32}>
                {menuConfig.map(item => (
                    <Col xs={24} sm={8} key={item.id} >
                        <Link to={item.link}>
                            <div className={style.menuCardContainer}>
                                <div className={style.title}>{item.title}</div>
                                <img src={item.imgSrc} className={style.image} />
                                <div className={style.description}> {item.description} </div>
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
export default MegaMenuCards