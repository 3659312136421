import React, {useEffect, useState} from 'react'
import style from './Jotform.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {message} from "antd";
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";
import { HEALTH_PROFILE_STATUS } from '@ph/common/global/Constants';

const HealthProfileform = (props) => {
    const [fetchingHealthProfileFormLink, setFetchingHealthProfileFormLink] = useState(false);
    const [healthProfileFormLink, setHealthProfileFormLink] = useState('');
    const { connectionInstance } = props;
    const { kitId } = useParams();
    const customKitcode = kitId ? kitId : '';
    const navigate = useNavigate()

    const getHealthProfileForm = async (kitcode) => {
        try {  
            let res = await connectionInstance.post(
                `${process.env.REACT_APP_API_URL}/healthprofile/${kitcode}`,
                {}
            );
            setHealthProfileFormLink(res.data.jotform_url)
            
        } catch (ex) {
            message.error("Something went wrong!");
            window.location = `${process.env.REACT_APP_SHOPIFY_URL}`;
        }
    };

    const getHealthProfileFormLink = async () => {
        setFetchingHealthProfileFormLink(true);
        try {
            const res = await connectionInstance.get(
            `${process.env.REACT_APP_API_URL}/healthprofile/${customKitcode}`
            );
            if (res && res.data) {
                if(res.data.status === HEALTH_PROFILE_STATUS.COMPLETED || res.data.status === HEALTH_PROFILE_STATUS.NOT_REQUIRED){
                    navigate("/dashboard")
                }else if (res.data.status === HEALTH_PROFILE_STATUS.IN_PROGRESS) {
                    setHealthProfileFormLink(res.data.jotform_url)
                }else {
                    getHealthProfileForm(customKitcode)
                }
            }
        } catch (error) {
            message.error("Something went wrong!");
            window.location = `${process.env.REACT_APP_SHOPIFY_URL}`;
        } finally {
            setFetchingHealthProfileFormLink(false);
        }
    };

    useEffect(() => {
        getHealthProfileFormLink();
    }, []);

    return (
        <div className={`${style.jotformContainer} jot-form-full-height`}>
            {
                fetchingHealthProfileFormLink || !healthProfileFormLink ?
                <FullPageLoader/> :
                    <iframe title="Health Profile Form"
                            onLoad={window.parent.scrollTo(0,0)}
                            allowtransparency="true"
                            allowFullScreen={true}
                            allow="geolocation; microphone; camera"
                            src={healthProfileFormLink}
                            sandbox="allow-forms allow-popups allow-scripts allow-top-navigation allow-same-origin"/>


            }
        </div>
    )
}

export default HealthProfileform;