import React, { createContext, useContext, useReducer } from 'react';
import { REPORT_PLAN_MODAL_TYPES, REPORT_PLANS } from '../constants/Report/ReportConstants';

// Define the context
const CartContext = createContext();

// Initial state for the cart
const initialState = {
    products: [],
    planModal: false,
    planModalType: REPORT_PLAN_MODAL_TYPES.WELCOME,
    includedProducts: [],
    md03UpgradeProducts: [],
    md03Product: null,
    reportPlan: REPORT_PLANS.TESTER,
    total: 0,
};

// Utility function to calculate the total price
const calculateTotal = (products) =>
    products.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

// Actions
const actionTypes = {
    ADD_PRODUCT: 'ADD_PRODUCT',
    REMOVE_PRODUCT: 'REMOVE_PRODUCT',
    UPDATE_QUANTITY: 'UPDATE_QUANTITY',
    UPDATE_INCLUDED_PRODUCTS: 'UPDATE_INCLUDED_PRODUCTS',
    UPDATE_MD03_UPGRADE_PRODUCTS: 'UPDATE_MD03_UPGRADE_PRODUCTS',
    UPDATE_MD03_PRODUCT: 'UPDATE_MD03_PRODUCT',
    UPDATE_REPORT_PLAN: 'UPDATE_REPORT_PLAN',
    UPDATE_PLAN_MODAL: 'UPDATE_PLAN_MODAL',
    UPDATE_PLAN_MODAL_TYPE: 'UPDATE_PLAN_MODAL_TYPE',
};

// Reducer to handle actions
function cartReducer(state, action) {
    switch (action.type) {
        case actionTypes.ADD_PRODUCT:
            // Check if product already exists
            const existingIndex = state.products.findIndex(
                (product) => product.product_id === action.payload.id
            );

            // If it exists, update the quantity
            if (existingIndex > -1) {
                let updatedProducts = [...state.products];
                updatedProducts[existingIndex].quantity += action.payload.quantity;

                return {
                    ...state,
                    products: updatedProducts,
                    total: calculateTotal(updatedProducts),
                };
            }

            // If it doesn't exist, add the product
            return {
                ...state,
                products: [...state.products, action.payload],
                total: calculateTotal([...state.products, action.payload]),
            };
        case actionTypes.REMOVE_PRODUCT:
            const filteredProducts = state.products.filter(
                (product) => product.product_id !== action.payload.id
            );

            return {
                ...state,
                products: filteredProducts,
                total: calculateTotal(filteredProducts),
            };
        case actionTypes.UPDATE_QUANTITY:
            const updatedProducts = state.products.map((product) =>
                product.product_id === action.payload.id
                    ? { ...product, quantity: action.payload.quantity }
                    : product
            );

            return {
                ...state,
                products: updatedProducts,
                total: calculateTotal(updatedProducts),
            };
        case actionTypes.UPDATE_INCLUDED_PRODUCTS:
            const newIncludedProducts = action.payload
                .filter(product => product.is_included)
                .map(product => ({
                    ...product,
                    quantity: 1,
                    md_03_price: 0
                }));

            return {
                ...state,
                includedProducts: newIncludedProducts
            };

        case actionTypes.UPDATE_MD03_UPGRADE_PRODUCTS:
            return {
                ...state,
                md03UpgradeProducts: action.payload
            };

        case actionTypes.UPDATE_MD03_PRODUCT:
            return {
                ...state,
                md03Product: action.payload
            };

        case actionTypes.UPDATE_REPORT_PLAN:
            return {
                ...state,
                reportPlan: action.payload
            };

        case actionTypes.UPDATE_PLAN_MODAL:
            return {
                ...state,
                planModal: action.payload
            };

        case actionTypes.UPDATE_PLAN_MODAL_TYPE:
            return {
                ...state,
                planModalType: action.payload
            };
        default:
            return state;
    }
}

// Context Provider
export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    // Utility functions to dispatch actions
    const addProduct = (product) => {
        if (state.reportPlan === REPORT_PLANS.TESTER && !state.md03Product && isMD03SerumExist([product])) {
            // If user is tester and have not added any serum to cart
            updateMD03Product(product)
            updateReportPlan(REPORT_PLANS.MD03)
            dispatch({ type: actionTypes.ADD_PRODUCT, payload: product });
            updatePlanModalType(REPORT_PLAN_MODAL_TYPES.WELCOME)
            updatePlanModal(true)

        } else {
            dispatch({ type: actionTypes.ADD_PRODUCT, payload: product });
        }
    };

    const removeProduct = (id) => {
        if (id === state.md03Product?.product_id) {
            searchAndUpdateMD03Product(state.products, id)
        } else {
            dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id } });
        }
    };

    const updateQuantity = (id, quantity) => {
        if (quantity === 0 && id === state.md03Product?.product_id) {
            searchAndUpdateMD03Product(state.products, id)
        } else if (quantity === 0) {
            dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id } });
        } else {
            dispatch({ type: actionTypes.UPDATE_QUANTITY, payload: { id, quantity } });
        }
    };

    const updateReportPlan = (plan) => {
        dispatch({ type: actionTypes.UPDATE_REPORT_PLAN, payload: plan });
    };

    const updateMD03Product = (product) => {
        dispatch({ type: actionTypes.UPDATE_MD03_PRODUCT, payload: product });
    };

    const searchAndUpdateMD03Product = (inputProducts, id) => {
        const newFilterProducts = inputProducts.filter(product => product?.product_id !== id)
        if (isMD03SerumExist(newFilterProducts)) {
            const allProductIdsSet = new Set(state.md03UpgradeProducts.map(product => product.product_id));
            const newMD03product = newFilterProducts.filter(product => allProductIdsSet.has(product.product_id));
            updateMD03Product(newMD03product[0])
            dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id } });
        } else {
            raiseMD03DowngradeWarning()
        }
    }

    const raiseMD03DowngradeWarning = () => {
        updatePlanModalType(REPORT_PLAN_MODAL_TYPES.DOWNGRADE)
        updatePlanModal(true)
    }

    const downgradeMD03Plan = () => {
        updateMD03Product(null)
        updateReportPlan(REPORT_PLANS.TESTER)
        updatePlanModal(false)
        dispatch({ type: actionTypes.REMOVE_PRODUCT, payload: { id: state?.md03Product?.product_id } });
    }

    // Function to update Include Product list
    const updateIncludedProducts = (products) => {
        if (products && products.length > 0) {
            dispatch({ type: actionTypes.UPDATE_INCLUDED_PRODUCTS, payload: products })
        }
    }
    const updateMD03UpgradeProducts = (products) => {
        if (products && products.length > 0) {
            dispatch({ type: actionTypes.UPDATE_MD03_UPGRADE_PRODUCTS, payload: products })
        }
    }

    const isMD03SerumExist = (inputProducts) => {
        const allProductIdsSet = new Set(state.md03UpgradeProducts.map(product => product.product_id));
        return inputProducts.some(product => allProductIdsSet.has(product.product_id));
    };

    const updatePlanModal = (modalState) => {
        dispatch({ type: actionTypes.UPDATE_PLAN_MODAL, payload: modalState });
    };

    const updatePlanModalType = (modalState) => {
        dispatch({ type: actionTypes.UPDATE_PLAN_MODAL_TYPE, payload: modalState });
    };

    return (
        <CartContext.Provider
            value={{
                products: state.products,
                total: state.total,
                includedProducts: state.includedProducts,
                reportPlan: state.reportPlan,
                md03Product: state.md03Product,
                planModal: state.planModal,
                planModalType: state.planModalType,
                updatePlanModal,
                updatePlanModalType,
                addProduct,
                removeProduct,
                updateQuantity,
                updateIncludedProducts,
                updateMD03UpgradeProducts,
                updateReportPlan,
                downgradeMD03Plan
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use cart context
export const useCart = () => useContext(CartContext);
