import React, { useEffect, useState } from 'react'
import { Typography, Space, Row, Col, Button, Modal } from 'antd';
import { ArrowLeftOutlined } from "@ant-design/icons";
import style from "./batchDetails.module.scss";
import moment from "moment";
import { dateFormats } from "@ph/common/global/Constants";
import { addBatchHistory, cancelBatch, deleteBatchHistory, getBatchCSVData, getBatchDetails, updateBatchHistory } from '../AdminPortalAPIService';
import { BATCH_STATUS, BATCH_STATUS_LABELS, BATCH_INPROGRESS_LABELS, BATCH_EDIT_ALLOWED, BATCH_NEXT_STATUS } from "@ph/common/constants/BatchConstants";
import CreateBatch from '../CreateBatch/CreateBatch';
import ConfirmationModal from '../ValidateKitFormContent/ConfirmationModal';
import ChangeBatchTime from './ChangeBatchTime';
import { handleErrorMsg } from '@ph/common/global/Helpers';
import { useOutletContext } from "react-router-dom";

const { Text } = Typography

const INITIAL_BATCH = [
    {
        status: BATCH_STATUS.DNA_EXTRACTION,
        label: BATCH_STATUS_LABELS[BATCH_STATUS.DNA_EXTRACTION],
        isCompleted: false,
        isActive: false,
        showCSV: true,
        isCurrent: false,
        completeText: "Completed successfully on ",
    },
    {
        status: BATCH_STATUS.SAMPLE_SHIPMENT,
        label: BATCH_STATUS_LABELS[BATCH_STATUS.SAMPLE_SHIPMENT],
        isCompleted: false,
        isActive: false,
        showCSV: false,
        isCurrent: false,
        completeText: "Shipped successfully on ",
    },
    {
        status: BATCH_STATUS.SAMPLE_SEQUENCING,
        label: BATCH_STATUS_LABELS[BATCH_STATUS.SAMPLE_SEQUENCING],
        isCompleted: false,
        isActive: false,
        showCSV: false,
        isCurrent: false,
        completeText: "Sequenced successfully on ",
    },
    {
        status: BATCH_STATUS.SERUM_DETERMINATION,
        label: BATCH_STATUS_LABELS[BATCH_STATUS.SERUM_DETERMINATION],
        isCompleted: false,
        isActive: false,
        showCSV: false,
        isCurrent: false,
        completeText: "Completed successfully on "
    }
]

const CONFIRMATION_MSG = {
    CANCEL: <>
        Are you sure you want to <span className='medium-text'>CANCEL this Batch?</span>
        <br />
        Doing so will push it its kits to ‘Validated Kits’ tab.
    </>,
    UNDO: 'Proceed to delete batch history status?'
}

function BatchDetails({ batchData, setSeletecBatch, backLabel, reload, setReload }) {
    const [batchStatus, setBatchStatus] = useState([])
    const [batchDetails, setBatchDetails] = useState({})
    const [currBatchStatus, setCurrBatchStatus] = useState('')
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [openBatchTimeModal, setOpenBatchTimeModal] = useState(false)
    const [confirmationMsg, setConfirmationMsg] = useState('')
    const [cancelBtnText, setCancelBtnText] = useState('')
    const [cancelBtnAction, setCancelBtnAction] = useState(null)
    const [proceedBtnText, setProceedBtnText] = useState('')
    const [proceedBtnAction, setProceedBtnAction] = useState(null)
    const [selectedBatchHistory, setSelectedBatchHistory] = useState()
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount] = useOutletContext();


    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
        reloadBatchData(batchData.batch_code)
        setRefreshSideBarCount(!refreshSideBarCount)
    };

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false)
    }

    const closeBatchTimeModal = () => {
        setOpenBatchTimeModal(false)
    }

    const handleBatchTimeUpdateClick = (history) => {
        if (![BATCH_STATUS.SERUM_DETERMINATION, BATCH_STATUS.SAMPLE_SEQUENCING, BATCH_STATUS.SAMPLE_SHIPMENT].includes(currBatchStatus)) {
            setOpenBatchTimeModal(true)
            setSelectedBatchHistory(history)
            setCancelBtnText("NO, GO BACK")
            setProceedBtnText("SAVE CHANGES")
            setProceedBtnAction(() => {
                return (updatedDate, updatedTime, status) => {
                    const data = {
                        batch_history_status: status,
                        modified_batch_history_datetime: `${updatedDate} ${updatedTime}`
                    }
                    updateBatchHistory(batchData.batch_code, data)
                        .then(res => reloadBatchData(batchData.batch_code))
                        .catch(error => handleErrorMsg(error, "Updating failed"))
                        .finally(() => closeBatchTimeModal())
                }
            });
            setCancelBtnAction(() => {
                return () => {
                    closeBatchTimeModal();
                }
            });
        }
    }

    const handleBatchHistory = (batchData) => {
        let currStatus = ''
        setBatchDetails()
        if (batchData && batchData.batch_history_status) {
            currStatus = batchData.batch_history_status[0].status
            setCurrBatchStatus(currStatus)

            let newBatchStatus = []
            if (currStatus === BATCH_STATUS.DRAFT) {
                newBatchStatus = INITIAL_BATCH
            } else if (currStatus === BATCH_STATUS.CREATED) {
                for (const history of INITIAL_BATCH) {
                    newBatchStatus.push({
                        ...history,
                        isActive: history.status === BATCH_STATUS.DNA_EXTRACTION
                    })
                }
            } else {
                const lookUpBatchStatus = {}
                for (const history of batchData.batch_history_status) {
                    lookUpBatchStatus[history.status] = history
                }
                let makeNextActive = false
                for (const history of INITIAL_BATCH) {
                    const currKey = history.status
                    if (currKey in lookUpBatchStatus) {
                        const currMatch = lookUpBatchStatus[currKey].status === currStatus
                        newBatchStatus.push({
                            ...history,
                            ...lookUpBatchStatus[currKey],
                            isCompleted: true,
                            isActive: makeNextActive,
                            isCurrent: currMatch
                        })
                        if (currMatch) {
                            makeNextActive = true
                        }
                    } else {
                        newBatchStatus.push({
                            ...history,
                            isActive: makeNextActive,
                        })
                        makeNextActive = false
                    }
                }
            }
            setBatchStatus(newBatchStatus)
        }
    }

    useEffect(() => {
        if (batchData) {
            handleBatchHistory(batchData)
            setBatchDetails(batchData)
        }
    }, [batchData])

    const reloadBatchData = (batchCode) => {
        getBatchDetails(batchCode)
            .then(res => {
                if (res && res.data) {
                    handleBatchHistory(res.data.batch_details)
                    setBatchDetails(res.data.batch_details)
                }
            })
    }

    const handleMarkAsDone = () => {
        addBatchHistory(batchData.batch_code, { batch_history_status: BATCH_NEXT_STATUS[currBatchStatus] })
            .then(res => reloadBatchData(batchData.batch_code))
            .catch(error => console.error(error))
    }



    const handledownloadCsv = () => {
        getBatchCSVData(batchData.batch_code)
            .then(res => {
                const csvData = res.data
                const blob = new Blob([csvData], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${batchData.batch_code}.csv`;
                link.click();
            })
            .catch(error => console.error(error))
    };

    const cancelBatchHandler = (batchCode) => {
        cancelBatch(batchCode)
            .then(res => {
                setIsModalOpen(false)
                setSeletecBatch('')
                setReload(!reload)
                setRefreshSideBarCount(!refreshSideBarCount)
            })
    }

    const handleUndoHistory = (batchStatus) => {
        setOpenConfirmationModal(true)
        setConfirmationMsg(CONFIRMATION_MSG.UNDO)
        setCancelBtnText("YES, GO AHEAD")
        setProceedBtnText("NO, GO BACK")
        setProceedBtnAction(() => {
            return () => {
                closeConfirmationModal();
            }
        });
        setCancelBtnAction(() => {
            return () => {
                deleteBatchHistory(batchData.batch_code, batchStatus)
                    .then(res => reloadBatchData(batchData.batch_code))
                    .catch(error => console.error(error))
                    .finally(() => {
                        setReload(!reload)
                        closeConfirmationModal()
                    })
            }
        });
    }

    const handleCancelBatchModal = () => {
        setOpenConfirmationModal(true)
        setConfirmationMsg(CONFIRMATION_MSG.CANCEL)
        setCancelBtnText("YES, GO AHEAD")
        setProceedBtnText("NO, GO BACK")
        setProceedBtnAction(() => {
            return () => {
                closeConfirmationModal();
            }
        });
        setCancelBtnAction(() => {
            return () => {
                cancelBatchHandler(batchData.batch_code);
            }
        });
    }

    return (
        <>
        <div>
            <Space size="large" direction="vertical" style={{ width: '100%' }}>
                    <div onClick={() => {
                        setSeletecBatch('')
                        setReload(!reload)
                    }} className='hand-cursor'>
                    <Space>
                        <ArrowLeftOutlined className="text-dark" />
                        <Text className="body-txt-md td-underline medium-text">{backLabel}</Text>
                    </Space>
                </div>
                <Row type="flex" justify="space-between" align="middle">
                        <Text className="h2">BATCH {batchData?.batch_code} | Manage Batch States </Text>
                        <Text className="body-txt-md td-underline medium-text hand-cursor" onClick={showModal}>
                            {BATCH_EDIT_ALLOWED[currBatchStatus] ? "EDIT BATCH PLATING" : "VIEW BATCH PLATING"}
                        </Text>
                </Row>
                <Row type="flex" align="middle">
                        <BatchDetailCard colSpan="8" title={currBatchStatus === BATCH_STATUS.SERUM_DETERMINATION ? "CURRENT STATUS" : "IN PROCESS"} subTitle={BATCH_INPROGRESS_LABELS[currBatchStatus]} dark={true} />
                        <BatchDetailCard colSpan="3" title="KITS" subTitle={String(batchDetails?.kit_count).padStart(2, '0')} />
                        <BatchDetailCard colSpan="4" title="SAMPLES" subTitle={String(batchDetails?.sample_count).padStart(2, '0')} />
                        <BatchDetailCard colSpan="7" title="BATCH CREATED ON" subTitle={moment(batchDetails?.created_on).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS)} />
                </Row>
                <div>
                        <Text className="body-txt-lg">CREATED BY : {batchDetails?.created_by} </Text>
                </div>
                <div>
                    {batchStatus && batchStatus.map(history => {
                        return (
                            <div className={style.detailRow} key={history.status}>
                                <div className={style.rowContainer}>
                                    <div className={style.titleContainer}>
                                        <Text className="h4" style={{ minWidth: "350px" }}>{history.label}</Text>
                                        <div style={{ width: '50%', textAlign: "left" }}>
                                            {history.isCompleted && (
                                                <Text className="body-txt-lg"> {history.completeText}{" "}
                                                    <span className={`${[BATCH_STATUS.SERUM_DETERMINATION, BATCH_STATUS.SAMPLE_SEQUENCING, BATCH_STATUS.SAMPLE_SHIPMENT].includes(currBatchStatus) ? '' : 'td-underline hand-cursor'}`} onClick={() => handleBatchTimeUpdateClick(history)}>
                                                        {moment(history.created_on).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS)}
                                                    </span>
                                                </Text>
                                            )}
                                            {!history.isCompleted && history.isActive && [BATCH_STATUS.SERUM_DETERMINATION, BATCH_STATUS.SAMPLE_SEQUENCING].includes(history.status) && (
                                                <Text className="body-txt-lg medium-text text-italic"> Will be updated automatically </Text>
                                            )}
                                        </div>
                                    </div>

                                    {history.isCurrent && history.isCompleted && ![BATCH_STATUS.SERUM_DETERMINATION, BATCH_STATUS.SAMPLE_SHIPMENT, BATCH_STATUS.SAMPLE_SEQUENCING].includes(history.status) && (
                                        <Text className="body-txt-lg td-underline medium-text text-walnut text-center hand-cursor" style={{ minWidth: "150px" }} onClick={() => handleUndoHistory(history.status)}>Undo</Text>
                                    )}

                                    {history.isActive && ![BATCH_STATUS.SERUM_DETERMINATION, BATCH_STATUS.SAMPLE_SEQUENCING].includes(history.status) && (
                                        <Button type="primary" className="ph-btn" onClick={() => handleMarkAsDone()}>MARK AS DONE</Button>
                                    )}
                                    {history.showCSV && history.isCompleted && (
                                        <Button type="secondary" className="ph-btn" onClick={handledownloadCsv} >DOWNLOAD CSV</Button>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                    {currBatchStatus !== BATCH_STATUS.SERUM_DETERMINATION && (
                    <div>
                        <Text className="body-txt-lg td-underline hand-cursor" onClick={handleCancelBatchModal} >
                            CANCEL BATCH
                        </Text>
                    </div>
                    )}
            </Space >
        </div>

            <Modal open={isModalOpen}
                onCancel={handleCancel}
                width='95%'
                style={{ top: 5 }}
                footer={[]}
            >

                <CreateBatch createNew={false} batchId={batchData.batch_code} closeModal={handleCancel} currBatchStatus={currBatchStatus} cancelBatchHandler={cancelBatchHandler} />
            </Modal>
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                closeConfirmationModal={closeConfirmationModal}
                confirmationMsg={confirmationMsg}
                cancelBtnText={cancelBtnText}
                cancelBtnAction={cancelBtnAction}
                proceedBtnText={proceedBtnText}
                proceedBtnAction={proceedBtnAction}
                closable={true}
                modalTop={'30%'}
            />
            <ChangeBatchTime
                openConfirmationModal={openBatchTimeModal}
                closeConfirmationModal={closeBatchTimeModal}
                selectedBatchHistory={selectedBatchHistory}
                cancelBtnText={cancelBtnText}
                cancelBtnAction={cancelBtnAction}
                proceedBtnText={proceedBtnText}
                proceedBtnAction={proceedBtnAction}
                closable={true}
            />
        </>
    )
}

export default BatchDetails

const BatchDetailCard = ({ colSpan, title, subTitle, dark = false }) => {
    return (
        <Col span={colSpan}>
            <div className={`${style.detailCard} ${dark ? "bg-night" : 'bg-day'}`}>
                <Space size="middle" direction="vertical" className={style.cardContainer}>
                    <Text className={`${dark ? "text-light" : "text-dark"} body-txt-lg medium-text`}>{title}</Text>
                    <div className='text-center'>
                        <Text className={`${dark ? "text-light" : "text-dark"}  h4 text-center`}>{subTitle}</Text>
                    </div>
                </Space>
            </div>
        </Col>
    )
}
