import React from 'react';
import { Col, Collapse, Row, Typography } from "antd";
import style from "../ReportV1/reportv1.module.scss";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from '../reportUtils';

const { Panel } = Collapse;

const { Text } = Typography;


function RecommendationInfo({ RecommendationInfoList, expandIconPosition, color = '#EAFFA6' }) {

    const styles = {
        '--base-color': color
    }
    return (
        <div className='full-width' style={styles}>
            <div className={style.reconHeaderTitle}> INGREDIENTS</div>
            <Collapse bordered={false} defaultActiveKey={['0']}
                accordion={true} expandIconPosition={expandIconPosition}
                className={`${style.reconCollapseItems}`}
                expandIcon={({ isActive }) =>
                    isActive ?
                        <MinusOutlined style={{ fontSize: '18px', color: '#2D2926' }} /> :
                        <PlusOutlined style={{ fontSize: '18px', color: '##2D2926' }} />
                }>
                {RecommendationInfoList.map((v, i) => {
                    return v &&
                        <Panel key={i} style={{ border: 'none' }}
                            header={
                                <div className={`${style.reconHeaderContainer}`} >
                                    <Text className={style.heading}>
                                        {v?.name}
                                    </Text>
                                </div>
                            }>
                            <div className={style.description}>
                                <div className={style.text}>
                                    Found in Food: {capitalizeFirstLetter(v?.found_in?.join(', '))}
                                </div>
                            </div>

                            <div className={style.benefits}>BENEFITS</div>
                            <div className={style.benefitsContainer}>{capitalizeFirstLetter(v?.benefits)}</div>

                        </Panel>;
                })}
            </Collapse>
        </div>
    )
}

export default RecommendationInfo