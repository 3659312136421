import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography, Row, Form, DatePicker, TimePicker } from "antd";
import style from '../ValidateKitFormContent/ValidateKitFormContent.module.scss';
import moment from 'moment';
import { dateFormats } from "@ph/common/global/Constants";
import { updateBatchHistory } from '../AdminPortalAPIService';
const { Text } = Typography;

function ChangeBatchTime({
    openConfirmationModal,
    closeConfirmationModal,
    selectedBatchHistory,
    cancelBtnText,
    cancelBtnAction = null,
    proceedBtnText,
    proceedBtnAction,
    cancelBtnClass = 'transparent',
    proceedBtnClass = '',
    isConfirmationInProgress = false,
    closable = false
}) {
    const [currdate, setCurrDate] = useState('')
    const [currTime, setCurrTime] = useState('')
    const dateFormat = 'MM/DD/YYYY';
    const timeFormat = "HH:mm"

    useEffect(() => {
        if (selectedBatchHistory && selectedBatchHistory.created_on) {
            console.log(selectedBatchHistory.created_on)
            //const momentDate = moment(selectedBatchHistory.created_on, "ddd, DD MMM YYYY HH:mm:ss [GMT]");
            const momentDate = moment(selectedBatchHistory.created_on).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS)
            const formatedDate = moment(momentDate)
            const formatedTime = moment(momentDate)

            setCurrDate(formatedDate)
            setCurrTime(formatedTime)

        }
    }, [selectedBatchHistory, openConfirmationModal])

    const onDateChange = (date, dateString) => {
        setCurrDate(date);
    };

    const onTimeChange = (time) => {
        setCurrTime(time)
    }

    const handleTimeUpdate = () => {
        const updatedDate = moment(currdate.utc()).format(dateFormat)
        const updatedTime = moment(currTime.utc()).format(timeFormat)
        proceedBtnAction(updatedDate, updatedTime, selectedBatchHistory.status)
    }

    return (
        <Modal open={openConfirmationModal}
            onCancel={closeConfirmationModal}
            afterClose={closeConfirmationModal}
            closeIcon={''} closable={closable} maskClosable={false}
            footer={''}
            style={{ top: '30%' }}
        >
            <div className='text-center' style={{ padding: '24px' }}>
                <Text className="body-txt-lg">Change Date & Time of {selectedBatchHistory?.label}</Text>
            </div>
            <Form>
                <Row type="flex" justify="center" align="middle">
                    <Row type="flex" justify="space-between" align="middle" style={{ width: '80%', marginBottom: '16px' }} className={style.datepickerContainer}>

                        <Text className="body-txt-lg">Date of Completion</Text>
                        <div style={{ width: '50%' }}>
                            <DatePicker value={currdate}
                                onChange={onDateChange}
                                format={dateFormat} placeholder={dateFormat}
                            />
                        </div>
                    </Row>
                    <Row type="flex" justify="space-between" align="middle" style={{ width: '80%' }} className={style.datepickerContainer}>
                        <Text className="body-txt-lg">Time of Completion</Text>
                        <div style={{ width: '50%' }}>
                            <TimePicker value={currTime}
                                onChange={onTimeChange}
                                format="HH:mm"
                            />
                        </div>
                    </Row>
                </Row>
            </Form>

            <div className={style.confirmationActionBtnContainer}>
                <Button className={`ph-btn ${cancelBtnClass}`}
                    onClick={cancelBtnAction || closeConfirmationModal}
                    disabled={isConfirmationInProgress}>
                    <Text className={`text-dark`}>{cancelBtnText}</Text>
                </Button>
                <Button className={`ph-btn ${proceedBtnClass}`} type='primary' onClick={handleTimeUpdate}
                    loading={isConfirmationInProgress} disabled={isConfirmationInProgress}>
                    <Text className={`text-light`}>{proceedBtnText}</Text>
                </Button>
            </div>
        </Modal>
    );
}

export default ChangeBatchTime;