import React, { useEffect, useRef, useState } from 'react';
import style from './LabPortalTopSectionContainer.module.scss';
import {Col, Input, Row, Typography} from "antd";
import {search_icon} from "@ph/common/assets";
import TimeFilterButton from "@ph/admin/src/component/global/TimeRangeFilterButton/TimeFilterButton";

const { Text } = Typography;

function LabPortalTopSectionContainer({
                                          subTitle,
                                          topRightContent,
                                          bottomRightContent,
                                          searchFilterChangedEventHandler,
    timeFilterChangedEventHandler,
    searchPlaceholder = "Search Kit Code",
    kitCodeFilter = ''
                                      }) {

    const [searchFilter, setSearchFilter] = useState(kitCodeFilter);
    const searchTimerRef = useRef(null);
    const timeFilterChangedEvent = (startAndEndDate) => {
        if (typeof timeFilterChangedEventHandler === "function") {
            timeFilterChangedEventHandler(startAndEndDate);
        }
    };

    useEffect(() => {
        setSearchFilter(kitCodeFilter)
    }, [kitCodeFilter])

    const searchInputChangedHandler = (event) => {
        const {value} = event.target;
        const trimmedVal = value.replaceAll(/[\s-_]/g, '');
        setSearchFilter(trimmedVal);

        if (searchTimerRef.current) {
            clearTimeout(searchTimerRef.current);
        }

        if (trimmedVal.length >= 1 && trimmedVal.length < 7) {
            //Show Error
            return;
        }

        searchTimerRef.current = setTimeout(() => {
            searchFilterChangedEventHandler(trimmedVal);
        }, 500);
    };

    return (
        <div className={style.topSectionWrapper}>
            <Row gutter={[0, 20]}>
                <Col span={12}>
                    <Text className={`${style.title} ${subTitle && style.subTitleBorder} h2`}>
                        {localStorage.getItem('currentNavLabel')}</Text>
                    {subTitle &&
                        <Text className={`${style.subTitle} h4`}>{subTitle}</Text>}
                </Col>
                <Col span={12} className='text-right'>
                    {topRightContent}
                </Col>
                <Col span={12}>
                    <Input
                        placeholder={searchPlaceholder}
                        prefix={<img src={search_icon} alt='Search'/>}
                        className={`main-search-input ${style.searchInput}`}
                        onChange={searchInputChangedHandler}
                        value={searchFilter}
                        status={(searchFilter && searchFilter.length < 7) ? 'error' : ''}
                    />
                </Col>
                <Col span={12} className='text-right'>
                    {bottomRightContent}
                    <>
                        <TimeFilterButton filterFor_='labAdminFilter'
                                          triggerTimeFilterChangeEvent={timeFilterChangedEvent}/>
                    </>
                </Col>
            </Row>
        </div>
    );
}

export default LabPortalTopSectionContainer;