import React from 'react';
import {Col, Divider, Row, Typography} from "antd";
import style from "./Report.module.scss";
import { PH_waves_report, PH_waves_report_mobile } from "@ph/common/assets";
import moment from "moment/moment";
import {getFriendlyKitCode} from "@ph/common/global/Helpers";
import { KIT_REPORT_TITLES,KIT_REPORTS_CONTENT } from '@ph/common/constants/KitConstants';

const { Text } = Typography;

function ReportTopSection({reportData, isETypeKit,kitType }) {

  let kitTypeName = reportData.kit_type_name ?
    (reportData.kit_type_name.includes('Skin Microbiome Discovery Kit') ?
      'Skin Microbiome Discovery Kit' : reportData.kit_type_name) : '';

    return (
        <>
            <Row className={`${style.topSection} ${style.box}`} align="stretch">
                <Col xs={24} sm={24} md={24} xl={14} className={style.topCol1}>
                    <Row align="stretch" style={{ height: '100%' }}>
                        <Col className={`${style.leftAlignmentMobile} ${style.textColHeight}`}
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}
                             xs={{span: 24, order: 1}} sm={{span: 24, order: 1}} md={{order: 1, span: 24}}
                            lg={{ order: 1, span: 12 }} xl={{ order: 1, span: 9 }}>
                          <Text className={`${style.boldSmallTitleText} text-dark`} > {KIT_REPORT_TITLES[kitType]} </Text>
                        </Col>
                        <Col className={`${style.leftAlignmentMobile} ${style.textColHeight}`}
                             style={{
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'flex-end',
                                 textAlign: 'end'
                             }}
                             xs={{span: 24, order: 3}} sm={{span: 24, order: 3}} md={{order: 3, span: 24}}
                            lg={{ order: 2, span: 12 }} xl={{ order: 1, span: 15 }}>
                          <Text className={style.kitTypeName}>{kitTypeName}</Text>
                        </Col>
                        <Col className={style.waveColHeight}
                             xs={{span: 24, order: 2}} sm={{span: 24, order: 2}} md={{order: 2, span: 24}}
                             lg={{order: 3, span: 24}} xl={{order: 3, span: 24}}>
                            <img src={PH_waves_report} className={`${style.wave} ${style.desktopImg}`} id='reportWave' alt='' />
                            <img src={PH_waves_report_mobile} className={`${style.wave} ${style.mobileImg}`} id='reportWave' alt='' />

                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} xl={10} className={style.topCol2}>
                    <div className={style.userDetailsTable}>
                        <div className={style.itemGap}>
                            <Text className={style.smallText}>FULL NAME:</Text>
                            <div style={{ marginLeft: '10px' }}><Text className={style.bodyText1}>{reportData.first_name && reportData.first_name + " "}{reportData.last_name && reportData.last_name}
                            </Text></div>
                        </div>
                        <Divider type="horizontal" className={style.horizontalseparator} />
                        <div className={style.userProfile}>
                            <div className={style.profileContent}>
                                <div className={style.itemGap}>
                                    <Text className={style.smallText}>KITCODE:</Text>
                                    <div style={{ marginLeft: '10px' }}>
                                        <Text className={style.bodyText1}>
                                            {getFriendlyKitCode(reportData.kitcode)}
                                        </Text>
                                    </div>
                                </div>
                                <Divider type="horizontal" className={style.horizontalseparator} />
                                <div className={style.itemGap}>
                                    <Text className={style.smallText}>SAMPLE DATE:</Text>
                                    <div style={{ marginLeft: '10px' }}><Text className={style.bodyText1}>{reportData.sample_date ? moment(reportData.sample_date).format('MM.DD.YYYY') : '-'}</Text>
                                    </div>
                                </div>
                                <Divider type="horizontal" className={style.horizontalseparator} />
                                <div className={style.itemGap}>
                                    <Text className={style.smallText}>SEASON:</Text>
                                    <div style={{ marginLeft: '10px' }}><Text className={style.bodyText1}>{reportData.season ? reportData.season : '-'}</Text>
                                    </div>
                                </div>
                            </div>
                            <div className={style.profileImage}>
                                {reportData.profile_image_url ?
                                    <svg className={style.profilePicContainer} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <clipPath id="image">
                                            <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#2E5ACC" />
                                        </clipPath>
                                        <image clipPath="url(#image)" height="100%" width="100%" href={reportData.profile_image_url} preserveAspectRatio="xMidYMin slice"></image>
                                    </svg>
                                    :
                                    <svg className={style.profilePicContainer} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#2E5ACC" />
                                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#FBEEE4" className={style.profilePicText}>
                                            {reportData.first_name ? reportData.first_name.charAt(0) : (reportData.last_name && reportData.last_name.charAt(0))}
                                        </text>
                                    </svg>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className={style.box + " " + style.userGreetingSection}>
                <Text className={style.userText} style={{ fontFamily: 'Light' }}>Hi {reportData.first_name ? reportData.first_name : (reportData.last_name && reportData.last_name)},</Text>
              <Text className={style.h2Text}>We are happy to present you your <span className='medium-text'>{KIT_REPORT_TITLES[kitType]}</span></Text>
                <Divider type="horizontal" className={style.horizontalseparator} style={{ marginTop: '5px' }} />
            </div>

            <div className={style.box}>
                <Row align="stretch" gutter={[40, 20]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{textAlign: "justify"}}>
                        <Text className={`${style.bodyText1} ${style.highlight}`}>
                          {KIT_REPORTS_CONTENT[kitType].introBlockLeft_1}
                        </Text>
                      {isETypeKit ?
                        '' :
                        <>
                          <br/><br/>
                          <Text className={`${style.bodyText1} ${style.highlight}`}>
                            {KIT_REPORTS_CONTENT[kitType].introBlockLeft_2}
                          </Text>
                        </>
                      }
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{textAlign: "justify"}}>
                      <Text className={`${style.bodyText1} ${style.highlight}`}>{KIT_REPORTS_CONTENT[kitType].introBlockRight_1}</Text>
                      {isETypeKit ?
                        '' :
                        <>
                          <br/><br/>
                          <Text className={style.bodyText1}>{KIT_REPORTS_CONTENT[kitType].introBlockRight_2}</Text>
                        </>
                      }
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ReportTopSection;