import React from 'react';
import {Button, Modal, Typography} from "antd";
import style from './ValidateKitFormContent.module.scss';

const {Text} = Typography;

function ConfirmationModal({
                               openConfirmationModal,
                               closeConfirmationModal,
                               confirmationMsg,
                               cancelBtnText,
                               cancelBtnAction = null,
                               proceedBtnText,
                               proceedBtnAction,
                               cancelBtnClass = 'transparent',
                               proceedBtnClass = '',
    isConfirmationInProgress = false,
    closable = false,
    modalTop = "10%"
                           }) {
    return (
        <Modal open={openConfirmationModal}
               onCancel={closeConfirmationModal}
               afterClose={closeConfirmationModal}
            closeIcon={''} closable={closable} maskClosable={false}
            footer={''}
            style={{ top: modalTop }}
        >
            <div className='text-center' style={{ paddingTop: '26px' }}>
                <Text className='body-txt-lg'>
                    {confirmationMsg}
                </Text>
            </div>

            <div className={style.confirmationActionBtnContainer}>
                <Button className={`ph-btn ${cancelBtnClass}`}
                        onClick={cancelBtnAction || closeConfirmationModal}
                        disabled={isConfirmationInProgress}>
                    <Text className={`text-dark`}>{cancelBtnText}</Text>
                </Button>
                <Button className={`ph-btn ${proceedBtnClass}`} type='primary' onClick={proceedBtnAction}
                        loading={isConfirmationInProgress} disabled={isConfirmationInProgress}>
                    <Text className={`text-light`}>{proceedBtnText}</Text>
                </Button>
            </div>
        </Modal>
    );
}

export default ConfirmationModal;