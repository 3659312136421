import style from "../Report.module.scss";
import {close_icon, complete_report_screenshot, sample_report} from "@ph/common/assets";
import {sandBorderBottom} from "@ph/common/global/Constants";
import RecommendedItemsColList from "../CollapsibleSectionComponents/RecommendedItemsColList";
import {Button, Col, Image, Modal, Row, Typography} from "antd";
import React, {useState} from "react";
import { button_arrow_black } from "@ph/common/assets/index";

const {Text} = Typography;

const SkinDiscoveryReportDetailsCont = () => {
  const [showSampleReport, setShowSampleReport] = useState(false);

  const sampleReportDetails = [{
    title: 'Skin Microbiome Constellation & Type',
    description: 'Your skin microbiome type is determined by a variety of factors, including your hydration level, skin microbial diversity, and microbial ecosystem makeup. It can alter due to lifestyle and environmental factors.'
  }, {
      title: 'Skin Hydration Level',
      description: 'Your hydration levels are a reflection of the moisture and oil we detect from your oil strips. An ideal level is in the mid to upper range (7-8). Your cheek and forehead can sometimes have drastically different levels.'
    }, {
      title: 'Skin Microbiome Age',
      description: 'Comparing your skin against a large, diverse pool in our database, your listed skin age is a reflection of a variety of factors, including your microbial diversity, hydration level, and balance of “good” vs “bad” bacteria.'
    },
    {
      title: 'Top 5 Good and Bad Skin Microbes',
      description: 'We present information about your most abundant good and bad skin microbes.'
    },
    , {
      title: 'Microbiome Diversity Score',
      description: 'Your microbiome diversity score is a measurement of the balance and variety of bacteria on your skin.'
    },
    {
      title: 'Skincare Ingredients Recommendations',
      description: 'Based on your skin type, we recommend skin care ingredients to use and to avoid.'
    }, {
      title: 'Food Recommendations',
      description: 'Based on your skin type, we recommend food ingredients to ingest and to avoid.'
    }, {
    title: 'Your Phage Serum  Recommendations',
    description: 'Based on your skin microbiome type and microbial ecosytem, we are able to recommend primary and secondary Custom Active Phage Serums that are uniquely suited to help you live in your best skin.'
    },];

  const viewSampleReportOnClickHandler = () => {
    setShowSampleReport(true);
  };

  return (
    <>
      <Row gutter={[20]}>
        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
          <Row gutter={[30]} className={`${style.recommendationRow} ${style.sampleReportDetailsRow}`}
               align="stretch">
            {sampleReportDetails.map((reportDetail, index) => {
              let key = `${reportDetail.title}_${index}`;
              let inlineStyle = sandBorderBottom;
              let iconComp = '';
              let itemName = reportDetail.title;
              let itemDesc = reportDetail.description;
              let itemColClass = 'text-light';
              let itemNameClass = 'text-light';
              if (index <= 1) {
                itemColClass = `${itemColClass} ${style.firstRowItems}`;
              }
              if (index > 6) {
                itemColClass = `${itemColClass} ${style.lastItem}`;
              }
              return <RecommendedItemsColList
                {...{ Text, key, itemColClass, inlineStyle, iconComp, itemName, itemDesc, itemNameClass }} />;
            })}

            {/* <Col xs={24} sm={24} md={24} xl={12} className={`${style.lastItem} show-only-on-desktop`}>
              <div className={style.recommendationCols}
                   style={{alignItems: 'flex-end'}}>
                <div className={style.content}>
                  <span>
                    <Text className={style.bodyText1}><strong>&nbsp;</strong></Text>
                  </span>
                  <Button type="secondary" className={`${style.viewSampleReportBtn} show-only-on-desktop`}
                    onClick={viewSampleReportOnClickHandler}>
                    View Sample Report
                    <img src={button_arrow_black} className='ph-btn-arrow' />
                  </Button>
                </div>
              </div>
            </Col> */}
          </Row>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={9}>
          <div className={style.sampleReportPreviewContainer}>
            <img
              className={`${style.completeReportScreenshot} hand-cursor`}
              alt="Complete Sample Report"
              src={complete_report_screenshot}
            />
            {/* <Button type="secondary" className={`${style.viewSampleReportBtn} hide-on-desktop`}
              onClick={viewSampleReportOnClickHandler}>
              View Sample Report
              <img src={button_arrow_black} className='ph-btn-arrow' />
            </Button> */}
          </div>
        </Col>
      </Row>

      <Modal
        title=""
        centered
        open={showSampleReport}
        onOk={() => setShowSampleReport(false)}
        onCancel={() => setShowSampleReport(false)}
        closable={true}
        closeIcon={<img src={close_icon} alt='x'/>}
        footer={null}
        className={`${style.sampleReportModal} transparent-modal`}
      >
        <div className='text-center'>
          <Image width={'100%'} src={sample_report} alt='Sample Report' preview={false}
                 placeholder={
                   <Image preview={false} src={complete_report_screenshot} width={'100%'}
                          className={style.blurredPreview}/>
                 }/>
          {/*<img src={sample_report} alt='Sample Report' width='100%' height='auto'/>*/}
        </div>
      </Modal>
    </>
  );
};

export default SkinDiscoveryReportDetailsCont;
