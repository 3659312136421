import React, {useEffect, useState} from 'react';
import {getKitsList} from "../AdminPortalAPIService";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import {message, Table} from "antd";
import { CheckColum, updateGlobalSearchStates, paginationDef } from "../LabPortalConstants";
import {sortOrderMap} from "@ph/common/global/Constants";
import {useOutletContext} from "react-router-dom";
import { getFriendlyKitCode } from '@ph/common/global/Helpers';
import moment from "moment/moment";
import { dateFormats } from "@ph/common/global/Constants";

function RegisteredKits({ id }) {
    const [refreshList, setRefreshList, refreshSideBarCount, setRefreshSideBarCount, globalSearch, setGlobalSearch] = useOutletContext();
    const [fetchingRegisteredItems, setFetchingRegisteredItems] = useState(false);
    const [registeredItems, setRegisteredItems] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortBy, setSortBy] = useState('registration_date');

    const {pageSize} = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => {
                setCurrentPage(page)
            }
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setStartAndEndDate(startAndEndDate);
        setCurrentPage(1);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setKitCodeFilter(kitCodeFilter);
        setCurrentPage(1);
    };

    const handleRegisteredKitsTableChange = (pagination, filters, sorter) => {
        const { field, order } = sorter;
        if (field && order && ((field !== sortBy) || (sortOrderMap[order || ''] !== sortOrder))) {
            setSortBy(field);
            if (order === 'descend') {
                setSortOrder('desc');
            } else if (order === 'ascend') {
                setSortOrder('asc');
            }
            setCurrentPage(1);
        }
    };

    let registerKitsColList = [
        {
            title: 'Kit Code',
            dataIndex: 'kitcode',
            key: 'kitcode',
            render: (kitCode) => getFriendlyKitCode(kitCode)
        },
        {
            title: 'Kit Type',
            dataIndex: 'kit_type',
            key: 'kit_type',
        },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        },
        {
            title: 'Health Intake Form',
            dataIndex: 'healthprofile_id',
            key: 'healthprofile_id',
            render: (d) => <CheckColum valid={!!d} />
        }
    ];

    useEffect(() => {
        updateGlobalSearchStates(id, globalSearch, setGlobalSearch, setKitCodeFilter, setRegisteredItems, setTotalCount, setFetchingRegisteredItems)
    }, [globalSearch])


    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            fetchRegisteredKits();
        }
    }, [currentPage, kitCodeFilter, startAndEndDate, sortOrder, sortBy]);

    useEffect(() => {
        if (!globalSearch.GSKitCode) {
            fetchRegisteredKits();
        }
        if (typeof setRefreshSideBarCount === 'function') {
            setRefreshSideBarCount(!refreshSideBarCount);
        }
        localStorage.setItem('currentNavLabel', "Registered Kits");
    }, [refreshList]);

    function fetchRegisteredKits() {
        setTotalCount(0);
        setRegisteredItems([]);
        setFetchingRegisteredItems(true);
        getKitsList('registered', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
            sortOrder,
            sortBy
        }).then(res => {
            if (res?.data?.kits_info_list) {
                setTotalCount(res.data.count);
                setRegisteredItems(res.data.kits_info_list);
            }
        }).catch(error => {
            message.error(error.response.data.message);
        }).finally(() => setFetchingRegisteredItems(false));
    }

    return (
        <>
            <LabPortalTopSectionContainer timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange} kitCodeFilter={kitCodeFilter} />
            <Table
                loading={fetchingRegisteredItems}
                columns={registerKitsColList}
                dataSource={registeredItems}
                pagination={pagination}
                className='lab-portal-table'
                onChange={handleRegisteredKitsTableChange}
            />
        </>
    );
}

export default RegisteredKits;