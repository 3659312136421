import React from 'react';
import {Button, Col, Row, Typography, Skeleton} from "antd";
import style from './PhMediaCard.module.scss';

const { Text } = Typography;

function PhMediaCard({
                         imgSrc,
                         cardTitle,
                         cardDescription,
                         buttonText= '',
                         rowProps= {},
                         hideButton= false,
                         imgLoading= false,
                         imgColSize= {xs: 24, sm: 24, md: 24, xl: 10},
                         imgColClassName= '',
                         tagPill = {className: 'hide', text: ''},
                         textColSize= {xs: 24, sm: 24, md: 24, xl: 14},
                         textColClassName= '',
                         cardTitleClassName = '',
                         btnClassName = '',
                         buttonCallToAction = null,
                         navigateTo = {path: '', openInNewTab: true}
}) {
    const buttonClickHandler = () => {
        if(buttonCallToAction && typeof buttonCallToAction === 'function') {
            buttonCallToAction();
            return ;
        }
        if(navigateTo && navigateTo.path) {
            window.open(navigateTo.path,
                (navigateTo.openInNewTab ? '_blank' : '_self'),
                'noreferrer');
        }
    };

    return (
        <Row {...rowProps} className={style.card}>
            <Col xs={imgColSize.xs} sm={imgColSize.sm}
                 md={imgColSize.md} xl={imgColSize.xl}
                 className={`img-container-square ${style.cardImgHolder} ${imgColClassName}`}>
                {(imgLoading) ?
                    <Skeleton.Image active={true} /> :
                    <>
                        <img src={imgSrc} alt='' />
                        <div className={`${style.tagPill} ${tagPill.className}`}>
                            {tagPill.text || ''}
                        </div>
                    </>
                }
            </Col>
            <Col xs={textColSize.xs} sm={textColSize.sm}
                 md={textColSize.md} xl={textColSize.xl}
                 className={`${style.cardTextBody} ${textColClassName}`}>
                <Text className={`${style.cardTitle} ${cardTitleClassName}`}>
                    {cardTitle}
                </Text>
                <div className={style.cardDescriptionWrapper}>
                    <div className={style.cardDescription}
                       style={(hideButton) ? {marginBottom: 0} : {}}>
                        <Text className="">{cardDescription}</Text>
                    </div>
                    {
                        !hideButton &&
                        <Button type="primary"
                                className={`${style.cardActionBtn} ${btnClassName}`}
                                onClick={buttonClickHandler}>{buttonText}</Button>
                    }
                </div>
            </Col>
        </Row>
    );
}

export default PhMediaCard;