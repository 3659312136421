import React, {useEffect, useState} from 'react';
import {getBatchList} from "../AdminPortalAPIService";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import {paginationDef} from "../LabPortalConstants";
import { Table, Typography } from "antd";
import moment from "moment/moment";
import {dateFormats} from "@ph/common/global/Constants";
import BatchDetails from '../BactchDetails/BatchDetails';

const { Text } = Typography;

function CompletedBatches(props) {
    const [fetchingCompletedBatches, setFetchingCompletedBatches] = useState(true);
    const [completedBatches, setCompletedBatches] = useState([]);
    const [selectedBatch, setSeletecBatch] = useState('')
    const [reload, setReload] = useState(false);

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);

    const { pageSize } = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const completedBatchColList = [
        {
            title: 'Batch Code',
            dataIndex: 'batch_code',
            key: 'batch_code'
        }, {
            title: 'Kit / Samples',
            key: 'kit_count',
            render: ({kit_count, sample_count}) => {
                return `${kit_count} / ${sample_count}`
            }
        }, {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by'
        }, {
            title: 'Last Activity',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (d) => {
                return (<div className='clearfix'>
                    <div className='fl'>{moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS)}</div>
                    <div className='fr'>
                        <Text className='body-txt-lg clickable-link visible-on-hover'>
                            View Details
                        </Text>
                    </div>
                </div>
                )
            }
        }];

    useEffect(() => {
        getBatchList('complete',
            {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
                kitCodeFilter,
                startAndEndDate,
            }
        ).then(res => {
            if (res && res.data && res.data.batch_list) {
                setCompletedBatches(res.data.batch_list);
                setTotalCount(res.data.count)
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => setFetchingCompletedBatches(false));
    }, [reload, currentPage, kitCodeFilter, startAndEndDate]);

    return (
        selectedBatch ?
            <BatchDetails batchData={selectedBatch} setSeletecBatch={setSeletecBatch} backLabel="BACK TO COMPLETED BATCHES" reload={reload} setReload={setReload} />
            :
        <>
                <LabPortalTopSectionContainer
                    timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                    searchFilterChangedEventHandler={handleSearchFilterChange}
                    searchPlaceholder={"Search Batch Code"}
                />

            <Table
                loading={fetchingCompletedBatches}
                columns={completedBatchColList}
                dataSource={completedBatches}
                    pagination={pagination}
                className='lab-portal-table'
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => setSeletecBatch(record)
                        }
                    }}
            />
        </>
    );
}

export default CompletedBatches;