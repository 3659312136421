import React, { useEffect } from 'react'
import { Typography, Grid } from 'antd';
import style from './PHFaceRangeSlider.module.scss';
import { generateGradientColors } from '../../Report/reportUtils';
import { FACE_AREA } from '@ph/common/constants/Report/ReportConstants';

const { Text } = Typography;
const { useBreakpoint } = Grid;

const DOM_IDS = {
    FOREHEAD: 'forehead',
    CHEEK: 'cheek',
    CHIN: 'chin',
    TRACK: 'track',
    THUMB: 'thumbContainer'
}
const SLIDER_DEFAULT_LABEL_RANGE = ['LOW', 'AVERAGE', 'HIGH']

const PHFaceRangeSlider = (props) => {
    const { rangeMin, rangeMax, cheekValue, foreheadValue, chinValue, color = "#2E5ACC", endColor = "#C46A5A", domId, backgroundSrc = "", mobileBackgroundSrc = '', customLabel = "", mobileScale = 1.5, sliderLabelRange = SLIDER_DEFAULT_LABEL_RANGE, threshold = false, smallContainer = false, gradientColor = "#ABBDEA" } = props;

    const screens = useBreakpoint();
    const isMobile = screens.xs

    useEffect(() => {
        setTimeout(() => {
            showVal(cheekValue, foreheadValue);
        }, 1000);
        // }
    }, []);

    const sliderStyles = {
        '--background': backgroundSrc,
        '--mobile-background': backgroundSrc,
        '--base-color': color,
        '--mobile-scale': mobileScale
    };

    const sliderColors = generateGradientColors(color, endColor, 24)

    const showVal = (cheekVal, foreheadVal) => {
        var cheek = document.getElementById(`${domId}${DOM_IDS.CHEEK}`);
        var forehead = document.getElementById(`${domId}${DOM_IDS.FOREHEAD}`);
        var chin = document.getElementById(`${domId}${DOM_IDS.CHIN}`)
        var track = document.getElementById(`${domId}${DOM_IDS.TRACK}`);
        var thumbContainer = document.getElementById(`${domId}${DOM_IDS.THUMB}`);
        var trackWidth = track.getBoundingClientRect().width;
        thumbContainer.setAttribute('style', 'width: ' + (Number(trackWidth)) + 'px');
        var thumbWidth = cheek.getBoundingClientRect().width;
        const oneCellWidth = trackWidth / 20

        // total cells 20
        const cellPerDigit = 20 / Number(Number(rangeMax) + Math.abs(Number(rangeMin)))
        const oneCellWidthPerDigit = oneCellWidth * cellPerDigit

        const initialWidth = Math.abs(Number(rangeMin)) * oneCellWidthPerDigit;

        if (cheekVal) {
            cheek.setAttribute('style', 'left: ' + `${(cheekVal * oneCellWidthPerDigit) + initialWidth}px`);
        } else {
            cheek.setAttribute('style', 'display: none');
        }

        if (foreheadVal) {
            forehead.setAttribute('style', 'left: ' + `calc(${(foreheadVal * oneCellWidthPerDigit) + initialWidth}px);`);
        } else {
            forehead.setAttribute('style', 'left: 0');
        }
        if (chinValue) {
            chin.setAttribute('style', 'left: ' + `calc(${(chinValue * oneCellWidthPerDigit) + initialWidth}px);`);
        } else {
            chin.setAttribute('style', 'display: none');
        }
    };

    const updateZIndex = (inputElement, zIndex) => {
        if (inputElement) {
            inputElement.style.zIndex = zIndex;
        }
    }

    const handleThumbClick = (area) => {
        var cheekThumb = document.getElementById(`${domId}${DOM_IDS.CHEEK}`);
        var foreheadThumb = document.getElementById(`${domId}${DOM_IDS.FOREHEAD}`);
        var chinThumb = document.getElementById(`${domId}${DOM_IDS.CHIN}`);
        switch (area) {
            case FACE_AREA.CHEEK:
                updateZIndex(cheekThumb, '300');
                updateZIndex(foreheadThumb, '200');
                updateZIndex(chinThumb, '100')
                break;
            case FACE_AREA.T_ZONE:
                updateZIndex(cheekThumb, '200');
                updateZIndex(foreheadThumb, '300');
                updateZIndex(chinThumb, '100')
                break;

            case FACE_AREA.CHIN:
                updateZIndex(cheekThumb, '100');
                updateZIndex(foreheadThumb, '200');
                updateZIndex(chinThumb, '300')
                break;

        }
    };

    return (
        <div className={`${style.slideContainer} ${smallContainer ? style.smallHeight : ''}`} style={sliderStyles}>
            {!(cheekValue || foreheadValue) ?
                <div className={style.NAContainer}>
                    <svg className={style.hexagon} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#FFFFFF" stroke="#767472" strokeWidth="3" />
                        <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#767472" className={style.NAtext}>
                            NA
                        </text>
                    </svg>
                    <div>
                        <div className={style.smallText} style={{ textAlign: 'left' }}>
                            Sample Not Received/Damaged/Result Not Generated
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className={style.textContainer}>
                        <Text className={`${style.smallText} ${style.left}`}>{sliderLabelRange[0]}</Text>
                        {/* <Text className={style.smallText} style={{ position: 'absolute', width: 'fit-content', left: '50%', transform: 'translate(-50%)' }}>Moderate</Text> */}
                        <Text className={`${style.smallText} ${style.middle}`}>{sliderLabelRange[1]}</Text>

                        <Text className={`${style.smallText} ${style.right}`}>{sliderLabelRange[2]}</Text>
                    </div>
                    <div className={style.inputContainer}>
                        <div className={style.thumbContainer} id={`${domId}${DOM_IDS.THUMB}`}>

                            <div className={style.thumbForehead} id={(`${domId}${DOM_IDS.FOREHEAD}`)} onClick={() => handleThumbClick(FACE_AREA.T_ZONE)}>
                                <SliderHex className={style.first} label={customLabel ? customLabel : "FOREHEAD"} value={foreheadValue} color={color} domId={domId} gradientColor={gradientColor} />
                            </div>
                            <div className={style.thumbCheek} id={(`${domId}${DOM_IDS.CHEEK}`)} onClick={() => handleThumbClick(FACE_AREA.CHEEK)}>
                                <SliderHex className={style.second} label={"CHEEKS"} value={cheekValue} color={color} domId={domId} gradientColor={gradientColor} />
                            </div>

                            <div className={style.thumbCheek} id={(`${domId}${DOM_IDS.CHIN}`)} onClick={() => handleThumbClick(FACE_AREA.CHIN)}>
                                <SliderHex className={style.third} label={"CHIN"} value={chinValue} color={color} domId={domId} gradientColor={gradientColor} />
                            </div>


                        </div>
                        {/* <div className={style.trackContainer}>
                            <div className={style.track} id="track" style={{ backgroundImage: `url(${isMobile ? mobileBackgroundSrc : backgroundSrc})` }}></div>
                        </div> */}
                        <div className={style.trackContainer}>
                            <div className={style.track} id={`${domId}${DOM_IDS.TRACK}`} >
                                {Array.from({ length: 20 }, (_, i) => i + 2).map(item => (
                                    <div style={{ minWidth: '5%', display: 'inline-block', height: '100%' }}>
                                        <div style={{ background: 'transparent', minWidth: `${100 - (item * 4.5)}%`, height: '100%', display: 'inline-block' }}></div>
                                        <div style={{ background: `${sliderColors[item - 1]}`, minWidth: `${item * 4.5}%`, height: '100%', display: 'inline-block' }}></div>
                                    </div>
                                ))}
                            </div>
                            {threshold && (
                                <div className={style.threshold}>
                                    <div className={style.thresholdText}>Threshold</div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}


export default PHFaceRangeSlider;

const SliderHex = ({ className, label, value, color, domId, gradientColor }) => {
    return (
        <div className={`${style.verticalArrow} ${className}`} >
            <div className={style.arrow}></div>
            <div className={style.arrowDot}></div>
            <svg width="110" height="110" viewBox="0 0 150 130" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.hex}>
                <path d="M108.471 129.348C110.968 129.348 113.275 128.018 114.527 125.859L147.964 68.1849C149.223 66.0135 149.223 63.3343 147.964 61.163L114.527 3.48904C113.275 1.32941 110.968 0 108.471 0H41.5369C39.0408 0 36.7336 1.32914 35.4814 3.48843L2.03636 61.1623C0.776993 63.334 0.776993 66.0138 2.03636 68.1855L35.4814 125.859C36.7336 128.019 39.0408 129.348 41.5369 129.348H108.471Z" fill={`url(#${domId}paint0_linear_5299_649)`} />
                {value ?
                    <>
                        <text x="50%" y="20%" dominantBaseline="middle" textAnchor="middle" fill="#FFFFFF" className='note-1 font-Mono-medium'>
                            {label}
                        </text>
                        <text x="50%" y="63%" dominantBaseline="middle" textAnchor="middle" fill="#FFFFFF" className={style.valueText}>
                            {value}
                        </text>
                    </>
                    :
                    <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle" fill="#FFFFFF" className={style.NAtext}>
                        NA
                    </text>}
                <defs>
                    <linearGradient id={`${domId}paint0_linear_5299_649`} x1="30" y1="142" x2="92.5159" y2="42.3236" gradientUnits="userSpaceOnUse">
                        <stop stop-color={gradientColor} />
                        <stop offset="1" stop-color={color} />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}