import Components from "@ph/common/components";
import connectionInstance from "@ph/admin/src/api_services/connection";

const AdminReport = () => {
    return (
        <div>
            <Components.Report
                connectionInstance={connectionInstance}
                isAdmin={true}
                primarySerumDesc={process.env.REACT_APP_PRIMARY_SERUM_DESC}
                secondarySerumDesc={process.env.REACT_APP_SECONDARY_SERUM_DESC} />
        </div>
    )
};

export default AdminReport;