import { KIT_TYPES } from "./KitConstants";
import {
    PH_armpit,
    PH_biohazard_pouch,
    PH_cheek,
    PH_chin,
    PH_control,
    PH_cruelty_free,
    PH_fragrance_free,
    PH_gluten_free,
    PH_mail,
    PH_oil_strip,
    PH_oil_strips,
    PH_sterile,
    PH_sulphate_free,
    PH_T_zone,
    list_icon
} from "@ph/common/assets";

const KIT_CONTENT_A = [
    '4 individually-wrapped swabs',
    '4 laboratory test tubes',
    '2 oil test strips',
    '2 plastic bags',
    '1 set of gloves',
    '1 black return envelope'
]
   
    
const KIT_CONTENT_B = [
    '2 individually-wrapped swabs',
    '2 laboratory test tubes',
    '1 set of gloves',
    '1 plastic bag',
    '1 black return envelope'
]
   
    
const KIT_INSTRUCTION_TILES_A = [
    {
        question: 'HOW MUCH TIME NEEDED FOR THE TEST?',
        title: '5 MINUTES',
        subtitle: 'to complete the test'
    },
    {
        question: 'WHEN TO TAKE THE TEST?',
        title: 'MORNING',
        subtitle: 'before washing your face'
    },
    {
        question: 'IDEAL DAYS OF TAKING THE TEST?',
        title: 'WEEKDAYS',
        subtitle: 'when you can mail your samples back to us on the same day you take them'
    },
    {
        question: 'IF MAILING THE SAMPLES ON THE SAME DAY NOT POSSIBLE THEN?',
        title: 'REFRIGERATE',
        subtitle: 'your tubes until you can mail out your package'
    }
]

const KIT_INSTRUCTION_TILES_B = [
    {
        question: 'HOW MUCH TIME NEEDED FOR THE TEST?',
        title: '5 MINUTES',
        subtitle: 'to complete the test'
    },
    {
        question: 'WHEN TO TAKE THE TEST?',
        title: 'MORNING',
        subtitle: 'before cleansing or using any products'
    },
    {
        question: 'IDEAL DAYS OF TAKING THE TEST?',
        title: 'WEEKDAYS',
        subtitle: 'when you can mail your samples back to us on the same day you take them'
    },
    {
        question: 'IF MAILING THE SAMPLES ON THE SAME DAY NOT POSSIBLE THEN?',
        title: 'REFRIGERATE',
        subtitle: 'your tubes until you can mail out your package'
    }
]




const KIT_USE_STEPS_A = [
    {
        stepNo: 1,
        emptyStep: 0,
        emptystepMobile: 0,
        imgSrc: PH_T_zone,
        title: 'T-ZONE',
        subtitle: <>Open one (1) swab. Swab from the left of your FOREHEAD to the right side and then back. Repeat for 15 seconds rotating the swab periodically.
                    <br /><br />
            Take the same swab and swab the left side of your NOSE. Firmly move the swab in that same area for 15 seconds, rotating the swab periodically. Make sure to cover the skin crease where your nose meets your cheeks. Repeat on the right side of your NOSE.
                    <br /><br />
            Open the accompanying tube labeled "T-ZONE," place the swab inside, and break off the top half where there is a natural breakpoint; close the container. Note: the swab will be easier to break off if you place your fingers just above the breakpoint.
                </>
    },
    {
        stepNo: 2,
        emptyStep: 6,
        emptystepMobile: 11,
        imgSrc: PH_cheek,
        title: 'CHEEKS',
        subtitle: <>
            Open another swab. Take the swab and swab the left CHEEK. Firmly move the swab in that same area for 15 seconds, rotating the swab periodically. Repeat on the right CHEEK.
            <br /><br />
            As above, place the swab inside the tube labeled "CHEEK," break off at breakpoint, and close the container.
        </>
    },
    {
        stepNo: 3,
        emptyStep: 6,
        emptystepMobile: 7,
        imgSrc: PH_chin,
        title:' CHIN',
        subtitle: <>
            Open another swab. Swab the left side of your CHIN area and firmly move the swab to the other side and then back. Repeat for 15 seconds, again rotating the swab periodically. With this same swab, feel free to swab your JAWLINE as well, especially if you tend to have breakouts in that area.
            <br /><br />
            As above, place the swab inside the tube labeled "CHIN," break off at breakpoint, and close the container. 
        </>
    },
    
    {
        stepNo: 4,
        emptyStep: 6,
        emptystepMobile: 8,
        imgSrc: PH_control,
        title:'CONTROL',
        subtitle: <>
            Open the last remaining swab. Take the swab stick and circle it in the air for 15 seconds. This swab allows us to understand what microbes might be in the air around you while you are sampling. It’s really important not to let the swab touch anything besides the air.
            <br /><br />
            As before, place the swab inside the CONTROL tube, break off at breakpoint, and close the container.
        </>
    },
    {
        stepNo: 5,
        emptyStep: 6,
        emptystepMobile: 8,
        imgSrc: PH_oil_strip,
        title:'MEASURE YOUR OIL LEVELS',
        subtitle: <>
            Pick up the FOREHEAD strip by holding the black end. There is a clear foil sticker on the square. DO NOT REMOVE FOIL. Apply the gray square directly onto your forehead just above your nose(avoid eyebrows) for 10 seconds. Set the strip aside.
            <br /><br />
            Pick up the CHEEK strip by holding the black end. Again, DO NOT REMOVE FOIL. As above, apply the gray square to your right cheek and hold it there for 10 seconds.
            <br /><br />
            Place both strips in the remaining small plastic bag.
        </>
    },
    {
        stepNo: 6,
        emptyStep: 6,
        emptystepMobile: 10,
        imgSrc: PH_mail,
        title: 'SHIP SWABS AND STRIPS BACK TO PARALLEL',
        subtitle: <>
            Place both plastic bags (one with your samples and tubes; one with your oil strips) inside the black self - addressed padded envelope.
            <br /><br />
            All orders within the US come with a pre-paid shipping label. Drop off at any USPS. For orders outside the US, swabs must be sent back to Parallel using the postal carrier of your choice. Ship to:
            <br /><br />
            Parallel Health @Biolabs / Lundquist Institute<br />
            1124 Carson St.MRL Building, Floor 3<br />
            Torrance, CA 90502<br />
            USA
        </>
    }
         
    
]

 
const KIT_USE_STEPS_B= [
    {
        stepNo: 1,
        emptyStep: 0,
        emptystepMobile: 0,
        imgSrc: PH_armpit,
        title:'SKIN PATCH',
        subtitle: <>
            Open one (1) swab. Swab the blemished skin area of your choice. Swab for 15 seconds rotating the swab periodically.
            <br /><br />
            Open the accompanying tube labeled "SKIN PATCH", place the swab inside, and break off the top half where there is a natural breakpoint; close container.
        </>
    },
    {
        stepNo: 2,
        emptyStep: 6,
        emptystepMobile: 7,
        imgSrc: PH_control,
        title:'CONTROL',
        subtitle: <>
            Open the remaining swab. Take the swab stick and circle it in the air for 15 seconds. This swab allows us to understand what microbes might be in the air around you while you are sampling. It’s really important not to let the swab touch anything besides the air.
            <br /><br />
            As before, place the swab inside the CONTROL tube, break off at breakpoint, and close the container.
            <br/><br />
            Place the two (2) tubes with swabs back inside the small plastic bag.
        </>
    },
    {
        stepNo: 3,
        emptyStep: 7,
        emptystepMobile: 9,
        imgSrc: PH_mail,
        title: 'SHIP SWABS AND STRIPS BACK TO PARALLEL',
        subtitle: <>
            Place both plastic bags (one with your samples and tubes; one with your oil strips) inside the black self - addressed padded envelope.
            <br /><br />
            All orders within the US come with a pre-paid shipping label. Drop off at any USPS. For orders outside the US, swabs must be sent back to Parallel using the postal carrier of your choice. Ship to:
            <br /><br />
            Parallel Health @Biolabs / Lundquist Institute<br />
            1124 Carson St.MRL Building, Floor 3<br />
            Torrance, CA 90502<br />
            USA
        </>
    }
]

const KIT_USE_STEPS_C= [
    {
        stepNo: 1,
        emptyStep: 0,
        emptystepMobile: 0,
        imgSrc: PH_armpit,
        title:'ARMPITS',
        subtitle: <>
            Open one (1) swab. Swab your right armpit for 15 seconds rotating the swab periodically; repeat on the left side for 15 seconds.
            <br /><br />
            Open the accompanying tube labeled "ARMPITS", place the swab inside, and break off the top half where there is a natural breakpoint; close container.
        </>
    },
    {
        stepNo: 2,
        emptyStep: 6,
        emptystepMobile: 8,
        imgSrc: PH_control,
        title:'CONTROL',
        subtitle: <>
            Open the remaining swab. Take the swab stick and circle it in the air for 15 seconds. This swab allows us to understand what microbes might be in the air around you while you are sampling. It’s really important not to let the swab touch anything besides the air.
            <br /><br />
            As before, place the swab inside the CONTROL tube, break off at breakpoint, and close the container.
            <br/><br />
            Place the two (2) tubes with swabs back inside the small plastic bag.
        </>
    },
    {
        stepNo: 3,
        emptyStep: 7,
        emptystepMobile: 9,
        imgSrc: PH_mail,
        title: 'SHIP SWABS AND STRIPS BACK TO PARALLEL',
        subtitle: <>
            Place both plastic bags (one with your samples and tubes; one with your oil strips) inside the black self - addressed padded envelope.
            <br /><br />
            All orders within the US come with a pre-paid shipping label. Drop off at any USPS. For orders outside the US, swabs must be sent back to Parallel using the postal carrier of your choice. Ship to:
            <br /><br />
            Parallel Health @Biolabs / Lundquist Institute<br />
            1124 Carson St.MRL Building, Floor 3<br />
            Torrance, CA 90502<br />
            USA
        </>
    }
]

export const KIT_INSTRUCTIONS = {
    [KIT_TYPES.TYPE_X]: {
        KIT_TITLE: "Skin Microbiome Discovery Kit Instructions",
        TILES : KIT_INSTRUCTION_TILES_A,
        CONTENT: KIT_CONTENT_A,
        KIT_STEPS: KIT_USE_STEPS_A
    },
    [KIT_TYPES.TYPE_A]: {
        KIT_TITLE: "Skin Microbiome Discovery Kit Instructions",
        TILES : KIT_INSTRUCTION_TILES_A,
        CONTENT: KIT_CONTENT_A,
        KIT_STEPS: KIT_USE_STEPS_A
    },
    [KIT_TYPES.TYPE_B]: {
        KIT_TITLE: "Body Blemish Test Kit Instructions",
        TILES : KIT_INSTRUCTION_TILES_B,
        CONTENT: KIT_CONTENT_B,
        KIT_STEPS: KIT_USE_STEPS_B
    },
    [KIT_TYPES.TYPE_C]: {
        KIT_TITLE: "Body Odor Test Kit Instructions",
        TILES : KIT_INSTRUCTION_TILES_B,
        CONTENT: KIT_CONTENT_B,
        KIT_STEPS: KIT_USE_STEPS_C
    }
}

export const KIT_INSTRUCTION_TAB_TITLES = {
    [KIT_TYPES.TYPE_A]: "FACIAL DISCOVERY",
    [KIT_TYPES.TYPE_B]: "BODY BLEMISH TEST",
    [KIT_TYPES.TYPE_C]: "BODY ODOR TEST",
}

export const KIT_RETEST_TAB_TITLES = {
    [KIT_TYPES.TYPE_A]: "Skin Discovery Test",
    [KIT_TYPES.TYPE_X]: "Skin Discovery Test",
    [KIT_TYPES.TYPE_E]: "Skin Discovery Test",
    [KIT_TYPES.TYPE_B]: "Body Blemish Test",
    [KIT_TYPES.TYPE_C]: "Odor Discovery Test",
}