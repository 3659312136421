import React from 'react';
import Slider from "../NoUISlider/Slider";
import {Typography} from "antd";
import style from "./SkinMicrobiomeSlider.module.scss"
import wNumb from "wnumb";

const { Text } = Typography;

function SkinMicrobiomeSlider({sliderLength, min, sliderVal}) {
    let valuesForSlider = Array.from({ length: sliderLength }, (_, i) => i+1);

    return (
        <>
            <div className={style.textContainer}>
                <Text className={style.smallText} style={{textAlign: "left"}}>Low</Text>
                <Text className={style.smallText} style={{textAlign: "right"}}>High</Text>
            </div>
            <div className={style.skinMicrobiomeSliderContainer}>
                <Slider
                    id='skinMicrobiomeSlider'
                    range={{min, max: valuesForSlider.length}}
                    start={sliderVal}
                    tooltips={wNumb({decimals: 1})}
                    disabled={true} />
            </div>
        </>
    );
}

export default SkinMicrobiomeSlider;