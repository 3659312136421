import React, {useState} from 'react';
import style from '../Report.module.scss';
import {close_icon} from "@ph/common/assets";
import constellationTypeImages from "@ph/common/assets/images/constellation-type";
import {Modal, Typography} from "antd";
import { close_icon_dark } from '@ph/common/assets/index';

const { Text } = Typography;

function ReportConstellationType({constellationType, skin_type_name}) {
    const [showFullSizeImage, setShowFullSizeImage] = useState(false);

    return (
        <>
            <div className={style.showInBigScreen}>
                <Text className="h4">
                    Your Skin Microbiome Constellation <strong>TYPE</strong>
                </Text>
                <img src={constellationTypeImages[`h_constellation_type_${constellationType}`]}
                     alt={skin_type_name} width='100%' />
            </div>
            <div className={`${style.showInSmallScreen} ${style.constellationTypeContainer}`}>
                <div className={style.constellationTitleContainer}>
                    <Text className="h4">
                        Your Skin Microbiome
                        <br/><strong>CONSTELLATION TYPE</strong>
                    </Text>
                </div>
                <img src={constellationTypeImages[`h_mini_constellation_type_${constellationType}`]}
                     alt={skin_type_name} width='100%' />
                <div className={style.viewAllActionContainer}>
                    <Text className={style.viewAllAction}
                          onClick={() => {
                              setShowFullSizeImage(true);
                              console.log("Open Vertical constellation: " + constellationType);
                          }}>VIEW ALL CONSTELLATIONS</Text>
                </div>
            </div>
            {/* closeIcon */}
            <Modal className={style.constellationModal}
                   open={showFullSizeImage}
                   closable={true}
                closeIcon={<img src={close_icon_dark} alt='x' />}
                   footer={null} onCancel={() => setShowFullSizeImage(false)}>
                <img src={constellationTypeImages[`v_constellation_type_${constellationType}`]}
                     alt={skin_type_name} width='100%' height='100%' />
            </Modal>
        </>
    );
}

export default ReportConstellationType;