import React, {useEffect, useState} from 'react';
import {message, Spin } from "antd";
import style from "../Dashboard/dashboard.module.scss";
import moment from "moment";
import Components from "@ph/common/components";
import { dateFormats } from "@ph/common/global/Constants";
import { KIT_RETEST_TAB_TITLES } from '@ph/common/constants/InstructionConstants';
import SuggestMD03 from './SuggestMD03';

const SuggestHealthKit = (props) => {
    const prodDetailsObj = {
        isLoading: true,
        data: {},
    };
    const [productDetailsLoading, setProductDetailsLoading] = useState(true);
    const [subScribedProdDetails, setSubScribedProdDetails] = useState({});
    const [skinMicrobiomeBundle, setSkinMicrobiomeBundle] = useState({});
    const [skinBundleDetails,setSkinBundleDetails] = useState({});
    const [skinCareRecommendationDetails, setSkinCareRecommendationDetails] =
        useState({
            personalized: {
                ...structuredClone(prodDetailsObj),
                prodId: "8013343293674",
            },
            concierge: {
                ...structuredClone(prodDetailsObj),
                prodId: "8013343064298",
            },
        });
    const { connectionInstance,reTestStatus } = props;
    let fields = "id,image,title,handle";

    const toggleProductDetailsLoader = (
        isLoading,
        productTypeKey,
        setterMethod
    ) => {
        if (productTypeKey && setterMethod) {
            setterMethod((prevState) => {
                prevState[productTypeKey].isLoading = isLoading;
                return {
                    ...prevState,
                };
            });
            return;
        }
        setProductDetailsLoading((prevState) => {
            prevState = isLoading;
            return prevState;
        });
    };

    const setProductDetails = (
        productDetails,
        setterMethod,
        productTypeKey
    ) => {
        if (productTypeKey && setterMethod) {
            setterMethod((prevState) => {
                prevState[productTypeKey].isLoading = false;
                prevState[productTypeKey].data = productDetails;
                return {
                    ...prevState,
                };
            });
            return;
        }
        setterMethod(productDetails);
    };

    const initFetchProductDetails = async (product_id, fields) => {
        return await connectionInstance.get(`/shopify/product_info`, {
            params: { product_id, fields },
        });
    };

    const getProductDetails = (
        kit_product_id,
        fields,
        setterMethod,
        productTypeKey,
        chainedCall
    ) => {
        toggleProductDetailsLoader(true, productTypeKey, setterMethod);
        initFetchProductDetails(kit_product_id, fields)
            .then(
                fetchProdDetailsSuccessHandler.bind(
                    this,
                    setterMethod,
                    productTypeKey
                )
            )
            .catch((error) => {
                message.error("Something went wrong!");
            })
            .finally(
                fetchProdDetailsFinalHandler.bind(
                    this,
                    setterMethod,
                    productTypeKey,
                    chainedCall
                )
            );
    };

    function fetchProdDetailsFinalHandler(
        setterMethod,
        productTypeKey,
        chainedCall
    ) {
        toggleProductDetailsLoader(false, productTypeKey, setterMethod);
        if (chainedCall && typeof chainedCall === "function") {
            chainedCall();
        }
    }

    function fetchProdDetailsSuccessHandler(setterMethod, productTypeKey, res) {
        if (res && res.data) {
            setProductDetails(res.data.product, setterMethod, productTypeKey);
        }
    }

    function userTestStatusResponseHandler(data) {
        const md03ProtocolId = process.env.REACT_APP_SHOPIFY_MD03_PROTOCOL
        let prodId = data.kit_product_id;
        if (!prodId) {
            return;
        }
        let setterMethod = () => {};
        if (data.status === "SUBSCRIBED") {
            setterMethod = setSubScribedProdDetails;
        } else if (
            data.status === "REQUIRED" ||
            data.status === "NOT_REQUIRED" ||
            data.status === "OVERDUE"
        ) {
            setterMethod = setSkinMicrobiomeBundle;
        }
        if( data.kit_bundle_product_id){
            getProductDetails(md03ProtocolId, fields, setSkinBundleDetails);
        }
        getProductDetails(md03ProtocolId, fields, setterMethod);
    }

    useEffect(() => {
      userTestStatusResponseHandler(reTestStatus);
           
        // setTimeout(() => {
        //     getProductDetails(
        //         skinCareRecommendationDetails.personalized.prodId,
        //         fields,
        //         setSkinCareRecommendationDetails,
        //         "personalized",
        //         () =>
        //             getProductDetails(
        //                 skinCareRecommendationDetails.concierge.prodId,
        //                 fields,
        //                 setSkinCareRecommendationDetails,
        //                 "concierge"
        //             )
        //     );
        // }, 1000);
    }, []);

    let dashboardStatusContainers = "";

    switch (reTestStatus.status) {
        case "SUBSCRIBED":
            dashboardStatusContainers = (
                <Components.KitShowcase
                    heading="Skin Microbiome Discovery Kit | Subscription Status"
                    title="Your next Skin Microbiome Discovery Kit will be shipped soon!"
                    subtitle={
                        <div className={style.skinTestProdName}>
                            {reTestStatus.product_title}
                        </div>
                    }
                    shippingInfo={
                        <div>
                            <b>
                                Subscribed for every{" "}
                                {reTestStatus.order_interval_frequency}{" "}
                                {reTestStatus.order_interval_unit}s
                            </b>{" "}
                            |{" "}
                            <span style={{ whiteSpace: "pre" }}>
                                Expected shipping date :{" "}
                                <b>
                                    {moment(
                                        reTestStatus.expected_shipping_date
                                    ).format(dateFormats.DDSPCMMMMCOMSPCYYYY)}
                                </b>
                            </span>
                        </div>
                    }
                    purchaseStatus={true}
                    productDetails={subScribedProdDetails}
                    productDetailsLoading={productDetailsLoading}
                    imgColClassName={style.kitShowCaseImgContainer}
                    isStoneHead= {false}
                    isSandHead={true}
                    isDarkBackGround={true}
                />
            );
            break;
        case "NOT_REQUIRED":
            // dashboardStatusContainers = reTestStatus.kit_product_id ? (
            //     <Components.KitShowcase
            //         heading={
            //             <span>
            //                 Get Your MD-03 Protocol™
            //             </span>
            //         }
            //         title={
            //             <div>
            //                 The Parallel Health experience <br/>
            //                 starts with a skin microbiome test
            //             </div>
            //         }
            //         subtitle="Discover your skin microbiome type, skin age, diversity score, hydration level, and much more to understand what skin health regimen is right for you."
            //         kitSubtitle = {
            //             <span>
            //                 Includes a comprehensive skin health test and Custom Active Phage Serum.
            //             </span>
            //         }
            //         buttonText="Start Your Protocol"
            //         purchaseStatus={false}
            //         productDetails={skinMicrobiomeBundle}
            //         productDetailsLoading={productDetailsLoading}
            //         isStoneHead= {false}
            //         isSandHead={true}
            //         isDarkBackGround={true}
            //     />
            // ) : (
            //     <></>
            // );
            dashboardStatusContainers = reTestStatus.kit_product_id ? (<SuggestMD03 />) : (<></>)
            break;
        case "REQUIRED":
            dashboardStatusContainers = (
                <Components.KitShowcase
                    heading={
                        <span>
                            Your Bi-Annual {KIT_RETEST_TAB_TITLES[reTestStatus.kit_type]} {" "}
                            <span style={{ whiteSpace: "pre" }}>
                                is Due
                            </span>
                            
                        </span>
                    }
                    title={
                        <div>
                            With every season and life change,<br />
                            your skin microbiome evolves.
                        </div>
                    }
                    subtitle="Your skin microbiome changes at least twice a year on average and up to four times a year, depending on environmental factors like lifestyle choices, climate, diet, stress levels, and those who live in your household. As such, we recommend you test at least every six months, or even every quarter, to stay up-to-date with the right skin health regimen."
                    kitSubtitle=""
                    buttonText="Order Protocol Now"
                    bundleButtonText="Order Bundle Now" 
                    purchaseStatus={false}
                    isStoneHead={true}
                    isSandHead={false}
                    isDarkBackGround={false}
                    productDetails={skinMicrobiomeBundle}
                    bundleProductDetails={skinBundleDetails}
                    productDetailsLoading={productDetailsLoading}
                />
            );
            break;

        case "OVERDUE":
            dashboardStatusContainers = (
                <Components.KitShowcase
                    heading={
                        <span>
                            Your Bi-Annual {KIT_RETEST_TAB_TITLES[reTestStatus.kit_type]} {" "}
                            <span style={{ whiteSpace: "pre" }}>
                                is Overdue
                            </span>
                        </span>
                    }
                    title={
                        <div>                            
                            With every season and life change,<br />
                            your skin microbiome evolves.
                        </div>
                    }
                    subtitle="Your skin microbiome changes at least twice a year on average and up to four times a year, depending on environmental factors like lifestyle choices, climate, diet, stress levels, and those who live in your household. As such, we recommend you test at least every six months, or even every quarter, to stay up-to-date with the right skin health regimen."
                    kitSubtitle=""
                    buttonText="Order Protocol Now"
                    bundleButtonText="Order Bundle Now" 
                    purchaseStatus={false}
                    isStoneHead={false}
                    isSandHead={true}
                    isDarkBackGround={false}
                    productDetails={skinMicrobiomeBundle}
                    dueDate={reTestStatus.retest_date}
                    bundleProductDetails={skinBundleDetails}
                    productDetailsLoading={productDetailsLoading}
                />
            );
            break;

        default:
            dashboardStatusContainers = (
                <div
                    className={`text-center ${reTestStatusIsLoading && "hide"}`}
                >
                    <Spin
                        size="large"
                        style={{
                            marginTop: "60px",
                            textAlign: "center",
                        }}
                    />
                </div>
            );
            break;
    }

    return (
        <div>
            {dashboardStatusContainers}
        </div>             
    );
};
export default SuggestHealthKit