import React from 'react'
import style from './ReportSections.module.scss'

function ReportSection({ headerComponent, className = '', children }) {
    return (
        <div className={`${style.reportSection} ${className}`}>
            {headerComponent}
            <div className={style.reportSectionContainer}>
                {children}
            </div>
        </div>
    )
}

export default ReportSection