import { shopify_icon_light } from "@ph/common/assets/index";
import { PROTOCOL_STEP_CARD_TYPES, PROTOCOL_STEP_TYPES } from "./constants";
import { olivia_md03,custom_active_1_md03, custom_active_3_md03 } from "@ph/common/assets/index";
import { PROTOCOL_IDS } from "@ph/subject/src/api_services/Protocol/protocolFetch"
import { PROTOCOL_SELLING_PLAN } from "@ph/subject/src/api_services/Protocol/protocolFetch";

export const PROTOCOL = {
    id: 'MD03',      //unique id
    fetch_id: PROTOCOL_IDS.MD03_PROTOCOL,  //  fetch details dynamically
    price_fetch_id: PROTOCOL_IDS.CUSTOM_PHAGE_ID,
    product_data: {
        product_id: 1,
        product_title: 'MD-03 Phage Protocol™',
        product_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
        product_image: null,
        product_price: 195,
    },
    steps: [
        {
            id: 1,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: 'SKIN DISCOVERY TEST',
            title: 'Choose your test',
            description: 'With every protocol, you will receive a test every 6 months. Select <span class="font-Neue-medium">SKIN DISCOVERY TEST</span> to learn about your facial skin microbiome and skin age. Select <span class="font-Neue-medium">BODY BLEMISH TEST</span> to learn about a specific skin area on your body.',
            selectionLabel: 'Choose 1',
            optional: true,
            step_data: [
                {
                    id: '11',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 1,
                    defaultSelectId: [111],
                    data: [
                        {
                            fetch_id: PROTOCOL_IDS.SKIN_TEST_WITH_PACK_1,
                            product_id: 111,
                            product_title: 'Skin Discovery Test',
                            product_description: "The Skin Discovery Test assesses your facial Skin Microbiome Type(s)™, skin age, and other health factors.",
                            product_image: null,
                            product_price: 0,
                        },
                        {
                            fetch_id: PROTOCOL_IDS.BODY_BLEMISH_PACK_1,
                            product_id: 112,
                            product_title: 'BODY Blemish Discovery Test',
                            product_description: "The Body Blemish Test assesses one particular skin area on your body that you choose. Common areas: Back, Shoulders, Shins, Upper Chest, Arms",
                            product_image: null,
                            product_price: 0,
                        }
                    ],
                }
            ]
        },
        {
            id: 2,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: '1:1 CLINICAL CONSULTATION',
            title: 'Personalized guidance and skin health reports',
            description: 'With every test, you will receive a consultation with a certified <span class="font-Neue-medium">Microbiome Dermatology™ doctor </span>to review your results and provide access to personalized prescriptions and other microbiome-supportive products. Also included is 24/7 on-going clinical support via text.',
            optional: true,
            step_data: [
                {
                    id: '21',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 2,
                    defaultSelectId: [211, 212],
                    data: [
                        {
                            product_id: 211,
                            product_title: 'SKIN HEALTH REPORT',
                            product_description: 'Learn about your microbial balance and diversity score, as well as other health factors, alongside personalized recommendations.',
                            product_image: olivia_md03,
                            product_price: 0,
                            is_included: true,
                        },
                        {
                            product_id: 212,
                            product_title: '1:1 Clinical Consultation(s) and 24/7 Support via Text with Parallel Microbiome Dermatology™ Doctor',
                            product_description: '',//1:1 clinical guidance and 24/7 ongoing support
                            product_image: null,
                            product_price: 0,
                            is_included: true,
                        }

                    ],
                }
            ]
        },
        {
            id: 3,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: 'ACTIVE PHAGE SERUM',
            title: 'Active Phage Serum',
            description: 'Based on your skin microbiome results, you will receive your <span class="font-Neue-medium">Custom Active Phage Serum, </span> which can support the <span class="font-Neue-medium">7 Hallmarks</span> of Skin Aging™, reduce breakouts and blemishes, improve skin tone and texture, and promote overall skin health and longevity.',
            selectionLabel: `Choose 1 <div>(For discounted family plans, reach out to us at <a href='mailto: hello@parallelhealth.io' rel="noreferrer">hello@parallelhealth.io</a>)</div>`,
            optional: true,
            step_data: [
                {
                    id: '31',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 1,
                    defaultSelectId: [311],
                    data: [
                        {
                            product_id: 311,
                            fetch_id: PROTOCOL_IDS.CUSTOM_PHAGE_ID, // custom to avoid duplicates
                            product_title: 'CUSTOM ACTIVE PHAGE SERUM (1 Pack)',
                            product_description: 'Choose to receive one (1) if you are applying to facial area at standard dosing or a small body area.',
                            product_image: null,
                            product_price: 195,

                        },
                        {
                            product_id: 312,
                            fetch_id: PROTOCOL_IDS.BODY_BLEMISH_PACK_3,
                            product_title: 'CUSTOM ACTIVE PHAGE SERUM (3 Pack)',
                            product_description: 'Choose to receive three (3) if you will apply to facial areas more than 2x/day or need to cover multiple or large body area(s)',
                            product_image: null,
                            product_price: 295,
                        }
                    ],
                }
            ]
        },
        {
            id: 4,
            type: PROTOCOL_STEP_TYPES.PRODUCT,
            label: 'PERSONALIZED ADD-ONS',
            title: 'Choose your Add-ons',
            description: 'To round out your skin health regimen, add the anti-aging powerhouse Blue Biotic™ Multi-Effect Peptide Cream or a Personalized Prescription for extra support. Access exclusive MD-03 pricing here.',
            selectionLabel: 'Add these super boosting products:',
            optional: true,
            step_data: [
                {
                    id: '41',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: false,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 0,
                    allowQuatity: true,
                    defaultSelectId: [],
                    data: [
                        // {
                        //     product_id: 411,
                        //     fetch_id: PROTOCOL_IDS.HOLY_CALMING_CLEANSER_ID,
                        //     product_title: 'HOLY CALMING CLEANSER',
                        //     product_description: 'This microbiome - friendly calming cleanser leaves skin refreshed ...',
                        //     product_image: null,
                        //     product_price: 32,
                        //     selling_plan: PROTOCOL_SELLING_PLAN.HOLY_CALMING_CLEANSER_SELLING_PLAN
                        // },
                        // {
                        //     product_id: 412,
                        //     fetch_id: PROTOCOL_IDS.SKIN_BARRIER_SILK_CREAM_ID,
                        //     product_title: 'SKIN BARRIER SILK CREAM',
                        //     product_description: 'This silky, rich microbiome - friendly moisturizing cream with peptides...',
                        //     product_image: null,
                        //     product_price: 85,
                        //     selling_plan: PROTOCOL_SELLING_PLAN.SKIN_BARRIER_SILK_CREAM_ID_SELLING_PLAN
                        // },
                        {
                            product_id: 413,
                            fetch_id: PROTOCOL_IDS.BLUE_PEPTIDE_CREAM_ID,
                            product_title: 'BLUE BIOTIC PEPTIDE CREAM',
                            product_description: 'Sparing no expense to formulate, this next - level peptide cream...',
                            product_image: null,
                            product_price: 205,
                            selling_plan: PROTOCOL_SELLING_PLAN.BLUE_PEPTIDE_CREAM_SELLING_PLAN
                        }
                    ],
                },
                {
                    id: '42',
                    type: PROTOCOL_STEP_CARD_TYPES.PRODUCT,
                    is_multiple: true,
                    optional: true,
                    fetch_id: null,
                    minNumberOfSelection: 1,
                    rootProduct: {
                        product_id: 411,
                        fetch_id: PROTOCOL_IDS.PERSONALIZED_PRESCRIPTIONS,
                        product_title: "PERSONALIZED PRESCRIPTIONS (Choose Your One Main Concern)",
                        product_description: "Sparing no expense to formulate, this next-level peptide cream features potent copper peptides, SNAP-8, and Matrixl-3000, alongside a palette of key supportive actives and biotics that promote collagen production, the reduction of fine lines and wrinkles, and an improvement in dyspigmentation. For those who use toxins and fillers, this product is especially useful in between treatments to elongate effects.",
                        product_image: null,
                        product_price: 65,
                        selling_plan: PROTOCOL_SELLING_PLAN.PERSONALIZED_PRESCRIPTIONS_SELLING_PLAN
                    }
                }
            ]
        },
        {
            id: 5,
            icon: shopify_icon_light,
            type: PROTOCOL_STEP_TYPES.CART,
            Title: 'Your MD-03',
            Sub_title: 'Protocol Summary',
            button_text: 'Add Protocol to Cart'
        }

    ],
}

export const MINI_PROTOCOL_PRODUCTS = [
    {
        product_title: 'Skin Discovery Test - Every 6 Months',
        product_description: 'The most advanced and comprehensive skin microbiome report, powered by whole genome sequencing',
        product_price: 0,
        discount_price: 435
    },
    {
        product_title: '1:1 Clinical Consultation with MD',
        product_description: 'Personalized evaluation and on-going support through our providers, including access to supplemental personalized prescriptions, as needed',
        product_price: 0,
    },
    {
        fetch_id: PROTOCOL_IDS.CUSTOM_PHAGE_ID,
        product_title: 'Active Phage Serum',
        product_description: 'Custom serum with nourishing ingredients and a potent precision phage cocktail designed for your skin microbiome',
        product_price: 195,
    }
]