import React from 'react';
import style from "./kitResult.module.scss";
import Components from "@ph/common/components";
import {getProductDescription} from "@ph/common/global/Helpers";
import {Col} from "antd";

function PrimaryRecommendationColumn({recommendedProds, fetchingReportData}) {
    return (
        <Col xs={{span: 24, order: 2}} sm={{span: 24, order: 2}} md={{span: 24, order: 2}}
             xl={{span: 12, order: 1}} className={style.colLeft}>
            {
                (recommendedProds && recommendedProds.length) &&
                <Components.PhMediaCard
                    imgLoading={fetchingReportData}
                    imgSrc={recommendedProds[0].image_url}
                    tagPill={{className: '', text: 'PRIMARY'}}
                    cardTitle={recommendedProds[0].name}
                    cardDescription={getProductDescription(recommendedProds[0])}
                    buttonText='SUBSCRIBE'
                    navigateTo={{
                        path: recommendedProds[0].shopify_product_url,
                        openInNewTab: true
                    }}
                />
            }
        </Col>
    );
}

export default PrimaryRecommendationColumn;