import React from 'react';
import { Row, Col } from 'antd';
import style from './ReportSections.module.scss';
import { addLeadingZero } from '../reportUtils';


function ReportSectionHeader({ headerData, score }) {
    return (
        <div className={style.reportSectionHeader}>
            <span className={style.reportSectionCount}>{headerData.count}</span>
            <Row>
                <Col xs={7} md={5} xl={3}>
                    <div className={style.reportSectionScore}>
                        {addLeadingZero(score)}
                    </div>
                </Col>
                <Col xs={17} md={19} xl={8}>
                    <div className={style.titleContainer}>
                        <div className={style.title}>
                            {headerData.title}
                        </div>
                        <div className={style.subTitle}>
                            {headerData.subTitle}
                        </div>
                    </div>
                </Col>
                <Col xs={24} xl={13}>
                    <div className={style.description}>
                        {headerData.description}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ReportSectionHeader