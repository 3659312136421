export const SHOPIFY_PAGES = {
    ACCOUNT: 'account',
    CART: 'cart',
    PARALLELOGRAM: 'blogs/parallelogram',
    SCIENCE: 'pages/science',
    SHOP: 'pages/all-products',
    CLINICIANS: 'pages/clinicians',
    TEAM: 'pages/our-team',
    FAQ: 'a/faq',
    REASEARCH: 'pages/whitepaper',
    POLICY: 'pages/privacy-policy',
    TERMS: 'pages/terms-of-service',
    DISCLAIMER: 'pages/disclaimer',
    PERSONALIZED_PRESCRIPTION: 'products/personalized-prescription',
    CLEANSER: 'products/cleanser',
    MOISTURISER: 'products/moisturiser',
    PROTOCOL: 'pages/md03-phage-protocol',
    SKIN_MICROBIOME_DISCOVERY_KIT: 'products/skin-microbiome-discovery-kit',
    BODY_BLEMISH_TEST_KIT: 'products/body-blemish-test-kit',
    BODY_BLEMISH_ODOR_KIT: 'products/body-blemish-odor-kit'
}