import React from 'react';
import {Col, Row, Spin} from "antd";

function TwoColumnLayout(props) {
  const {
    featureWebImage,
    featureMobileImage,
    hideLayoutHeader = true,
    showSpinner = false,
    featureImageAlt = 'Feature Image'
  } = props;

  return (
    <div className={`two-col-wrapper ${!hideLayoutHeader ? 'with-header' : ''}`}>
      <Spin
        spinning={showSpinner}
        size="large"
        style={{position: "fixed", top: "15vh"}}
      >
        <Row>
          <Col xs={24} sm={24} md={24} xl={11} className='feature-section'>
            <img src={featureWebImage} className='feature-image-web' alt={featureImageAlt}/>
            <img src={featureMobileImage} className='feature-image-mobile'
                 alt={featureImageAlt}/>
          </Col>
          <Col xs={24} sm={24} md={24} xl={13} className='content-section'>
            {props.children}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default TwoColumnLayout;