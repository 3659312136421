
export const BATCH_STATUS = {
    DRAFT: "DRAFT",
    CREATED: "CREATED",
    DNA_EXTRACTION: "DNA_EXTRACTION",
    SAMPLE_SHIPMENT: "SAMPLE_SHIPMENT",
    SAMPLE_SEQUENCING: "SAMPLE_SEQUENCING",
    SERUM_DETERMINATION: "SERUM_DETERMINATION"
}

export const BATCH_NEXT_STATUS = {
    [BATCH_STATUS.DRAFT]: [BATCH_STATUS.CREATED],
    [BATCH_STATUS.CREATED]: [BATCH_STATUS.DNA_EXTRACTION],
    [BATCH_STATUS.DNA_EXTRACTION]: [BATCH_STATUS.SAMPLE_SHIPMENT],
    [BATCH_STATUS.SAMPLE_SHIPMENT]: [BATCH_STATUS.SAMPLE_SEQUENCING],
    [BATCH_STATUS.SAMPLE_SEQUENCING]: [BATCH_STATUS.SERUM_DETERMINATION],
}

export const BATCH_STATUS_LABELS = {
    [BATCH_STATUS.DRAFT]: "DRAFT",
    [BATCH_STATUS.CREATED]: "CREATED",
    [BATCH_STATUS.DNA_EXTRACTION]: "Plating & DNA Extraction",
    [BATCH_STATUS.SAMPLE_SHIPMENT]: "Sample Shipment",
    [BATCH_STATUS.SAMPLE_SEQUENCING]: "Sample Sequencing",
    [BATCH_STATUS.SERUM_DETERMINATION]: "Serum Determination",
}

export const BATCH_INPROGRESS_LABELS = {
    "": "",
    [BATCH_STATUS.DRAFT]: "DRAFT",
    [BATCH_STATUS.CREATED]: "Plating & DNA Extraction",
    [BATCH_STATUS.DNA_EXTRACTION]: "Sample Shipment",
    [BATCH_STATUS.SAMPLE_SHIPMENT]: "Sample Sequencing",
    [BATCH_STATUS.SAMPLE_SEQUENCING]: "Serum Determination",
    [BATCH_STATUS.SERUM_DETERMINATION]: "Completed",
}

export const BATCH_EDIT_ALLOWED = {
    [BATCH_STATUS.DRAFT]: true,
    [BATCH_STATUS.CREATED]: true,
    [BATCH_STATUS.DNA_EXTRACTION]: true,
    [BATCH_STATUS.SAMPLE_SHIPMENT]: false,
    [BATCH_STATUS.SAMPLE_SEQUENCING]: false,
    [BATCH_STATUS.SERUM_DETERMINATION]: false,
}

export const BATCH_CONTROL_ALLOWED = {
    [BATCH_STATUS.DRAFT]: true,
    [BATCH_STATUS.CREATED]: true,
    [BATCH_STATUS.DNA_EXTRACTION]: false,
    [BATCH_STATUS.SAMPLE_SHIPMENT]: false,
    [BATCH_STATUS.SAMPLE_SEQUENCING]: false,
    [BATCH_STATUS.SERUM_DETERMINATION]: false,
}

export const BATCH_SKIPCELL_ALLOWED = {
    [BATCH_STATUS.DRAFT]: true,
    [BATCH_STATUS.CREATED]: true,
    [BATCH_STATUS.DNA_EXTRACTION]: true,
    [BATCH_STATUS.SAMPLE_SHIPMENT]: false,
    [BATCH_STATUS.SAMPLE_SEQUENCING]: false,
    [BATCH_STATUS.SERUM_DETERMINATION]: false,
}