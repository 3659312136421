import { useEffect, useState } from "react";
import { Button, Col, Drawer, Row, Space, Typography } from "antd";
// import Icon from '@ant-design/icons';
import {
    PH_Logo_v2,
    PH_logo_light_tm,
    PH_menu_icon,
    PH_menu_icon_light,
    shopify_icon,
    shopify_icon_light,
    user_icon,
    user_icon_light
} from "@ph/common/assets";
import style from "./header.module.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { buildCompleteShopifyLink } from "@ph/common/global/Helpers";
import { header_hex, button_arrow_black } from "@ph/common/assets/index";
import { SHOPIFY_PAGES } from "@ph/common/constants/ShopifyPages";
import { useNavigate } from "react-router-dom";
import MegaMenu from "../MegaMenu/MegaMenu";
import { MEGA_MENU_CONFIG } from "@ph/common/global/Constants";

const { Text, Link, Title } = Typography;

const MOBILE_LINKS = [
    {
        title: 'Products',
        link: buildCompleteShopifyLink(SHOPIFY_PAGES.SHOP)
    },
    {
        title: 'Science',
        link: buildCompleteShopifyLink(SHOPIFY_PAGES.SCIENCE)
    },
    {
        title: 'Learn',
        link: buildCompleteShopifyLink(SHOPIFY_PAGES.PARALLELOGRAM)
    },
    {
        title: 'For Clinicians',
        link: buildCompleteShopifyLink(SHOPIFY_PAGES.CLINICIANS)
    },
    {
        title: 'Kit Usage Instructions',
        link: '/kit-user-guide'
    },
    {
        title: 'My Skin Tests, Reports and Forms',
        link: '/dashboard'
    },
    {
        title: 'My Order History & Account Details',
        link: buildCompleteShopifyLink(SHOPIFY_PAGES.ACCOUNT)
    }
]

const Header = (props) => {
    const { darkHeader, reportLayout } = props;
    let location = useLocation();
    const navigate = useNavigate()
    const [currentPath, setcurrentPath] = useState(location.pathname);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const user = useSelector((state) => state.userData.userData);

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    return (
        !(currentPath.toString().includes('getstarted')) ?
            <>
                <div className={style.hideHeaderInSmallScreen}>
                    <header id="PHheader" className={darkHeader ? style.headerContainer + " " + style.darkHeader : style.headerContainer}
                        style={reportLayout ? { borderBottom: '2px solid #FD8272' } : { borderBottom: 'none' }}>
                        <Row style={{ height: 'inherit' }}>
                            {/* <Col xs={24} sm={4} md={4} xl={4} className={style.logoSection}>
                                <img src={darkHeader ? PH_logo_light : PH_logo_dark} alt="Parallel" />
                            </Col> */}
                            {/* <Col xs={24} sm={15} md={15} xl={15} className={style.headerTabContainer}> */}
                            <Col xs={24} sm={10} md={10} xl={10} className={style.headerTabContainer}>
                                <div className={style.logoSection}>
                                    <Link href='/dashboard'>
                                        <img src={darkHeader ? PH_logo_light_tm : PH_Logo_v2} alt="Parallel" />
                                    </Link>
                                </div>
                                {!reportLayout &&
                                    <div className={style.headerTab}>
                                        {/* <Link className={style.headerTabLink}>
                                            <Text className={style.text}>TAKE QUIZ</Text>
                                        </Link> */}
                                        <Link className={style.headerTabLink} href={buildCompleteShopifyLink(SHOPIFY_PAGES.SHOP)}>
                                            <Text className={style.text}>Products</Text>
                                        </Link> 
                                        <Link className={style.headerTabLink}
                                            href={buildCompleteShopifyLink(SHOPIFY_PAGES.SCIENCE)}>
                                            <Text className={style.text}>Science</Text>
                                        </Link>
                                        <Link className={style.headerTabLink}
                                            href={buildCompleteShopifyLink(SHOPIFY_PAGES.PARALLELOGRAM)}>
                                            <Text className={style.text}>Learn</Text>
                                        </Link>
                                        {/* <Link className={style.headerTabLink}>
                                            <Text className={style.text}>STORIES</Text>
                                        </Link> */}
                                        <Link className={style.headerTabLink}
                                            href={buildCompleteShopifyLink(SHOPIFY_PAGES.CLINICIANS)}>
                                            <Text className={style.text}>For Clinicians</Text>
                                        </Link>
                                    </div>
                                }
                            </Col>
                            {/* <Col xs={0} md={2} className={style.hexSection}> */}
                            <img src={header_hex} className={style.headerHex} />
                            {/* </Col> */}
                            <Col xs={24} sm={14} md={14} xl={14} className={style.buttonSection}>
                                <Space className={style.space}>
                                    {/* <Link href='/dashboard'>
                                        <Text className={`${style.linkText} td-underline`}>My Dashboard</Text>
                                    </Link> */}
                                    {/* <Button type="primary" className={style.dashboardButton}>MY DASHBOARD</Button> */}
                                    {/* <img src={darkHeader ? search_icon_light : search_icon} alt="Parallel" className={style.icon} /> */}
                                    {/* <Link href={buildCompleteShopifyLink(SHOPIFY_PAGES.ACCOUNT)}>
                                        <Text className={style.linkText}>My Account</Text>
                                    </Link> */}
                                    <Button type="secondary" className={style.protocolButton} onClick={() => window.location = `${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`}>
                                        Start Your Protocol
                                        <img src={button_arrow_black} className='ph-btn-arrow' />
                                    </Button>
                                    {/* <div className={style.verticalSeparator}></div> */}
                                    <MegaMenu
                                        rootButton={<img src={darkHeader ? user_icon_light : user_icon} className={style.icon} alt='user' />}
                                        menuConfig={MEGA_MENU_CONFIG}
                                    />

                                    <img src={darkHeader ? shopify_icon_light : shopify_icon} className={style.icon}
                                         alt='cart'
                                        onClick={() => window.location = `${buildCompleteShopifyLink(SHOPIFY_PAGES.CART)}`} />

                                </Space>
                            </Col>
                        </Row>
                    </header>
                </div>

                {/* <div id="PHheader"> */}
                <div className={style.hideHeaderInBigScreen}>
                    <header id="PHheader2" className={darkHeader ? style.headerContainer + " " + style.darkHeader : style.headerContainer}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
                            <div className={style.logoSection}>
                                {/* <Text className={style.appName}>header</Text> */}
                                <Button className={style.sidebarToggleButton} type="link" onClick={() => showDrawer()}>
                                    <img src={darkHeader ? PH_menu_icon_light : PH_menu_icon}
                                        className={style.icon} alt='Menu' />
                                </Button>  
                            </div>
                            <img src={header_hex} alt="Parallel" className={style.headerHex} />
                            <div className={style.buttonSection}>
                                <Space className={style.space}>
                                    {/* <img src={darkHeader ? search_icon_light : search_icon} className={style.icon}
                                         alt='search'/> */}
                                    {/* <img src={darkHeader ? shopify_icon_light : shopify_icon} className={style.icon} /> */}
                                    <img src={darkHeader ? shopify_icon_light : shopify_icon} className={style.icon}
                                        alt='cart'
                                        onClick={() => window.location = `${buildCompleteShopifyLink(SHOPIFY_PAGES.CART)}`} />

                                </Space>
                            </div>
                        </div>
                        <Drawer
                            placement="left"
                            closable={true}
                            title={<img src={header_hex} alt="Parallel" style={{ marginTop: '12px', marginLeft: '-28px', width: '34px' }} />}
                            onClose={closeDrawer}
                            visible={drawerVisible}
                            width={'80vw'}
                            className={style.drawer}
                        >
                            <div className={style.sidebarRoutesSection}>
                                {MOBILE_LINKS.map(item => (
                                    <MobileDrawerLink text={item.title} link={item.link} key={item.title} />
                                ))}
                            </div>

                            <div className={style.buttonContainer}>
                                <Button type="secondary" onClick={() => window.location = `${buildCompleteShopifyLink(SHOPIFY_PAGES.PROTOCOL)}`}>
                                    Start Your Protocol
                                    <img src={button_arrow_black} className='ph-btn-arrow' />
                                </Button>
                            </div>
                        </Drawer>
                    </header>
                </div>
                {/* </div> */}
            </>
            : <></>
    );
};

export default Header;


const MobileDrawerLink = ({ link, text }) => {
    return (
        <div className={style.routeUrl}>
            <Link href={link}>
                <Text className={style.text}>{text}</Text>
            </Link>
        </div>
    )
}