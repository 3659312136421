import React, {useState} from 'react';
import style from "./Report.module.scss";

function ReadMoreDescContainer({Paragraph, description, rowCount = 4, productId = 1, productName = ''}) {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [fullDescriptionKey, setFullDescriptionKey] = useState(productId);

    const markup = typeof description === 'object' && description.length
        ? description.flatMap((part, index) =>
            index === description.length - 1
                ? part
                : [part, <b key={index}>{productName}</b>]
        )
        : [description];

    // const finalDescription = <ReactMarkdown children={description}
    //                                         components={{p: React.Fragment}}/>

    const toggleFullProdDescHandler = (showOrHide) => {
        if(typeof showOrHide === 'boolean') {
            setShowFullDescription(showOrHide);
        } else {
            setShowFullDescription(!showFullDescription);
        }
        setFullDescriptionKey(fullDescriptionKey + (!showFullDescription ? 0 : 1));
    };

    return (
        <>
            <div key={fullDescriptionKey}>
                <Paragraph className="body-txt-md"
                    ellipsis={{
                        rows: rowCount, expandable: true, symbol: <span className='ant-typography-expand note-1'>
                            READ MORE
                        </span>,
                               onExpand: () => toggleFullProdDescHandler(true)}}>
                    {markup}
                </Paragraph>
            </div>
            {showFullDescription &&
                <span className='ant-typography-expand note-1'
                      onClick={() => toggleFullProdDescHandler(false)}>
                    READ LESS
                </span>}
        </>
    );
}

export default ReadMoreDescContainer;