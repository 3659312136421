import React from 'react';
import Components from "@ph/common/components";
import {Row, Col} from "antd";
import style from "./SkinCareRecommendations.module.scss";
import {getShopifyProdUrl} from "@ph/common/global/Helpers";

function SkinCareRecommendations({skinCareRecommendationDetails}) {
    const {personalized, concierge} = skinCareRecommendationDetails;

    let prodNav = {path: '', openInNewTab: true};
    let personalizedProdNav = {}, conciergeProdNav = {};
    if(personalized.data && personalized.data.handle) {
        personalizedProdNav = {...prodNav, path: getShopifyProdUrl(personalized.data.handle)};
    }
    if(concierge.data && concierge.data.handle) {
        conciergeProdNav = {...prodNav, path: getShopifyProdUrl(concierge.data.handle)};
    }
    return (
        <Components.PhCard title="Recommendations For Your Immediate Skincare"
                           isStoneHead={true}>
            <div className={style.recommendationsBody}>
                <Row>
                    <Col xs={24} sm={24} md={24} xl={12} className=''>
                        <Components.PhMediaCard imgLoading={personalized.isLoading}
                                                imgSrc={personalized.data && personalized.data.image && personalized.data.image.src}
                                                cardTitle={personalized.data && personalized.data.title}
                                                cardDescription="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh..."
                                                buttonText="VIEW & BUY"
                                                navigateTo={personalizedProdNav} />
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={12} className=''>
                        <Components.PhMediaCard imgLoading={concierge.isLoading}
                                                imgSrc={concierge.data && concierge.data.image && concierge.data.image.src}
                                                textColClassName={style.bodyDescription}
                                                cardTitle={concierge.data && concierge.data.title}
                                                cardDescription="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh..."
                                                buttonText="VIEW & BUY"
                                                navigateTo={conciergeProdNav} />
                    </Col>
                </Row>
            </div>
        </Components.PhCard>
    );
}

export default SkinCareRecommendations;