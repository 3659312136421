import React, {useEffect} from 'react';
import Cookies from 'universal-cookie';
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";

function RedirectFromShopify({ pageToRedirect, clientId, redirectURI }) {
    const cookies = new Cookies();
    const {
        REACT_APP_MINI_ORANGE_URL,
        REACT_APP_MINI_ORANGE_LOGIN_PATH,
        REACT_APP_MINI_ORANGE_ID,
        REACT_APP_ENV,
        REACT_APP_REDIRECT_URI
    } = process.env;

    let env = (REACT_APP_ENV === "prod_") ? '' : REACT_APP_ENV;

    useEffect(() => {
        cookies.set('pageToRedirect', pageToRedirect, { path: '/', domain: '.parallelhealth.io' });
        let completeUrl = `${REACT_APP_MINI_ORANGE_URL}/${REACT_APP_MINI_ORANGE_LOGIN_PATH}/${REACT_APP_MINI_ORANGE_ID}?client_id=${env}${clientId || pageToRedirect}&redirect_uri=${REACT_APP_REDIRECT_URI}/${redirectURI}`;
        return window.location.replace(completeUrl);
    }, []);

    return (
        <FullPageLoader />
    )
}

export default RedirectFromShopify;