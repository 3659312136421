import React, {useEffect, useState} from 'react';
import {left_arrow, PH_user_details_feature_img_web} from "@ph/common/assets";
import TwoColumnLayout from "@ph/common/components/LayoutComponents/TwoColumnLayout";
import {Button, Form, Input, Typography} from "antd";
import style from './ContactDetailsForm.module.scss';
import PhoneInput from "antd-phone-input";
import {initiateTokenGeneration} from "@ph/common/global/Helpers";
import {SHOPIFY_PAGES} from "@ph/common/constants/ShopifyPages";

const { Text } = Typography;

const INIT_ERROR = {
  firstName: false,
  lastName: false,
  contactNo: false,
}

function ContactDetailsForm({connectionInstance}) {
  const [loadingInProgress, setLoadingInProgress] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [savingInProgress, setSavingInProgress] = useState(false);
  const {REACT_APP_SHOPIFY_URL} = process.env;
  const SHOPIFY_ACCOUNT_LINK = `${REACT_APP_SHOPIFY_URL}/${SHOPIFY_PAGES.ACCOUNT}`;
  const [fieldError, setFieldError] = useState(INIT_ERROR)
  const [responseErrors, SetResponseErrors] = useState([])

  const [form] = Form.useForm();

  const getCustomerDetails = async () => {
    try {
      setLoadingInProgress(true);
      const res = await connectionInstance.get(
        `${process.env.REACT_APP_API_URL}/subject/shopify_profile`
      );
      if (res?.data) {
        const {first_name, last_name, phone} = res?.data;
        setFirstName(first_name || '');
        setLastName(last_name || '');

        if (phone !== '') {
          setPhoneNumber(phone);
        }

        form.resetFields();
        form.setFieldsValue({
          first_name: first_name || '',
          last_name: last_name || '',
          phoneNumber: phone || ''
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingInProgress(false);
    }
  };

  const backClickHandler = () => window.location.replace(SHOPIFY_ACCOUNT_LINK);

  const onFinish = async (values) => {
    setSavingInProgress(true);
    SetResponseErrors([])
    const {first_name, last_name, phoneNumber} = values;
    let formattedPhoneNumber = '';
    if (typeof phoneNumber === 'string')
      formattedPhoneNumber = phoneNumber;
    else if (typeof phoneNumber === 'object' && phoneNumber?.countryCode &&
      phoneNumber?.areaCode && phoneNumber?.phoneNumber)
      formattedPhoneNumber = `+${phoneNumber.countryCode}${phoneNumber.areaCode}${phoneNumber.phoneNumber}`;

    let bodyFormData = new FormData();
    bodyFormData.append('first_name', first_name.trim());
    bodyFormData.append('last_name', last_name.trim());
    bodyFormData.append('phone', formattedPhoneNumber);


    try {
      const response = await connectionInstance.patch('/subject/shopify_profile',
        bodyFormData);
      window.location.replace(initiateTokenGeneration(SHOPIFY_ACCOUNT_LINK));
    } catch (error) {
      if (error?.response?.data?.message) {
        SetResponseErrors(error?.response?.data?.message)
      } else {
        SetResponseErrors(['Please enter correct details.'])
      }
    } finally {
      setSavingInProgress(false);
    }
  };

  const validateFirstname = (rule, value) => {
    if (value.trim() === '') {
      setFieldError({ ...fieldError, firstName: true });
      return Promise.reject('First Name cannot be empty or contain only spaces.');
    } else {
      setFieldError({ ...fieldError, firstName: false });
      return Promise.resolve();
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  return (
    <TwoColumnLayout
      featureWebImage={PH_user_details_feature_img_web}
      featureMobileImage={PH_user_details_feature_img_web}
      hideLayoutHeader={false}>
      <div className={style.rightSectionWrapper}>
        <div className={`text-left ${style.backLinkContainer}`}>
          <Text className='body-txt-md hand-cursor' onClick={backClickHandler}>
            <img src={left_arrow} alt='<-' height='14px'/> &nbsp; Go Back
          </Text>
        </div>
        <div className={style.formContainer}>
          <div className={`${style.formTitle} text-left`}>
            <Text className='h4'>Add/Edit Account Details</Text>
          </div>
          <Form form={form}
                name="customer-details"
                autoComplete="off"
                onFinish={onFinish}
                size="large"
                className={style.formGroup}
                disabled={loadingInProgress || savingInProgress}>
            <Form.Item
              name="first_name"
              rules={[{ required: true, message: 'Please enter your first name!' },
              { validator: validateFirstname },
              ]}
              validateStatus={fieldError.firstName ? 'error' : ''}
              help={fieldError.firstName ? 'First Name cannot be empty or contain only spaces.' : ''}
            >
              <Input className={style.input}
                placeholder="First Name*"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="last_name"
            >
              <Input className={style.input}
                placeholder="Last Name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className='phone-input'
              name="phoneNumber"
            >
              <PhoneInput
                placeholder="Phone Number"
                enableSearch
                value={phoneNumber}
                onChange={value => {
                  setPhoneNumber(value);
                }}
              />
            </Form.Item>
            {responseErrors && responseErrors.map(error => <p className='text-error text-left'>{error}</p>)}
            <p className='text-justify'>
              <Text className='note-1'>
                Details added here will be used for your account and in your health reports.
              </Text>
            </p>
            <Form.Item className='text-left'>
              <Button type="primary" htmlType="submit">
                Save Details
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </TwoColumnLayout>
  );
}

export default ContactDetailsForm;