import React, { useRef, useEffect } from "react";
import nouislider from "nouislider";
import "nouislider/dist/nouislider.css";

function Slider(props) {
    const sliderRef = useRef(null);

    const toggleSliderState = (disable) => {
        if(disable) {
            sliderRef.current.disable();
        } else {
            sliderRef.current.enable();
        }
    };

    useEffect(() => {
        // initialize the slider once the component mounts
        sliderRef.current = nouislider.create(document.getElementById(props.id), {...props});
        toggleSliderState(props.disabled);

        // cleanup function to destroy the slider when the component unmounts
        return () => {
            sliderRef.current.destroy();
        };
    }, []);

    return <div id={props.id} />;
}



Slider.defaultProps = {
    animate: true,
    behaviour: "tap",
    className: null,
    clickablePips: false,
    connect: false,
    direction: "ltr",
    disabled: false,
    format: null,
    margin: null,
    limit: null,
    keyboardSupport: true,
    id: null,
    instanceRef: null,
    padding: 0,
    pips: null,
    snap: false,
    step: null,
    style: null,
    orientation: "horizontal",
    tooltips: false,
    onChange: () => {},
    onEnd: () => {},
    onSet: () => {},
    onSlide: () => {},
    onStart: () => {},
    onUpdate: () => {}
};

export default Slider;