import React from 'react';
import style from "./kitStatus.module.scss";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {Button, Typography} from "antd";
import {check_circle_black} from "@ph/common/assets";
import { button_arrow } from '@ph/common/assets/index';

const { Text } = Typography;

function KitStatusInfo({
                           bgColor,
                           exclamationIcon,
                           title,
                           subtitle,
                           buttonText,
                           btnClickHandler,
                           healthProfileCompleted
}) {
    const btnCallToAction = () => {
        if(btnClickHandler && typeof btnClickHandler === "function")
            btnClickHandler();
    };

    return (
        <div className={`${style.statusBlock} ${bgColor}`}>
            {
                exclamationIcon ?
                    <ExclamationCircleFilled className={style.blockIcon} /> :
                    <img src={check_circle_black} className={style.blockIcon} alt='' style={{marginBottom: '8px'}}/>
            }
            { !healthProfileCompleted && (
                <Text className={style.heading}>{title}</Text>
            )}
            <Text className={style.subtitle}>{subtitle}</Text>
            { !healthProfileCompleted && (
                <Button type="primary" className={style.blockButton} onClick={btnCallToAction}>
                    {buttonText}
                    <img src={button_arrow} className='ph-btn-arrow' />
                </Button>
            )}
        </div>
    );
}

export default KitStatusInfo;