import React from 'react'
import { Button, Typography, Row, Col } from 'antd';
import style from './crossSellBanner.module.scss';
import Marquee from "react-fast-marquee";
import {marquee_1, marquee_2, marquee_3, marquee_4} from "@ph/common/assets";
import Components from "@ph/common/components";

const { Text } = Typography;

const CrossSellBanner = () => {

    return (
        <Components.PhCard title="Explore Our Skincare Offerings"
                           isStoneHead={true}
                           cardClass={style.crossSellBox}>
            <div className={style.bannerContent}>
                <div className={style.marqueeSection} onClick={() => window.open(`${process.env.REACT_APP_SHOPIFY_URL}/collections/all`, '_blank')}>
                    <Marquee className={style.marqueeContent} gradient={false}>
                        <img src={marquee_1} className={style.marqueeImage} alt="PH" />
                        <img src={marquee_3} className={style.marqueeImage} alt="PH" />
                        <img src={marquee_2} className={style.marqueeImage} alt="PH" />
                        <img src={marquee_4} className={style.marqueeImage} alt="PH" />
                    </Marquee>
                </div>
                <div className={style.bannerSection}>
                    <div className={style.banner}>
                        <Row>
                            <Col xs={24} sm={24} md={11} xl={11} className={style.colType1}>
                                <div><Text className={style.bannerTitle}>All our products are made responsibly
                                    with precision <span style={{ whiteSpace: 'nowrap' }}>& care.</span></Text></div>
                                <Button className={style.bannerButton} type="secondary">SHOP NOW</Button>
                            </Col>
                            <Col xs={24} sm={24} md={13} xl={13} className={style.colType2}>
                                <img className={style.bannerImage} alt="Parallel" />
                            </Col>
                        </Row>
                        <Button className={style.bannerButtonSmall} type="secondary">SHOP NOW</Button>
                    </div>
                </div>
            </div>
        </Components.PhCard>
    )
}

export default CrossSellBanner;