import React from 'react';
import ReactDOM from 'react-dom';
import MiniProtocol from '../PHProtocol/MiniProtocol/MiniProtocol';

function SuggestMD03() {
    return ReactDOM.createPortal(
        // Any valid React child: it could be a JSX expression, a string, etc.
        <><MiniProtocol /></>,
        // A DOM element into which the portal should be rendered.
        document.getElementById('miniProtocol')
    );
}

export default SuggestMD03