import { Checkbox, Button, Typography } from 'antd';
import style from './PHIncrementSelectButton.module.scss';
import {
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';

const { Text } = Typography

function PHIncrementSelectButton({ productQuantity, handleOnMinusClick, handleOnPlusClick, handleCheckbox, included = false, className = '' }) {
    return (
        <>
            {(productQuantity > 0 || included) && (
                <IncrementDecrementButton
                    productQuantity={productQuantity}
                    onMinusClick={handleOnMinusClick}
                    onPlusClick={handleOnPlusClick}
                    included={included}
                    className={className}
                />
            )}

            <Checkbox className={`${style.checkbox} ${className}`} checked={productQuantity} onClick={handleCheckbox} />
        </>
    )
}

export default PHIncrementSelectButton

const IncrementDecrementButton = ({ productQuantity, onMinusClick, onPlusClick, included, className }) => {
    return (
        <div className={`${style.incrementButton} ${className}`}>
            {included && productQuantity < 1 && (
                <div className={style.included}>1 INCLUDED</div>

            )}
            {productQuantity > 0 && (
                <>
                    <Button
                        type="primary"
                        icon={<MinusOutlined className={style.buttonIcon} />}
                        className={style.minusButton}
                        onClick={(event) => {
                            event.stopPropagation()
                            onMinusClick()
                        }}
                    />
                    <Text className={style.count}>{productQuantity}</Text>
                    <Button
                        type="primary"
                        icon={<PlusOutlined className={style.buttonIcon} />}
                        className={style.plusButton}
                        onClick={(event) => {
                            event.stopPropagation()
                            onPlusClick()
                        }}
                    />
                </>
            )}

        </div>
    )
}