import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import {Table} from "antd";
import {dateFormats} from "@ph/common/global/Constants";
import {getBatchList} from "../AdminPortalAPIService";
import {paginationDef} from "../LabPortalConstants";
import LabPortalTopSectionContainer from "../LabPortalTopSectionContainer/LabPortalTopSectionContainer";
import { BATCH_STATUS_LABELS } from '@ph/common/constants/BatchConstants';

function CancelledBatches() {
    const [fetchingCancelledBatches, setFetchingCancelledBatches] = useState(true);
    const [cancelledBatches, setCancelledBatches] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [kitCodeFilter, setKitCodeFilter] = useState('');
    const [startAndEndDate, setStartAndEndDate] = useState(null);

    const { pageSize } = paginationDef,
        pagination = {
            ...paginationDef,
            current: currentPage,
            total: totalCount,
            onChange: (page) => setCurrentPage(page)
        };

    const timeFilterChangedEventHandler = (startAndEndDate) => {
        setCurrentPage(1);
        setStartAndEndDate(startAndEndDate);
    };

    const handleSearchFilterChange = (kitCodeFilter) => {
        setCurrentPage(1);
        setKitCodeFilter(kitCodeFilter);
    };

    const cancelledBatchColList = [
        {
            title: 'Batch Code',
            dataIndex: 'batch_code',
            key: 'batch_code'
        }, {
            title: 'Kit / Samples',
            key: 'kit_count',
            render: ({kit_count, sample_count}) => {
                return `${kit_count} / ${sample_count}`
            }
        }, {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by'
        }, {
            title: 'Last Activity',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (d) => d ? moment(d).format(dateFormats.DDSPCMMMCOMSPCAPOSYYSPCHHMMSS) : "-"
        }, {
            title: 'Cancelled By',
            dataIndex: 'batch_history_status',
            key: 'batch_history_status',
            render: (d) => {
                if (d.length) {
                    return <div>{d[0].created_by}</div>
                }
                return '';
            }
        }, {
            title: 'Cancelled Stage',
            dataIndex: 'batch_history_status',
            key: 'batch_history_status',
            render: (d) => {
                if(d.length) {
                    return BATCH_STATUS_LABELS[d[1].status];
                }
                return '';
            }
        }];

    useEffect(() => {
        setFetchingCancelledBatches(true);
        getBatchList('cancelled', {
            offset: (currentPage - 1) * pageSize,
            limit: pageSize,
            kitCodeFilter,
            startAndEndDate,
        }).then(res => {
            if (res && res.data && res.data.batch_list && res.data.batch_list.length) {
                setCancelledBatches(res.data.batch_list);
                setTotalCount(res.data.count)
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => setFetchingCancelledBatches(false));
    }, [currentPage, kitCodeFilter, startAndEndDate]);

    return (
        <>
            <LabPortalTopSectionContainer
                timeFilterChangedEventHandler={timeFilterChangedEventHandler}
                searchFilterChangedEventHandler={handleSearchFilterChange}
                searchPlaceholder={"Search Batch Code"}
            />

            <Table
                loading={fetchingCancelledBatches}
                columns={cancelledBatchColList}
                dataSource={cancelledBatches}
                pagination={pagination}
                className='lab-portal-table'
            />
        </>
    );
}

export default CancelledBatches;

// Sample Date & Samples are compulsory - Then valid. If one of them is missing, make it as on-hold
// Oil strip is optional
// Comments optional