import { useCallback, useEffect, useState } from 'react';
import ServiceDashboardTopSectionContainer from '../ServiceDashboardTopSectionContainer/ServiceDashboardTopSectionContainer';
import { Col, Row, Typography, Button, InputNumber } from "antd";
import style from "./GenerateKits.module.scss";
import { getKitTypes, generateKitCodes } from "../ServiceDashboardAPIService";
import { handleErrorMsg, handleSuccessMsg } from '@ph/common/global/Helpers';
import Components from "@ph/common/components";
import ConfirmationModal from 'src/component/AdminLabPortal/ValidateKitFormContent/ConfirmationModal';
import { useOutletContext } from "react-router-dom";
const { Text } = Typography;

function GenerateKits() {

    const [refreshSideBarCount, setRefreshSideBarCount, kitTypeList] = useOutletContext();
    const [kitType, setKitType] = useState(null)
    const [kitAmount, setKitAmount] = useState(1)
    const [kitTypes, setKitTypes] = useState([])
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

    const onChange = useCallback((value) => {
        setKitAmount(value)
    }, [])

    const selectOnChangeHandler = useCallback((value) => {
        setKitType(value)
    }, [])

    const resetForm = useCallback(() => {
        setKitType(null)
        setKitAmount(1)
        setOpenConfirmationModal(false)
    }, [])

    const handleKitGenerate = useCallback(() => {
        const requestBody = {
            kit_type_id: kitType,
            num_codes: kitAmount
        }
        generateKitCodes(requestBody)
            .then(() => {
                handleSuccessMsg(`${kitAmount} - ${kitType} TYPE Kit Codes are generated!`)
                resetForm()
            })
            .catch(error => handleErrorMsg(error, "Unable to generate kit codes,Please try again!"))
    }, [kitType, kitAmount])

    const updateKitTypes = () => {
        const resOptions = []
        kitTypeList.map(kitType => resOptions.push({ ...kitType, label: `${kitType.id} Type (${kitType.name})` }))
        setKitTypes(resOptions)
    }

    useEffect(() => {
        if (kitTypeList) {
            updateKitTypes()
        }
    }, [kitTypeList])

    useEffect(() => {
        localStorage.setItem('currentNavLabel', "Generate Kits");
    }, []);


    return (
        <div><ServiceDashboardTopSectionContainer />
            <Row gutter={20} align="center">
                <Col span={8}>
                    <Text> KIT TYPE</Text>
                    <Components.CustomSelect placeholder="Select a kit type" onChangeHandler={selectOnChangeHandler} options={kitTypes} selected={kitType} />
                </Col>
                <Col span={8}>
                    <Text>NUMBER OF KIT CODES TO BE GENERATED</Text>

                    <InputNumber size="large" min={1} max={100000} defaultValue={1} onChange={onChange} className={style.phNumberInput} />
                </Col>
                <Col span={8} mt={2}>
                    <Button type="primary" className={`ph-btn ${style.genarteButton}`} disabled={!kitType} onClick={() => setOpenConfirmationModal(true)}>GENERATE</Button>
                </Col>
            </Row>
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                closeConfirmationModal={() => setOpenConfirmationModal(false)}
                confirmationMsg={`Are you sure you want to generate ${kitAmount} - ${kitType} TYPE Kit Codes?`}
                cancelBtnText={"NO, GO BACK"}
                cancelBtnAction={() => setOpenConfirmationModal(false)}
                proceedBtnText={"YES, GO AHEAD"}
                proceedBtnAction={() => handleKitGenerate()}
                closable={true}
                modalTop='30%'
            />
        </div>
    )
}

export default GenerateKits