import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Col, Row, Typography, Tooltip, message, Space } from "antd";
import OtpInput from "react18-input-otp";
import style from './CreateBatch.module.scss';
import ControlsAndKitsList from "./ControlsAndKitsList/ControlsAndKitsList";
import PCRWells from "./PCRWells/PCRWells";
import { addBatchHistory, addControlToBatch, addKitToBatch, deleteControlFromBatch, deleteKitFromBatch, getBatchCSVData, getBatchDetails, skipBatchCell } from '../AdminPortalAPIService';
import { getFriendlyKitCode, handleErrorMsg } from '@ph/common/global/Helpers';
import { BATCH_CONTROL_ALLOWED, BATCH_EDIT_ALLOWED, BATCH_SKIPCELL_ALLOWED, BATCH_STATUS } from '@ph/common/constants/BatchConstants';
import moment from "moment";
import { dateFormats } from "@ph/common/global/Constants";
import ConfirmationModal from '../ValidateKitFormContent/ConfirmationModal';

const { Text } = Typography;

const RES_CONST = {
    SKIPPED: 'skipped',
    CONTROLS: 'controls'
}


const intialCellData = {
    isSkipped: false,
    isControl: false,
    cellNo: '',
    cellRow: 1,
    cellCol: 1,
    cellLabel: '',
    cellComponent: '',
    cellBgColorClass: '',
    tableLabel: '',
    key: ''
}

const CONFIRMATION_MSG = {
    CANCEL: <>
        Are you sure you want to <span className='medium-text'>CANCEL this Batch?</span>
        <br />
        Doing so will remove all the kits from this batch.
    </>,
    REMOVE_KIT: <>
        Are you sure you want to <span className='medium-text'>REMOVE THIS KIT </span> from the batch?
        <br />
        <span className='text-italic'>
            Removing the kit will skip the cells on the batch map.
        </span>
    </>,
    REALOCATE: "Do you want to reallocate the swab cell to the next available slot?"
}

const getControlLabel = (input) => {
    if (input.length === 4) {
        const CONTROL = { R: "Reagent", W: 'Water' }
        return `${input[0]}${input[1]} ${CONTROL[input[2]]} ${input[3]}`
    }
    return ''
}

const ROWS = " ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");


function CreateBatch({ batchId, createNew = false, closeModal, currBatchStatus = BATCH_STATUS.DRAFT, cancelBatchHandler }) {
    const [code, setCode] = useState("");
    const [disableAddToBatch, setDisableAddToBatch] = useState(true);
    const [swabsData, setSwabsData] = useState([])
    const [batchData, setBatchData] = useState({})
    const [batchDetails, setBatchDetails] = useState({})
    const [selectedCell, setSelectedCell] = useState('')
    const [isEmptyCellSelected, setEmptyCellSelected] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [confirmationMsg, setConfirmationMsg] = useState('')
    const [cancelBtnText, setCancelBtnText] = useState('')
    const [cancelBtnAction, setCancelBtnAction] = useState(null)
    const [proceedBtnText, setProceedBtnText] = useState('')
    const [proceedBtnAction, setProceedBtnAction] = useState(null)
    const [autoSaveTime, setAutoSaveTime] = useState('')


    useEffect(() => {
        if (batchData && selectedCell in batchData) {
            if (batchData[selectedCell].isSkipped) {
                setEmptyCellSelected(false)
            } else {
                setEmptyCellSelected(true)
            }
        } else {
            setEmptyCellSelected(true)
        }
    }, [selectedCell])

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false)
    }

    const handleInputChange = (input) => {
        setCode(input);
        if (input && input.toString().length === 7) {
            setDisableAddToBatch(false);
        } else {
            setDisableAddToBatch(true);
        }
    };


    const handleAddKitClick = () => {
        if (code) {
            addKitToBatch(batchId, { kitcode: code.toUpperCase() })
                .then(batchResHandler)
                .catch(error => {
                    handleErrorMsg(error, "Unable to Add this Kit!")
                    setCode('')
                })
        }
    }

    const handleRemoveKit = (record) => {
        if (record) {
            if (currBatchStatus === BATCH_STATUS.DRAFT) {
                deleteKitFromBatch(batchId, record.tableLabel, record.kitTypeName)
                    .then(batchResHandler)
                    .catch(error => handleErrorMsg(error, "Unable to Remove this Kit!"))
            } else {
                setOpenConfirmationModal(true)
                setConfirmationMsg(CONFIRMATION_MSG.REMOVE_KIT)
                setCancelBtnText("No,Go Back")
                setProceedBtnText("YES, REMOVE KIT")
                setProceedBtnAction(() => {
                    return () => {
                        deleteKitFromBatch(batchId, record.tableLabel, record.kitTypeName)
                            .then(batchResHandler)
                            .catch(error => handleErrorMsg(error, "Unable to Remove this Kit!"))
                            .finally(() => closeConfirmationModal())
                    }
                });
                setCancelBtnAction(() => {
                    return () => {
                        closeConfirmationModal();
                    }
                });
            }

        }
    }

    const handleRemoveControl = (record) => {
        if (record) {
            deleteControlFromBatch(batchId, `${ROWS[record.cellRow]}${record.cellCol}`, record.key)
                .then(batchResHandler)
                .catch(error => handleErrorMsg(error, "Unable to Remove this Control!"))
        }
    }

    const handleSkipPostionClick = () => {
        if (selectedCell) {
            if (batchData && selectedCell in batchData) {
                let payload = {
                    cell_position: selectedCell
                }
                if (batchData[selectedCell].isControl) {
                    payload = {
                        ...payload,
                        control_name: batchData[selectedCell].key
                    }
                }
                if (batchData[selectedCell].kitTypeName) {
                    payload = {
                        ...payload,
                        kitcode: batchData[selectedCell].tableLabel,
                        kit_type_name: batchData[selectedCell].kitTypeName,
                        swab_type: batchData[selectedCell].cellLabel
                    }
                }
                setOpenConfirmationModal(true)
                setConfirmationMsg(CONFIRMATION_MSG.REALOCATE)
                setCancelBtnText("JUST SKIP CELL")
                setProceedBtnText("REALLOCATE TO NEXT SLOT")
                setProceedBtnAction(() => {
                    return () => {
                        skipBatchCell(batchId, { ...payload, allocate_new_cell: true })
                            .then(batchResHandler)
                            .catch(error => handleErrorMsg(error, "Unable to Skip this position!"))
                            .finally(() => closeConfirmationModal())
                    }
                });
                setCancelBtnAction(() => {
                    return () => {
                        skipBatchCell(batchId, { ...payload })
                            .then(batchResHandler)
                            .catch(error => handleErrorMsg(error, "Unable to Skip this position!"))
                            .finally(() => closeConfirmationModal())
                    }
                });
            } else {
                skipBatchCell(batchId, { cell_position: selectedCell })
                    .then(batchResHandler)
                    .catch(error => handleErrorMsg(error, "Unable to Skip this position!"))
            }
        }
    }
    const handleAddControlReagentClick = () => {
        if (selectedCell) {
            addControlToBatch(batchId, { cell_position: selectedCell, control_type: "CTR" })
                .then(batchResHandler)
                .catch(error => handleErrorMsg(error, "Unable to add this Control,Maximum 6 controls are allowed!"))
        }
    }

    const handleAddControlWaterClick = () => {
        if (selectedCell) {
            addControlToBatch(batchId, { cell_position: selectedCell, control_type: "CTW" })
                .then(batchResHandler)
                .catch(error => handleErrorMsg(error, "Unable to add this Control,Maximum 6 controls are allowed!"))
        }
    }


    const getRowAndColumn = (inputString) => {
        return {
            row: ROWS.indexOf(inputString[0]),
            col: inputString.slice(1)
        }
    }

    const getSkipCellData = (data) => {
        const tempSkipCellData = {}
        for (const cell of data) {
            const { row, col } = getRowAndColumn(cell)
            tempSkipCellData[`${ROWS[row]}${col}`] = { ...intialCellData, cellNo: cell, cellRow: row, cellCol: col, isSkipped: true, cellComponent: SkippedCell }
        }
        return tempSkipCellData
    }

    const getControlCellData = (data) => {
        const tempControlCellData = {}
        for (const [key, value] of Object.entries(data)) {
            const { row, col } = getRowAndColumn(value)
            tempControlCellData[`${ROWS[row]}${col}`] = { ...intialCellData, cellNo: value, cellRow: row, cellCol: col, isControl: true, cellComponent: ControlCell, cellLabel: key.slice(2), tableLabel: getControlLabel(key), key: key }
        }
        return tempControlCellData
    }

    const getUniqueKitData = (kitCode, data, kitType) => {
        const tempUniqueCellData = {}
        for (const [key, value] of Object.entries(data)) {
            const { row, col } = getRowAndColumn(value)
            tempUniqueCellData[`${ROWS[row]}${col}`] = { ...intialCellData, cellNo: value, cellRow: row, cellCol: col, cellComponent: KitCell, cellLabel: key, cellBgColorClass: style[key], tableLabel: kitCode, kitTypeName: kitType }
        }
        return tempUniqueCellData
    }

    const getKitsData = (kitType, data) => {
        let tempKitsData = {}    
        for (const [key, value] of Object.entries(data)) {
            const uniqueKitData = getUniqueKitData(key, value, kitType)
            tempKitsData = { ...tempKitsData, ...uniqueKitData }
        }
        return tempKitsData
    }
    const getKitsSwabData = (kitType, data) => {
        let tempkitSwabData = []
        for (const [key, value] of Object.entries(data)) {
            const uniqueKitData = { ...value, tableLabel: key, kitTypeName: kitType }
            tempkitSwabData.push(uniqueKitData)
        }
        return tempkitSwabData
    }

    const batchResHandler = (res) => {
        if (!res && !res.data) {
            console.error("Something went wrong which fetching details")
        }
        setCode('')
        const batchDetails = res.data.batch_details
        setBatchDetails(batchDetails)
        setAutoSaveTime(batchDetails.batch_history_status[0].created_on)
        const gridData = batchDetails.tray_info.grid_data
        let tempRes = {}
        let currentSwabsData = []
        for (const [key, value] of Object.entries(gridData)) {
            if (key === RES_CONST.SKIPPED) {
                const skipCelldata = getSkipCellData(value)
                tempRes = { ...tempRes, ...skipCelldata }
            }
            else if (key === RES_CONST.CONTROLS) {
                const controlCellData = getControlCellData(value)
                tempRes = { ...tempRes, ...controlCellData }
            } else {
                const kitswabsData = getKitsSwabData(key, value)
                currentSwabsData = [...currentSwabsData, ...kitswabsData]
                const kitsData = getKitsData(key, value)
                tempRes = { ...tempRes, ...kitsData }
            }

        }
        setBatchData(tempRes)
        setSwabsData(currentSwabsData)
    }

    const handledownloadCsv = () => {
        getBatchCSVData(batchId)
            .then(res => {
                const csvData = res.data
                const blob = new Blob([csvData], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${batchId}.csv`;
                link.click();
            })
            .catch(error => console.error(error))
    };

    const handleCancelBatchModal = () => {
        setOpenConfirmationModal(true)
        setConfirmationMsg(CONFIRMATION_MSG.CANCEL)
        setCancelBtnText("YES, GO AHEAD")
        setProceedBtnText("NO, GO BACK")
        setProceedBtnAction(() => {
            return () => {
                closeConfirmationModal();
            }
        });
        setCancelBtnAction(() => {
            return () => {
                cancelBatchHandler(batchId);
                closeConfirmationModal();
            }
        });
    }

    const handleFinaliseBatch = () => {
        if (currBatchStatus === BATCH_STATUS.DRAFT) {
            addBatchHistory(batchId, { batch_history_status: BATCH_STATUS.CREATED })
                .then(res => {
                    closeModal();
                })
                .catch(error => handleErrorMsg(error, "Failed to update status!"))
        } else {
            closeModal();
        }
    };

    useEffect(() => {
        if (batchId) {
            getBatchDetails(batchId)
                .then(batchResHandler)
                .catch(error => console.error(error))
        }
    }, [batchId])

    return (
        <>
            <Row>
                <Col md={8} lg={8} xl={8}>

                    <Space size="small" direction="vertical" style={{ marginTop: '12px', marginBottom: '8px', width: '100%' }}>
                        <Text className='body-txt-lg'>Batch Code : {batchId}</Text>
                        <Text className='h2'>{createNew ? "Create Batch" : BATCH_EDIT_ALLOWED[currBatchStatus] ? "Edit Batch Plate" : "Batch Plate"}</Text>
                        {currBatchStatus !== BATCH_STATUS.DRAFT && (
                            <Text className="body-txt-lg"><span className='medium-text'>Created By: </span>  {batchDetails?.created_by}</Text>
                        )}
                    </Space>



                    {currBatchStatus === BATCH_STATUS.DRAFT && (
                    <div>
                        <p>
                            <Text className='body-txt-lg'>
                                Scan or enter the kit codes to be added
                            </Text>
                        </p>
                        <OtpInput
                            value={code}
                            onChange={handleInputChange}
                            numInputs={7}
                            separator={<span style={{ width: "8px" }}></span>}
                            isInputNum={false}
                            className='otp-input opt-input-small'
                            shouldAutoFocus={true}
                            focusStyle={{
                                border: "1px solid #CFD3DB",
                                outline: "none"
                            }}
                        />
                    </div>
                    )}
                    <br/>
                    <div className='clearfix'>
                        <div className='float-left'>
                            <Text className='body-txt-lg'>
                                <b>{batchDetails?.sample_count}/90 samples added | {batchDetails?.control_count}/6 controls added</b>
                            </Text>
                        </div>
                        {currBatchStatus === BATCH_STATUS.DRAFT && (
                        <div className='float-right'>
                            <Button type='primary' disabled={disableAddToBatch} onClick={handleAddKitClick} className="ph-btn" >Add to Batch</Button>
                        </div>
                        )}
                    </div>
                    <br />
                    <ControlsAndKitsList Text={Text} currBatchStatus={currBatchStatus} batchData={batchData} swabsData={swabsData} handleRemoveKit={handleRemoveKit} handleRemoveControl={handleRemoveControl} />
                </Col>
                <Col md={16} lg={16} xl={16}
                     style={{padding: '8px 30px 30px'}}>
                    <Card className={style.pcrLayoutContainer}>
                        <div className='clearfix'>
                            {BATCH_SKIPCELL_ALLOWED[currBatchStatus] && (
                            <div className='float-left'>
                                <Button className="ph-btn" disabled={!isEmptyCellSelected} onClick={handleSkipPostionClick} >Skip Position</Button>
                            </div>
                            )}
                            {BATCH_CONTROL_ALLOWED[currBatchStatus] && (
                            <div className='float-right'>
                                <Button type='primary' className="ph-btn" disabled={!isEmptyCellSelected} onClick={handleAddControlReagentClick} > Add Reagent Control</Button> &nbsp;
                                <Button type='primary' className="ph-btn" disabled={!isEmptyCellSelected} onClick={handleAddControlWaterClick} >Add Water Control</Button>
                            </div>
                            )}
                        </div>
                        <PCRWells batchData={batchData} setSelectedCell={setSelectedCell} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md={9} lg={9} xl={9}> </Col>
                <Col md={15} lg={15} xl={15} style={{ marginLeft: '-32px' }}>
                    <Row type="flex" justify="space-between" align="middle" >
                        <Space size="middle">
                            {currBatchStatus !== BATCH_STATUS.SERUM_DETERMINATION && (
                            <Text className="body-txt-lg td-underline hand-cursor" onClick={handleCancelBatchModal} >
                                CANCEL BATCH
                            </Text>
                            )}
                            <Text className="body-txt-lg td-underline hand-cursor" onClick={handledownloadCsv} >
                                DOWNLOAD CSV
                            </Text>
                        </Space>
                        {BATCH_SKIPCELL_ALLOWED[currBatchStatus] && currBatchStatus === BATCH_STATUS.DRAFT && (
                        <Space size="middle">
                                <Text className="body-txt-md"> Auto Saved {moment(autoSaveTime, "ddd, DD MMM YYYY HH:mm:ss [GMT]").fromNow()}</Text>
                            <Button key="submit" type="primary" className="ph-btn" onClick={handleFinaliseBatch}>
                                {currBatchStatus === BATCH_STATUS.DRAFT ? "FINALISE" : "SAVE CHANGES"}
                            </Button>
                        </Space>
                        )}
                    </Row>
                </Col>
            </Row>
            <ConfirmationModal
                openConfirmationModal={openConfirmationModal}
                closeConfirmationModal={closeConfirmationModal}
                confirmationMsg={confirmationMsg}
                cancelBtnText={cancelBtnText}
                cancelBtnAction={cancelBtnAction}
                proceedBtnText={proceedBtnText}
                proceedBtnAction={proceedBtnAction}
                closable={true}
            />

        </>
    );
}

export default CreateBatch;



const ControlCell = ({ cellLabel, CTRLabel = "CT" }) => {
    return (
        <div className={`${style.kitCell} ${style.controls}`}>
            <svg className={style.profilePicContainer} viewBox="0 0 134 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.6711 1.5H37.6721H95.9943V0L95.9952 1.5C97.2283 1.49921 98.4399 1.82308 99.5079 2.43896C100.576 3.05484 101.463 3.94098 102.079 5.00814L131.226 55.4874L131.226 55.4877C131.842 56.5541 132.166 57.7639 132.166 58.9953C132.166 60.2267 131.842 61.4364 131.226 62.5029L131.226 62.503L102.07 112.992C101.453 114.059 100.566 114.945 99.4984 115.561C98.4304 116.177 97.2189 116.501 95.9858 116.5H95.9848H37.6633C36.4315 116.499 35.2216 116.175 34.1551 115.559C33.0886 114.943 32.2031 114.058 31.5874 112.992C31.5873 112.992 31.5873 112.992 31.5872 112.992L2.4405 62.5032L2.44031 62.5029C1.82428 61.4364 1.5 60.2267 1.5 58.9953C1.5 57.7639 1.82428 56.5541 2.44031 55.4877L2.44044 55.4874L31.5872 5.00814C31.5872 5.00807 31.5873 5.008 31.5873 5.00793C32.2037 3.94086 33.0905 3.0548 34.1584 2.43896C35.2265 1.82308 36.438 1.49921 37.6711 1.5Z" fill="#E18672" />
                <text x="50%" y="48%" dominantBaseline="top" textAnchor="middle" fill="#2D2A26" className={style.profileText} >
                    {CTRLabel}
                </text>
                <text x="50%" y="76%" dominantBaseline="bottom" textAnchor="middle" fill="#2D2A26" className={style.profileText} style={{ fontWeight: '600' }}>
                    {cellLabel}
                </text>
            </svg>
        </div>
    )
}

const SkippedCell = () => {
    return (
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" className={style.profilePicContainer}>
            <rect x="8.3916" y="28.5342" width="28.4853" height="4.74756" transform="rotate(-45 8.3916 28.5342)" fill="#BE123C" />
            <rect x="11.751" y="8.3916" width="28.4853" height="4.74756" transform="rotate(45 11.751 8.3916)" fill="#BE123C" />
        </svg>

    )
}

const KitCell = ({ cellLabel, cellBgColorClass = "", tableLabel = "" }) => {
    return (

        <div className={`${style.kitCell} ${cellBgColorClass}`}>
            <Tooltip placement="top" title={`Kit : ${getFriendlyKitCode(tableLabel)}`} zIndex={999999999}>
                <span className='h4'> {cellLabel} </span>
            </Tooltip>
        </div>

    )
}