import React from 'react';
import style from "../Report.module.scss";
import {Button, Col, Divider, Row, Space, Tooltip} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import Components from "@ph/common/components";
import {PH_cheek_emo, PH_forehead_emo, PH_forehead_emo_dark} from "@ph/common/assets";
import ReadMoreDescContainer from "../ReadMoreDescContainer";
import {navigateToShopifyUrl} from "../reportUtils";
import { button_arrow } from '@ph/common/assets/index';

function SkinAgeAndHydrationLevelCard({Text, Paragraph, reportData, recommendedProduct}) {

    const skinHydrationRangeMin = '1';
    const skinHydrationRangeMax = '10';

    return (
        <div className={style.box + " " + style.fullWidthTile}>
            <Row gutter={[20]} justify="space-between" align="start">
                <Col xs={24} sm={24} md={24} xl={12}>
                    <div className={style.fullWidthTile1}>
                        <div className={style.leftAlignmentMobile}
                             style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Text className="h4" style={{ paddingRight: '22px' }}>
                                Your Skin Microbiome <strong>AGE</strong></Text>
                            <Tooltip title="Comparing your skin against a large, diverse pool in our database, your listed age range is a reflection of a variety of factors, including your microbial diversity, hydration level, and balance of “good” vs “bad” bacteria."
                                     color={"#2d2a26"}
                                overlayInnerStyle={{ color: '#fff', padding: '16px' }}
                                     placement="topRight"
                                     className={style.showInSmallScreen}
                                     trigger="click">
                                <ExclamationCircleOutlined key="microbiomeAge" className={style.tooltipIcon} />
                            </Tooltip>
                            <Tooltip title="Comparing your skin against a large, diverse pool in our database, your listed age range is a reflection of a variety of factors, including your microbial diversity, hydration level, and balance of “good” vs “bad” bacteria."
                                color={"#2d2a26"} overlayInnerStyle={{ color: '#fff', padding: '16px' }}
                                     className={style.showInBigScreen}
                                     trigger="hover">
                                <ExclamationCircleOutlined key="microbiomeAge" className={style.tooltipIcon} />
                            </Tooltip>
                        </div>
                        <div className={style.ageDisplayGrid}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'center' }}>
                                <div className={style.ageDisplayBlock1}>
                                    <Text style={{ letterSpacing: '-3px' }} className={style.h1Text + " " + style.quincyText}>{reportData.skin_age && reportData.skin_age}</Text>
                                </div>
                                <Text className={`note-1 font-Mono-regular`}>CURRENT SKIN <span className='font-Mono-medium'>MICROBIOME AGE</span></Text>
                            </div>
                            <div></div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'center' }}>
                                <div className={style.ageDisplayBlock2}>
                                    <Text style={{ color: '#2E5ACC', letterSpacing: '-3px' }} className={style.h1Text + " " + style.quincyText}>{reportData.real_skin_age && Math.floor(Number(reportData.real_skin_age))}</Text>
                                </div>
                                <Text className={`note-1 font-Mono-regular`}>SKIN <span className='font-Mono-medium'>REAL AGE</span></Text>
                            </div>
                        </div>
                    </div>
                    <div className={style.showInSmallScreen} style={{ margin: '15px 0' }} />
                </Col>
                <Col xs={24} sm={24} md={24} xl={12} className={style.leftCol}>
                    <Text className={style.bodyText1} style={{textAlign: "justify"}}>
                        Your skin microbiome age is not static and can change due to environmental factors, such as stress, weather, diet, or skin care regimens. If your skin age is lower than your real age, good job! Continued maintenance will further help you age better. But don’t worry if your microbiome age is not where you want it to be right now. We’re here to help.
                        <br/><br/>
                        Scroll below to find recommendations on how to best optimize your skin health, leading to an improved skin microbiome age.
                    </Text>
                </Col>
            </Row>

            <Divider type="horizontal" className={style.horizontalseparator + " " + style.boxMargin} />

            <Row className={`${style.box} ${style.hydrationBox}`} gutter={[20]}>
                <Col xs={24} sm={24} md={24} xl={12}>
                    <div className={style.fullWidthTile1}>
                        <Row>
                            <Col className={style.leftAlignmentMobile}
                                 style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ order: 1, span: 24 }} lg={{ order: 1, span: 24 }} xl={{ order: 1, span: 24 }}>
                                <Text className="h4" style={{ paddingRight: '22px' }}>Hydration <strong>LEVEL</strong></Text>
                                <Tooltip title="Your hydration level is a reflection of how oily vs dry your skin is. An ideal level is in the mid to upper range (~7-8 out of 10). Your cheek and forehead can sometimes have drastically different levels."
                                    color={"#2d2a26"} overlayInnerStyle={{ color: '#fff', padding: '16px' }}
                                         placement="topRight"
                                         className={style.showInSmallScreen}
                                         trigger="click">
                                    <ExclamationCircleOutlined key="hydrationLevel" className={style.tooltipIcon} />
                                </Tooltip>
                                <Tooltip title="Your hydration level is a reflection of how oily vs dry your skin is. An ideal level is in the mid to upper range (~7-8 out of 10). Your cheek and forehead can sometimes have drastically different levels."
                                    color={"#2d2a26"} overlayInnerStyle={{ color: '#fff', padding: '16px' }}
                                         className={style.showInBigScreen}
                                         trigger="hover">
                                    <ExclamationCircleOutlined key="hydrationLevel" className={style.tooltipIcon} />
                                </Tooltip>
                            </Col>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ order: 3, span: 24 }} lg={{ order: 3, span: 24 }} xl={{ order: 3, span: 24 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Components.HydrationRangeSlider
                                        rangeMax={skinHydrationRangeMax}
                                        rangeMin={skinHydrationRangeMin}
                                        cheekValue={reportData.cheek_skin_hydration && Number(reportData.cheek_skin_hydration) !== -1 ? Math.round(Number(reportData.cheek_skin_hydration)) : null}
                                        foreheadValue={reportData.forehead_skin_hydration && Number(reportData.forehead_skin_hydration) !== -1 ? Math.round(Number(reportData.forehead_skin_hydration)) : null}
                                    />
                                    <div className={style.emoSection}>
                                        {(reportData.cheek_skin_hydration) && (
                                            Math.round(Number(reportData.cheek_skin_hydration)) === Math.round(Number(reportData.forehead_skin_hydration)) ?
                                                ((reportData.forehead_skin_hydration && Number(reportData.forehead_skin_hydration) !== -1) || (Number(reportData.cheek_skin_hydration) !== -1)) &&
                                                <Space>
                                                    <img src={PH_forehead_emo_dark} className={style.emoIcon} />
                                                        <div style={{ background: '#BAB9B8', color: '#fff' }} className={style.emoText}>
                                                            Cheek: <span className='font-Neue-medium'>{reportData.cheek_skin_hydration}</span></div>
                                                </Space>
                                                :
                                                ((reportData.forehead_skin_hydration && Number(reportData.forehead_skin_hydration) !== -1) || (Number(reportData.cheek_skin_hydration) !== -1)) &&
                                                <Space>
                                                    <img src={PH_cheek_emo} className={style.emoIcon} />
                                                        <div style={{ background: '#BAB9B8', color: '#fff' }} className={style.emoText}>
                                                            Cheek: <span className='font-Neue-medium'>{Number(reportData.cheek_skin_hydration) !== -1 ? reportData.cheek_skin_hydration : 'NA'}</span></div>
                                                </Space>
                                        )}
                                        {(reportData.forehead_skin_hydration &&
                                            ((reportData.cheek_skin_hydration && Number(reportData.cheek_skin_hydration) !== -1) || (Number(reportData.forehead_skin_hydration) !== -1)) &&
                                            <Space>
                                                <img src={PH_forehead_emo} className={style.emoIcon} />
                                                <div style={{ background: '#767472', color: '#fff' }} className={style.emoText}>
                                                    Forehead: <span className='font-Neue-medium'>{Number(reportData.forehead_skin_hydration) !== -1 ? reportData.forehead_skin_hydration : 'NA'}</span></div>
                                            </Space>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                {recommendedProduct &&
                    <Col xs={24} sm={24} md={24} xl={12}
                         className={style.leftCol}>
                        <div className={style.tileContent}>
                            {recommendedProduct.image_url ?
                                <img src={recommendedProduct.image_url}
                                     className={style.tileImage + " " + style.pointContent}
                                     onClick={() => navigateToShopifyUrl(recommendedProduct)} alt='' />
                                : <img className={style.placeHolderImage} alt='' />
                            }
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
                                gap: '15px', position: 'relative' }}>
                                <span>
                                    <Text className={style.bodyText1}>RECOMMENDATION:</Text><br />
                                    <Paragraph className={`${style.bodyText1} ${style.pointContent}`}
                                               ellipsis={{ rows: 2 }}
                                               onClick={() => navigateToShopifyUrl(recommendedProduct)}>
                                        <strong>{recommendedProduct.name || ''}</strong>
                                    </Paragraph>
                                </span>
                                <div className={style.descContainer}>
                                    {recommendedProduct.finalDescription &&
                                        <ReadMoreDescContainer Paragraph={Paragraph}
                                                               productId={recommendedProduct.product_id}
                                                               productName={recommendedProduct.name}
                                                               description={recommendedProduct.finalDescription} />
                                    }
                                </div>
                                <Button type="primary" className={`${style.button} ${style.preOrder}`}
                                        onClick={() => navigateToShopifyUrl(recommendedProduct)}>
                                    Buy Now
                                    <Divider type="vertical"
                                        style={{ background: '#fff', height: '70%', margin: '0 10px' }} />
                                    ${recommendedProduct.price}
                                    <img src={button_arrow} className='ph-btn-arrow' />
                                </Button>
                            </div>
                        </div>
                    </Col>
                }
            </Row>
        </div>
    );
}

export default SkinAgeAndHydrationLevelCard;