
export const ALL_KIT_STATUS = [
    {
        value: '',
        label: 'All Kit Status'
    },
    {
        value: 'unassigned',
        label: 'Unassigned'
    },
    {
        value: 'assigned',
        label: 'Assigned'
    },
    {
        value: 'registered',
        label: 'Registered'
    },
    {
        value: 'received',
        label: 'Received'
    },
    {
        value: 'valid',
        label: 'Valid'
    },
    {
        value: 'on_hold',
        label: 'On hold'
    },
    {
        value: 'rejected',
        label: 'Rejected'
    },
    {
        value: 'processed',
        label: 'Processed'
    },
    {
        value: 'shipped',
        label: 'Shipped'
    },
    {
        value: 'sequenced',
        label: 'Sequenced'
    },
    {
        value: 'completed',
        label: 'Completed'
    }
]

export const ALL_KITS_TYPE = {
    value: '',
    label: 'All Kit Type'
}

