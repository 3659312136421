import React, { useState, useEffect } from 'react'
import {Typography, Row, Col, Steps, Divider, message, Spin} from 'antd';
import {CheckCircleFilled, ExclamationCircleFilled, WarningFilled} from '@ant-design/icons';
import moment from 'moment';
import Components from "@ph/common/components";
import {dateFormats} from "@ph/common/global/Constants";
import style from './kitStatus.module.scss';
import KitStatusInfo from "@ph/common/components/KitStatus/KitStatusInfo";
import {
    awaiting_swabs,
    awaiting_swabs_active,
    swab_received,
    microbesSequencing,
    reportsReady,
    awaiting_swabs_done,
    swab_received_active,
    swab_received_done,
    microbesSequencing_active,
    microbesSequencing_done,
    reportsReady_active,
    reportsReady_done
} from "@ph/common/assets";
import {getFriendlyKitCode} from "@ph/common/global/Helpers";
import FindYourKit from './FindYourKit';

const { Text } = Typography;

const HEALTH_PROFILE_STATUS = {
    COMPLETED : "COMPLETED",
    IN_PROGRESS : "IN_PROGRESS",
    REQUIRED: "REQUIRED",
    NOT_REQUIRED: "NOT_REQUIRED"
}

const KitStatus = ({connectionInstance}) => {
    const [fetchingKitStatusList, setFetchingKitStatusList] = useState(true);
    const [kitStatusList, setKitStatusList] = useState([]);
    const [showKitResult, setShowKitResult] = useState(true);
    const statusIconsList = [{
        src: awaiting_swabs,
        description: "Awaiting your swabs",
        incomplete: true,
    }, {
        src: swab_received,
        description: "Processing swabs"
    }, {
        src: microbesSequencing,
        description: "Sequencing microbes"
    }, {
        src: reportsReady,
        description: "Processing bioinformatics"
    }];
    let kitStatusContent = "";

    let verticalLines = {
        icon:(<Divider className={style.verticalDivider} type="vertical" />),
        description:""
    };
    let horizontalLines = {
        icon:(<Divider className={style.horizontalDivider} type="horizontal" />),
        description:""
    };
    let statusIconObj = {
        icon: (<img alt='' className={style.stepIcon} src={awaiting_swabs} />),
        description: "",
        className: "status-icon-container"
    };

    function itemsBuilder(iconsList, forHorizontalTracker) {
        let length = iconsList.length + ((iconsList.length - 1) * 2);
        let trackerLines = horizontalLines;
        if(forHorizontalTracker) {
            length = ((iconsList.length + 1) * 3) + iconsList.length;
            trackerLines = verticalLines;
        }
        return Array.from({ length }, itemsBuilderMapper.bind(this, iconsList, forHorizontalTracker, trackerLines));
    }

    function itemsBuilderMapper(iconsList, forHorizontalTracker, trackerLines, _, i) {
        let next = (i + 1), setIcon = (i % 3 === 0), iconIdx = i / 3;
        if (forHorizontalTracker) {
            iconIdx = (next / 4) - 1;
            setIcon = (next % 4 === 0);
        }
        if (setIcon) {
            const {src,incomplete, description, completed} = {...iconsList[iconIdx]};
            let iconObj = {...statusIconObj};
            iconObj.icon = (<img alt='' className={style.stepIcon} src={src}/>);
            iconObj.description = description;
            if(iconIdx === 0) {
                iconObj.className += ` first-icon`;
            }
            if (completed) {
                let checkedIcon = <CheckCircleFilled className='status-completed' style={{ color: "#2E5ACC" }} />;
                iconObj.description = (forHorizontalTracker) ?
                    (<>{description}<br/>{checkedIcon}</>) :
                    (<>{description} {checkedIcon} </>)
            }
            if(incomplete) {
                let incompleteIcon = <ExclamationCircleFilled className='status-completed' style={{ color: "#FF677B " }} />
                iconObj.description = (forHorizontalTracker) ?
                    (<>{description}<br/>{incompleteIcon}</>) :
                    (<>{description} {incompleteIcon} </>)

            }
            return {...iconObj};
        }
        return {...trackerLines};
    }

    const getAllKitsIDs = (data) => {
        return data.map(item => item.kitcode);
    }

    const getKitStatus = async () => {
        setFetchingKitStatusList(true);
        // let baseUrl = process.env.REACT_APP_API_URL.replace('.dev', '.qa');
        const response =  await connectionInstance.get(
            `${process.env.REACT_APP_API_URL}/kit/all`
        );
        const healthKitInfo = await fetchAllHealthProfileDetails(getAllKitsIDs(response.data))
        let finalData = response.data
        finalData.forEach(kitData => {
            const currHealthProfileInfo = healthKitInfo.filter(hData => hData.data.kitcode === kitData.kitcode)
            if(currHealthProfileInfo) {
                const currentHealthProfileData = currHealthProfileInfo[0].data
                kitData["healthprofile_jotform_url"] = currentHealthProfileData.jotform_url;
                kitData["healthprofile_status"] = currentHealthProfileData.status;
            }
        });
        response.data = finalData
        
        return response
    };

    const getHealthProfileStatus = async (kitcode) => {
        // let baseUrl = process.env.REACT_APP_API_URL.replace('.dev', '.qa');
        const res = await connectionInstance.get(
            `${process.env.REACT_APP_API_URL}/healthprofile/${kitcode}`
        );
        const tempres = res.data
        if(res && res.data){
            tempres["kitcode"] = kitcode
        }
        res.data = tempres
        return res
    };

    const fetchAllHealthProfileDetails = async (kitcodes) => {
        const promises = kitcodes.map(kitcode => getHealthProfileStatus(kitcode));
        const kitResults = await Promise.all(promises);
        return kitResults;
    };

    const getHealthProfileForm = async (kitcode,jotform_url) => {
        try {
            if(jotform_url){
                window.open(jotform_url, "_blank");
            }else {
                let res = await connectionInstance.post(
                    `${process.env.REACT_APP_API_URL}/healthprofile/${kitcode}`,
                    {}
                );
                window.open(res.data.jotform_url, "_blank");
            }
        } catch (ex) {
            console.log(ex);
            const res = await getHealthProfileStatus(kitcode)
            if(res && res.data){
                window.open(res.data.jotform_url, "_blank");
            }
        }
    };

    const openHealthProfileForm = (kitCode) => {
        window.open(`${process.env.REACT_APP_REDIRECT_URI}/healthprofile-form/${kitCode}`)
    }

    function setKitInfoObj(kit) {
        let infoObj = {
            title: 'ACTION REQUIRED:',
            exclamationIcon: true,
            subtitle: 'Complete Your Skin Health Profile',
            bgColor: 'bg-stone',
            buttonText: 'Start',
            btnClickHandler: () => openHealthProfileForm(kit.kitcode),
            healthProfileCompleted: false
        };
        if (kit.healthprofile_status == HEALTH_PROFILE_STATUS.COMPLETED) {
            infoObj = {
                title: '',
                exclamationIcon: false,
                subtitle: 'Your skin health profile is complete',
                bgColor: 'bg-stone',
                buttonText: 'VIEW',
                healthProfileCompleted: true,
            };
        }
        return infoObj;
    }

    function setKitHVItemsList(kit) {
        let iconsList = structuredClone(statusIconsList),
            {kit_history_status_list} = kit,
            statusLen = kit_history_status_list.length,
            reportReady = false,
            kitFailed = false;
        if(statusLen <= 1) {
            iconsList[0].src = awaiting_swabs_active;
            if(kit_history_status_list[0].status === "EXCEPTION") {
                iconsList[0].description = (kit_history_status_list[0].status_detail || '');
            }
        } else {
            for(let i = statusLen-2; i>=0; i--) {
                ({iconsList, reportReady, kitFailed} =
                    getIconListForMultiStateKit(kit_history_status_list[i].status, i, iconsList, reportReady, kitFailed));
            }
        }
        return {
            horizontalItems: itemsBuilder(iconsList, true),
            verticalItems: itemsBuilder(iconsList),
            reportReady,
            kitFailed
        };
    }

    function getIconListForMultiStateKit(status, i, iconsList, reportReady, kitFailed) {
        switch (status) {
            default:
            case "REGISTERED":
                iconsList[0].src = awaiting_swabs_active;
                iconsList[0].incomplete = true;
                break ;
            case "LAB_INTAKE":
                iconsList[0] = {
                    src: awaiting_swabs_done,
                    description: "Swabs received",
                    completed: true
                };
                iconsList[1].src = swab_received_active;
                iconsList[1].incomplete = true;
                break ;
            case "PROCESSED":
                iconsList[1] = {
                    src: swab_received_done,
                    description: "Swabs processed",
                    completed: true
                };
                iconsList[2].src = microbesSequencing_active;
                iconsList[2].incomplete = true;
                break ;
            case "SEQUENCED":
                iconsList[2] = {
                    src: microbesSequencing_done,
                    description: "Microbes sequenced",
                    completed: true
                };
                iconsList[3].src = reportsReady_active;
                iconsList[3].incomplete = true;
                break ;
            case "COMPLETE":
                reportReady = true;
                setShowKitResult(true);
                iconsList[2] = {
                    src: microbesSequencing_done,
                    description: "Analyzing your skin microbiome",
                    completed: reportReady
                };
                iconsList[3] = {
                    src: reportsReady_done,
                    description: "Reports Prepared",
                    completed: reportReady
                };
                break ;
            case "EXCEPTION":
                kitFailed = true;
                break;
        }
        return {iconsList, reportReady, kitFailed};
    }

    function kitStatusResponseHandler(res) {
        if(!res || !res.data) {
            message.error("Unable to fetch Response from server!");
            return ;
        }
        // res.data = [{"healthprofile_id":null,"kit_history_status_list":[{"created_by":"22","created_on":"Wed, 11 Jan 2023 12:55:56 GMT","status":"REGISTERED","status_detail":null}],"kit_type":"Skin Microbiome Discovery Kit(Facial)","kitcode":"A3W75W7","lab_intake_date":null,"registration_date":"Wed, 11 Jan 2023 12:55:56 GMT","sample_date":null,"infoObj":{"title":"To Do!","exclamationIcon":true,"subtitle":"Complete Your Skin Health Profile","bgColor":"bg-bright-terra","buttonText":"START"}},{"healthprofile_id":34,"kit_history_status_list":[{"created_by":"22","created_on":"Wed, 11 Jan 2023 12:55:56 GMT","status":"LAB_INTAKE","status_detail":null},{"created_by":"3","created_on":"Wed, 18 Jan 2023 11:35:24 GMT","status":"REGISTERED","status_detail":null}],"kit_type":"Dummy Kit","kitcode":"X34LQVJ","lab_intake_date":null,"registration_date":"Wed, 18 Jan 2023 11:35:24 GMT","sample_date":null},{"healthprofile_id":35,"kit_history_status_list":[{"created_by":"22","created_on":"Wed, 11 Jan 2023 12:55:56 GMT","status":"COMPLETE","status_detail":null},{"created_by":"22","created_on":"Wed, 11 Jan 2023 12:55:56 GMT","status":"PROCESSED","status_detail":null},{"created_by":"22","created_on":"Wed, 11 Jan 2023 12:55:56 GMT","status":"LAB_INTAKE","status_detail":null},{"created_by":"3","created_on":"Wed, 18 Jan 2023 11:35:24 GMT","status":"REGISTERED","status_detail":null}],"kit_type":"Dummy Kit 2","kitcode":"X34LQBJ","lab_intake_date":null,"registration_date":"Wed, 18 Jan 2023 11:35:24 GMT","sample_date":null}];
        let kitStatusData = structuredClone(res.data);
        kitStatusData.forEach((kit) => {
            kit.infoObj = setKitInfoObj(kit);
            let hvItemsList = setKitHVItemsList({...kit});
            kit.horizontalItems = hvItemsList.horizontalItems;
            kit.verticalItems = hvItemsList.verticalItems;
            kit.reportReady = hvItemsList.reportReady;
            kit.kitFailed = hvItemsList.kitFailed;
        });
        setKitStatusList(kitStatusData);
    }

    useEffect(() => {
        getKitStatus()
            .then(kitStatusResponseHandler)
            .catch(() => message.error("Something went wrong in Fetching Kit Status!"))
            .finally(() => setFetchingKitStatusList(false));
    }, []);

   

    const viewReportHandler = (kitId) => window.open(`/report/${kitId}`, '_blank');

    function getKitStatusFlow(kitStatusItem) {
        return (
            <>
                <div className='horizontalStepKitFlow'>
                    <Steps labelPlacement='vertical' current={0} className={style.step}
                           items={kitStatusItem.horizontalItems}>
                    </Steps>
                </div>
                <div className='verticalStepKitFlow'>
                    <Steps labelPlacement='horizontal' direction="vertical" current={0}
                           items={kitStatusItem.verticalItems}>
                    </Steps>
                </div>
            </>
        )
    }

    function kitStatusRowsRenderFn(kitStatusItem, index) {
        return (!kitStatusItem.reportReady && !kitStatusItem.kitFailed) ?
            (<div className={style.statusContentWrapper} key={index}>
                <div className={style.statusContent}>
                    <div className={style.titleContainer}>
                        { kitStatusItem.sample_date && (
                        <div className={style.sampleDateContainer}>
                            <Text className={`${style.bodyText} ${style.kitDate}`}>
                                    Sample Date: <span style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}>
                                    {moment(kitStatusItem.sample_date).format(dateFormats.DDSPCMMMMCOMSPCYYYY)}</span>
                            </Text>
                        </div>
                        )}
                        <Text className={style.title}>
                            {kitStatusItem.kit_type} : <span className='font-Neue-medium'>{getFriendlyKitCode(kitStatusItem.kitcode)}</span>
                        </Text>
                    </div>
                    <Row>
                        <Col xs={24} sm={24} md={24} xl={5}>
                            <KitStatusInfo {...kitStatusItem.infoObj} />
                        </Col>
                        <Col xs={0} sm={0} md={0} xl={1}
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <div className={style.verticalSeparator}></div> */}
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={18} className={style.colRight} align="middle">
                            {getKitStatusFlow(kitStatusItem)}
                        </Col>
                    </Row>
                </div>
                <Row className={style.statusContent}>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <div className={style.rowDivider}/>
                    </Col>
                </Row>
            </div>) : (
                <div>
                    <Components.ReportRow 
                        sampleDate = {moment(kitStatusItem.sample_date).format(dateFormats.DDSPCMMMMCOMSPCYYYY)}
                        sampleDateLabel = "Sample Date:"
                        kitNo = {getFriendlyKitCode(kitStatusItem.kitcode)}
                        kitName = {kitStatusItem.kit_type}
                        buttonLabel="View Report"
                        onClick = {() => viewReportHandler(kitStatusItem.kitcode) }
                        isReportError = { kitStatusItem.kitFailed }
                    />
                </div>
            );
    }

     if(fetchingKitStatusList) {
        kitStatusContent = (
            <div className="text-center" style={{padding: "40px"}}>
                <Spin size="large" className={`dark-spinner card-content-spinner`} />
            </div>
        );
    } else {
        kitStatusContent = (
            kitStatusList.length && kitStatusList.map(kitStatusRowsRenderFn)
        );
    }

    return (
        <>
            {!fetchingKitStatusList && !kitStatusList.length ? 
                (
                    <div>
                        <Components.EmptyData title="You don’t have any skin health reports yet" />
                        <FindYourKit />
                        <Components.SuggestManyHealthKits connectionInstance={connectionInstance} />
                    </div>
                )
                : (
                    <>
                        <div className={style.subjectKitContent}>
                            {kitStatusContent}
                        </div>
                        <FindYourKit />
                    </>
                )
            }
        </>
    )
}

export default KitStatus;