import { Outlet } from "react-router-dom";
import AdminComponent from "@ph/admin/src/component";
import "./App.scss";

const LightLayout = () => {
    return (
        <>
            <AdminComponent.AdminHeader />
            <section className="section">
                <div className={"container"}>
                    <Outlet />
                </div>
            </section>
        </>
    );
};

export default LightLayout;