import React, { useEffect, useState } from 'react';
import style from './ValidateKitFormContent.module.scss';
import moment from "moment/moment";
import { Button, Col, DatePicker, Form, Input, message, Row, Spin, Typography } from "antd";
import {
    check_circle_black,
    close_circle,
    close_circle_black,
    close_icon,
    photo_camera,
    pic_uploaded_preview
} from "@ph/common/assets";
import { dateFormats } from "@ph/common/global/Constants";
import WebcamModalComponent from "./WebcamModalComponent";
import { dataURLtoFile } from "@ph/common/global/Helpers";
import ConfirmationModal from "./ConfirmationModal";
import { moveReceivedKitToRegistered, validateKits } from "../AdminPortalAPIService";

const { TextArea } = Input;
const { Text } = Typography;

function ValidateKitFormContent({
    title, record, closeClickHandler, onValidationStatusChanged,
    validationFormContext,
    isEditable = true, isReadOnly = false,
    onPublish = null
}) {
    const [componentDisabled, setComponentDisabled] = useState(false);
    const [swabSamplesStatus, setSwabSamplesStatus] = useState([]);
    const [sampleDate, setSampleDate] = useState('');
    const [oilStripImageUrls, setOilStripImageUrls] = useState([]);
    const [oilStripImageIdx, setOilStripImageIdx] = useState(0);
    const [validationComment, setValidationComment] = useState("");
    // const [validationFromData, setValidationFromData] = useState(null);
    const [imgCaptureModalIsOpen, setImgCaptureModalIsOpen] = useState(false);
    const [imgReadyToUpload, setImgReadyToUpload] = useState(false);
    const [oilStripURLToDelete, setOilStripURLToDelete] = useState({});
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationMsg, setConfirmationMsg] = useState('');
    const [cancelBtnText, setCancelBtnText] = useState('');
    const [proceedBtnText, setProceedBtnText] = useState('');
    const [proceedBtnClass, setProceedBtnClass] = useState('');
    const [isConfirmationInProgress, setIsConfirmationInProgress] = useState(false);
    const [proceedBtnAction, setProceedBtnAction] = useState(() => () => {
    });

    const { kitcode } = record;
    let isOnHoldKit = (validationFormContext === 'onHold');
    let newValidationStatus = '';
    let validationData = new FormData();

    const imgReceiver = (imgSrc) => {
        let type = (oilStripImageIdx === 0) ? 'CK' : 'TZ';
        try {
            setImgReadyToUpload(true);
            let updatedOilStripImgUrls = JSON.parse(JSON.stringify(oilStripImageUrls));
            updatedOilStripImgUrls[oilStripImageIdx][1] =
                dataURLtoFile(imgSrc, `${kitcode}_Oil_Strip_${type}.jpeg`);
            setOilStripImageUrls(updatedOilStripImgUrls);
        } catch (ex) {
            console.log(ex);
        }
    };

    const handleCancel = () => {
        setImgCaptureModalIsOpen(false);
    };

    const closeConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };

    const onDateChange = (date, dateString) => {
        setSampleDate(dateString);
    };

    const onCommentChange = (e) => {
        setValidationComment(e.target.value);
    };

    const dateFormat = 'MM/DD/YYYY';

    const sampleItemClickHandler = (i) => {
        if (componentDisabled) {
            return;
        }
        let newSwabSamplesStatus = [...swabSamplesStatus];
        newSwabSamplesStatus[i][1] = newSwabSamplesStatus[i][1] === "yes" ? "no" : "yes";
        setSwabSamplesStatus(newSwabSamplesStatus);
    };

    const oilStripLabelExtractor = (stripType) => {
        const stripTypeArr = stripType?.split("_");
        return stripTypeArr?.length === 2 ? `${stripTypeArr[0]} ${stripTypeArr[1]}` : "";
    };

    const removeOilStripImg = (i) => {
        let updatedOilStripImgUrls = JSON.parse(JSON.stringify(oilStripImageUrls));
        const oilStripName = updatedOilStripImgUrls[i][1];
        updatedOilStripImgUrls[i][1] = null;
        setOilStripImageUrls(updatedOilStripImgUrls);
        if (imgReadyToUpload) {
            setImgReadyToUpload(false);
            return;
        }
        setOilStripURLToDelete({ "Oil_Strip": oilStripName });
    };

    const openImageCaptureModal = (i) => {
        if (componentDisabled) {
            return;
        }
        setOilStripImageIdx(i);
        setImgReadyToUpload(false);
        setImgCaptureModalIsOpen(true);
    };

    const markKitAsNotReceived = () => {
        setComponentDisabled(true);
        setIsConfirmationInProgress(true);
        moveReceivedKitToRegistered(kitcode)
            .then(() => {
                message.success("Kit unreceived successfully!");
                if (typeof onValidationStatusChanged === 'function')
                    onValidationStatusChanged();
                closeClickHandler();
            })
            .catch((error) => {
                message.error("Error occurred while marking kit as received!");
            })
            .finally(() => {
                setComponentDisabled(false);
                setIsConfirmationInProgress(false);
                closeConfirmationModal();
            });
    };

    const confirmMarkKitAsNotReceived = () => {
        setConfirmationMsg('Are you sure you want to mark as NOT RECEIVED? Doing so will push it to the ‘Registered Kits’ tab.');
        setProceedBtnText('YES, GO AHEAD');
        setCancelBtnText('NO, GO BACK');
        setProceedBtnAction(() => {
            return () => {
                markKitAsNotReceived();
            }
        });
        setIsConfirmationInProgress(false);
        setOpenConfirmationModal(true);
    };

    const handlePublishClick = () => {
        if (record?.is_approved) {
            // if Report is published, resent report ready email
            setConfirmationMsg('Are you sure you want to resend report ready email?');
        } else {
            // Publish report
            setConfirmationMsg(` Are you sure you want to publish report for kitcode : ${record?.kitcode} ?`);
        }
        setProceedBtnText('YES, GO AHEAD');
        setCancelBtnText('NO, GO BACK');
        setProceedBtnAction(() => {
            return () => {
                setIsConfirmationInProgress(true)
                onPublish().finally(res => {
                    setIsConfirmationInProgress(false)
                    setOpenConfirmationModal(false);
                })
            }
        });
        setIsConfirmationInProgress(false);
        setOpenConfirmationModal(true);
    }

    const validateKit = () => {
        setIsConfirmationInProgress(true);
        setImgReadyToUpload(false);
        validateKits(kitcode, validationData)
            .then(() => {
                let successMessage = "Kit samples validated successfully!";
                console.log(newValidationStatus);
                if (newValidationStatus === 'on_hold') {
                    successMessage = "Kit samples put on-hold successfully!";
                } else if (newValidationStatus === 'rejected') {
                    successMessage = "Kit samples rejected successfully!";
                }
                message.success(successMessage);
                if (typeof onValidationStatusChanged === 'function') {
                    onValidationStatusChanged();
                }
                closeClickHandler();
            })
            .catch((error) => {
                let failureMessage = "Error occurred while validating kit samples!";
                if (newValidationStatus === 'on_hold') {
                    failureMessage = "Error occurred while putting the kit on-hold!";
                } else if (newValidationStatus === 'rejected') {
                    failureMessage = "Error occurred while rejecting the kit!";
                }
                message.error(failureMessage);
            }).finally(() => {
                setIsConfirmationInProgress(false);
                closeConfirmationModal();
            });
    };

    const prepareValidationData = (skipValidationCheck, validationStatus) => {
        validationData = new FormData();
        let isFormInvalid = false;
        validationData.append('delete_oil_strip_urls', JSON.stringify(oilStripURLToDelete));
        if (!sampleDate) {
            isFormInvalid = true;
        }
        validationData.append("sample_date", sampleDate);

        let swab_samples_status = {};
        swabSamplesStatus.forEach((sample) => {
            swab_samples_status[sample[0]] = sample[1];
            if (sample[1] === 'no') {
                isFormInvalid = true;
            }
        });
        validationData.append("swab_samples_status", JSON.stringify(swab_samples_status));

        oilStripImageUrls.forEach((stripImg) => {
            if (stripImg[1]) {
                validationData.append('file', stripImg[1]);
            }
        });

        validationData.append("comment", validationComment);

        validationStatus = validationStatus || 'valid';
        setCancelBtnText('RE-ASSESS KIT');
        if (!skipValidationCheck && isFormInvalid) {
            validationStatus = 'on_hold';
            setConfirmationMsg('Are you sure you want to put the kit on-hold? Doing so will notify the customer support to proceed accordingly.');
            setProceedBtnText('YES, ON-HOLD');
            setProceedBtnClass('ph-btn-danger');
        } else if (validationStatus === 'rejected') {
            setConfirmationMsg('Are you sure you want to save the changes and reject the kit? Doing so will notify the admin to proceed accordingly.');
            setProceedBtnText('YES, REJECT');
            setProceedBtnClass('ph-btn-danger');
        } else {
            setConfirmationMsg('All the required fields are entered successfully. The kit is considered valid. Are you sure you want to move ahead?');
            setProceedBtnText('YES, GO AHEAD');
            setProceedBtnClass('ph-btn-success');
        }
        newValidationStatus = validationStatus;
        validationData.append("validation_status", validationStatus);
        setProceedBtnAction(() => {
            return () => {
                validateKit();
            }
        });
        setIsConfirmationInProgress(false);
        setOpenConfirmationModal(true);
    };

    useEffect(() => {
        if (record.sample_date) {
            setSampleDate(moment(record.sample_date).format(dateFormat));
        }
        setSwabSamplesStatus(Object.entries(record.swab_samples_status));
        setOilStripImageUrls(Object.entries(record.oil_strip_image_urls));
        setValidationComment(record.comments || '');
        setOilStripURLToDelete({});
        if (typeof isEditable === "boolean") {
            setComponentDisabled(!isEditable);
        }
        if (typeof isReadOnly === "boolean") {
            setComponentDisabled(isReadOnly);
        }
        return () => {
            setSwabSamplesStatus([]);
            setOilStripImageUrls([]);
            setValidationComment('');
            setOilStripURLToDelete({});
        };
    }, [record]);

    return (
        <>
            <div className={style.expandedRow}>
                <div className={style.expandedRowArrow}>
                    <div className={style.expandedRowArrowTR}></div>
                </div>
                <Row>
                    <Col span={12}>{title}</Col>
                    <Col span={12} className='text-right'>
                        <img className={`${style.closeIcon} hand-cursor`} src={close_icon} alt='close'
                            onClick={closeClickHandler} />
                    </Col>
                </Row>
                <Form disabled={componentDisabled}>
                    <Row className={style.validateFormContainer}>
                        <Col span={5}>
                            <Text className={`${style.label} body-txt-md`}>SAMPLE COLLECTION DATE</Text>
                            <Form.Item className={style.datepickerContainer}>
                                <DatePicker value={sampleDate ? moment(sampleDate, dateFormat) : ''}
                                    onChange={onDateChange}
                                    format={dateFormat} placeholder={dateFormat} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Text className={`${style.label} body-txt-md`}>SAMPLES RECEIVED</Text>
                            <Text className={`${style.helpText}`}>
                                (Click on the samples to deselect and mark them missing if any)
                            </Text>
                            <Row className={style.samplesContainer}>
                                {swabSamplesStatus.map(([name, availability], i) => {
                                    let isSelected = (availability === "yes");
                                    return (<Col span={6} key={i} className={isSelected && style.selected}
                                        onClick={() => {
                                            sampleItemClickHandler(i);
                                        }}>
                                        <Text className={`${style.sampleLabel} body-txt-lg`}>{name}</Text>
                                        <img src={isSelected ?
                                            check_circle_black : close_circle} alt='status' />
                                    </Col>)
                                })}
                            </Row>
                        </Col>
                        <Col span={10}>
                            <Text className={`${style.label} body-txt-md`}>IMAGES OF OIL STRIPS RECEIVED</Text>
                            <Row className={style.oilStripContainer} gutter={[0, 16]}>
                                {oilStripImageUrls.map(([label, url], i) => {
                                    let urlIsAvailable = (url !== null);
                                    return (<React.Fragment key={i}>
                                        <Col span={10} key={`label_${i}`}
                                            className={`${style.labelContainer} ${urlIsAvailable && style.selected}`}>
                                            <Text className={`${style.stripLabel} body-txt-lg text-uppercase`}>
                                                {oilStripLabelExtractor(label)}
                                            </Text>
                                        </Col>
                                        <Col span={14} key={`imgUploader_${i}`}>
                                            <img src={photo_camera} alt='cam' className='hand-cursor'
                                                onClick={() => openImageCaptureModal(i)} />
                                            <div className={style.oilStripImageActionContainer}>
                                                {
                                                    urlIsAvailable ?
                                                        <>
                                                            <div className={style.imgPreviewContainer}>
                                                                <img src={pic_uploaded_preview}
                                                                    className={style.previewIcon}
                                                                    alt='preview' />
                                                                <img src={close_circle_black}
                                                                    className={style.deleteOilStripImageIcon}
                                                                    alt='delete'
                                                                    onClick={() => removeOilStripImg(i)} />
                                                            </div>
                                                            <Text italic={true}
                                                                className={`${style.lightText} body-txt-lg`}>
                                                                {(imgReadyToUpload) ? 'Ready to be' : ''} Uploaded
                                                            </Text>
                                                        </> :
                                                        <>
                                                            <Text strong={true} underline={true}
                                                                className={`${style.lightText} body-txt-lg hand-cursor`}
                                                                onClick={() => openImageCaptureModal(i)}>
                                                                Take Photo
                                                            </Text>
                                                            <div className={`${style.imgPreviewContainer} invisible`}>
                                                                <img src={pic_uploaded_preview}
                                                                    className={style.previewIcon}
                                                                    alt='preview' />
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </Col>
                                    </React.Fragment>)
                                })}
                            </Row>
                        </Col>
                    </Row>
                    <Row className={style.validationStatusEmailContainer}>
                        {record.validated_by && record.registration_date &&
                            <Col span={6}>
                                <Text className={style.lightText}>REGISTERED ON : <span
                                    className='body-txt-lg'>{moment(record.registration_date).format(dateFormats.MMSLADDSLAYYYYSPCATSPCHHCOLMM)}</span>
                                </Text>
                            </Col>}
                        <Col span={8}>
                            <Text className={style.lightText}>RECEIVED BY : <span
                                className='body-txt-lg'>{record.received_by}</span>
                            </Text>
                        </Col>
                        {record.validated_by &&
                            <Col span={8}>
                                <Text className={style.lightText}>VALIDATED BY : <span
                                    className='body-txt-lg'>{record.validated_by}</span>
                                </Text>
                            </Col>}
                        {record?.is_approved_at && (
                            <Col span={8}>
                                <Text className={style.lightText}>PUBLISHED ON : <span
                                    className='body-txt-lg'>{moment(record.is_approved_at).format(dateFormats.MMSLADDSLAYYYYSPCATSPCHHCOLMM)}</span>
                                </Text>
                            </Col>
                        )}
                        {!record.validated_by &&
                            <Col span={6} offset={9} className='text-right'>
                                <Text underline={true} strong={true}
                                    className={`${style.lightText} body-txt-md hand-cursor`}
                                    onClick={confirmMarkKitAsNotReceived}>MARK AS NOT RECEIVED</Text>
                            </Col>}

                    </Row>
                    <Form.Item>
                        <TextArea rows={4} placeholder='Your comments here (if any)'
                            value={validationComment} onChange={onCommentChange} />
                    </Form.Item>
                    <Form.Item
                        className={`text-center ${(componentDisabled || isOnHoldKit) ? 'hide' : ''}`}>
                        <Button type="secondary" onClick={() => prepareValidationData()}>PROCEED</Button>
                    </Form.Item>
                    <Form.Item
                        className={`text-center ${(componentDisabled || !isOnHoldKit) ? 'hide' : ''}`}>
                        <Button className={'ph-btn ph-btn-success no-border'}
                            onClick={() => prepareValidationData(true, 'valid')}>VALIDATE</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Button className={'ph-btn ph-btn-danger no-border'}
                            onClick={() => prepareValidationData(true, 'rejected')}>REJECT</Button>
                    </Form.Item>
                </Form>
                {onPublish && (
                    <Form>
                        <Form.Item
                            className={`text-center ${(false) ? 'hide' : ''}`}>
                            <Button className={style.reportButton}
                                onClick={() => window.open(`/report/${record.kitcode}`, '_blank')}>VIEW REPORT</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button className={'ph-btn'}
                                onClick={handlePublishClick}>{record?.is_approved ? "RESEND EMAIL" : "PUBLISH"}</Button>
                        </Form.Item>
                    </Form>
                )}

            </div>

            <WebcamModalComponent open={imgCaptureModalIsOpen} onClose={handleCancel} imgReceiver={imgReceiver} />

            <ConfirmationModal openConfirmationModal={openConfirmationModal}
                closeConfirmationModal={closeConfirmationModal}
                confirmationMsg={confirmationMsg}
                proceedBtnAction={proceedBtnAction}
                cancelBtnText={cancelBtnText}
                proceedBtnText={proceedBtnText}
                proceedBtnClass={proceedBtnClass}
                isConfirmationInProgress={isConfirmationInProgress}
                modalTop={"30%"}
            ></ConfirmationModal>
        </>
    );
}

export default ValidateKitFormContent;