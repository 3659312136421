import SkinDiscoveryReportDetailsCont from "./SkinDiscoveryReportDetailsCont";
import styles from "../Report.module.scss";

const SampleReportDetailsBox = () => {
  return (
    <div className={`${styles.topSection} ${styles.tile1}`} style={{"height": "auto"}}>
      <div className={`h5 text-light`}>
        WHAT DO YOU GET FROM THE SKIN ASSESSMENT REPORT?
      </div>
      <SkinDiscoveryReportDetailsCont/>
    </div>
  );
};

export default SampleReportDetailsBox;
