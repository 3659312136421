import React from 'react';
import {Space, Spin} from "antd";

function FullPageLoader({mTop = '60px'}) {
    return (
        <div style={{ marginTop: mTop, textAlign: 'center' }}>
            <Space size="middle">
                <Spin size="large" />
            </Space>
        </div>
    );
}

export default FullPageLoader;