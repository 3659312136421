import React, { useState, useEffect } from 'react'
import { Typography } from 'antd';
import style from './rangeSlider.module.scss';

const { Text } = Typography;

const RangeSlider = (props) => {
    const { rangeMin, rangeMax, rangeVal, inputId, outputId, outputContainerId } = props;
    const [disableSlider, setDisableSlider] = useState(false);

    useEffect(() => {
        showVal(rangeMin);
        if (rangeVal) {
            setTimeout(() => {
                showVal(rangeVal);
            }, 1000);
        }
    }, []);

    const showVal = (value) => {
        // setRangeVal(value);
        var output = document.getElementById(outputId);
        var outputContainer = document.getElementById(outputContainerId);
        var input = document.getElementById(inputId);
        var sliderWidth = input.getBoundingClientRect().width;
        outputContainer.setAttribute('style', 'width: ' + (Number(sliderWidth)) + 'px');
        var outputWidth = output.getBoundingClientRect().width;
        
        const newVal = ((Number(value) - Number(rangeMin)) * 100) / (Number(rangeMax) - Number(rangeMin));
        output.setAttribute('style', 'left: ' + `calc(${newVal}% - ${(Number(outputWidth) * newVal / 100)}px)`);
        setDisableSlider(true);
    };

    return (
        <div className={style.slideContainer}>
            <div className={style.textContainer}>
                <Text className={style.smallText} style={{ position: 'absolute', left: '10px' }}>Poor</Text>
                <Text className={style.smallText} style={{ position: 'absolute', right: '10px' }}>Excellent</Text>
            </div>
            <div className={style.inputContainer}>
                {/* <div className={style.leftBar}>
                    <Text className={style.smallText}>Poor</Text>
                    <div className={style.verticalBar}></div>
                </div>
                <div className={style.rightBar}>
                    <Text className={style.smallText}>Excellent</Text>
                    <div className={style.verticalBar}></div>
                </div> */}
                <input className={style.slider} disabled={disableSlider} type="range"
                       min={rangeMin} max={rangeMax} value={rangeVal} id={inputId} readOnly={true}
                />
            </div>
            <div id={outputContainerId} className={style.outputContainer}>
                <output htmlFor={inputId} id={outputId}>{rangeVal}</output>
            </div>
        </div>
    )
}

export default RangeSlider;