import { useEffect, useState } from "react";
import { Typography, Space } from "antd";
import style from "./ProtocolHeader.module.scss";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useScrollingUp from '../UseScrollingUp';
import { useNavigate } from 'react-router-dom';
import { close_icon } from "@ph/common/assets/index";

const { Text, Link, Title } = Typography;

const ProtocolHeader = (props) => {
    const scrolled = useScrollingUp();
    let location = useLocation();
    let navigate = useNavigate();
    const [currentPath, setcurrentPath] = useState(location.pathname);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const user = useSelector((state) => state.userData.userData);

    useEffect(() => {
        setcurrentPath(location.pathname);
    }, [location]);

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    function handleGoBack() {
        navigate(-1);
    }

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('#desktopHeader');
        if (!header) return;
        const scrollTop = window.scrollY;
        if (header && header.classList) {
            scrollTop >= 250 ?
                header.classList.add(style.headerContainer) :
                header.classList.remove(style.headerContainer);
        }
    };

    return (
        <>
            <header className={scrolled ? style.stickyHeader + " " + style.headerContainer : style.headerContainer}>
                <div className="viewBoxWidth smallViewBoxWidth" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <div className={style.buttonSection}>
                        <Space>
                            <img src={close_icon} className={style.cartIcon} alt=''
                                onClick={handleGoBack} />
                        </Space>
                    </div>
                </div>
            </header>
        </>
    );
};

export default ProtocolHeader;
