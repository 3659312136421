import React from "react";
import style from "./PhNavCard.module.scss";
import { Typography, Card } from "antd";
import { Link } from 'react-router-dom';

const { Text } = Typography;

const PhNavCard = ({
  children,
  title = "",
  titleClass = "",
  subTitle = "",
  subTitleClass = "",
  cardClass = "",
  cardLink = "#",
  disable = false
}) => {
  return (
    <Link to={cardLink} style={{display:"flex",width:"100%",height:"100%"}}>
      <Card className={`${style.box} ${cardClass} ${disable ? '' : style.navHover}`} disable={true}>
        {/* <div className={`${style.navImage} ${disable ? style.disableImg : ''}`}>
          {disable && (
            <span className="fr body-txt-lg text-bright-terra">COMING SOON</span>
          )}
        </div> */}
        <div className={style.titleContainer}>
          <div className={`${style.navTitle} ${titleClass} ${disable ? style.disableText : ''}`}>{title}</div>
          {disable && (
            <span className={`${style.commingSoon} body-txt-lg text-bright-terra`}>COMING SOON</span>
          )}
        </div>
        
        <div className={` note-2 ${style.navSubTitle} ${subTitleClass} ${disable ? style.disableText : ''}`}>{subTitle}</div>
      </Card>
    </Link>
  );
};

export default PhNavCard;
