import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Cookies from 'universal-cookie';
import FullPageLoader from "@ph/common/components/Global/FullPageLoader/FullPageLoader";

const RedirectAfterSignUp = () => {
    const cookies = new Cookies();
    let navigate = useNavigate();

    useEffect(() => {
        // const hasPage = AuthToken.getToken('page');
        const pageToRedirect = cookies.get('pageToRedirect');
        if (pageToRedirect === "kit-user-guide") {
            navigate('/qr-login');
        } else if (pageToRedirect === "dashboard") {
            navigate('/');
        } else {
            window.location = `${process.env.REACT_APP_SHOPIFY_URL}/account`;
        }
    }, []);

    return (
        <FullPageLoader />
    )
}
export default RedirectAfterSignUp